import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import DeedOfContract from "assets/replicas/lord-of-the-rings/lotr-deed-of-contract";
import { Link } from "react-router-dom";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 27, month: 11, year: 2020 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This prop is unique in a way that it doesn't actually exist in any of
        the films - it was created by me. When I was working on the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + DeedOfContract.id}
          target="_blank"
        >
          Deed of Contract
        </Link>
        , which represents the contract between Bilbo and the Company in the
        film, I thought it would be great to also have the book version of the
        contract. In the book, the Company leaves in the morning before Bilbo
        wakes up, leaving only this letter explaining the situation.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=zWMe459C-ks" />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Since this letter never appeared in any of the films, I knew from the
        start that I'd have to design the entire prop myself - including the
        text. That was before I found out that there actually is a version of
        the letter from none other than J.R.R. Tolkien himself! He wanted to
        include it in the book, but it never made it into the final publication.
        No matter, I felt that this was a great opportunity to bring the letter
        to life and design it as if I was tasked to create it as a prop for the
        film. Here are the main features:
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Written by hand`,
            description: `The letter was written by my hand. To get as close as possible to the original letter by J.R.R Tolkien, this approach required many re-writings. However, I think these were definitely worth the time.`,
          },
          {
            title: `High-quality handmade paper`,
            description: `The paper I used for this prop is an expensive handmade paper from a historical paper mill - the only one of its kind in my country, and the oldest in Europe. They've been making paper since 1590s and managed to keep the traditional production of paper to this day. It is commonly used for important/diplomatic documents (my master's degree certificate is also printed on their paper). If there was a paper mill in Middle-earth, I think this would be the closest thing to it.`,
          },
          {
            title: `High-quality print`,
            description: `The letter was printed on a high-end professional printer, using a high-quality water-resistant ink. The result looks quite natural, there's no pixelation or any other flaws that are often associated with printing.

      `,
          },
          {
            title: `Authentic aging process`,
            description: `The letter was (reasonably) aged. I needed to give the paper a bit more "character", to make it into something that could be used as a film prop. However, I tried to keep it subtle, because the letter is not supposed to be hundreds of years old.`,
          },
          {
            title: `Real wax seal`,
            description: `I usually prefer paper props that are more three-dimensional, and since a simple letter is just too flat for my taste, I've decided to make it more interesting by sealing it with a bit of string and a wax seal. I intentionally didn't allow the wax to stick to the paper, so the letter can be opened and read without the need to damage the prop.`,
          },
        ]}
      />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
