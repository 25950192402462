import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "daily-prophet-hp5-dumbledore-daft-or-dangerous",
  name: `The Daily Prophet\n'Dumbledore: Daft or Dangerous?'`,
  origin: "Harry Potter and the Order of the Phoenix",

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
