import "./index.css";

import React, { useEffect, useState } from "react";
import { getSavedPassword, isValidEncodedPassword, requiresAuth } from "utils";
import store, { persistor } from "./store";

import App from "App";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "styles/GlobalStyle";
import LoginPage from "pages/Login";
import { PersistGate } from "redux-persist/lib/integration/react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import ScrollRestoration from "react-scroll-restoration";
import SimpleReactLightbox from "simple-react-lightbox";
import { ThemeProvider } from "styled-components";
import breakpoint from "styles/breakpoints";
import theme from "styles/theme";

const AppWrapper = () => {
  let [isAuthorized, setIsAuthorized] = useState<boolean>(!requiresAuth());

  useEffect(() => {
    const savedPassword = getSavedPassword();
    if (savedPassword && isValidEncodedPassword(savedPassword)) {
      onLogin();
    }
  }, []);

  const onLogin = () => {
    setIsAuthorized(true);
  };

  return !isAuthorized ? <LoginPage onLogin={onLogin} /> : <App />;
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollRestoration />
          <ThemeProvider theme={{ ...theme, breakpoint }}>
            <SimpleReactLightbox>
              <GlobalStyle />
              <AppWrapper />
            </SimpleReactLightbox>
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
