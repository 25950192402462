import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";
import { ProductsIncludeBundles, getProducts } from "utils";

import ButtonLink from "components/Interaction/ButtonLink";
import ContentBox from "components/ContentBox";
import ProductList from "pages/E-Shop/ProductList";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div``;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const DetailRelatedProducts = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  if (props.product.isBundle) {
    return null;
  }

  const products = getProducts({
    includeBundles: ProductsIncludeBundles.exclude,
    excludeProductId: props.product?.id,
    categoryId: (props.product as ProductType)?.categories?.map((c) => c.id),
    groupId: (props.product as ProductType)?.groups?.map((g) => g.id),
    limit: {
      maxCount: 3,
      randomized: true,
    },
  });

  if (!products?.length) {
    return null;
  }

  return (
    <ContentBox title="Similar Products">
      <Wrapper>
        <ProductList
          products={products}
          productItem={{
            wide: products?.length <= 1,
          }}
        />

        <LinkWrapper>
          <ButtonLink to={routes.eshop.slug}>More Products</ButtonLink>
        </LinkWrapper>
      </Wrapper>
    </ContentBox>
  );
};

export default DetailRelatedProducts;
