import AnimatedSprite, {
  AnimatedSpritePlay,
} from "components/Media/AnimatedSprite";
import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
  SpriteType,
} from "types";
import styled, { css, keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";

import ContentBox from "components/ContentBox";
import { HandPointer } from "@styled-icons/fa-solid/HandPointer";

const ViewWrapper = styled.div`
  --is-hovered: 0;
  --overlay-opacity: 0.1;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  transition: all 0.1s ease;

  &:hover {
    filter: brightness(1.1);
    --is-hovered: 1;
    --overlay-opacity: 0.7;
  }

  ${(props) => props.theme.breakpoint.S} {
    margin-bottom: 20px;
  }
`;

const View = styled.div`
  /*   
  transform: scale(1.25);
  ${(props) => props.theme.breakpoint.L} {
    transform: scale(1);
  } 
   */
`;

const Background = styled.div`
  --grid-size-x: 30px;
  --grid-size-y: var(--grid-size-x);
  --grid-point-size: 1px;
  --grid-color: ${(props) => props.theme.white}15;

  --fade-start: 20%;
  --fade-end: 80%;
  --fade-width: 100%; /* Width of the fade ellipse */
  --fade-height: 80%; /* Height of the fade ellipse */

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-position: center;
  background-size: var(--grid-size-x) var(--grid-size-y);
  background-image: radial-gradient(
    circle,
    var(--grid-color) var(--grid-point-size),
    rgba(0, 0, 0, 0) 1px
  );

  -webkit-mask-image: radial-gradient(
    ellipse var(--fade-width) var(--fade-height),
    rgba(0, 0, 0, 1) var(--fade-start),
    rgba(0, 0, 0, 0) var(--fade-end)
  );
  mask-image: radial-gradient(
    ellipse var(--fade-width) var(--fade-height),
    rgba(0, 0, 0, 1) var(--fade-start),
    rgba(0, 0, 0, 0) var(--fade-end)
  );
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
`;

const Overlay = styled.div<{ isVisible: boolean }>`
  pointer-events: none;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  transition: all 0.3s ease-out;

  ${(props) =>
    props.isVisible
      ? css`
          opacity: var(--overlay-opacity);
        `
      : css`
          opacity: 0;
        `}
`;

const HintIcon = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.white};

  svg {
    z-index: 10;
    width: var(--icon-size);
    height: var(--icon-size);
  }
`;

const HintHighlightAnimation = keyframes`
  to { 
    transform: scale(1.3);
  }
`;

const HintHighlight = styled.div`
  z-index: 1;
  position: absolute;

  width: var(--highlight-size);
  min-width: var(--highlight-size);
  max-width: var(--highlight-size);
  height: var(--highlight-size);
  min-height: var(--highlight-size);
  max-height: var(--highlight-size);

  background: ${(props) => props.theme.black};
  border: 1px solid ${(props) => props.theme.white}88;
  border-radius: 50%;

  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-name: ${HintHighlightAnimation};
`;

const HintText = styled.div`
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${(props) => props.theme.white};
  background: ${(props) => props.theme.black};
  padding: 5px 10px;
  border-radius: 4px;

  ${(props) => props.theme.breakpoint.S} {
    font-size: 9px;
  }
`;

const Hint = styled.div<{ isHidden: boolean; isMinified: boolean }>`
  --icon-size: 14px;
  --highlight-size: 30px;

  pointer-events: none;

  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  opacity: calc(0.7 - var(--is-hovered) * 0.6);

  transition: all 0.2s ease;

  ${(props) => props.theme.breakpoint.S} {
    --icon-size: 12px;
    --highlight-size: 24px;
    gap: 8px;
  }

  ${(props) =>
    props.isMinified
      ? css`
          left: 90%;
          right: 0px;
          bottom: -10px;

          ${(props) => props.theme.breakpoint.S} {
            left: 80%;
            bottom: -20px;
          }
          ${(props) => props.theme.breakpoint.XS} {
            bottom: -30px;
          }

          ${HintText} {
            opacity: 0;
          }
        `
      : css`
          left: 0;
          right: 0;
          bottom: 10%;

          ${(props) => props.theme.breakpoint.M} {
            bottom: 6%;
          }
          ${(props) => props.theme.breakpoint.S} {
            bottom: -10px;
          }
          ${(props) => props.theme.breakpoint.XS} {
            bottom: -24px;
          }
        `}

  ${(props) =>
    props.isHidden &&
    css`
      opacity: 0;
      transform: translateY(20px);
    `}
`;

const DetailExplodedView = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  const [play, setPlay] = useState<AnimatedSpritePlay>(undefined);
  const [overlayIsVisible, setOverlayIsVisible] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);
  const [hintIsMinified, setHintIsMinified] = useState(false);
  const wasClickedRef = useRef(wasClicked);
  const anchorRef = useRef<HTMLDivElement>();

  let explodedView = (props.product as ProductType)?.explodedView;
  const sprite: SpriteType = explodedView;

  const frameDuration_Milliseconds = 30;

  useEffect(() => {
    wasClickedRef.current = wasClicked;
  }, [wasClicked]);

  const onClick = () => {
    setPlay(
      play === AnimatedSpritePlay.once
        ? AnimatedSpritePlay.onceReversed
        : AnimatedSpritePlay.once
    );
    setWasClicked(true);
  };

  //
  // SPRITE
  //
  const [spriteLoaded, setSpriteLoaded] = useState(false);

  const onFirstFrame = () => {};

  const onFirstFrameEnd = () => {};

  const onLastFrame = () => {
    setOverlayIsVisible(true);
  };
  const onLastFrameEnd = () => {
    setOverlayIsVisible(false);
  };

  //
  // SCROLL
  //
  useEffect(() => {
    if (spriteLoaded) {
      const handleScroll = () => {
        if (anchorRef.current) {
          const rect = anchorRef.current.getBoundingClientRect();
          const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

          if (!wasClickedRef.current && isVisible) {
            setPlay(AnimatedSpritePlay.once);
            setHintIsMinified(true);
          }
        }
      };

      handleScroll();
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spriteLoaded]);

  //
  // OVERLAY
  //
  const renderOverlay = () => {
    return (
      <Overlay isVisible={overlayIsVisible}>
        <img src={explodedView.labels} alt={props.product?.name} />
      </Overlay>
    );
  };

  //
  // RENDER
  //
  if (!explodedView) {
    return null;
  }

  return (
    <ContentBox title="Assembly Diagram" allowOverflow noPadding>
      <ViewWrapper onClick={onClick}>
        <Background />

        <View ref={anchorRef}>
          <AnimatedSprite
            src={sprite}
            alt={props.product.name}
            animation={{
              play: play,
              frameDuration_Milliseconds,
            }}
            loadingStyle={{
              cssDefault: css`
                transition: all 0.2s ease-out;
              `,
              cssBeforeLoad: css`
                opacity: 0;
                transform: scale(0.5);
              `,
            }}
            onLoad={() => setSpriteLoaded(true)}
            onFirstFrame={onFirstFrame}
            onFirstFrameEnd={onFirstFrameEnd}
            onLastFrame={onLastFrame}
            onLastFrameEnd={onLastFrameEnd}
            renderOverlay={explodedView.labels ? renderOverlay : undefined}
          />
        </View>

        <Hint
          isHidden={!spriteLoaded || wasClicked}
          isMinified={hintIsMinified}
        >
          <HintIcon>
            <HandPointer />
            <HintHighlight />
          </HintIcon>
          <HintText>Show Exploded View</HintText>
        </Hint>
      </ViewWrapper>
    </ContentBox>
  );
};

export default DetailExplodedView;
