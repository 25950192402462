import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 23, month: 7, year: 2019 }),
  version: "1",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is the very first version of the Deed of Contract. It has many
        shortcomings, that just couldn't be avoided at the time of making it.
        The most noticeable is probably the scale - this version of the contract
        is actually a bit smaller than the original prop, because I had limited
        size that I could print. This is, fortunately, no longer a problem, so
        version 2 is 1:1 with the original. The next difference is the paper -
        while I used an expensive hand-made paper from a local mill, it was not
        the same as the original. Again, this was fixed in version 2. There are
        many other differences, but these are the main ones.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=LRx2pCjjcSM" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
