import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "sw-dl44-luke-bespin",
  name: `DL-44 Blaster\nLuke Bespin (Denix Conversion)`,
  origin: "Star Wars: Episode 5 - The Empire Strikes Back",

  category: replicaCategories.starWars,

  versions: [Version1],
};

export default data;
