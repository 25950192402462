import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import background from "assets/background/background_planet-01_top.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spacer = styled.div`
  height: 6vh;
`;

const SubTitle = styled.h3``;

const Title = styled.h1`
  padding: 0px;
  margin: 0px;
`;

const Intro = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background} mobileScale={1.2}>
      <Wrapper>
        <NameContainer>
          <SubTitle>portfolio</SubTitle>
          <Title>Jan Hamernik</Title>

          <Spacer />

          <ButtonScrollToSection onClick={props.onNavClick} />
        </NameContainer>
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Intro;
