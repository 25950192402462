import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1yVZBAZJKGWztygXiMcpwL1yyehhZ98We/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Mark-5-3D-Model",
  name: "Mark 5 3D Model",
  description: `The product contains three .obj files, which can be imported to common 3d software, such as 3Ds Max, Cinema 4D, Maya, Blender etc. Each .obj file has a 3d model of the suit with different subdivision level. The model is great for HUD schematics, pop-ups, blueprints, diagnostics, holographics, graphical user interfaces and much more! No materials or rigs included, those are in the 'Suit' version.`,

  price: 29,
  previousPrice: 39,
  softwareRequired: [],

  categories: [productCategories.file3DModel],
  groups: [productGroups.ironMan],
  license: licenses.editorial,

  images: gallery,
  videos: [
    {
      src: "https://www.youtube.com/watch?v=MMarOTLnx0M",
      note: "This product contains only the 3D Model presented in this video, not the rig, materials or textures.",
    },
  ],

  contents: [
    {
      text: "3D Model - Mark 5 Suit - subdivision level 0",
      format: fileFormats.obj,
    },
    {
      text: "3D Model - Mark 5 Suit - subdivision level 1",
      format: fileFormats.obj,
    },
    {
      text: "3D Model - Mark 5 Suit - subdivision level 2",
      format: fileFormats.obj,
    },
    {
      text: "about",
      isDivider: true,
    },
    { text: "No rig, textures or materials included" },
  ],
  tags: ["3D Model"],
};

export default data;
