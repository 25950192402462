import { useState } from 'react'
import { routes } from 'config'

import LayoutSections from 'layouts/LayoutSections'

import Intro from './Sections/Intro'
import Products from './Sections/Products'
import ProductsAdditional from './Sections/ProductsAdditional'
import About from './Sections/About'
import Demo from './Sections/Demo'

const AAEHUDFactory = () => {
  const [activeId, setActiveId] = useState(routes.aaeHudFactory.anchors.intro)
  const [scrollId, setScrollId] = useState(null)

  const sections = [
    {
      label: 'Intro',
      component: (
        <Intro
          onNavClick={() => setScrollId(routes.aaeHudFactory.anchors.products)}
        />
      ),
      id: routes.aaeHudFactory.anchors.intro,
    },
    {
      label: 'HUDs',
      component: (
        <Products
          onNavClick={() =>
            setScrollId(routes.aaeHudFactory.anchors.productsAdditional)
          }
        />
      ),
      id: routes.aaeHudFactory.anchors.products,
    },
    {
      label: 'Additional',
      component: (
        <ProductsAdditional
          onNavClick={() => setScrollId(routes.aaeHudFactory.anchors.about)}
        />
      ),
      id: routes.aaeHudFactory.anchors.productsAdditional,
    },
    {
      label: 'About',
      component: (
        <About
          onNavClick={() => setScrollId(routes.aaeHudFactory.anchors.demo)}
        />
      ),
      id: routes.aaeHudFactory.anchors.about,
    },
    {
      label: 'Demo',
      component: <Demo />,
      id: routes.aaeHudFactory.anchors.demo,
    },
  ]

  return (
    <LayoutSections
      sections={sections}
      activeId={activeId}
      setActiveId={setActiveId}
      scrollId={scrollId}
      setScrollId={setScrollId}
    />
  )
}

export default AAEHUDFactory
