import styled, { css } from "styled-components";

import ContentBox from "components/ContentBox";
import { Equals } from "@styled-icons/fa-solid/Equals";
import { Plus } from "@styled-icons/boxicons-regular/";
import { ProductBundleType } from "types";
import ProductItem from "components/ProductItem";

const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-left: -40px;
`;

const ProductListItem = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ invisible: boolean }>`
  svg {
    width: 30px;
    margin-right: 20px;
    opacity: 0.4;
    ${({ invisible }) =>
      invisible &&
      css`
        opacity: 0;
      `}
  }
`;
const EqualsWrapper = styled.div`
  width: 50px;
  svg {
    width: 18px !important;
  }
`;

const DetailBundle = (props: { product: ProductBundleType }) => {
  const product = props.product;

  return (
    <ContentBox title="What's Inside?">
      <ProductList>
        {product.products.map((product, i) => (
          <ProductListItem key={i} invisible={i === 0}>
            <IconWrapper invisible={i === 0}>
              <Plus />
            </IconWrapper>
            <ProductItem product={product} thin />
          </ProductListItem>
        ))}
        <ProductListItem>
          <EqualsWrapper>
            <IconWrapper>
              <Equals />
            </IconWrapper>
          </EqualsWrapper>
          <ProductItem product={product} thin />
        </ProductListItem>
      </ProductList>
    </ContentBox>
  );
};

export default DetailBundle;
