import styled from 'styled-components'

const Title = styled.h2`
  margin-top: 10vh;
  margin-bottom: 6vh;
`

const ReplicaBigTitle = (props: { title: string }) => {
  return <Title>{props.title}</Title>
}

export default ReplicaBigTitle
