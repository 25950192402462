import { UserAstronaut, UserGraduate } from "@styled-icons/fa-solid/";

import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule_2.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  max-width: 720px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Paragraph = styled.p`
  text-align: justify;
`;
const Icon = styled.div`
  svg {
    margin-top: 3vh;
    margin-bottom: 0.7vh;
    width: 20px;
    color: ${(props) => props.theme.white}AA;
  }
`;

const Spacer = styled.div`
  height: 5vh;
`;
const ContentSpacer = styled.div`
  height: 3vh;
`;

const About = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background} fadePercentage={20}>
      <Wrapper>
        <Spacer />
        <SectionHeading title="About Me" subtitle="Who am I?" />
        <ContentSpacer />
        <Icon>
          <UserAstronaut />
        </Icon>
        <Paragraph>
          I started dabbling in visual effects and prop-making when I was about
          12 years old. I always loved Star Wars (to be clear, I very
          specifically mean George Lucas's era), and especially lightsabers.
          Naturally, I wanted to have my very own lightsaber, but I quickly
          realized it wasn't possible to create a blade made of pure plasma. So,
          my 12-year-old self thought: how do they have lightsabers in the
          movies? This sparked my interest in two of my biggest hobbies: visual
          effects (to create the lightsaber blade effect) and prop making (to
          craft the tangible lightsaber hilt).
        </Paragraph>
        <Icon>
          <UserGraduate />
        </Icon>
        <Paragraph>
          I graduated from high school with a specialization in 'Computer Aided
          Design' (Machine Industry). I learned how to design machines and other
          components using CAD programs and how to operate various types of
          machinery, which greatly enhanced my prop-making skills. I also hold a
          master's degree in Applied Computer Science, which is invaluable for
          coding and developing my own projects. Currently, I work full-time in
          the IT industry as a full-stack developer, occasionally dabbling in
          UI/UX design, databases, and other related areas, in addition to
          working on my passion projects.
        </Paragraph>

        <ContentSpacer />
        <Spacer />
        <ButtonScrollToSection onClick={props.onNavClick} label="Contact Me" />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default About;
