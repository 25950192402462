import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "thorins-letter-to-bilbo",
  name: `Thorin's Letter to Bilbo`,
  origin: "Original prop based on The Hobbit (Book)",

  category: replicaCategories.lotr,

  versions: [Version1],
};

export default data;
