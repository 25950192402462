import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { HashLink } from "react-router-hash-link";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaMaraudersMapPages from "components/Replicas/ReplicaMaraudersMapPages";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaQuote from "components/Replicas/ReplicaQuote";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import Video from "components/Media/Video";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";
import videoExtensions from "./video - extensions.mp4";
import videoVolvelle from "./video - lunar-volvelle.mp4";

/* import Img_Stack from "./stack.png"; */

/* import ReplicaStats from "components/Replicas/ReplicaStats"; */

let gallery = [];
let gallery_extension = [];
let gallery_intro = [];
let gallery_intro_insult = [];
let gallery_Pages_Official_Aedificium_Oriens_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Aedificium_Oriens_Redesigned = [];
let gallery_Pages_Official_Aedificium_Oriens_Alternative = [];
let gallery_Pages_Official_Aedificium_Oriens_Fixed_Architecture = [];
let gallery_Pages_Official_Aedificium_Oriens_Differences = [];
let gallery_Pages_Official_Charms_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Charms_Redesigned = [];
let gallery_Pages_Official_Charms_Differences = [];
let gallery_Pages_Official_Turris_Magnus_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Turris_Magnus_Redesigned = [];
let gallery_Pages_Official_Turris_Magnus_Differences = [];
let gallery_Pages_Official_Headmasters_Office_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Headmasters_Office_Deathly_Hallows = [];
let gallery_Pages_Official_Headmasters_Office_Differences = [];
let gallery_Pages_Official_Office_Detail = [];
let gallery_Pages_Official_Seventh_Floor_Order_of_the_Phoenix = [];
let gallery_Pages_Official_Seventh_Floor_Half_Blood_Prince = [];
let gallery_Pages_Official_Seventh_Floor_Deathly_Hallows = [];
let gallery_Pages_Official_Seventh_Floor_Differences = [];
let gallery_Pages_Official_Hogwarts_Grounds_Default = [];
let gallery_Pages_Official_Hogwarts_Grounds_Extended = [];
let gallery_Pages_Official_Hogwarts_Grounds_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Hogwarts_Grounds_Differences = [];
let gallery_Pages_Official_Room_of_Doom_Early_Prop = [];
let gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban = [];
let gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban_Additional_Labels =
  [];
let gallery_Pages_Official_Room_of_Doom_Differences = [];
let gallery_Pages_Official_Clock_Tower = [];
let gallery_Pages_Custom_Astronomy_Tower = [];
let gallery_Pages_Custom_Castle_Overview = [];
let gallery_Pages_Custom_Great_Hall = [];
let gallery_Pages_Custom_Greenhouses = [];
let gallery_Pages_Custom_Gryffindor_Common_Room = [];
let gallery_Pages_Custom_Hospital_Wing = [];
let gallery_Pages_Custom_Moaning_Myrtles_Bathroom = [];
let gallery_Pages_Custom_Quidditch_Pitch = [];
let gallery_Pages_Custom_Training_Grounds = [];
let gallery_Pages_Custom_Transfiguration_Courtyard = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_extension = importAll(
    (require as any).context("./gallery-extension", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_intro = importAll(
    (require as any).context("./gallery - intro", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_intro_insult = importAll(
    (require as any).context(
      "./gallery - intro insult",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Aedificium_Oriens_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Aedificium Oriens (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Aedificium_Oriens_Redesigned = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Aedificium Oriens (Redesigned)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Aedificium_Oriens_Alternative = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Aedificium Oriens (Alternative)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Aedificium_Oriens_Fixed_Architecture = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Aedificium Oriens (Fixed Architecture)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Aedificium_Oriens_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Aedificium Oriens - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Charms_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Charms (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Charms_Redesigned = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Charms (Redesigned)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Charms_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Charms - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Turris_Magnus_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Turris Magnus (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Turris_Magnus_Redesigned = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Turris Magnus (Redesigned)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Turris_Magnus_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Turris Magnus - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Headmasters_Office_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Headmaster's Office (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Headmasters_Office_Deathly_Hallows = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Headmaster's Office (Deathly Hallows)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Headmasters_Office_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Headmaster's Office - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Office_Detail = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Office Detail (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Seventh_Floor_Order_of_the_Phoenix = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Seventh Floor (Order of the Phoenix)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Seventh_Floor_Half_Blood_Prince = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Seventh Floor (Half-Blood Prince)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Seventh_Floor_Deathly_Hallows = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Seventh Floor (Deathly Hallows)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Seventh_Floor_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Seventh Floor - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Hogwarts_Grounds_Default = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Hogwarts Grounds (Default)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Hogwarts_Grounds_Extended = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Hogwarts Grounds (Extended)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Hogwarts_Grounds_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Hogwarts Grounds (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Hogwarts_Grounds_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Hogwarts Grounds - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Room_of_Doom_Early_Prop = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Room of Doom (Early Prop)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Room of Doom (Prisoner of Azkaban)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban_Additional_Labels =
    importAll(
      (require as any).context(
        "./gallery - Pages - Official - Room of Doom (Prisoner of Azkaban, Labels)",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
  gallery_Pages_Official_Room_of_Doom_Differences = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Room of Doom - Differences",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Official_Clock_Tower = importAll(
    (require as any).context(
      "./gallery - Pages - Official - Clock Tower",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Astronomy_Tower = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Astronomy Tower",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Castle_Overview = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Castle Overview",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Great_Hall = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Great Hall",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Greenhouses = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Greenhouses",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Gryffindor_Common_Room = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Gryffindor Common Room",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Hospital_Wing = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Hospital Wing",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Moaning_Myrtles_Bathroom = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Moaning Myrtle's Bathroom",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Quidditch_Pitch = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Quidditch Pitch",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Training_Grounds = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Training Grounds",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Pages_Custom_Transfiguration_Courtyard = importAll(
    (require as any).context(
      "./gallery - Pages - Custom - Transfiguration Courtyard",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 17, month: 10, year: 2023 }),
  datePublished: createDate({ day: 30, month: 10, year: 2023 }),
  version: "7",
  isSeries: true,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryExtension = transformImages(gallery_extension);
  const galleryIntro = transformImages(gallery_intro);
  const galleryIntroInsult = transformImages(gallery_intro_insult);
  const galleryPagesOfficial_Aedificium_Oriens_Prisoner_of_Azkaban =
    transformImages(
      gallery_Pages_Official_Aedificium_Oriens_Prisoner_of_Azkaban
    );
  const galleryPagesOfficial_Aedificium_Oriens_Redesigned = transformImages(
    gallery_Pages_Official_Aedificium_Oriens_Redesigned
  );
  const galleryPagesOfficial_Aedificium_Oriens_Alternative = transformImages(
    gallery_Pages_Official_Aedificium_Oriens_Alternative
  );
  const galleryPagesOfficial_Aedificium_Oriens_Fixed_Architecture =
    transformImages(
      gallery_Pages_Official_Aedificium_Oriens_Fixed_Architecture
    );
  const galleryPagesOfficial_Aedificium_Oriens_Differences = transformImages(
    gallery_Pages_Official_Aedificium_Oriens_Differences
  );
  const galleryPagesOfficial_Charms_Prisoner_of_Azkaban = transformImages(
    gallery_Pages_Official_Charms_Prisoner_of_Azkaban
  );
  const galleryPagesOfficial_Charms_Redesigned = transformImages(
    gallery_Pages_Official_Charms_Redesigned
  );
  const galleryPagesOfficial_Charms_Differences = transformImages(
    gallery_Pages_Official_Charms_Differences
  );
  const galleryPagesOfficial_Turris_Magnus_Prisoner_of_Azkaban =
    transformImages(gallery_Pages_Official_Turris_Magnus_Prisoner_of_Azkaban);
  const galleryPagesOfficial_Turris_Magnus_Redesigned = transformImages(
    gallery_Pages_Official_Turris_Magnus_Redesigned
  );
  const galleryPagesOfficial_Turris_Magnus_Differences = transformImages(
    gallery_Pages_Official_Turris_Magnus_Differences
  );
  const galleryPagesOfficial_Headmasters_Office_Prisoner_of_Azkaban =
    transformImages(
      gallery_Pages_Official_Headmasters_Office_Prisoner_of_Azkaban
    );
  const galleryPagesOfficial_Headmasters_Office_Deathly_Hallows =
    transformImages(gallery_Pages_Official_Headmasters_Office_Deathly_Hallows);
  const galleryPagesOfficial_Headmasters_Office_Differences = transformImages(
    gallery_Pages_Official_Headmasters_Office_Differences
  );
  const galleryPagesOfficial_Office_Detail = transformImages(
    gallery_Pages_Official_Office_Detail
  );
  const galleryPagesOfficial_Seventh_Floor_Order_of_the_Phoenix =
    transformImages(gallery_Pages_Official_Seventh_Floor_Order_of_the_Phoenix);
  const galleryPagesOfficial_Seventh_Floor_Half_Blood_Prince = transformImages(
    gallery_Pages_Official_Seventh_Floor_Half_Blood_Prince
  );
  const galleryPagesOfficial_Seventh_Floor_Deathly_Hallows = transformImages(
    gallery_Pages_Official_Seventh_Floor_Deathly_Hallows
  );
  const galleryPagesOfficial_Seventh_Floor_Differences = transformImages(
    gallery_Pages_Official_Seventh_Floor_Differences
  );
  const galleryPagesOfficial_Hogwarts_Grounds_Default = transformImages(
    gallery_Pages_Official_Hogwarts_Grounds_Default
  );
  const galleryPagesOfficial_Hogwarts_Grounds_Extended = transformImages(
    gallery_Pages_Official_Hogwarts_Grounds_Extended
  );
  const galleryPagesOfficial_Hogwarts_Grounds_Prisoner_of_Azkaban =
    transformImages(
      gallery_Pages_Official_Hogwarts_Grounds_Prisoner_of_Azkaban
    );
  const galleryPagesOfficial_Hogwarts_Grounds_Differences = transformImages(
    gallery_Pages_Official_Hogwarts_Grounds_Differences
  );
  const galleryPagesOfficial_Room_of_Doom_Early_Prop = transformImages(
    gallery_Pages_Official_Room_of_Doom_Early_Prop
  );
  const galleryPagesOfficial_Room_of_Doom_Prisoner_of_Azkaban = transformImages(
    gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban
  );
  const galleryPagesOfficial_Room_of_Doom_Prisoner_of_Azkaban_Additional_Labels =
    transformImages(
      gallery_Pages_Official_Room_of_Doom_Prisoner_of_Azkaban_Additional_Labels
    );
  const galleryPagesOfficial_Room_of_Doom_Differences = transformImages(
    gallery_Pages_Official_Room_of_Doom_Differences
  );
  const galleryPagesOfficial_Clock_Tower = transformImages(
    gallery_Pages_Official_Clock_Tower
  );
  const galleryPagesCustom_Astronomy_Tower = transformImages(
    gallery_Pages_Custom_Astronomy_Tower
  );
  const galleryPagesCustom_Castle_Overview = transformImages(
    gallery_Pages_Custom_Castle_Overview
  );
  const galleryPagesCustom_Great_Hall = transformImages(
    gallery_Pages_Custom_Great_Hall
  );
  const galleryPagesCustom_Greenhouses = transformImages(
    gallery_Pages_Custom_Greenhouses
  );
  const galleryPagesCustom_Gryffindor_Common_Room = transformImages(
    gallery_Pages_Custom_Gryffindor_Common_Room
  );
  const galleryPagesCustom_Hospital_Wing = transformImages(
    gallery_Pages_Custom_Hospital_Wing
  );
  const galleryPagesCustom_Moaning_Myrtles_Bathroom = transformImages(
    gallery_Pages_Custom_Moaning_Myrtles_Bathroom
  );
  const galleryPagesCustom_Quidditch_Pitch = transformImages(
    gallery_Pages_Custom_Quidditch_Pitch
  );
  const galleryPagesCustom_Training_Grounds = transformImages(
    gallery_Pages_Custom_Training_Grounds
  );
  const galleryPagesCustom_Transfiguration_Courtyard = transformImages(
    gallery_Pages_Custom_Transfiguration_Courtyard
  );

  const versionAnchors = {
    earlyProp: "v7-early-prop",
    prisonerOfAzkaban: "v7-prisoner-of-azkaban",
    snapeInsult: "v7-snape-insult",
    orderOfThePhoenix: "v7-order-of-the-phoenix",
    halfBloodPrince: "v7-half-blood-prince",
    deathlyHallows: "v7-deathly-hallows",
    ultimateMap: "v7-ultimate-map",
    extensionPhilosophersStoneLocations: "v7-philosophers-stone-locations",
    extensionChamberOfSecretsLocations: "v7-chamber-of-secrets-locations",
  };

  const anchors = {
    intro: "v7-intro",
    intro_insult: "v7-intro-insult",
    page_official_hogwarts_grounds: "v7-hogwarts-grounds",
    page_official_room_of_doom: "v7-room-of-doom",
    page_official_office_detail: "v7-office-detail",
    page_official_turris_magnus: "v7-turris-magnus",
    page_official_charms: "v7-charms",
    page_official_headmasters_office: "v7-headmasters-office",
    page_official_aedificium_oriens: "v7-aedificium-oriens",
    page_official_seventh_floor: "v7-seventh-floor",
    page_official_clock_tower: "v7-clock-tower",
    page_custom_transfiguration_courtyard: "v7-transfiguration-courtyard",
    page_custom_astronomy_tower: "v7-astronomy-tower",
    page_custom_castle_overview: "v7-castle-overview",
    page_custom_great_hall: "v7-great-hall",
    page_custom_quidditch_pitch: "v7-quidditch-pitch",
    page_custom_training_grounds: "v7-training-grounds",
    page_custom_gryffindor_common_room: "v7-gryffindor-common-room",
    page_custom_moaning_myrtles_bathroom: "v7-moaning-myrtles-bathroom",
    page_custom_greenhouses: "v7-greenhouses",
    page_custom_hospital_wing: "v7-hospital-wing",
  };

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        The Marauder's Map made its debut in Harry Potter and the Prisoner of
        Azkaban. For me, it's been something of a Holy Grail in terms of props,
        and I spent over a decade perfecting my own replica. I learned a lot
        over the years, and all that knowledge finally culminated in the "v7"
        version of The Marauder's Map, which I consider to be the definitive
        one. It builds upon the previous versions and addresses the very few
        remaining nitpicks. The absolutely most important update is that I
        created all of the designs using the same ink and nib as the original
        prop, ensuring the replica is as authentic as possible. The previous
        versions were mostly created in digital form, but for this one, every
        single page was painstakingly written by hand with real-life ink before
        being digitized.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The goal of this replica is not to be a 1:1 recreation of the original
        prop, but rather its own object that just "feels right" when held. For
        instance, it's my understanding that the original prop was printed on A3
        sheets of paper and then assembled (unless I'm mistaken), a technique I
        employed for all my previous replicas. However, the v7 is printed on a
        high-end large-format printer, mostly in a single piece, resulting in
        seamless sections of the map and an overall prop that feels less 'rigid'
        without the presence of glue joints. Additionally, I've opted not to
        include the name and version of the replica on the back side of the map,
        enhancing its resemblance to an authentic in-universe object.
      </ReplicaParagraph>

      <ReplicaSpacer />

      <ReplicaQuote source="Remus Lupis, Harry Potter and the Prisoner of Azkaban">
        Given that the original prop underwent multiple iterations throughout
        the films, it's impossible to create one definitive replica that
        encompasses all the pages seen on screen. New pages were added and later
        modified, and there are even some earlier versions of certain pages, and
        so on. Rather than having to select which designs to replicate, I've
        opted to create all the individual iterations of the map. This approach
        has afforded me the opportunity to include absolutely everything.
      </ReplicaQuote>

      {/* <ReplicaStats
        reverse
        labels={[
          <b>Version 7</b>,
          "reworked from scratch",
          "designed with real ink",
          "more accurate to the original prop",
          "new designs and pages",
          "expandable with new pages",
          "100% handmade",
          "and much more!",
        ]}
        img={Img_Stack}
      /> */}

      {/*
       *************************************************************
       */}

      <ReplicaBigTitle title="v7 Prop Versions" />

      <ReplicaParagraph>
        If you look closely at the Marauder's Map as it is featured throughout
        the movies, most notably in the Prisoner of Azkaban, you may notice
        quite a few differences between the individual film versions of the map,
        and even more differences from individual shots in the Prisoner of
        Azkaban. But more on that below. For quite some time, I tried to come up
        with a single prop replica that would encapsulate all the different
        designs. However, back in version 6, I decided it was simply impossible.
        It cannot be achieved because, for example, there are multiple versions
        of just the intro page, and there is no way to have more than one intro
        page while keeping the original format of the map intact. Since I'm
        quite a big fan of details, I have decided that rather than trying to
        encapsulate all the different iterations of the prop in a single
        replica, it would be much more logical to create multiple
        replicas/versions of the prop as it appeared at the time of filming.
      </ReplicaParagraph>

      {/*
       *************************************************************
       */}

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.earlyProp}
        title='v7 "Early Prop"'
        youtube="https://www.youtube.com/watch?v=gtk5Nxgs-nc"
        content={
          <ReplicaParagraph>
            During my research, I observed that The Marauder's Map appears quite
            different from scene to scene in the Harry Potter and the Prisoner
            of Azkaban film. Based on my findings, it seems that the map wasn't
            entirely finalized during shooting, as virtually all of the wide
            shots featuring the map lack the page designs seen in close-up
            shots. I surmise that the close-ups were filmed much later in the
            process, after the individual pages were completed. The 'v7 Early
            Prop' is a replica of the map used for the wide shots during
            filming.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Mooney)", anchor: anchors.intro },
          {
            name: "Hogwarts Grounds (Extended)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Room of Doom (Early Prop)",
            anchor: anchors.page_official_room_of_doom,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.prisonerOfAzkaban}
        title='v7 "Prisoner of Azkaban"'
        youtube="https://www.youtube.com/watch?v=Zahs8AotFjQ"
        content={
          <ReplicaParagraph>
            This version of the map meticulously includes all the pages with
            intricate details, such as the 3D stairs, as seen in The Prisoner of
            Azkaban close-up shots. Based on (unconfirmed) insider information
            and my own research, it appears that the new pages were simply
            affixed on top of the previous 'Early Prop' map. However, the middle
            section of the map differs from the Early Prop, suggesting the
            possibility of multiple copies created either for filming or later
            for exhibitions and similar purposes. The 'v7 Prisoner of Azkaban'
            encapsulates all the designs one would expect to find in the map
            after watching the film. It's worth noting that the page designs
            shown on screen are entirely distinct from the official licensed
            replica of the map available in stores.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Mooney, Soft Shadows)", anchor: anchors.intro },
          {
            name: "Office Detail",
            anchor: anchors.page_official_office_detail,
          },
          {
            name: "Turris Magnus (Prisoner of Azkaban)",
            anchor: anchors.page_official_turris_magnus,
          },
          {
            name: "Charms (Prisoner of Azkaban)",
            anchor: anchors.page_official_charms,
          },
          {
            name: "Hogwarts Grounds (Prisoner of Azkaban)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Room of Doom (Prisoner of Azkaban)",
            anchor: anchors.page_official_room_of_doom,
          },
          {
            name: "Headmaster's Office (Prisoner of Azkaban)",
            anchor: anchors.page_official_headmasters_office,
          },
          {
            name: "Ædificium Oriens (Prisoner of Azkaban)",
            anchor: anchors.page_official_aedificium_oriens,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.snapeInsult}
        title='v7 "Snape Insult"'
        youtube="https://www.youtube.com/watch?v=KnhCbcjzluQ"
        content={
          <ReplicaParagraph>
            This is the version of the map that is visible after Snape catches
            Potter out of bed at night while he's looking for Pettigrew, and
            attempts to force The Marauder's Map to reveal its contents. The
            scene deviates from the book, which includes more insults, but I'm
            particularly fond of the design. It's an essential component of the
            v7 series, which aims to depict all the versions of the map.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Snape Insult)", anchor: anchors.intro },
          { name: "empty pages", anchor: null },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.orderOfThePhoenix}
        title='v7 "Order of the Phoenix"'
        youtube="https://www.youtube.com/watch?v=rVE42w7nQz8"
        content={
          <ReplicaParagraph>
            While the map was never shown on screen in the fifth film, there's
            information available that shows a new version of the map was
            developed for The Order of the Phoenix. This version is said to have
            incorporated most of the page designs from 'v7 Prisoner of Azkaban.'
            Additionally, there's a picture of a newly designed page featuring
            the seventh floor and the Room of Requirement. While this page isn't
            strictly canon, as it never appeared on screen and the Room of
            Requirement is explicitly stated to be missing from the map in the
            sixth book, one can't argue with the 'rule-of-cool' of this design.
            So here it is in 'v7 Order of the Phoenix'.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Mooney)", anchor: anchors.intro },
          {
            name: "Turris Magnus (Prisoner of Azkaban)",
            anchor: anchors.page_official_turris_magnus,
          },
          {
            name: "Charms (Prisoner of Azkaban)",
            anchor: anchors.page_official_charms,
          },
          {
            name: "Hogwarts Grounds (Quidditch Pitch)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Seventh Floor (Order of the Phoenix)",
            anchor: anchors.page_official_seventh_floor,
          },
          {
            name: "Room of Doom (Prisoner of Azkaban)",
            anchor: anchors.page_official_room_of_doom,
          },
          {
            name: "Clock Tower (rotated)",
            anchor: anchors.page_official_clock_tower,
          },
          {
            name: "Ædificium Oriens (Prisoner of Azkaban, trimmed corner)",
            anchor: anchors.page_official_aedificium_oriens,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.halfBloodPrince}
        title='v7 "Half-Blood Prince"'
        youtube="https://www.youtube.com/watch?v=w2Ct0ChDEVg"
        content={
          <ReplicaParagraph>
            Harry Potter and the Half-Blood Prince was the first film to bring
            the Marauder's Map back on screen, even if only briefly. The prop
            showcases a new design for the 7th floor, and most of the other
            pages were also redesigned. I was informed (though it's unconfirmed)
            that these pages were actually redesigned when The Prisoner of
            Azkaban was produced, which explains why they appear in the official
            licensed replica of The Marauder's Map, even though they were never
            seen on screen. The 'v7 Half-Blood Prince' is the first replica from
            the v7 series to feature these designs.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Mooney)", anchor: anchors.intro },
          {
            name: "Turris Magnus (Redesigned)",
            anchor: anchors.page_official_turris_magnus,
          },
          {
            name: "Charms (Redesigned)",
            anchor: anchors.page_official_charms,
          },
          {
            name: "Hogwarts Grounds (Quidditch Pitch)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Seventh Floor (Half-Blood Prince)",
            anchor: anchors.page_official_seventh_floor,
          },
          {
            name: "Room of Doom (Prisoner of Azkaban with additional labels)",
            anchor: anchors.page_official_room_of_doom,
          },
          {
            name: "Clock Tower",
            anchor: anchors.page_official_clock_tower,
          },
          {
            name: "Ædificium Oriens (Redesigned)",
            anchor: anchors.page_official_aedificium_oriens,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.deathlyHallows}
        title='v7 "Deathly Hallows"'
        youtube="https://www.youtube.com/watch?v=ru4_Ql3NAOQ"
        content={
          <ReplicaParagraph>
            The Deathly Hallows version of the map bears a resemblance to the
            Half-Blood Prince's version, but there are notable differences. For
            instance, there's a slight alteration in the design of the 7th
            floor, and the film showcases a new design for the Headmaster's
            Office. These changes aren't the only distinctions, however. I've
            also incorporated intriguing design alternatives that were created
            by the original artists but never made it into the original prop.
            This sets it apart even further from 'v7 Half-Blood Prince'.
          </ReplicaParagraph>
        }
        pages={[
          { name: "Intro (Mooney)", anchor: anchors.intro },
          {
            name: "Turris Magnus (Redesigned)",
            anchor: anchors.page_official_turris_magnus,
          },
          {
            name: "Charms (Redesigned)",
            anchor: anchors.page_official_charms,
          },
          {
            name: "Hogwarts Grounds (Quidditch Pitch)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Seventh Floor (Deathly Hallows)",
            anchor: anchors.page_official_seventh_floor,
          },
          {
            name: "Room of Doom (Prisoner of Azkaban with additional labels)",
            anchor: anchors.page_official_room_of_doom,
          },
          {
            name: "Headmaster's Office (Deathly Hallows)",
            anchor: anchors.page_official_headmasters_office,
          },
          {
            name: "Ædificium Oriens (Alternative)",
            anchor: anchors.page_official_aedificium_oriens,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.ultimateMap}
        title='v7 "Ultimate Map"'
        youtube="https://www.youtube.com/watch?v=150Crqr_MTc"
        content={
          <>
            <ReplicaParagraph>
              As the name suggests, this version represents the ultimate
              culmination of the best and most captivating designs showcased
              throughout the films, complete with all the flaps and 3D stairs.
              The introduction is more faithful to the book, featuring the
              nickname 'Moony' instead of the film's 'Mooney', which was
              incorrect. But that's not all! To enhance the map's detail even
              further, I crafted my own pages featuring notable Hogwarts
              locations, such as the Astronomy Tower with a fully functional
              Lunar Volvelle, or the Great Hall, for example. Additionally, the
              map was designed with future extensions in mind, allowing for the
              addition of more pages without the need for awkward gluing in
              places where they don't belong, or the necessity of releasing a
              new replica with the new additions.
            </ReplicaParagraph>

            <ReplicaParagraph>
              It is important to note that although this is the most
              comprehensive version of The Marauder's Map v7 series, each
              version (from the v7 series) offers something unique that cannot
              be found in any of the other versions.
            </ReplicaParagraph>
          </>
        }
        pages={[
          { name: "Intro (Moony)", anchor: anchors.intro },
          {
            name: "Turris Magnus (Prisoner of Azkaban)",
            anchor: anchors.page_official_turris_magnus,
          },
          {
            name: "Charms (Prisoner of Azkaban)",
            anchor: anchors.page_official_charms,
          },
          {
            name: "Transfiguration Courtyard",
            anchor: anchors.page_custom_transfiguration_courtyard,
          },
          {
            name: "Astronomy Tower",
            anchor: anchors.page_custom_astronomy_tower,
          },
          {
            name: "Hogwarts Grounds (Quidditch Pitch)",
            anchor: anchors.page_official_hogwarts_grounds,
          },
          {
            name: "Seventh Floor (Deathly Hallows)",
            anchor: anchors.page_official_seventh_floor,
          },
          {
            name: "Room of Doom (Prisoner of Azkaban with additional labels)",
            anchor: anchors.page_official_room_of_doom,
          },
          {
            name: "Castle Overview",
            anchor: anchors.page_custom_castle_overview,
          },
          {
            name: "Great Hall",
            anchor: anchors.page_custom_great_hall,
          },
          {
            name: "Ædificium Oriens (Fixed Architecture)",
            anchor: anchors.page_official_aedificium_oriens,
          },
          {
            name: "Clock Tower",
            anchor: anchors.page_official_clock_tower,
          },
          {
            name: "ready for 4 parallel map extensions",
            anchor: null,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.extensionPhilosophersStoneLocations}
        title={`v7 Extension - "Philosopher's Stone Locations"`}
        youtube="https://www.youtube.com/watch?v=LBpTBsBAx2k"
        content={
          <ReplicaParagraph>
            These pages are not intended to serve as a standalone replica of The
            Marauder's Map, but rather as supplementary pages that can be
            seamlessly added to the 'v7 Ultimate Map' prop replica. While these
            pages can certainly be incorporated into any of the v7 prop
            replicas, the Ultimate Map is specifically designed with
            extendability in mind. This extension includes three pages featuring
            notable locations from the first film - the Quidditch Pitch, the
            Training Grounds where the flying lessons take place, and finally,
            the Gryffindor Common Room.
          </ReplicaParagraph>
        }
        pages={[
          {
            name: "Quidditch Pitch",
            anchor: anchors.page_custom_quidditch_pitch,
          },
          {
            name: "Training Grounds",
            anchor: anchors.page_custom_training_grounds,
          },
          {
            name: "Gryffindor Common Room",
            anchor: anchors.page_custom_gryffindor_common_room,
          },
        ]}
      />

      <ReplicaMaraudersMapPages
        anchor={versionAnchors.extensionChamberOfSecretsLocations}
        title={`v7 Extension - "Chamber of Secrets Locations"`}
        youtube="https://www.youtube.com/watch?v=XfDlKB_U7jY"
        content={
          <ReplicaParagraph>
            Once again, it's important to emphasize that these pages are not
            meant to function as a standalone replica of The Marauder's Map, but
            rather an extension to the Ultimate Map. These three new pages
            feature notable locations from the second film, including the
            introduction of the greenhouses. Additionally, we have Moaning
            Myrtle's bathroom and the Hospital Wing, which briefly appeared in
            the first film but gained more significance in the second film.
          </ReplicaParagraph>
        }
        pages={[
          {
            name: "Moaning Myrtle's Bathroom",
            anchor: anchors.page_custom_moaning_myrtles_bathroom,
          },
          {
            name: "Greenhouses",
            anchor: anchors.page_custom_greenhouses,
          },
          {
            name: "Hospital Wing",
            anchor: anchors.page_custom_hospital_wing,
          },
        ]}
      />

      {/*
       *************************************************************
       */}

      <ReplicaBigTitle title="Designed For Extendability" />

      <ReplicaParagraph>
        Beyond the quest for accuracy and authenticity in my version of The
        Marauder's Map, one of the reasons I find myself returning to this
        replica time and again is the sheer joy of sitting down and crafting new
        designs, purely because I'm enamored with the overall style of the prop.
        However, introducing these new pages often meant releasing an entirely
        updated replica, as I tend to learn and refine my skills with each
        iteration. Now that I'm at a point where I'm genuinely content with
        every aspect of the replica, I had to anticipate the need for adding new
        pages even after the replica is finished.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryExtension} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        In order to address the issue of extendability, I made the decision to
        incorporate four separate 'half-pages' into the middle of the Ultimate
        Map—two on each side. These half-pages are cleverly concealed behind the
        main folded pages, ensuring they don't disrupt the overall appearance
        and feel of the replica. Moreover, the map extensions are designed with
        ending half-pages on both sides, allowing them to be added to either the
        left or right side of the map. One of the ending half-pages can then be
        used to securely attach the extension to the half-page on the prop,
        while the other remains an integral part of the final design.
        Additionally, the remaining ending page can also be employed to connect
        it with another extension, potentially enabling the map to be extended
        indefinitely in theory.
      </ReplicaParagraph>

      <Video src={videoExtensions} autoPlay heightPercentage="40%" />

      {/*
       *************************************************************
       */}

      <ReplicaBigTitle title="Page Designs" />

      <ReplicaParagraph>
        It is always difficult to come up with a way to showcase the amount of
        work that went into such a massive project like this. I have decided
        that since a lot of the v7 versions of the map share some of the pages,
        it would be best to go through the individual pages by themselves. This
        also gives me the opportunity to talk a bit about each page, because
        sometimes there are multiple versions, and it is interesting to note the
        differences. Each version of the replica that is described above has a
        list of pages that are included - also note that you can click on the
        page in the list to automatically scroll to its description below.
      </ReplicaParagraph>

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Intro"
        subtitle={`And its variations`}
        anchor={anchors.intro}
      />

      <ReplicaQuote source="Harry Potter and the Prisoner of Azkaban">
        "The map," said Lupin. "The Marauder's Map. I was in my office examining
        it --" "You know how to work it?" Harry said suspiciously. "Of course I
        know how to work it," said Lupin, waving his hand impatiently. "I helped
        write it. I'm Moony -- that was my friends' nickname for me at school."
      </ReplicaQuote>

      <ReplicaParagraph>
        There are several versions of the Intro to The Marauder's Map, each with
        minor differences. For instance, the usual intro showcases what I refer
        to as 'regular shadows' on the drawing of the castle. However, if you
        examine the intro page seen in the very first scene where the Marauder's
        Map is handed to Harry in The Prisoner of Azkaban, you'll notice that
        the shadows appear much more detailed in that version — which I
        categorize as 'soft shadows' in the gallery below. Another noteworthy
        aspect of the intro is the leading text, which in the film prop reads
        'Mooney'. This, in fact, is a mistake, as the correct version from the
        book should be 'Moony', without the 'e'. While I included the mistake in
        the film versions of my replicas, the Ultimate Map is book-accurate and
        uses 'Moony' instead.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryIntro} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor
        level={2}
        title='"Snape Insult"'
        anchor={anchors.intro_insult}
      />

      <ReplicaParagraph>
        This is a complete separate design from the main one, with the intention
        to depict the scene when Severus Snape is trying to uncover the secret
        of the map. Upon using magic on the blank piece of parchment, the
        Marauders start to insult Snape. While there are much more insults in
        the book version of the scene, the film version captures the spirit of
        the scene quite nicely. This particular prop can be seen in The Prisoner
        of Azkaban, after Snape catches Harry wandering the castle at night
        looking for Pettigrew.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryIntroInsult} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Charms"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_charms}
      />

      <ReplicaParagraph>
        I usually call the page designs by either a location that they depict,
        or by one of the prominent labels included in the design. The "Charms"
        page has the Charms classroom in the middle, for example. What's notable
        about this page is that this is where Harry first notices Peter
        Pettigrew on the map in The Prisoner of Azkaban.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        This page is quite notable in the third film, where we can see a
        close-up shot of the map. That is why I call this version of the page
        the "Prisoner of Azkaban" version. It features two flaps with 3D stairs,
        making it quite an interesting part of the map. It was actually quite
        difficult to gather enough reference images to piece together the look
        of the entire page, because we can see only a section of the page in the
        film. However, this page is relatively common when looking at images of
        the original prop, so I was able to figure out the entire page quite
        precisely.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Charms_Prisoner_of_Azkaban} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Redesigned"' />

      <ReplicaParagraph>
        From what I learned, although I have no way of actually confirming it,
        the pages featured in the films were completely redesigned at the time
        of the making of Prisoner of Azkaban. This "Redesigned" version of the
        page can be found in the officially licensed replica of The Marauder's
        Map, which leads me to believe that this piece of information is
        correct, although it does not make much sense to me to redesign the
        pages only after the close-up shots were already filmed. This is also
        the reason why the licensed replica is not accurate to the film scenes
        at all, which bothers me to no end.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Charms_Redesigned} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Charms - Differences" />

      <ReplicaParagraph>
        Apart from depicting roughly the same layout, these two versions of the
        page are completely different. The film version ("Prisoner of Azkaban")
        features much more text design, while the "Redesigned" version relies
        more on lines. While both of the pages feature "Charms Classroom" in the
        middle, all the other labels are completely different - like "Peeves may
        lurk here" vs "Room of Doom" at the top of the page, for example. It is
        also notable that the "Redesigned" version does not have the intricate
        3D stairs, perhaps to make it easier to produce.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Charms_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Turris Magnus"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_turris_magnus}
      />

      <ReplicaParagraph>
        This page can be seen in the Prisoner of Azkaban, when Harry is
        exploring the map on his bed at night, after he received it from Fred
        and George. This particular page is the first one that can be seen in
        the close-up shot, where we can see Argus Filch, the caretaker of
        Hogwarts School of Witchcraft and Wizardry, prowling the school.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        Just like the Charms page above, the film version ("Prisoner of
        Azkaban") is quite intricate in its design, once again featuring 3D
        stairs. It features Hogwarts Turris Magnus, which could be roughly
        translated from latin as "The Great Tower of Hogwarts". It is also known
        as the Defence Tower, one of the largest towers of the Hogwarts Castle.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Turris_Magnus_Prisoner_of_Azkaban}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Redesigned"' />

      <ReplicaParagraph>
        The redesigned version is once again the one that can be found in the
        officially licensed prop replica, which features the redesigned page
        that is different from the film version. This page, much like the
        redesigned Charms page, was never seen in the films.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Turris_Magnus_Redesigned} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Turris Magnus - Differences" />

      <ReplicaParagraph>
        The layout depicted in those two versions of the page is practically the
        same, with one notable difference - you may notice that the layout from
        "Prisoner of Azkaban" film version is scaled up a bit. The labels on the
        pages are mostly the same, but there are some differences, like the
        missing "Serpentine Corridor" label from the film version. The most
        noticeable difference is once again the absence of 3D stairs on the
        redesigned version. The style of these two pages is surprisingly mostly
        the same, with only minor differences, mostly due to the missing 3D
        stairs, which impacts the design a bit.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Turris_Magnus_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Ædificium Oriens"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_aedificium_oriens}
      />

      <ReplicaParagraph>
        This is the very first page that can be seen in The Prisoner of Azkaban
        right after Fred and George give Harry the Marauder's Map and
        demonstrate to him what it does. This page supposedly features
        Dumbledore pacing in his study, however, it is interesting to note that
        the close-up shot of Dumbledore actually features a different design
        from the one on this page. Not to miss anything, I created the{" "}
        <HashLink to={"#" + anchors.page_official_office_detail} smooth>
          close-up version
        </HashLink>{" "}
        as well.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        The page depicts Ædificium Oriens, which can be translated from latin as
        "East Building". You can also find Hogwarts Turris Magnus from the
        previous page in here - that's the tower with Dumbledore's study in the
        top right corner of the page. After researching the film version of this
        page, I concluded that its design was probably not fully finished, at
        least at the time of filming. The bottom part of the page that cannot be
        seen on screen actually does not exist, so I had to complete the design
        in the same style myself.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Aedificium_Oriens_Prisoner_of_Azkaban}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Redesigned"' />

      <ReplicaParagraph>
        This page was also later redesigned in a different style. Once again,
        this version can be found in the officially licensed replica, which
        doesn't match what can be seen in the film. This version, much like the
        other redesigned pages, never appeared in any of the films. However, it
        can be found in photos from different Harry Potter exhibitions featuring
        the map. The map on display has 3D stairs on several pages, which means
        that it is not the official replica, because it doesn't have those. That
        being said, since the map on display uses the redesigned pages, it is
        also not the original prop, so it is an interesting mix of the two.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Aedificium_Oriens_Redesigned} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Alternative"' />

      <ReplicaParagraph>
        Throughout my research, I came upon an interesting spin on the design of
        this page, which caught my eye. Since this version comes from the same
        artist that designed the original prop, I have decided to create this
        page as well. The outer parts of the castle are mostly the same as the
        redesigned version, but the inner part is completely different.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Aedificium_Oriens_Alternative} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Fixed Architecture"' />

      <ReplicaParagraph>
        When going through Hogwarts architecture and blueprints, I noticed that
        the castle layout is not entirely accurate. The Clock Tower at the
        bottom of the page is supposed to be separated from the main building by
        a ravine. For the Ultimate Map version of my replica, I have decided to
        make the page a bit more accurate. To make the page look less flat, I
        have decided to put the bridge over the ravine on a separate piece of
        paper that folds in the other direction. The design under this "paper
        bridge" shows the ravine without the bridge, making the design more
        layered.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Aedificium_Oriens_Fixed_Architecture}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Ædificium Oriens - Differences" />

      <ReplicaParagraph>
        The differences between the "Prisoner of Azkaban" and the "Redesigned"
        versions are quite notable. The film version seems much more detailed,
        using practically just text to form the architecture, rather than
        relying on drawing lines like the Redesigned version does. Apart from
        that, there are also differences in the placement of the layout on the
        page, some missing labels etc. The "Alternative" version is similar to
        the "Redesigned" one, with the differences described above. The "Fixed
        Architecture" page expands on the film version, which means that
        everything that can be seen in the film is included on this page, while
        also fixing the bottom of the page to make it more accurate.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Aedificium_Oriens_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Headmaster's Office"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_headmasters_office}
      />

      <ReplicaParagraph>
        While the Headmaster's office can be found on several different page
        designs, like the Ædificium Oriens, for example, this specific page
        gives the office a bit more attention.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        While this page never appeared in The Prisoner of Azkaban film, nor in
        the officially licensed replica, throughout my research, I found
        pictures of the original prop that clearly show that this page was a
        part of it. It is notable that it is very similar to the Turris Magnus
        page, which is perhaps why it was never really used, because it is not
        different enough.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Headmasters_Office_Prisoner_of_Azkaban}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Deathly Hallows"' />

      <ReplicaParagraph>
        Unlike the Prisoner of Azkaban version, this page was actually shown on
        screen in Harry Potter and the Deathly Hallows film. It makes an
        appearance as Harry watches Snape in the Headmaster's office while on
        the run from the Ministry / the Death Eaters.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Headmasters_Office_Deathly_Hallows}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor
        level={2}
        title="Headmaster's Office - Differences"
      />

      <ReplicaParagraph>
        At first glance, these two pages look practically the same, but that is
        not the case. It is surprising that they did not reuse the Prisoner of
        Azkaban version of the map in the Deathly Hallows, because the
        differences are quite minor. The differences are mainly in the style of
        the Headmaster's office, which uses much more text in the Prisoner of
        Azkaban. The label for the office is also placed a bit differently in
        each version. It stands to reason that perhaps they simplified the
        design in order to make it easier to spot Snape on the map in the film.
        Since the map is shown only very briefly, it probably makes it a lot
        clearer for the viewer to notice what is happening this way.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Headmasters_Office_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Seventh Floor"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_seventh_floor}
      />

      <ReplicaParagraph>
        This is perhaps the most noticeable addition to the map after the Harry
        Potter and the Prisoner of Azkaban film. This is due to the fact that
        the seventh floor is where the Room of Requirement is located, which
        played a pivotal role in both The Order of the Phoenix and The
        Half-Blood Prince books/films. Since all of this happens in the later
        books, it was probably not even considered when the original prop was
        designed.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Order of the Phoenix"' />

      <ReplicaParagraph>
        Even though The Marauder's Map never made an appearance in the Order of
        the Phoenix film, those who are interested in the map most probably came
        across a picture of the seventh floor page designed for the film. I
        probably already mentioned that the design of this page actually goes
        against canon, because the Room of Requirement is not supposed to be a
        part of the map. It is suggested that either the Marauders did not know
        about it, or that it was unplottable (meaning that it would be protected
        against being put on any maps). However, it is still a very cool
        addition.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Seventh_Floor_Order_of_the_Phoenix}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Half-Blood Prince"' />

      <ReplicaParagraph>
        The Marauder's Map made a brief appearance in Harry Potter and the Half
        Blood Prince, showing this specific page. In the film, Harry watches
        Draco Malfoy on the seventh floor, and it is later shown that he
        disappears from the map. The book goes into much more depth around the
        mystery of Malfoy disappearing from the map, and it is only later
        revealed that it is due to him visiting the Room of Requirement, which
        isn't on the map. The film makes it perhaps a bit too obvious what is
        happening, but it is a different medium.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Seventh_Floor_Half_Blood_Prince} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Deathly Hallows"' />

      <ReplicaParagraph>
        The Marauder's Map makes a grand return one last time in the last film,
        once again showing someone disappearing from the map in front of the
        Room of Requirement - this time it is Harry instead of Malfoy. This is
        perhaps the only page that is shown in multiple films, however, there
        are once again some changes, but more on that below.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Seventh_Floor_Deathly_Hallows} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Seventh Floor - Differences" />

      <ReplicaParagraph>
        The design of the page stayed mostly the same, with one major change.
        The entrance to the Room of Requirement in the Half-Blood Prince is
        depicted to be on the wall closer to the bottom of the page - the
        corridor that leads to the dead end. The Deathly Hallows show the
        entrance to be on the wall facing the corridor on the right side of the
        page. That being said, you can tell where the entrance is only by the
        position from which Malfoy and Harry disappear from the map, not the
        design itself. However, to perhaps make it look more like a wall, the
        design of the entrance wall was changed in the Deathly Hallows, where
        the section with bird symbols was replaced with text. A less noticeable
        difference is that the pages are actually folded differently in each
        version.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Seventh_Floor_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Hogwarts Grounds"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_hogwarts_grounds}
      />

      <ReplicaParagraph>
        I think that this is one of the very first pages that were designed for
        the original prop, because it is featured in every version of the map
        throughout the films, and it is the main part of the Early Prop. That
        being said, there are still some variations to the design throughout the
        iterations of the map.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Default / Quidditch Pitch"' />

      <ReplicaParagraph>
        The 'Default' design, as the name suggests, showcases Hogwarts and its
        surrounding areas, including notable features like the Whomping Willow
        and the Dark Forest. In some versions of my replicas, the Quidditch
        Pitch is also included. It's worth noting that this addition came later
        in the lifetime of the original prop and was never featured in any of
        the films. I have no way of confirming it, but it was reportedly
        designed for the Goblet of Fire, although the Marauder's Map itself is
        not seen in the film.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Hogwarts_Grounds_Default} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Extended"' />

      <ReplicaParagraph>
        Most of the replicas contain only the middle part of the Hogwarts
        Grounds design, because the rest of the map is filled with pages
        depicting different parts of Hogwarts. The extended version is arguably
        the original design, because it fills the entirety of the Early Prop.
        The detailed pages were later attached to the prop covering the original
        pages, leading me to believe that this is how this section was
        originally designed.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Hogwarts_Grounds_Extended} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        When studying the Marauders Map scenes in The Prisoner of Azkaban film,
        I noticed that there were slight differences between the "Default"
        design, and the one shown on screen. We can only see the right half of
        the design, but it appears that the Dark Forest is completely missing,
        and the lake is written in cursive, rather than capital letters. I was
        unable to find any other pictures of the screen-used design, so I had to
        transform the Default design into the same style as the parts visible on
        screen to complete it.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={galleryPagesOfficial_Hogwarts_Grounds_Prisoner_of_Azkaban}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Hogwarts Grounds - Differences" />

      <ReplicaParagraph>
        I mostly went over the differences already, but to recap - there are
        basically three versions of the Hogwarts Grounds. There is the Default
        design with the lake in capital letters, and the same one with the
        Quidditch Pitch. Lastly, there's the Prisoner of Azkaban version, which
        features the lake in cursive and is without the Quidditch Pitch. As I
        mentioned, it seems that the Dark Forest (the large red part on the left
        of the castle) is missing, but since only the right half of the design
        is shown in the film, I have decided to keep the forest, but make it a
        bit smaller as to not be on the right side of the design, so it is still
        true to the film.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Hogwarts_Grounds_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Room of Doom"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_room_of_doom}
      />

      <ReplicaParagraph>
        The Room of Doom page is the middle part of the entire map, featuring
        the location where Harry meets Peter Pettigrew for the first time
        (although he was in his rat form, and only ran past Harry). It is also
        where Harry was then immediately caught by Severus Snape, who was then
        insulted by the map after trying to force it to reveal its secrets.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor level={2} title='"Early Prop"' />

      <ReplicaParagraph>
        It took me a relatively long time to notice that the design of this page
        is completely different in the Early Prop, considering how many years I
        spent working on the map. To my defense, it is only shown for about
        three frames at the end of The Prisoner of Azkaban, when Lupin folds the
        map to give it back to Harry.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Room_of_Doom_Early_Prop} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title='"Prisoner of Azkaban"' />

      <ReplicaParagraph>
        Next to the Early Prop version, this is the second version of the design
        that is shown in the same film, making both of them canon. This is
        actually how the idea of creating several iterations of the replica for
        the v6/v7 series of my replicas first came to my mind, because these two
        completely different styles cannot coexist inside a single replica.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Room_of_Doom_Prisoner_of_Azkaban} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor
        level={2}
        title='"Prisoner of Azkaban with additional labels"'
      />

      <ReplicaParagraph>
        When looking at pictures from different exhibitions where the map is on
        display, or even at the officially licensed replica (which, to be
        honest, ranks extremely low in my research, due to its low quality and
        accuracy to the films), I noticed that there are also additional labels
        that are not featured in the film. My guess is that they wanted to make
        the design a bit more clean on film, so that Harry's and Pettigrew's
        footprints would not be placed over the labels - everything is much more
        clear when you remove them.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={
          galleryPagesOfficial_Room_of_Doom_Prisoner_of_Azkaban_Additional_Labels
        }
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor level={2} title="Room of Doom - Differences" />

      <ReplicaParagraph>
        As I mentioned before, the Early Prop is completely different from the
        Prisoner of Azkaban version - meaning the one that is shown on screen in
        the close-up shots when Harry tries to find Pettigrew. It is apparent
        that the style of the map was not yet fully finalized in the Early Prop,
        because it is quite distinct from all the other pages in the map,
        including the redesigned versions. The layout is also a bit different,
        mostly there are a lot more intersections of the corridors in the Early
        Prop.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Room_of_Doom_Differences} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Astronomy Tower"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_astronomy_tower}
      />

      <ReplicaParagraph>
        This page was completely designed by me. The idea first started by
        wanting to include the Astronomy Tower in the map, because it is quite a
        notable location, since that is where Dumbledore died. I also wanted to
        come up with my own "cool" additions that would make the map more
        intricate, which is how I came up with the idea of including a working
        Lunar Volvelle. This idea was not random, as it is rooted in the fact
        that Remus Lupin, one of the Marauders, is a werewolf. Since the
        Volvelle is meant to be used for working out the position of the sun and
        moon, it seems somewhat relevant to his condition. At the bottom of the
        page, there are also noted the dates of full moons. I'm aware that the
        Marauders were at Hogwarts since 1971, however, they most certainly did
        not write the map right away, which is why I chose to start with the
        year 1974. Granted, that may still be a bit soon, considering the
        magical prowess that was presumably needed to create a map like this,
        and that they did not become Animagi until their fifth year.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Astronomy_Tower} />

      <Video src={videoVolvelle} autoPlay />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Great Hall"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_great_hall}
      />

      <ReplicaParagraph>
        This page is also my own addition to the map, this time featuring the
        Great Hall. Although that is the main section of the page, the more
        noticeable feature is perhaps the flap that extends the page and depicts
        the path to the Boathouse.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Great_Hall} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Hospital Wing"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_hospital_wing}
      />

      <ReplicaParagraph>
        As the name suggests, this page depicts the Hospital Wing, which played
        a prominent role in the Harry Potter and the Chamber of Secrets film.
        Everything was designed by me. I once again tried to come up with a way
        to make the map more intricate, so I came up with this new type of flap,
        that extends the design over the neighboring pages, making the map more
        three dimensional, rather that a flat piece of parchment.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Hospital_Wing} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Office Detail"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_office_detail}
      />

      <ReplicaParagraph>
        This was never intended to be a standalone page, but rather a solution
        to the inconsistency of the designs shown in the Prisoner of Azkaban
        film. When Harry first uses the map, he looks at the{" "}
        <HashLink to={"#" + anchors.page_official_aedificium_oriens}>
          Ædificium Oriens
        </HashLink>{" "}
        page, and it is implied that he notices Dumbledore pacing in his study.
        However, then there's a close-up shot of Dumbledore, and the design of
        the office is quite different to the page that Harry was looking at.
        Since I wanted to have every single design in my replicas, I have
        decided to do it like this.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Office_Detail} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Clock Tower"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_official_clock_tower}
      />

      <ReplicaParagraph>
        While this page is never seen in the films, nor in the original prop, it
        has apparently been added to the map some time during or after the
        filming of the Prisoner of Azkaban, because it can be seen in pictures
        of the map from different exhibitions.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesOfficial_Clock_Tower} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Castle Overview"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_castle_overview}
      />

      <ReplicaParagraph>
        This page is once again my own addition to the map. While going through
        the original designs made for the films, I noticed that they usually
        show either extreme close-ups of certain parts of Hogwarts, or a page
        like the{" "}
        <HashLink to={"#" + anchors.page_official_hogwarts_grounds}>
          Hogwarts Grounds
        </HashLink>{" "}
        which is on the other end of the spectrum, focusing on an extremely
        large area. I wanted to create something in between that would show the
        entirety of Hogwarts, which is how this page came to be.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Castle_Overview} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Greenhouses"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_greenhouses}
      />

      <ReplicaParagraph>
        The greenhouses are first shown in the second film, Harry Potter and the
        Chamber of Secrets, however, they never made it into the original
        Marauder's Map designs. To "fix" this, I came up with my very own page
        depicting this notable location at Hogwarts.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Greenhouses} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Gryffindor Common Room"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_gryffindor_common_room}
      />

      <ReplicaParagraph>
        Since the Marauders were all members of Gryffindor House, it seems
        natural that their common room would also be in the map. The Gryffindor
        common room changes places throughout the films, so to be clear, this
        page should be true to how it is depicted in the first film.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Gryffindor_Common_Room} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Moaning Myrtle's Bathroom"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_moaning_myrtles_bathroom}
      />

      <ReplicaParagraph>
        Moaning Myrtle's bathroom is arguably the most important location of the
        second film, because that is (spoiler alert) where the entrance to the
        Chamber of Secrets is located. When coming up with the idea for this
        page, I had to research to make sure that Moaning Myrtle already haunted
        the bathroom at the time when the Marauders were attending hogwarts,
        because otherwise it wouldn't make sense that they'd include that in the
        map. However, they were at Hogwarts since 1971, while Myrtle died in
        1943, so it is safe to assume that they probably encountered her.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Moaning_Myrtles_Bathroom} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Quidditch Pitch"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_quidditch_pitch}
      />

      <ReplicaParagraph>
        Although there is a version of the{" "}
        <HashLink to={"#" + anchors.page_official_hogwarts_grounds}>
          Hogwarts Grounds
        </HashLink>{" "}
        that includes the Quidditch Pitch, I though that an entire stadium would
        deserve its very own page, which is why I made one.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Quidditch_Pitch} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Training Grounds"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_training_grounds}
      />

      <ReplicaParagraph>
        This page, designed by me, depicts another location from the first film.
        This is where the flying lessons take place, and where Harry first flies
        on a broom and has a confrontation with Malfoy that leads to him
        becoming a seeker on the Gryffindor's Quidditch team.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Training_Grounds} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSubTitleAnchor
        title="Transfiguration Courtyard"
        subtitle={`v${VERSION_DATA.version} page design`}
        anchor={anchors.page_custom_transfiguration_courtyard}
      />

      <ReplicaParagraph>
        The Transfiguration Courtyard leads to the Transfiguration Classroom,
        where Harry takes his first (surprise, surprise) Transfiguration lesson.
        Aside from that, you can also find the Twin Towers and some of the
        greenhouses on the page as well. This is another one of my own additions
        to the map.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryPagesCustom_Transfiguration_Courtyard} />
      <ReplicaSpacer />

      {/*
       *************************************************************
       */}

      <ReplicaSpacer />
      <ReplicaSpacer />
      <ReplicaSpacer />
      <ReplicaSpacer />
      <ReplicaSpacer />
      <ReplicaSpacer />

      <ReplicaQuote source="Remus Lupin, Harry Potter and the Prisoner of Azkaban">
        "Here -- I brought this from the Shrieking Shack last night," he said,
        handing Harry back the Invisibility Cloak. "And..." He hesitated, then
        held out the Marauder's Map too. "I am no longer your teacher, so I
        don't feel guilty about giving you back this as well. It's no use to me,
        and I daresay you, Ron, and Hermione will find uses for it."
      </ReplicaQuote>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
