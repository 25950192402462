import { ProductsIncludeBundles, getProducts } from "utils";
import { useEffect, useState } from "react";

import { AnyProductType } from "types";
import ProductItem from "components/ProductItem";
import styled from "styled-components";

const Products = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  gap: 12px;
  place-items: center;

  ${(props) => props.theme.breakpoint.L} {
    grid-template-columns: repeat(auto-fit, 200px);
  }
  ${(props) => props.theme.breakpoint.S} {
    grid-template-columns: repeat(auto-fit, 180px);
  }
`;

const ProductList = (props: {
  products?: AnyProductType[];
  includeBundles?: ProductsIncludeBundles;
  categoryId?: string;
  groupId?: string;
  productId?: string;
  productItem?: {
    wide?: boolean;
    thin?: boolean;
  };
}) => {
  const [displayedProducts, setDisplayedProducts] = useState(
    props.products || []
  );

  useEffect(() => {
    if (!props.products) {
      setDisplayedProducts(
        getProducts({
          includeBundles: props.includeBundles,
          categoryId: props.categoryId,
          groupId: props.groupId,
          productId: props.productId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.products,
    props.includeBundles,
    props.categoryId,
    props.groupId,
    props.productId,
  ]);

  return (
    <Products>
      {displayedProducts.map((product, i) => (
        <ProductItem
          key={i}
          product={product}
          wide={props.productItem?.wide}
          thin={props.productItem?.thin}
        />
      ))}
    </Products>
  );
};

export default ProductList;
