import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (5) - Deathly Hallows - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub5-deathly-hallows',
  name: 'Deathly Hallows',
  gallery,
  video: 'https://www.youtube.com/watch?v=BFvJqgNJP6M',
  content: (
    <>
      <ReplicaParagraph>
        Much like the prop in the Half-Blood Prince, the Marauder's Map made
        just a quick appearance in the film. The shots from the Deathly Hallows
        Part 1 revealed a page featuring a new design with the headmaster's
        office (with Snape) and Part 2 revealed a redesigned page with the
        seventh floor. The design changes were not that large, the difference is
        in the "bird circle" sign near the compass rose - they removed it.
        However, I noticed that the position of the page must be different from
        the Half-Blood Prince version, because it is impossible to fold the page
        as we see it in the Deathly Hallows Part 2 the way the Half-Blood Prince
        page is attached to the prop - these two just do not fit both the films
        at the same time, so the layout must be different as well.
      </ReplicaParagraph>
    </>
  ),
}

export default data
