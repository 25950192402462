import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_comparison = [];

let galleryWIP_before_paint = [];
let galleryWIP_bunny_ears = [];
let galleryWIP_emitter = [];
let galleryWIP_emitter_cover = [];
let galleryWIP_greeblies = [];
let galleryWIP_handle_cover = [];
let galleryWIP_handle_neck = [];
let galleryWIP_neck_base = [];
let galleryWIP_neck_rib = [];
let galleryWIP_neck_ring = [];
let galleryWIP_plug_pin = [];
let galleryWIP_pommel = [];
let galleryWIP_pommel_cap = [];
let galleryWIP_pommel_neck = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_comparison = importAll(
    (require as any).context("./gallery-comparison", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_before_paint = importAll(
    (require as any).context(
      "./gallery-wip-before-paint",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_bunny_ears = importAll(
    (require as any).context(
      "./gallery-wip-bunny-ears",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter_cover = importAll(
    (require as any).context(
      "./gallery-wip-emitter-cover",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_handle_cover = importAll(
    (require as any).context(
      "./gallery-wip-handle-cover",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_handle_neck = importAll(
    (require as any).context(
      "./gallery-wip-handle-neck",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_neck_base = importAll(
    (require as any).context("./gallery-wip-neck-base", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_neck_rib = importAll(
    (require as any).context("./gallery-wip-neck-rib", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_neck_ring = importAll(
    (require as any).context("./gallery-wip-neck-ring", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_plug_pin = importAll(
    (require as any).context("./gallery-wip-plug-pin", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel_cap = importAll(
    (require as any).context(
      "./gallery-wip-pommel-cap",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_pommel_neck = importAll(
    (require as any).context(
      "./gallery-wip-pommel-neck",
      false,
      /\.(png|jpe?g)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: true,
  page: undefined,
  dateCreated: createDate({ day: 15, month: 12, year: 2023 }),
  datePublished: createDate({ day: 7, month: 8, year: 2024 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is an entirely original design that I came up with in an attempt to
        bring some of my own design ideas to life. For the purposes of
        presentation, I dubbed this lightsaber "hope". Why? Because the design
        is heavily based on the two hero lightsabers from Episode 4 - A New
        Hope. I still consider Luke's Graflex-based lightsaber and Obi-wan's
        found-parts-saber my all time favorite designs, so this is a way of
        honoring them.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />

      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=2E1V74KFQdA"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Design" />

      <ReplicaParagraph>
        The entire project started when I was thinking about making a new
        lightsaber, but this time with a little bit more freedom. I also already
        have most of the lightsaber hilts from George Lucas' Star Wars in my
        DetailBundle, and I wanted something unique. Since I love the Graflex
        design, and also Obi-wan's thin-neck lightsaber, I started thinking
        about combining these two into a single hilt. And just like that, this
        lightsaber was born.
      </ReplicaParagraph>

      <ReplicaParagraph>
        I didn't want to just blatantly take parts of each hilt and mash them
        together, but instead mimic the design highlights of each saber to
        create something that is "inspired" by those designs, not "copy-pasted".
        The similarities are mostly on the front part of the lightsaber. I
        considered including a Graflex clamp in my design, or the t-tracks for a
        grip, but both of these took the final design too close to originals. I
        also wanted the lightsaber to be more ergonomic, so I let go of the
        clamp and t-tracks in favor of my own design.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_comparison)}
        size={GallerySize.FullWidth}
      />

      <ReplicaSubTitleAnchor title="Emitter" />

      <ReplicaParagraph>
        The emitter shape is very close to Obi-wan's lightsaber, but with
        different dimensions to better fit the overall esthetic of the
        lightsaber hilt. Obi-wan's emitter is way to big for my uses, so I
        changed the proportions/size.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Just like the emitter, the thin neck of the saber closely resembles
        Obi-wan's saber, but with different dimensions. I also made the neck
        from brass to both make it close to obi-wan's, and to give the
        lightsaber a more interesting look by using different materials.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck_base)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        To make the final emitter assembly easier to machine, I split the neck
        into multiple parts that would be much easier to make on a lathe. These
        parts are then stacked on the brass neck body (above) and the emitter is
        screwed on top of it to hold it all together.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck_rib)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The emitter is mostly made out of metal, but I also 3D printed some of
        the internal parts. This allowed me to create complex parts that I would
        never be able to machine out of metal with precision.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck_ring)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Emitter Cover" />

      <ReplicaParagraph>
        The front part of the lightsaber covers the emitter and inner parts.
        This section was heavily inspired by the Graflex flashgun used in
        Episode 4 and 5 for Luke's lightsaber. Although I love the original
        asymmetrical shape, it didn't really work with the emitter design. I
        also wanted to make it a bit different from the Graflex shape, so I made
        it symmetrical.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter_cover)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The "bunny ears" on Graflex flashgun look good, but since they're placed
        a bit farther from the emitter in my design, and they're on both sides,
        they would get in the way of comfortably holding the lightsaber. Due to
        this, and to change things a bit from the original, I scaled the bunny
        ears down and made them more flushed with the outer diameter.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_bunny_ears)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The last thing needed to complete the emitter and its cover was to
        machine four identical pins. These go into the Graflex-like holes on the
        sides of the lightsaber. Machining such small parts can be a bit tricky,
        because they can often bend when they get too thin.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_plug_pin)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        With the front part of the lightsaber finished, it was time to move on
        to the rear/middle section. I originally intended to put t-tracks here
        as a grip, but I felt that it started to look too much like Luke's
        Graflex lightsaber, and it didn't really work proportionally with the
        rest of the lightsaber. Instead, I chose to put two mirrored grooves on
        the sides of the handle with LEDs inside (these are not wired). This
        style is more prequel-like in design, but I think it fits the hilt
        rather nicely.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle_cover)} />
      <ReplicaSpacer />
      <ReplicaParagraph>
        To connect the front and rear sections of the lightsaber, I machined
        this "neck" part. There isn't really anything special about this part,
        it required just a bit of work on a lathe to get it done. With the two
        parts of the lightsaber now fitting together into a single hilt, the
        lightsaber was almost finished.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle_neck)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        The pommel is another part (aside from the handle) that has a unique
        design not based on the two lightsabers. I wanted to have a pommel
        similar to Obi-wan's lightsaber, but it didn't really fit the overall
        design. In the end, I came up with a pommel with only shallow grooves
        (unlike Obi-wan's), and a bit more complex shape.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The pommel is split into three individual parts. This was necessary,
        because I wouldn't otherwise be able to machine the part the way I
        designed it. There are inner grooves that would complicate things a lot.
        However, when split into three parts, it was rather easy to make the
        pommel.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel_neck)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The bottom of the pommel is designed to hold a D-ring. The prequel
        trilogy lightsabers use Covertec Wheel to hang the hilt onto a belt.
        Although I don't mind it (quite the opposite), I felt like this saber
        really deserved the old-school D-ring that fits the original trilogy
        era.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel_cap)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        With the hilt practically finished, the very last bit was adding
        "greeblies". I machined two non-working buttons that (at first glance)
        resemble the Graflex top button, and the glass eye at the bottom. When
        designing the hilt, I strategically placed the buttons in places where
        they wouldn't get in the way when holding the lightsaber with both
        hands. My goal was to make a hilt that would feel practical and
        comfortable to hold.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_greeblies)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Before Aging" />
      <ReplicaParagraph>
        After all the parts were finished, it was time to finally assemble the
        complete lightsaber hilt. While I always like the "raw" look of the
        lightsabers I make, the final painting, chemical blackening and
        weathering is really necessary to tie everything together and give the
        hilt a history. All in all, I'm quite pleased with how it turned out.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_before_paint)} />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
