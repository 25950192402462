import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (3) - Order of the Phoenix - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub3-order-of-the-phoenix',
  name: 'Order of the Phoenix',
  gallery,
  video: 'https://www.youtube.com/watch?v=x_elWGQH-BA',
  content: (
    <>
      <ReplicaParagraph>
        The prop created for the Order of the Phoenix was, unfortunately, never
        seen in the final film. However, there are some pictures of the page
        that they designed for the seventh floor - with the Room of Requirement.
        Now, if you're a Harry Potter fan, you already know that it is
        impossible for the Room of Requirement to be on the map, because it is
        unplottable. That's a mistake that the original authors also realized,
        so the room is not visible in the next iterations of the page design.
        However, I really like the 3D design that they put together, so I
        managed to figure out how to do it myself (a painfully slow process).
      </ReplicaParagraph>
      <ReplicaParagraph>
        I also have the information that they used the original designs that
        were seen on screen in the Prisoner of Azkaban for the other pages
        (please note that this version is still quite different from the
        "Prisoner of Azkaban" replica). Anyway, I had enough information to put
        together a version of the prop that could be close to the actual prop
        that we've never seen.
      </ReplicaParagraph>
    </>
  ),
}

export default data
