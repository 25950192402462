import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 12, month: 7, year: 2019 }),
  version: "1",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        I've been wanting to create something from The Lord of the Rings
        universe for quite a long time, but (until now) I never really decided
        what I wanted to make. The Lord of the Rings props are more about
        swords, rings (duh...), armor, etc., but there are also quite a few
        paper props - maps, to be precise, and I LOVE old maps. What also
        excited me about this project were the invisible runes. I've been
        thinking about various solutions, and ended up using a UV reactive
        paint. Just like in the movie, the runes are invisible, but they can be
        revealed with UV light.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} />
      <ReplicaSpacer />

      <YouTubeVideo src="https://www.youtube.com/watch?v=YENmUwu8azc" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
