import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";

import DetailAAEHUDFactory from "./DetailAAEHUDFactory";
import DetailDetailBundle from "./DetailBundle";
import DetailProduct from "./DetailProduct";

const DetailWhatsInside = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isBundle = product.isBundle;
  const isAAEHUDFactory = product.isAAEHUDFactory;

  if (isAAEHUDFactory) {
    product = product as ProductAAEHUDFactoryType;
    return (
      <DetailAAEHUDFactory product={product as ProductAAEHUDFactoryType} />
    );
  }

  if (isBundle) {
    return <DetailDetailBundle product={product as ProductBundleType} />;
  }

  return <DetailProduct product={product as ProductType} />;
};

export default DetailWhatsInside;
