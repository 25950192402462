import Gallery, { GalleryImageType } from "components/Media/Gallery";
import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";

import ContentBox from "components/ContentBox";
import styled from "styled-components";
import { transformImages } from "utils";

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const DetailGallery = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isBundle = product.isBundle;
  let images = {};

  if (!isBundle) {
    product = product as ProductType;
    images = product.images;
  }

  if (isBundle) {
    product = product as ProductBundleType;
    product.products.forEach((p) => {
      if (p.images) {
        images = { ...images, ...p.images };
      }
    });
  }

  const galleryImages: GalleryImageType[] = transformImages(images);

  return galleryImages?.length ? (
    <ContentBox title="Gallery">
      <Wrapper>
        <Gallery images={galleryImages} />
      </Wrapper>
    </ContentBox>
  ) : null;
};

export default DetailGallery;
