import styled, { css } from "styled-components";

const Wrapper = styled.div<{ level: number }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  ${(props) =>
    props.level === 2 &&
    css`
      margin-top: 30px;
    `}

  h2 {
    span {
      font-size: 0.8em;

      ${(props) =>
        props.level === 2 &&
        css`
          font-size: 0.5em;
          letter-spacing: 0.15em;
          color: ${(props) => props.theme.white}cc;
        `}
    }
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: -70px;
`;

const Subtitle = styled.h3`
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${(props) => props.theme.white}88;

  width: 80%;
`;

const SpacerTop = styled.div<{ level: number }>`
  height: 3vh;

  ${(props) =>
    props.level === 2 &&
    css`
      height: 0vh;
    `}
`;

const SpacerContent = styled.div`
  height: 2vh;

  ${(props) =>
    props.level === 2 &&
    css`
      height: 0;
    `}
`;

const ReplicaSubTitleAnchor = (props: {
  level?: 2;
  anchor?: string;
  title: string;
  subtitle?: string;
}) => {
  return (
    <Wrapper level={props.level}>
      {props.anchor && <Anchor id={props.anchor} />}
      <SpacerTop level={props.level} />
      <h2>
        <span>{props.title}</span>
      </h2>
      {props.subtitle && (
        <Subtitle className="subtitle">{props.subtitle}</Subtitle>
      )}
      <SpacerContent level={props.level} />
    </Wrapper>
  );
};

export default ReplicaSubTitleAnchor;
