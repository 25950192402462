import {
  AnyProductType,
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";
import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";
import ProductItemBase from "components/ProductItemBase";
import fileFormats from "assets/misc/fileFormats";
import { routes } from "config";
import { useState } from "react";

const Box = styled.div<{ wide?: boolean; thin?: boolean }>`
  position: absolute;
  border-radius: 2px;
  background: ${(props) => props.theme.black}DD;
  padding: 10px 10px;
  //min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  transition: all 0.2s ease;

  right: 36px;
  bottom: 60px;

  ${(props) =>
    props.thin
      ? css`
          right: 27px;
          bottom: 54px;
        `
      : css``}

  ${(props) =>
    props.wide
      ? css`
          left: 40%;
          right: 40%;
          bottom: 66px;
        `
      : css``}
`;

const Wrapper = styled(Link)`
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    ${Box} {
      background: ${(props) => props.theme.black}EE;
    }
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.white};

  * {
    font-weight: 500;
  }
  span {
    font-size: 0.9em;
    margin-right: 1px;
  }
`;
const PricePrevious = styled.div`
  --line-color: ${(props) => props.theme.red}AA;

  color: ${(props) => props.theme.red};
  margin-right: 8px;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    var(--line-color) 49.5%,
    var(--line-color) 50.5%,
    transparent 52.25%
  );
  opacity: 0.6;
`;

const PriceSave = styled.div`
  color: ${(props) => props.theme.green};
  margin-bottom: 8px;
  font-size: 0.9em;
  font-weight: 600;
  opacity: 0.9;
`;

const Icon = styled.div`
  color: ${(props) => props.theme.white};
  margin-bottom: 10px;
  --iconSize: 17px;
  svg {
    width: var(--iconSize);
    height: var(--iconSize);
  }
`;

const Tags = styled.div`
  margin-bottom: 10px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  color: ${(props) => props.theme.white};
  font-size: 10px;
  font-weight: 500;
  opacity: 0.7;
`;

const ProductItem = (props: {
  product: AnyProductType;
  wide?: boolean;
  thin?: boolean;
  hideInfo?: boolean;
}) => {
  let product = props.product as AnyProductType;
  const isBundle = product.isBundle;
  const isAAEHUDFactory = product.isAAEHUDFactory;
  const isRegularProduct = !isBundle && !isAAEHUDFactory;

  const [isHovered, setIsHovered] = useState<boolean>(false);

  //
  // RENDER
  //
  const renderPrice = () => {
    let previousPrice = props.product.previousPrice;
    if (isBundle) {
      const productPricesSum = (props.product as ProductBundleType).products
        ?.map((p) => p.price)
        .reduce((a, b) => a + b, 0);
      previousPrice = productPricesSum;
    }

    return (
      <Price>
        {previousPrice ? (
          <PricePrevious>
            <span>$</span>
            {previousPrice}
          </PricePrevious>
        ) : null}
        <div>
          <span>$</span>
          {props.product.price}
        </div>
      </Price>
    );
  };

  const renderPriceSaving = () => {
    const bundle = product as ProductBundleType;
    if (isBundle && bundle.products?.length) {
      let priceSaved = 0;
      bundle.products.forEach((product) => {
        priceSaved += product.price;
      });
      priceSaved = priceSaved - bundle.price;

      return (
        <PriceSave>
          {"Save "}
          <span>$</span>
          {priceSaved}
        </PriceSave>
      );
    }
  };

  const renderFormatIcons = () => {
    let icon: any = null;
    product = product as ProductType;
    if (isRegularProduct && product.contents?.length) {
      for (const content of product.contents) {
        if (content?.format?.icon) {
          if (!content.format.icon._source) {
            continue;
          }
          icon = content.format.icon;
          break;
        }
      }
    }
    if (isAAEHUDFactory) {
      icon = fileFormats.aep.icon;
    }
    return icon ? <Icon>{icon}</Icon> : null;
  };

  const renderTags = () => {
    product = props.product as ProductType | ProductAAEHUDFactoryType;
    if ((isRegularProduct || isAAEHUDFactory) && product.tags?.length) {
      return product.tags?.length ? (
        <AnimateHeight
          id={`${product.id}-${Math.random() * 1000}`}
          duration={400}
          height={isHovered && product.tags.length > 1 ? "auto" : 20}
        >
          <Tags>
            {product.tags?.map((tag, i) => (
              <div key={i}>{tag}</div>
            ))}
          </Tags>
        </AnimateHeight>
      ) : null;
    }
  };

  return (
    <Wrapper
      to={`/${
        props.product.isAAEHUDFactory
          ? routes.aaeHudFactory.slug
          : routes.eshop.slug
      }/${props.product.id}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <ProductItemBase
        product={props.product}
        wide={props.wide}
        thin={props.thin}
        subtitle={
          props.product.isBundle
            ? "Product Bundle"
            : props.product.isAAEHUDFactory
            ? "AAE HUD Factory v2"
            : ""
        }
      />

      {!props.hideInfo && (
        <Box wide={props.wide} thin={props.thin}>
          {renderPrice()}
        </Box>
      )}

      {false && !props.hideInfo && (
        <Box wide={props.wide} thin={props.thin}>
          {true /* disable icons without warnings about unused 'renderFormatIcons' definition */
            ? null
            : renderFormatIcons()}
          {renderTags()}
          {renderPriceSaving()}
          {renderPrice()}
        </Box>
      )}
    </Wrapper>
  );
};

export default ProductItem;
