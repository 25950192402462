import { useState } from 'react'
import { routes } from 'config'

import LayoutSections from 'layouts/LayoutSections'

import Intro from './Sections/Intro'
import PurchaseGuide from './Sections/PurchaseGuide'
import HelpDesk from './Sections/HelpDesk'
import License from './Sections/License'
import WarrantiesRefunds from './Sections/WarrantiesRefunds'

const TermsAndConditions = () => {
  const [activeId, setActiveId] = useState(routes.terms.anchors.intro)
  const [scrollId, setScrollId] = useState(null)

  const sections = [
    {
      label: 'Intro',
      component: (
        <Intro
          onNavClick={() => setScrollId(routes.terms.anchors.purchaseGuide)}
        />
      ),
      id: routes.terms.anchors.intro,
    },
    {
      label: 'Purchase Guide',
      component: (
        <PurchaseGuide
          onNavClick={() => setScrollId(routes.terms.anchors.helpDesk)}
        />
      ),
      id: routes.terms.anchors.purchaseGuide,
    },
    {
      label: 'Help Desk',
      component: (
        <HelpDesk
          onNavClick={() => setScrollId(routes.terms.anchors.license)}
        />
      ),
      id: routes.terms.anchors.helpDesk,
    },
    {
      label: 'License',
      component: (
        <License
          onNavClick={() =>
            setScrollId(routes.terms.anchors.warrantiesAndRefunds)
          }
        />
      ),
      id: routes.terms.anchors.license,
    },
    {
      label: 'Warranties/Refunds',
      component: <WarrantiesRefunds />,
      id: routes.terms.anchors.warrantiesAndRefunds,
    },
  ]

  return (
    <LayoutSections
      sections={sections}
      activeId={activeId}
      setActiveId={setActiveId}
      scrollId={scrollId}
      setScrollId={setScrollId}
    />
  )
}

export default TermsAndConditions
