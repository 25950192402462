import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import styled from "styled-components";

let gallery = [];
let images = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  images = importAll(
    (require as any).context("./images", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 15, month: 8, year: 2016 }),
  version: "4",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const progressImages = transformImages(images);

  const images1 = [...progressImages].splice(0, 4);
  const images2 = [...progressImages].splice(4, 2);
  const images3 = [...progressImages].splice(6, 2);
  const images4 = [...progressImages].splice(8, 4);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        After watching the Prisoner of Azkaban and comparing it to my replica,
        I've decided it was time to really put some work into the precision of
        my work. Until this moment, I was basically trying to recreate the
        official replica. But that's not what I wanted, I wanted the map from
        the film, and that's different from the official replica. That's why
        I've decided to start again from scratch, throwing out all the reference
        pictures of the replica and using just the films as a reference. I
        started by trying to finally figure out my problem with the paper. I
        tried several ways to age the paper, but none of them worked very well.
        But I still kept trying, because I simply wanted to finally solve this.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images1} />
      <SpacerContent />

      <ReplicaParagraph>
        One day, I was going through a stationery store (again), and I finally
        found the kind of paper I was looking for. I immediately brought it home
        and started to work on the parchment texture for it. It worked just the
        way I imagined, so the problem was finally solved for good (Or so I
        thought - spoiler alert, the next versions each use a different type of
        paper).
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images2} />
      <SpacerContent />

      <ReplicaParagraph>
        After the paper problem, the second thing you see the most, is the
        text/font. That's why I decided to create the fonts again from scratch,
        using the knowledge I gained from creating the previous fonts. I didn't
        try to speed things up, I really wanted to do this right, because the
        whole map is based on this.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images3} />
      <SpacerContent />

      <ReplicaParagraph>
        Once the fonts were finished, I could start working on the pages based
        on the film prop. It was often very difficult, because you can't see the
        map in the films that much. Some pages were never seen in the film, and
        one page (7th floor) has two different designs... it was actually much
        more difficult to choose between those two, than working without any
        reference. The layout of this replica is also different from the
        official replica, because I tried to stay true to the film. Again, to
        keep the map symmetrical (because of the 7th floor page), I had to
        design two more pages. I always love working on my own designs, which I
        always based on Hogwarts architecture.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images4} />
      <SpacerContent />

      <ReplicaParagraph>
        Anyway, for quite some time, version 4 was thought to be the final
        version. I didn't see myself doing another version anytime soon, as I
        was pretty happy with it at the moment. It took me more than 3 years of
        hard work to get it looking like this.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <SpacerContent />
      <YouTubeVideo src="https://www.youtube.com/watch?v=Dq9Y1oCCOPE" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
