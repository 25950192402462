import Gallery, { GallerySize } from "components/Media/Gallery";
import { ReplicaVersionType, SpriteDirection, ThumbnailSize } from "types";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";
import thumbnailSprite from "./thumbnail-anim-sprite-6-frames.png";

let gallery = [];
let galleryPower = [];
let gallery_wip_electronics = [];
let gallery_wip_emitter = [];
let gallery_wip_emitterTip = [];
let gallery_wip_grenade = [];
let gallery_wip_handle = [];
let gallery_wip_motorHousingAssembly = [];
let gallery_wip_neck = [];
let gallery_wip_pommel = [];
let gallery_wip_prePaintAssembly = [];
let gallery_wip_preparations = [];
let gallery_wip_steelCore = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryPower = importAll(
    (require as any).context("./gallery-power", false, /\.(png|jpe?g|svg)$/)
  );

  gallery_wip_electronics = importAll(
    (require as any).context(
      "./gallery-wip-electronics",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_emitter = importAll(
    (require as any).context(
      "./gallery-wip-emitter",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_emitterTip = importAll(
    (require as any).context(
      "./gallery-wip-emitter-tip",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_grenade = importAll(
    (require as any).context(
      "./gallery-wip-grenade",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_handle = importAll(
    (require as any).context(
      "./gallery-wip-handle",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_motorHousingAssembly = importAll(
    (require as any).context(
      "./gallery-wip-motor-housing-assembly",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_neck = importAll(
    (require as any).context("./gallery-wip-neck", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_wip_pommel = importAll(
    (require as any).context(
      "./gallery-wip-pommel",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_prePaintAssembly = importAll(
    (require as any).context(
      "./gallery-wip-prepaint-assembly",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_preparations = importAll(
    (require as any).context(
      "./gallery-wip-preparations",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_steelCore = importAll(
    (require as any).context(
      "./gallery-wip-steel-core",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: true,
  page: undefined,
  dateCreated: createDate({ day: 3, month: 2, year: 2024 }),
  datePublished: createDate({ day: 7, month: 8, year: 2024 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail: {
    static: thumbnail,
    sprite: thumbnailSprite,
    frameCount: 6,
    direction: SpriteDirection.horizontal,
  },
  thumbnailSize: ThumbnailSize.L,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Since childhood, I've been captivated by the lightsaber effects in
        George Lucas' original Star Wars films. What I find most impressive is
        that the lightsaber effects in Star Wars Episode 4: A New Hope were
        primarily achieved through in-camera techniques. Although this method
        posed limitations during filming, and if I'm not mistaken, it was
        abandoned for Episode 5, I still find the concept of creating lightsaber
        effects in-camera to be incredibly impressive. This replica endeavors to
        capture the essence of the original stunt saber used for Obi-Wan
        Kenobi's lightsaber scenes, featuring a motor housed inside the hilt
        that spins the blade covered in reflective material. It's this spinning
        motion that provides the illusion of a pulsating blade. Furthermore,
        it's noteworthy that this same hilt, commonly known in the community as
        "v2", was later repurposed in Return of the Jedi for Luke Skywalker's
        new lightsaber.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The original motorized hilt required wires to run through the lightsaber
        pommel, up to the actors' sleeves, and connect to an external battery.
        While crafting my replica, I took some liberties to update certain
        elements. I opted for contemporary connectors, utilized a black wire
        instead of white, and crafted a custom battery box. Nevertheless, the
        fundamental concept remains faithful to the essence of the original
        prop. Acknowledging the significance of spinning the blade at the
        correct speed, I also incorporated a speed regulator. This regulator can
        be optionally attached to the battery case and inserted into the circuit
        to precisely control the spinning speed. The battery case is designed to
        be conveniently attached to a person's belt, allowing for easy handling
        of the lightsaber while carrying the battery.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryPower)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        To ensure all parts and tools required for assembling the prop are
        neatly organized and easily accessible, I sourced a box of appropriate
        size and designed its interior to securely hold all components in place.
        Additionally, I included all necessary Allen wrenches inside the box to
        facilitate disassembly and reassembly. To account for potential losses,
        I also added a few spare screws. The box also includes velcro straps
        designed to secure the wire to the wearer's arm, preventing it from
        flapping around during use.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaParagraph>
        This project has been one of the most challenging endeavors I've
        undertaken, primarily due to my limited experience with wiring.
        Designing my own circuit with a motor, a speed regulator, and a
        custom-wired battery box—all of which needed to fit inside a slender
        lightsaber hilt—was no easy task. To tackle this challenge, I began by
        creating a temporary circuit to ensure it functioned as needed before
        proceeding with the hilt assembly.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_preparations)}
        size={GallerySize.FullWidth}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Motor Circuit" />

      <ReplicaParagraph>
        After gaining confidence in my ability to execute the project, I began
        working on the final circuit. This involved connecting a motor to a
        lever switch, mirroring the setup of the original prop, and ensuring a
        wire would extend from the hilt. Recognizing that the small hole in the
        pommel wouldn't accommodate the connector, I incorporated additional
        parts to facilitate easy disassembly and reassembly of the circuit
        without the need for repeated soldering. I aimed to ensure that all
        wires were attached to the parts via connectors, making it easy to swap
        individual parts and dismantle the circuit with ease. The external
        circuit features a custom battery case and a speed regulator, which can
        be attached to control the motor's spin speed.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_electronics)}
        size={GallerySize.L}
      />

      <ReplicaSubTitleAnchor title="'Grenade' and Handle" />

      <ReplicaParagraph>
        The design of this stunt hilt was inspired by Obi-Wan Kenobi's hero
        lightsaber prop, although not precisely replicated. This departure from
        exact replication reflects the historical practices of that time, where
        stringent visual consistency was not a top priority, and a reasonable
        resemblance sufficed. The design of the hero prop would have posed
        challenges for prop makers due to its limited space to house the motor.
        Additionally, attempting to precisely replicate the hilt would have been
        unnecessarily challenging, given its construction from real-life parts.
        Notably, one of these parts originates from a grenade, hence why I refer
        to the ribbed section as a 'Grenade'.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_grenade)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The connection between the grenade and handle parts posed specific
        challenges, as they had to accommodate the motor and its wiring inside,
        along with a mechanism for securing the motor via set screws.
        Additionally, space had to be allocated for the transistors attached to
        the Graflex clamp that surrounds these parts, and a method for securely
        holding these two parts together had to be devised.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_handle)}
        size={GallerySize.L}
      />

      <ReplicaSubTitleAnchor title="Motor Housing Assembly" />

      <ReplicaParagraph>
        As mentioned above, the motor housing assembly comprises the grenade and
        handle parts, along with a Graflex clamp. The grenade part underwent
        careful machining to precisely fit the motor to a specific depth, with
        three set screws positioned around the motor to secure it inside.
        Meanwhile, the handle features a lever switch protruding for toggling
        the motor on and off. To facilitate ease of assembly, an additional
        connector joins the motor and the switch.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_motorHousingAssembly)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Steel Core" />

      <ReplicaParagraph>
        After completing the motor assembly, the next step was to transfer the
        spinning motion to the detachable blade outside the hilt. Following the
        design of the original prop, I machined a steel rod that would be
        attached to the motor on one side and exit the emitter through a ball
        bearing on the other. The blade could then be mounted onto the steel rod
        and secured using a set screw, allowing it to spin when the motor was
        activated.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_steelCore)}
        size={GallerySize.L}
      />

      <ReplicaSubTitleAnchor title="Neck" />

      <ReplicaParagraph>
        To ensure that the steel rod could extend fully out of the lightsaber,
        it was necessary to keep the parts hollow. This presented a challenge,
        requiring me to machine the 'neck' part connecting the emitter and the
        grenade part with threaded ends. This allowed all the parts to be
        screwed together seamlessly, while maintaining the hollow core.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_neck)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Emitter" />

      <ReplicaParagraph>
        From my research, it appears that there are two theories on how the
        emitter worked. One theory suggests that the entire emitter was
        spinning, but I'm not entirely convinced of its practicality. It seems
        more logical to have just the blade spinning. I suspect this theory may
        have arisen from observations of the stunt hilt breaking at the thin
        spot on the "neck" before the emitter, leading to the misconception that
        the emitter was always detached. However, I acknowledge that I'm not an
        authority on the details and may not be entirely correct. Nonetheless,
        I've chosen to have only the blade spinning. To ensure smooth rotation
        without any drag, I incorporated a ball bearing inside the body of the
        emitter, secured in place by a set screw. Given that this screw can also
        be found on the original prop, I strongly suspect that this is the way
        it originally worked.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_emitter)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I'm not entirely certain that the tip of the emitter was a part of the
        original prop in Episode 4. For one, there is no such part featured on
        Obi-Wan's hero lightsaber hilt - it is hollow. I believe it is more
        likely that this "tip" part was actually the bottom end of the blade,
        which had a set screw to attach the blade to the steel rod. As I
        mentioned before, this hilt was later repurposed to serve as Luke's
        lightsaber in Return of the Jedi. I theorize that they simply cut the
        end of the blade and kept it as part of the emitter. Since I have no
        solid proof that the hilt in Episode 4 didn't have this tip, and I have
        evidence that it is featured in Return of the Jedi, I have decided to
        include it in this replica. After all, it can be detached at any time,
        which means that I can have the emitter both with or without the tip.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_emitterTip)}
        size={GallerySize.L}
      />

      <ReplicaSubTitleAnchor title="Pommel" />

      <ReplicaParagraph>
        The pommel is always the part I dread the most, all because of the
        grooves that need to be milled at precise angles. I have always machined
        this part myself from scratch, but it was always a nerve-wracking
        ordeal. For this replica, I initially attempted to make the pommel
        myself as usual. However, after spending an entire day working on it,
        only to have the grooves end up misaligned, I sought out an alternative
        solution. I decided to have the grooves CNC machined by a third-party
        company. Once I received the semi-finished part with the grooves
        machined, I completed the remaining work myself. Although it was not
        cheap to have the grooves machined externally, it was certainly worth it
        considering how much stress and time I ended up saving in the end.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_pommel)}
        size={GallerySize.L}
      />

      <ReplicaSubTitleAnchor title="Pre-Paint Assembly" />

      <ReplicaParagraph>
        With the pommel finished, I was finally able to assemble all the
        components, including the motor and all the greeblies. Before moving on
        to the final painting and weathering process, I conducted a test to
        ensure the functionality of the hilt with a spinning blade. It's worth
        noting that the blades for the original props were not cylindrical, but
        slightly tapered, with a diameter that varied along their length. This
        presented a significant challenge as finding a conical tube of the
        required diameter and length took several weeks. After a successful
        test, I proceeded to finish the hilt with painting, chemical blackening,
        and weathering.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_prePaintAssembly)}
        size={GallerySize.FullWidth}
      />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
