import { css } from 'styled-components'
import { defaultButtonCss } from './Buttons'

//
// Default Styles
//

const resetLinkCss = css`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    background: none;
  }
`

export const defaultLinks = css`
  a {
    ${resetLinkCss}
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
    transition: all 0.2s ease;

    &.link-as-button {
      ${defaultButtonCss}
      text-decoration: none;
    }
  }
`
