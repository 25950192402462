import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import styled from "styled-components";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 13, month: 6, year: 2011 }),
  version: "2",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        So, the first version was finished, and as you can see, there was a lot
        of room for improvement. For the second version, I tried to find a
        different paper. Something that would look more like a piece of
        parchment without the need of printing all of it. I tried different
        types of paper and even a wrapping paper, but none of it gave me what I
        was looking for, so the second version stayed unfinished.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
