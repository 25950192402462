import { routes } from "config";
import styled from "styled-components";
import LayoutFullscreen from "layouts/LayoutFullscreen";

import SectionHeading from "components/SectionHeading";
import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import YouTubeVideo from "components/Media/YouTubeVideo";

import Background from "assets/background/background_aae_hud_factory.jpg";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Video = styled.div`
  width: 90%;
  max-width: 1100px;
`;

const Small = styled.span`
  position: relative;
  font-size: 0.35em;
  font-weight: 600;
  letter-spacing: 0.3em;
  opacity: 0.8;
  bottom: -4px;
`;

const Intro = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={Background}>
      <Wrapper>
        <SectionHeading
          title={
            <>
              AAE HUD Factory<Small>v2.0</Small>
            </>
          }
          subtitle={[
            "Realistic HUDs with eye reflections and dynamic lighting",
            "No 3rd Party Plugins Needed",
          ]}
          link={`/${routes.aaeHudFactoryFeatures.slug}`}
          linkTitle="Project Features"
        />

        <Spacer />
        <Video>
          <YouTubeVideo
            src="https://www.youtube.com/watch?v=oa7DfI_l4k4"
            autoplay
            hideControls
          />
        </Video>
        <Spacer />

        <ButtonScrollToSection onClick={props.onNavClick} />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Intro;
