import { ProductType, SpriteDirection } from "types";

import SHARED_PRINTABLE_INFO from "../shared-info";
import background from "./background.png";
import explodedView_Labels from "./exploded-view_labels.png";
import explodedView_Sprite from "./exploded-view_sprite.png";
import explodedView_Thumbnail from "./exploded-view_thumbnail.png";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
/* try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
} */

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1KgCAmc2J-i4zLNTs2mHbLQyREMReURpO/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Printable-Lightsaber-Luke-Ep6-V2",
  name: "Luke Ep6 Lightsaber\n'Vee-Two'",
  description: () => (
    <>
      {SHARED_PRINTABLE_INFO.getPropDescription(
        `Luke's v2 stunt lightsaber from Episode 6: Return of the Jedi`
      )}{" "}
      {SHARED_PRINTABLE_INFO.getDiagramDescription()}
    </>
  ),

  price: SHARED_PRINTABLE_INFO.prices.lightsaber,
  softwareRequired: [],

  categories: [productCategories.file3DPrintable],
  groups: [productGroups.starWars],
  license: licenses.personal,

  images: gallery,
  videos: [],
  explodedView: {
    static: explodedView_Thumbnail,
    sprite: explodedView_Sprite,
    frameCount: 11,
    direction: SpriteDirection.vertical,
    labels: explodedView_Labels,
  },

  contents: [...SHARED_PRINTABLE_INFO.contents],
  tags: [...SHARED_PRINTABLE_INFO.tags],

  text: [
    ...SHARED_PRINTABLE_INFO.texts.graflexClampLogo,
    {
      type: "divider",
    },
    ...SHARED_PRINTABLE_INFO.texts.lukeEp6Lightsabers,
  ],
};

export default data;
