import DeadSeaScrollsAllSouldDeuteronomy from "./history-dead-sea-scrolls-all-souls-deuteronomy";
import UsDeclarationOfIndependence from "./history-us-declaration-of-independence";

// WIP REPLICAS WILL BE FILTERED OUT OF THE LIST OF REPLICAS
// AND WILL BE ACCESSIBLE THROUGH /replicas-wip URL
const replicas = [
  DeadSeaScrollsAllSouldDeuteronomy,
  UsDeclarationOfIndependence,
];

export default replicas;
