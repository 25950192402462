import Background from "assets/background/background_aae_hud_factory_section_demo.jpg";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import SectionHeading from "components/SectionHeading";
import YouTubeVideo from "components/Media/YouTubeVideo";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 25vh;
`;

const Spacer = styled.div`
  height: 3vh;
`;

const Video = styled.div`
  width: 90%;
  max-width: 1100px;
`;

const Intro = () => {
  return (
    <LayoutFullscreen background={Background}>
      <Wrapper>
        <SectionHeading
          title="Incredible Variability"
          subtitle={[
            "A powerful tool for creating HUDs",
            "Design your own HUDs",
          ]}
          link={`/${routes.aaeHudFactoryFeatures.slug}`}
          linkTitle="Project Features"
        />

        <Spacer />
        <Video>
          <YouTubeVideo src="https://www.youtube.com/watch?v=j7BCltXTAjU" />
        </Video>
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Intro;
