import Contact from "components/Contact";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import background from "assets/background/background_planet-02_bottom.jpg";

const ContactSection = () => {
  return (
    <LayoutFullscreen background={background}>
      <Contact />
    </LayoutFullscreen>
  );
};

export default ContactSection;
