import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import DeedOfContract from "assets/replicas/lord-of-the-rings/lotr-deed-of-contract";
import { Link } from "react-router-dom";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import ThrorsMap from "assets/replicas/lord-of-the-rings/lotr-thrors-map";
import { routes } from "config";
import singleImg1 from "./single-images/marauders-map-v5.0-01-00.jpg";
import singleImg2 from "./single-images/marauders-map-v5.0-02-00.jpg";
import styled from "styled-components";

let gallery = [];
let gallery2 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  gallery2 = importAll(
    (require as any).context("./gallery2", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 25, month: 6, year: 2019 }),
  version: "5.0",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryImages2 = transformImages(gallery2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        The urge to continue perfecting the Marauder's Map came back like a
        boomerang - enter version 5.0, 5.1 and 5.2. I just wanted to create
        another pages and extend the v4 replica, but I ended up revisiting the
        entire prop. When I was working on version 4, I developed a project and
        a DetailBundle of tools to help me generate the pages. I turned those
        tools into the AAE Map Maker (After Effects project) and used it to
        create this version of the map. The AAE Map Maker was developed for
        creating Marauder's Map pages, but it proved to be quite useful for any
        type of map or document - I used it to create the first versions of my
        replicas of{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ThrorsMap.id}
          target="_blank"
        >
          Thrór's map
        </Link>{" "}
        and the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + DeedOfContract.id}
          target="_blank"
        >
          Deed of Contract
        </Link>{" "}
        , and it worked brilliantly. This version has much more pages than the
        previous ones, I designed a lot of new pages and included one last part
        from the film, which I totally forgot in the all the previous versions -
        but more on that later. I also used a different paper than before...
        again.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages} size={GallerySize.S} />
      <SpacerContent />

      <ReplicaParagraph>
        All of my projects start with research. This time (it's the fifth
        version) there wasn't much that I could find online that I didn't see
        before. However, I was re-watching all the films one day, and I noticed
        that I totally missed that the Marauder's Map makes a quick appearance
        in the Deathly Hallows Part 1. In the scene, Harry is watching Snape in
        the headmaster's office, so I fixed my mistake and now you can see that
        page in my replica as well:
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={[{ src: singleImg1, name: `Marauder's Map v5.0` }]} />
      <SpacerContent />

      <ReplicaParagraph>
        Once the research was over, I started with the design process. I reused
        a lot of my designs from the previous version, because they were
        compatible with the AAE Map Maker workflow. Well, they are the reason
        the workflow is the way it is, the AAE Map Maker was developed from
        those pages, so it makes sense. Anyway, the idea is that you can use a
        toolbox to design your pages in black and white (quite simple) and then
        use an AAE project that will convert those designs into a printable
        page. It gives it an ink-written look, which is fully customizable and
        you can use two layers. It also generates infinite ultra HD parchment
        texture, so you can chain multiple pages together, and the connections
        are seamless - that's the general idea.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={[{ src: singleImg2, name: `Marauder's Map v5.0` }]} />
      <SpacerContent />

      <ReplicaParagraph>
        Anyway, I revisited all the previous pages, got rid of my previous
        designs and designed a lot of brand new pages. I also had to tweak the
        overall layout of the prop, so that it wouldn't be just a loooong piece
        of paper, but something more intricate. I tried to use the opportunity
        of designing the pages myself to cover the remaining parts of the castle
        - the library, the Great Hall, the boathouse, Quidditch pitch ...
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages2} />
      <SpacerContent />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
