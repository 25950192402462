import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import styled from "styled-components";

let gallery = [];
let images = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  images = importAll(
    (require as any).context("./images", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 20, month: 4, year: 2012 }),
  version: "3",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  const progressImages = transformImages(images);

  const images1 = [...progressImages].splice(0, 2);
  const images2 = [...progressImages].splice(2, 2);
  const images3 = [...progressImages].splice(4, 2);
  const images4 = [...progressImages].splice(6, 4);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Now that I established some workflow to this, I started to dive deeper
        into the details of the map. Most important of all, I've decided to
        create my own font based on the original prop. After all, all you can
        see on the map - where ever you look - is the text. The font is mostly
        hand-written and scanned. I've never tried to make a font before, so
        again, this took a lot of time.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images1} />
      <SpacerContent />

      <ReplicaParagraph>
        Since I didn't find the correct paper yet, I continued printing the map
        on a regular white paper - but this time from both sides. Also, this is
        the first time that I've decided to add the missing page from the
        Half-Blood Prince featuring the 7th floor corridor. This page is missing
        from the official replica, because they didn't update it after the new
        films came out.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images2} />
      <SpacerContent />

      <ReplicaParagraph>
        This time, I spent a lot more time tinkering with the look of the
        parchment for printing. I did a lot of test prints, as you can see
        below. It's not the worst result, but you can tell that the base is
        white - especially when you cut the paper, you can see the white edges.
        I did a lot of test prints of the final pages as well, just so I could
        figure out how to put it all together. Since I was adding the 7th floor
        corridor page, I had to design two more pages to keep the map
        symmetrical. This was quite fun, because I had a free reign over the
        whole page, so I could do whatever I wanted.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images3} />
      <SpacerContent />

      <ReplicaParagraph>
        To check the layout of the map, I printed it in a smaller scale, and put
        it together. This allowed me to check what I was doing without wasting
        too much time and printer ink on it. And, of course, the miniature looks
        cool. The layout of the map is very important, and I dedicated a lot of
        time into planning this. As you can see, I created several types of
        layouts. This helped me to prepare the pages for the final printing,
        because I knew exactly where the glue seams would be.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images4} />
      <SpacerContent />

      <ReplicaParagraph>
        The v3 replica was not too bad (well, it kinda was, according to my
        current standards), but I still felt that I could do better. I still
        don't like the fact that it's printed on a white paper, and the fonts
        that I created also needed some work. Most of the problems I had with
        this map were actually caused by the official replica - I used reference
        images of the replica to guide me, but the replica is really different
        from the prop in the Prisoner of Azkaban. That means that the layout of
        the map is all wrong, there are some pages missing, the design of the
        pages is also very different etc. etc. I also still wasn't too happy
        with the resolution of the pages. It was a little bit better than the
        previous one, but you could still see some pixelation.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
