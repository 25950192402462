import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import VoldemortsWand from "assets/replicas/harry-potter/hp-voldemorts-wand";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWip1 = [];
let galleryWip2 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip1 = importAll(
    (require as any).context("./wip1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip2 = importAll(
    (require as any).context("./wip2", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 9, month: 8, year: 2019 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryWipImages1 = transformImages(galleryWip1);
  const galleryWipImages2 = transformImages(galleryWip2);

  //const galleryDayImages2_sub1 = [...galleryDayImages2].splice(0, 9)

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        I'm a fan of many franchises, and Harry Potter is among those. I've read
        all the books when I was a kid and they first came out. I remember that
        I was in the first grade when I first discovered the world of Harry
        Potter, about two years before the first film went to cinemas, if I
        recall it correctly. When the movies came out, I fell in love with the
        visual design of this world. The props are just beautiful. Anyway, just
        like any other Harry Potter fan, I've always wanted to have a wand in my
        DetailBundle. And, being the person I am, I always wanted to make one
        myself, not buy it.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I chose to create the Elder Wand first, because its design seemed easy
        to replicate. As this was my first wand, I had to figure out what type
        of material I would use. I've decided to go with air-dry clay (I know
        now that it was not the best choice). As with all my replicas, I had to
        do my research first and look at a lot of images of the wand, before
        actually starting to work on the prop itself.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />
      <ReplicaSubTitleAnchor title="Sculpting" />

      <ReplicaParagraph>
        The base of the wand is a wooden stick. I then added clay on top of the
        base and did a rough approximation of the final shape. As I said before,
        the air-dry clay is not very good for this type of work. First of all,
        you have to wait quite a long time (24 hours, if I remember correctly)
        for the clay to dry. I'm not a fan of waiting, so this part of the
        process was no fun at all. Another flaw of this material is the fact
        that the clay cracked in a few places as it dried. The cracks were not a
        big deal for this wand, but I can't imagine doing{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + VoldemortsWand.id}
          target="_blank"
        >
          Voldemort's wand
        </Link>{" "}
        like this, it wouldn't work. Anyway, after the clay finally dried, I
        took my dremmel tool and used it to sculpt the final shape of the wand.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryWipImages1} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Painting" />
      <ReplicaParagraph>
        Once I had the shape of the wand, the only thing left to do was to paint
        the whole thing. I didn't have much experience with painting, and this
        was the first time I tried to imitate wood, so it was a trial-and-error
        type of approach. I painted the wand black and then I started adding
        brown paint. I spent quite a lot of time on this part of the process. I
        used knife to scrape pieces of the brown layer off of the wand - I was
        hoping to get a nice wooden texture this way. It worked, but it took
        some time to get the hang of it. I often scraped too much of the paint
        off, so I had to paint it again and again... and again.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {galleryWipImages2?.length > 0 && (
        <Gallery images={galleryWipImages2} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaParagraph>
        Anyway, I think I managed to get a nice texture in the end. The last bit
        were the runes on the wand. I originally intended to print a little
        paper with the runes on it, and glue it onto the wand. It didn't look so
        good, so I've decided to paint it by hand - that turned out to be the
        better choice.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Final Though" />
      <ReplicaParagraph>
        I think the wand turned out looking great in the end, although I have to
        admit that I was getting a bit sceptical throughout the painting
        process. Also, I discovered that the air-dry clay is a bad choice for
        this type of build, its brittle and often cracks when drying. All in
        all, I'm very happy with the result.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
