import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Description = styled.p`
  width: 100%;
  max-width: 800px;
  text-align: justify;
`

const Subtitle = styled.p`
  font-size: 9px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${props => props.theme.white}44;

  width: 80%;
`

const SectionSubHeading = (props: {
  title: any
  subtitle?: string | string[]
  description?: string
}) => {
  return (
    <Wrapper>
      <h3>{props.title}</h3>
      {props.subtitle &&
        (typeof props.subtitle === 'string' ? (
          <Subtitle className="subtitle">{props.subtitle}</Subtitle>
        ) : (
          <>
            {(props.subtitle as string[]).map((s, i) => (
              <Subtitle key={i} className="subtitle">
                {s}
              </Subtitle>
            ))}
          </>
        ))}
      {props.description && <Description>{props.description}</Description>}
    </Wrapper>
  )
}

export default SectionSubHeading
