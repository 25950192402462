import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_assembly = [];
let galleryWIP_blade_plug = [];
let galleryWIP_blade_plug_pin = [];
let galleryWIP_emitter = [];
let galleryWIP_grenade_cap = [];
let galleryWIP_grenade = [];
let galleryWIP_handle = [];
let galleryWIP_neck = [];
let galleryWIP_pommel = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_assembly = importAll(
    (require as any).context("./gallery-wip-assembly", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_blade_plug = importAll(
    (require as any).context(
      "./gallery-wip-blade plug",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_blade_plug_pin = importAll(
    (require as any).context(
      "./gallery-wip-blade plug pin",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_grenade_cap = importAll(
    (require as any).context(
      "./gallery-wip-grenade cap",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_grenade = importAll(
    (require as any).context(
      "./gallery-wip-grenade v2",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_handle = importAll(
    (require as any).context("./gallery-wip-handle", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_neck = importAll(
    (require as any).context("./gallery-wip-neck", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: true,
  page: undefined,
  dateCreated: createDate({ day: 5, month: 1, year: 2024 }),
  datePublished: createDate({ day: 7, month: 8, year: 2024 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This lightsaber is a replica of a particularly intriguing prop. Fans
        familiar with lightsaber props from the original Star Wars trilogy
        likely know that the hilt considered to be Luke's canonical lightsaber
        was assembled only after the filming of Return of The Jedi. However,
        this prop, dubbed "v3," remains somewhat of a mystery. While its color
        scheme matches Luke's official hilt design, there are significant
        differences between the two. One notable distinction is the inclusion of
        the Graflex clamp on v3, absent from the official design.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />

      {/* <YouTubeVideo
        src="https://www.youtube.com/watch?v=ywFxOT1CfPA"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer /> */}

      <ReplicaParagraph>
        The prop hilt wielded by Luke in the Return of the Jedi is referred to
        as "v2" and receives considerable screen time. From my research, I
        believe that v3 originates from the same mold as v2, making them
        identical in construction. However, v3 appears to have been later
        painted to resemble the official design of Luke's lightsaber, often
        leading to confusion with the canon hilt. For instance, a picture of
        this hilt can be found in the Visual Dictionary, although it is not
        considered canon.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Neck and 'Grenade' Parts" />
      <ReplicaParagraph>
        Knowing that the lightsaber utilizes a Graflex clamp, I aimed to
        maintain its functionality from the outset. Consequently, I integrated
        slots for the clamp into the lightsaber's components, enabling easy
        disassembly akin to the original Graflex flashgun. The original prop, as
        far as I understand, was cast in solid aluminum. Given that my replica
        is assembled from machined parts, resulting in a construction that
        differs significantly, incorporating the Graflex clamp appears more
        beneficial than detrimental to the final product, even if it deviates
        slightly from the original prop.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_grenade)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        My previous lightsaber replicas were consistently constructed with a
        threaded rod running through the middle to hold all the parts together.
        However, as I'm planning something far more complex than a static
        replica, I've opted to explore a new method with this hilt and abandon
        the threaded rod approach. Instead, I've chosen to connect the parts by
        screwing them together. Although this entails additional work machining
        the threads, it yields a solid assembly with a hollow core.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_grenade_cap)}
        size={GallerySize.S}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Wherever the design permitted – or required it, to be more precise – I
        utilized existing visible screws from the original prop to aid in
        holding the pieces together. I did not add any additional screws to the
        overall design that would differ from the original prop. A prime example
        of this is the neck part below, which is attached to the assembly via a
        set screw.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck)} />

      <ReplicaSubTitleAnchor title="Emitter" />
      <ReplicaParagraph>
        Based on the reference photos I gathered, it seems that the "v3" saber
        is distinguished by a notably large set screw on the side of the
        emitter. This represents the most distinctive difference from the "v2"
        emitter, despite both sharing the same basic shape. Given that both the
        v2 and v3 emitters likely originate from the same mold, this similarity
        in shape is to be expected.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_emitter)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Unlike the "v2" saber, the emitter of "v3" is hollow. Since the inside
        of the emitter is often difficult to discern in reference photos, I had
        to rely on my imagination to envision its internal structure. I suspect
        that the original prop may have contained a ball bearing inside, through
        which a motorized steel rod protruded outside of the saber to attach a
        stunt blade. This speculation is based on the fact that this prop
        originates from the era of Episode 4, when lightsaber blades were
        practical effects. It's possible that, over time, the steel rod inside
        the "v3" hilt was cut down.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_blade_plug)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        To imitate the mechanism mentioned above, I too machined a short piece
        of steel rod and inserted it into the core of the emitter. However, in
        my replica, the rod does not extend all the way through to the middle of
        the lightsaber, as there is no motor to spin it anyway.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_blade_plug_pin)} />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        The handle is a relatively simple part to machine on a lathe. Like the
        original prop, there's a set screw installed at the end of the handle,
        which will later be used to secure the pommel in place. Similar to the
        "grenade" part mentioned earlier, I incorporated slots that enable
        disassembly of the lightsaber through the Graflex clamp mechanism.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle)} />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        The pommel on "v3" and similar lightsabers presents a considerable
        challenge to machine using my small lathe. While I'm capable of doing
        so, milling the grooves typically requires an entire day's work, and
        there's a high risk of misalignment. I believe I had to remake the
        pommel twice to achieve the desired quality. The original prop housed a
        motor with wires protruding from a small hole in the pommel to connect
        to a power supply. This hole is still present on the "v3" hilt, even
        though no wires pass through it. In my replica, I included this hole but
        opted not to drill all the way through. The hole will be covered with
        tape, mimicking the original prop, and it will only reveal the outline
        of the hole. This eliminates the need to drill all the way through.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel)} />

      <ReplicaSubTitleAnchor title="Pre-Paint Assembly" />
      <ReplicaParagraph>
        This is perhaps the most satisfying part of the prop-making process -
        the final assembly of all the parts. This is when the lightsaber looks
        brand new, without any paint or weathering. It's worth noting that the
        Graflex clamp on the "v3" features a few fairly large holes, which are
        not yet visible in the photos below. Once I ensured that I had all the
        necessary parts, it was time to paint and weather the lightsaber to
        achieve the final prop replica.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_assembly)}
        size={GallerySize.XXL}
      />

      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
