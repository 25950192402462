import { ReplicaType } from "types";
import Version0 from "./version-0";
import Version1 from "./version-1";
import Version2 from "./version-2";
import Version3 from "./version-3";
import Version4 from "./version-4";
import Version5_0 from "./version-5.0";
import Version5_1 from "./version-5.1";
import Version5_2 from "./version-5.2";
import Version6 from "./version-6";
import Version7 from "./version-7";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "marauders-map",
  name: `The Marauder's Map`,
  origin: "Harry Potter movies, books and my ideas",

  sort: {
    preferredIndex: 0,
  },

  category: replicaCategories.harryPotter,

  versions: [
    Version7,
    Version6,
    Version5_2,
    Version5_1,
    Version5_0,
    Version4,
    Version3,
    Version2,
    Version1,
    Version0,
  ],
};

export default data;
