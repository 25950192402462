import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";

import SectionHeading from "components/SectionHeading";
import products from "assets/products";
import { routes } from "config";
import styled from "styled-components";

const Description = styled.p`
  margin-top: 3vh;
  text-align: justify;
`;

const DetailHeading = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isBundle = product.isBundle;
  const isAAEHUDFactory = product.isAAEHUDFactory;

  //
  // Getters
  //
  /* const getTags = () => {
    let tags = [];
    if (isBundle) {
      const bundle = product as ProductBundleType;
      bundle.products.forEach((p) =>
        p.tags.forEach((tag) => {
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
        })
      );
    } else {
      tags = (product as ProductType).tags;
    }
    return tags;
  };

  const getSubtitle = () => {
    let subtitleArray: string[] = [];
    if (isBundle) {
      subtitleArray.push(
        `DetailBundle of ${
          (product as ProductBundleType).products.length
        } products`
      );
    } else {
      subtitleArray.push(`Standalone Product`);
    }
    subtitleArray.push(getTags().join(", "));
    return subtitleArray;
  }; */

  const getDescription = () => {
    if (isBundle) {
      return (
        <Description>
          A DetailBundle of {(product as ProductBundleType).products.length}{" "}
          products neatly packed together and available for a lower price.
          Below, you can find and explore all the products included in this
          DetailBundle. If you're interested in saving money while getting more
          than one product at a time, this is the perfect choice for you!
        </Description>
      );
    }
    if (isAAEHUDFactory) {
      return null;
    }

    const description = (product as ProductType).description;

    return (
      <Description>
        {typeof description === "string"
          ? description
          : description({ products, routes })}
      </Description>
    );
  };

  //
  // RENDER
  //
  return (
    <>
      <SectionHeading
        title={product.name}
        /*  subtitle={getSubtitle()} */
        link={
          isAAEHUDFactory
            ? `/${routes.aaeHudFactory.slug}`
            : `/${routes.eshop.slug}`
        }
        linkTitle={isAAEHUDFactory ? "AAE HUD Factory" : "E-Shop"}
      />
      {getDescription()}
    </>
  );
};

export default DetailHeading;
