import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "sw-lightsaber-custom-01",
  name: `Lightsaber\nCustom Design "Hope"`,
  origin: "Original Design by Jan Hamernik",

  category: replicaCategories.starWars,

  versions: [Version1],
};

export default data;
