import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaQuote from "components/Replicas/ReplicaQuote";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaStats from "components/Replicas/ReplicaStats";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import imageBooks from "./image - books.png";
import thumbnail from "./thumbnail.png";

let gallery = [];
let gallery_PrinceVsClean = [];
let gallery_Handwriting = [];
let gallery_EverlastingElixirs = [];
let gallery_Golpalott = [];
let gallery_Bezoar = [];
let gallery_Draught = [];
let gallery_Sectumsempra = [];
let gallery_Spells = [];
let gallery_Levicorpus = [];
let gallery_Euphoria = [];
let gallery_Felix = [];
let gallery_Polyjuice = [];
let gallery_Potions = [];
let gallery_Amortentia = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_PrinceVsClean = importAll(
    (require as any).context(
      "./gallery - prince vs clean",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Handwriting = importAll(
    (require as any).context(
      "./gallery - handwriting style",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_EverlastingElixirs = importAll(
    (require as any).context(
      "./gallery - everlasting elixirs",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Golpalott = importAll(
    (require as any).context(
      "./gallery - golpalott",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Bezoar = importAll(
    (require as any).context("./gallery - bezoar", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_Draught = importAll(
    (require as any).context(
      "./gallery - draught of living death",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Sectumsempra = importAll(
    (require as any).context(
      "./gallery - sectumsempra",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Spells = importAll(
    (require as any).context("./gallery - spells", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_Levicorpus = importAll(
    (require as any).context(
      "./gallery - levicorpus",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Euphoria = importAll(
    (require as any).context(
      "./gallery - euphoria",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Felix = importAll(
    (require as any).context("./gallery - felix", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_Polyjuice = importAll(
    (require as any).context(
      "./gallery - polyjuice",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_Potions = importAll(
    (require as any).context("./gallery - potions", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_Amortentia = importAll(
    (require as any).context(
      "./gallery - amortentia",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 18, month: 8, year: 2022 }),
  datePublished: createDate({ day: 9, month: 2, year: 2023 }),
  version: "2",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Slughorn strode over to a corner cupboard and, after a moment’s
        foraging, emerged with two very battered-looking copies of Advanced
        Potion-Making by Libatius Borage, which he gave to Harry and Ron along
        with two sets of tarnished scales.
      </ReplicaQuote>

      <ReplicaParagraph>
        Making its appearance in Harry Potter and the Half-Blood Prince, the
        Advanced Potion-Making textbook was an integral part of the story. In
        the first potions class of his sixth year, Harry inadvertently ended up
        with an old copy of the textbook that belonged to the so called
        "Half-Blood Prince". Instructions in this book were heavily modified by
        the original owner, the "Prince", who also used the book to scribble
        down various spells, jinxes and other useful tips. Throughout the story,
        Prince's copy of the book allowed Harry to excel in potion-making, until
        it ultimately got him into trouble after using an unknown dangerous
        spell - Sectumsempra.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=WxRDhzYOkaw" />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The goal of this replica was to create something that could pass off as
        a real textbook that could be read from cover to cover. The pages are
        covered in hundreds of handwritten notes, modifying the original
        instructions, referencing/clarifying information in the book, writing
        down useful/invented spells and much more. It was written and formatted
        as a real-life book, meaning that page numbers in the list of contents
        or in the Potions and Ingredients Index all match the actual pages, same
        as any page numbers mentioned in the text itself when referencing other
        parts of the book. On the outside, the book features a lot of silver
        embellishments, from gilded edges to the canvas book cover, which was
        aged to closely resemble the original prop.
      </ReplicaParagraph>

      <ReplicaStats
        labels={[
          <b>Version 2</b>,
          "completely reworked from scratch",
          "more accurate to the original prop",
          "immersive and fully readable",
          "hundreds of handwritten notes",
          "silver embellishments",
          "100% handmade",
          "and much more!",
        ]}
        img={imageBooks}
      />

      <ReplicaSubTitleAnchor title={`"Clean" vs "Prince's" Copy`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Although the amount of homework they had been given prevented him from
        reading the whole of his copy of Advanced Potion-Making, he had skimmed
        through it sufficiently to see that there was barely a page on which the
        Prince had not made additional notes, not all of them concerned with
        potion-making. Here and there were directions for what looked like
        spells that the Prince had made up himself.
      </ReplicaQuote>

      <ReplicaParagraph>
        When making the replica, it was necessary to create the entire textbook
        first, and then fill it with Prince's handwritten notes. This process
        naturally lead to two versions of this replica - Prince's copy and a
        "clean" copy without all the handwritten notes. This is actually a
        second version (v2) of the replica that I created, and one of the
        improvements over the previous version was staying more true to the
        description in the quote above -{" "}
        <i>
          "there was barely a page on which the Prince had not made additional
          notes"
        </i>
        . Taking inspiration from that sentence, I tried to take my time when
        creating the handwritten notes, both to keep my ideas fresh and to add
        as much of Prince's notes as possible without getting too tired of this
        stage of the process. I also made Prince's copy much more battered than
        the clean version, which I justify by the fact that the Prince was
        obviously using the book quite a lot, it was practically a notebook for
        his experiments.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_PrinceVsClean)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Matching Prince's Handwriting Style`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry bent swiftly over the tattered book Slughorn had lent him. To his
        annoyance he saw that the previous owner had scribbled all over the
        pages, so that the margins were as black as the printed portions.
        Bending low to decipher the ingredients (even here, the previous owner
        had made annotations and crossed things out) Harry hurried off toward
        the store cupboard to find what he needed.
      </ReplicaQuote>

      <ReplicaParagraph>
        When it came to the handwritten notes, two things were very important to
        me. It was essential that all the notes matched the handwriting style
        that can be seen in the film prop. This was relatively easy for the
        parts of the book that were designed for the film, because there was a
        clear reference to how the notes should look. However, I then had to
        extrapolate from these designs and basically learn to write in the same
        style by hand, figuring out the individual letters and their distinct
        shapes. This was quite difficult, because my natural handwriting is very
        different from Prince's - I'm much more inclined to use straight lines
        in my writing, for example. Prince's handwriting is also sometimes
        barely readable, which is in stark contrast with my quite legible style.
        In the beginning, I often had to re-write the notes until I was
        satisfied with their look, but since there were literally hundreds
        (maybe thousands) of handwritten notes, I got the hang of the new style
        relatively soon. In fact, I later had to basically re-learn my natural
        style, because I got so used to writing the notes that it stuck with me
        for quite some time after that.
      </ReplicaParagraph>

      <ReplicaParagraph>
        The second important thing was the placement and quantity of the notes.
        The quote above very specifically states that there were many notes and
        annotations to the printed text, so my goal was to really focus on each
        page and fill it with as many notes as I could think of. Many of these
        are addressing the printed text, but there are also notes about Prince's
        spells and jinxes and other interesting stuff relevant to the topic in
        the current chapter. The notes also had to be rather chaotically placed,
        which, once again, was a bit of a problem for me. I'm basically Prince's
        polar opposite, because I like to keep things organized and tidy, but I
        understand that such style would be very boring to look at, so I had to
        overcome this "problem".
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Handwriting)} />
      <ReplicaSpacer />

      <ReplicaBigTitle title={`True to the Film and the Book`} />
      <ReplicaParagraph>
        The challenging part of creating Harry Potter replicas is often my
        desire to fully replicate the movie prop, but, at the same time, keep it
        true to its description in the Harry Potter books. Luckily, the props
        are usually not designed in their entirety, leaving a lot of areas to be
        filled with my own designs based on the book descriptions. For this
        replica, everything that can be seen in the film can also be found in my
        replica, as well as everything that was designed for the original prop
        that I could find. However, only very few pages were actually designed,
        and since I had to fill the entire textbook, there were hundreds of
        pages that were (almost) entirely up to me to come up with. I was not
        completely free in my designs, because I had to follow the chapters and
        topics that can be seen in the list of contents on the original prop.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title={`Draught of Living Death`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        “So,” said Slughorn, suddenly brisk, “how are you to win my fabulous
        prize? Well, by turning to page ten of Advanced Potion-Making. We have a
        little over an hour left to us, which should be time for you to make a
        decent attempt at the Draught of Living Death.
        <br />
        ...
        <br />
        Having finished chopping his roots, Harry bent low over his book again.
        It was really very irritating, having to try and decipher the directions
        under all the stupid scribbles of the previous owner, who for some
        reason had taken issue with the order to cut up the sopophorous bean and
        had written in the alternative instruction: 'Crush with flat side of
        silver dagger, releases juice better than cutting.'
      </ReplicaQuote>

      <ReplicaParagraph>
        Page 10, featuring the instructions for Draught of Living Death, is
        perhaps the most documented section of the textbook. It plays a big role
        in both the film and the book, for it is the first time that Harry comes
        across Prince's notes and discovers how useful they are. Luckily, the
        original design of the prop, that is heavily featured in the film, is
        fairly accurate to the book description, so I followed the original
        design in its entirety. If I were to nitpick one difference, Prince's
        note in the film says "<i>Crush with blade, releases juice better</i>",
        while the book version is "
        <i>
          Crush with flat side of silver dagger, releases juice better than
          cutting
        </i>
        ". Generally, when I'm creating a replica, the design of the film prop
        takes precedence over the book description, which is why I kept the
        shorter note, which I assume was shortened to work better on screen.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Draught)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`An Elixir to Induce Euphoria`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Cheered by this thought, Harry skimmed through his copy of Advanced
        Potion-Making and found a heavily corrected Half-Blood Prince’s version
        of “An Elixir to Induce Euphoria,” which seemed not only to meet
        Slughorn’s instructions, but which might (Harry’s heart leapt as the
        thought struck him) put Slughorn into such a good mood that he would be
        prepared to hand over that memory if Harry could persuade him to taste
        some...
        <br />
        ...
        <br />
        And what’s that I smell? Mmmm... you’ve added just a sprig of
        peppermint, haven’t you? Unorthodox, but what a stroke of inspiration,
        Harry, of course, that would tend to counterbalance the occasional side
        effects of excessive singing and nose-tweaking.... I really don’t know
        where you get these brain waves, my boy... unless —” Harry pushed the
        Half-Blood Prince’s book deeper into his bag with his foot. “— it’s just
        your mother’s genes coming out in you!” “Oh... yeah, maybe,” said Harry,
        relieved.
      </ReplicaQuote>

      <ReplicaParagraph>
        The Elixir to Induce Euphoria is briefly mentioned in one of the
        potion-making classes. It is described in the book as being heavily
        corrected by the Prince, so, naturally, I tried to stay true to that
        description in my version of the instructions in the textbook. Slughorn
        also mentioned that Harry added <i>a sprig of peppermint</i>, which was
        obviously Prince's addition as well, so I made sure to include that as
        Prince's note in my replica.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Euphoria)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Polyjuice Potion`} />

      <ReplicaParagraph>
        The Polyjuice Potion was one of the first prominent potions that
        appeared in the Harry Potter Series. It made its first appearance in
        Harry Potter and the Chamber of Secrets, when the trio secretly makes
        the potion in order to confirm their suspicions that Malfoy was
        Slytherin's heir. Although it does not make an appearance in Harry
        Potter and the Half-Blood Prince, the pages for its instructions were
        actually designed for the original prop of the textbook. They do not
        make an appearance in the film (the second page does, but it is
        obviously misplaced in the context of the book), but the pages can be
        clearly visible in photos of the prop, which is why I also replicated
        these for my replica.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Polyjuice)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Felix Felicis`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry pulled his copy of Advanced Potion-Making out of his bag and
        looked up Felix Felicis. “Blimey, it’s seriously complicated,” he said,
        running an eye down the list of ingredients. “And it takes six months...
        You’ve got to let it stew....” “Typical,” said Ron.
      </ReplicaQuote>

      <ReplicaParagraph>
        The Felix Felicis potion plays a huge role in the story, making it
        possible for Harry to retrieve the important memory from Slughorn. All
        of that was possible thanks to Prince's notes in the Advanced
        Potion-Making textbook, which helped Harry win Felix by concocting the
        perfect Draught of Living Death. However, Felix Felicis gets another
        mention in relation to the textbook, and that was when Harry found the
        instructions on how to brew it, stating it was seriously complicated. To
        expand on that idea, I made the instructions much longer that the other
        ones, spanning over four pages in total.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Felix)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Other Potions`} />

      <ReplicaParagraph>
        Apart from the already mentioned potions, there are lots of instructions
        for other potions as well, including Amortentia (the most powerful love
        potion in existence), preparation of Veritaserum, Ageing potion,
        Swelling Solution and much more. There's also a recipe for the Wolfsbane
        Potion, with which I had to make a difficult choice whether to add it to
        the textbook or not. The problem is, it is supposed to be a rather
        recent discovery. This is confirmed by Remus Lupin in Harry Potter and
        the Prisoner of Azkaban:
      </ReplicaParagraph>

      <ReplicaQuote source="Remus Lupin, Harry Potter and the Prisoner of Azkaban">
        I was a very small boy when I received the bite. My parents tried
        everything, but in those days there was no cure. The potion that
        Professor Snape has been making for me is a very recent discovery. It
        makes me safe, you see.
        <br />
        ...
        <br />
        Before the Wolfsbane Potion was discovered, however, I became a fully
        fledged monster once a month. It seemed impossible that I would be able
        to come to Hogwarts. Other parents weren't likely to want their children
        exposed to me.
      </ReplicaQuote>

      <ReplicaParagraph>
        From the quote above, it can be reasonably assumed that the potion did
        not exist when Remus Lupin was attending Hogwarts, because otherwise
        they'd use it to make Lupin's transformations less dangerous. However,
        the Advanced Potion-Making textbook already existed at that time,
        because that's when the Prince (Severus Snape) was writing his notes in
        it, and that's the exact same textbook that Harry now owns. Despite all
        of this, the textbook's list of contents I was able to find actually
        includes Wolfsbane Potion, so there was a decision needed to be made. I
        ultimately decided to include this potion, because it is recognizable
        and it is more likely that people would notice it was missing, rather
        than figure out that the potion could not be a part of the textbook,
        because it did not exist.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Potions)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The book is also filled with random in-universe notes. For example,
        there's a list of potions, where the Prince wrote 'L.E.' next to
        Amortentia. I came up with this idea as a little nod to him being in
        love with Lily Evans. At that point in time, he might have been
        contemplating tricking her into drinking Amortentia (love potion),
        because their relationship was basically ruined at that time. I only
        wrote the initials 'L.E.', because Harry would most probably recognize
        the full name of his own mother - and he was actively trying to find
        anything that would point to the identity of the Prince.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Amortentia)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Golpalott’s Third Law`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry pulled out his trusty copy of Advanced Potion-Making and turned to
        the chapter on antidotes. There was Golpalott’s Third Law, stated word
        for word as Hermione had recited it, but not a single illuminating note
        in the Prince’s hand to explain what it meant. Apparently the Prince,
        like Hermione, had had no difficulty understanding it.
      </ReplicaQuote>

      <ReplicaParagraph>
        This is purely from the book. Golpalott’s Third Law was mentioned during
        one of Harry's potion classes, and it was the one time that Harry got
        really close to having his newly found reputation as a master potion
        maker come crumbling down, due to his inability to understand it on his
        own. This happened during a class about antidotes, so that's where this
        section can be found in my version of the textbook.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Golpalott)} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        More to avoid watching this irritating sight than anything else, Harry
        bent over the Half-Blood Prince’s book and turned a few pages with
        unnecessary force. And there it was, scrawled right across a long list
        of antidotes: Just shove a bezoar down their throats. Harry stared at
        these words for a moment. Hadn’t he once, long ago, heard of bezoars?
      </ReplicaQuote>

      <ReplicaParagraph>
        In the end, Harry gets saved by a mention of a bezoar. Although it is
        described (see quote above) as being <i>scrawled right across</i> the
        list of antidotes, I chose to deviate from this description a bit by
        putting the note next to the list, rather than across. Why? I found out
        that when I wrote the note across the list, it was barely readable, and
        since it is so important to the story, I've decided to go with the more
        readable option.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Bezoar)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Sectumsempra`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        “He would if you’d just listened to Snape in our first year,” said
        Hermione dismissively. Harry ignored her. He had just found an
        incantation (“Sectumsempra!”) scrawled in a margin above the intriguing
        words “For Enemies,” and was itching to try it out, but thought it best
        not to in front of Hermione. Instead, he surreptitiously folded down the
        corner of the page.
      </ReplicaQuote>

      <ReplicaParagraph>
        The Sectumsempra curse was a turning point in Harry's "relationship"
        with the textbook. After seriously wounding Draco Malfoy by using the
        curse, Harry got rid of the textbook in the Room of Requirement, never
        to be seen again in both the film and the books. Once again, there are
        slight differences between the film and the book version of the page,
        but as I mentioned before, the film takes precedence.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Sectumsempra)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Scribbled Spells and Jinxes`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry felt, however, that the Half-Blood Prince’s copy of Advanced
        Potion-Making hardly qualified as a textbook. The more Harry pored over
        the book, the more he realized how much was in there, not only the handy
        hints and shortcuts on potions that were earning him such a glowing
        reputation with Slughorn, but also the imaginative little jinxes and
        hexes scribbled in the margins, which Harry was sure, judging by the
        crossings-out and revisions, that the Prince had invented himself.
        <br />
        ...
        <br />
        Harry had already attempted a few of the Prince’s self-invented spells.
        There had been a hex that caused toenails to grow alarmingly fast (he
        had tried this on Crabbe in the corridor, with very entertaining
        results); a jinx that glued the tongue to the roof of the mouth (which
        he had twice used, to general applause, on an unsuspecting Argus Filch);
        and, perhaps most useful of all, Muffliato, a spell that filled the ears
        of anyone nearby with an unidentifiable buzzing, so that lengthy
        conversations could be held in class without being overheard.
      </ReplicaQuote>

      <ReplicaParagraph>
        Apart from helpful tips about potion-making, the Prince also wrote down
        a lot of hexes and jinxes. I took great care to include all the
        mentioned spells in my replica as well, along with{" "}
        <i>the crossings-out and revisions</i>. On top of those, I also added a
        few more spells here and there, either as an out-of-context note by the
        Prince, or as a helpful tip for the potion-making process. Below are a
        few examples of those.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Spells)} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Sitting up in bed, Harry turned the book sideways so as to examine more
        closely the scribbled instructions for a spell that seemed to have
        caused the Prince some trouble. There were many crossings-out and
        alterations, but finally, crammed into a corner of the page, the
        scribble: Levicorpus (nvbl) While the wind and sleet pounded
        relentlessly on the windows, and Neville snored loudly, Harry stared at
        the letters in brackets. Nvbl . . . that had to mean “nonverbal.”
        <br />
        ...
        <br />
        He groped for the potion book and riffled through it in a panic, trying
        to find the right page; at last he located it and deciphered one cramped
        word underneath the spell: Praying that this was the counter-jinx, Harry
        thought Liberacorpus! with all his might.
      </ReplicaQuote>

      <ReplicaParagraph>
        The 'Levicorpus' spell deserves a bit of attention, because there is a
        rather detailed description of how this spell was scribbled down in the
        book, along with the '(nvbl)' note (meaning 'nonverbal') and the
        counter-jinx. The scene also illustrates how careless Harry was when he
        performed unknown spells and jinxes on the people around him, which
        eventually lead to unintentionally wounding Malfoy with the Sectumsempra
        curse.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_Levicorpus)}
        size={GallerySize.M}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Everlasting Elixirs`} />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry said nothing. He thought his voice might soon vanish from lack of
        use. He bent a little lower over Advanced Potion-Making and continued to
        make notes on Everlasting Elixirs, occasionally pausing to decipher the
        Prince’s useful additions to Libatius Borage’s text.
      </ReplicaQuote>

      <ReplicaParagraph>
        This little mention in the book proved to be a bit of a challenge to
        include in my replica. As I mentioned before, I had to follow the
        chapters and topics specified in the list of contents - and herein lied
        the problem, because the list of contents does not include a chapter for
        Everlasting Elixirs. To circumvent this "technicality", I placed
        Everlasting Elixirs sub-chapters into the "Elixir of Life Explained"
        chapter, which can be found in the list of contents, and I tried my best
        to make those sub-chapters make sense in this context.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_EverlastingElixirs)} />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
