import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1yQqrKgbKWYY2hABtto7nizz_hX5IAJx_/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Prison-3D-Model",
  name: "Prison 3D Model",
  description: `The product contains an .obj file with a 3d model of a prison, which can be imported to common 3d software, such as 3Ds Max, Cinema 4D, Maya, Blender etc. It is great for holographic environments, call-of-duty-like mission briefing videos, hud schematics and much more!`,

  price: 10,
  softwareRequired: [],

  categories: [productCategories.file3DModel],
  groups: [productGroups.misc],
  license: licenses.commercial,

  images: gallery,
  videos: [],

  contents: [
    {
      text: "3D model - Prison",
      format: fileFormats.obj,
    },
    {
      text: "about",
      isDivider: true,
    },
    { text: "No rig, materials or textures" },
  ],
  tags: ["3D Model"],
};

export default data;
