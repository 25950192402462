import { QuoteAltLeft, QuoteAltRight } from "@styled-icons/boxicons-solid/";

import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.black}88;
  padding: 25px;
`;

const Quote = styled.p`
  text-align: justify;
  font-size: 1em;
  font-weight: 400;
  font-style: italic;
  line-height: 1.4em;
  white-space: pre-wrap;

  svg {
    color: ${(props) => props.theme.white}33;
    width: 24px;
    margin: 0 15px;
    &:first-child {
      margin-left: 0;
      margin-top: -8px;
    }
    &:last-child {
      margin-right: 0;
      margin-bottom: -8px;
    }
  }
`;

const Source = styled.p`
  text-align: right;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 1.4em;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  opacity: 0.6;
`;

const ReplicaQuote = (props: { children?: any; source?: string }) => {
  return (
    <Wrapper>
      <Quote>
        <QuoteAltLeft />
        {props.children}
        <QuoteAltRight />
      </Quote>
      {props.source && <Source>{props.source}</Source>}
    </Wrapper>
  );
};

export default ReplicaQuote;
