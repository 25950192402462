import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_3d_model = [];
let galleryWIP_bunny_ears = [];
let galleryWIP_connection_rods = [];
let galleryWIP_control_box = [];
let galleryWIP_crystal = [];
let galleryWIP_crystal_holder = [];
let galleryWIP_crystal_chamber = [];
let galleryWIP_emitter = [];
let galleryWIP_emitter_shroud = [];
let galleryWIP_greeblies = [];
let galleryWIP_handle = [];
let galleryWIP_head_pins = [];
let galleryWIP_pommel = [];
let galleryWIP_pre_paint = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_3d_model = importAll(
    (require as any).context("./gallery-wip-3d-model", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_bunny_ears = importAll(
    (require as any).context(
      "./gallery-wip-bunny-ears",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_connection_rods = importAll(
    (require as any).context(
      "./gallery-wip-connection-rods",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_control_box = importAll(
    (require as any).context(
      "./gallery-wip-control-box",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_crystal = importAll(
    (require as any).context("./gallery-wip-crystal", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_crystal_holder = importAll(
    (require as any).context(
      "./gallery-wip-crystal-holder",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_crystal_chamber = importAll(
    (require as any).context(
      "./gallery-wip-crystal-chamber",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter_shroud = importAll(
    (require as any).context(
      "./gallery-wip-emitter-shroud",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_handle = importAll(
    (require as any).context("./gallery-wip-handle", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_head_pins = importAll(
    (require as any).context("./gallery-wip-head-pins", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pre_paint = importAll(
    (require as any).context("./gallery-wip-pre-paint", false, /\.(png|jpe?g)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 13, month: 6, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        I've been itching to take a stab at manually machining a
        Graflex-flashgun-based lightsaber from scratch for quite some time now,
        however, since I already acquired more than one of those to add to my
        DetailBundle, I've yet to decide to actually do it. I recently stumbled
        upon a video from Star Wars: The Force Unleashed, a game I remember
        playing years ago, and the perfect project presented itself -
        Starkiller's lightsaber. Its design is obviously heavily based on a
        Graflex flashgun, although it's not entirely the same, so that's one
        step closer to my original idea of recreating a Graflex lightsaber. I
        was also intrigued by the exposed crystal chamber. It doesn't allow for
        the usual way I build my lightsabers - with a threaded rod running
        through the core and holding all the pieces together. In short, I was
        ready to take on a much more difficult project, and this was the perfect
        combination of what I was looking for.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=FYNKB4SjyFM"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Starkiller's lightsaber was a very difficult build, mainly due to two
        reasons. As far as I know, the lightsaber never existed as a physical
        prop, and thus the design choices were not limited by things like
        gravity or the need to create mechanical connections between parts
        (unless you want to glue everything together). However, those were the
        things that I had to solve in order for the lightsaber to be sturdy, but
        look the same. I actually gave up at one point when designing the
        internal parts of the emitter, because it was too difficult to fit
        everything into such small space... but I obviously couldn't stay away
        from the project for long. The other reason is the fact that the game
        model is low-poly, meaning that its mesh is not very detailed and most
        of the details are a simple texture. Since it is obvious that the design
        was based on a Graflex flashgun, this was an easier problem to solve,
        because I could use the flashgun as a reference.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Assembly Design" />
      <ReplicaParagraph>
        As I already mentioned, as far as design goes, this was a difficult
        project to figure out. I started by taking the low-poly 3D model from
        the game, and based my dimensions on it. I digitally recreated the outer
        parts of the lightsaber, and then I designed the internal parts to hold
        everything together in the same way that the game model does. However,
        some small changes were obviously required, because some parts of the
        game model are not even connected to anything... that wouldn't work in
        the real world. However, I always kept those changes on the inside, and
        in a way that would not disrupt the silhouette of the lightsaber - that
        should always stay the same for the lightsaber appear to be the same.
      </ReplicaParagraph>

      <ReplicaParagraph>
        The front part of the lightsaber also required some reverse-engineering
        type of thinking. I've seen some replicas of this lightsaber before, and
        it seems that a lot of people take the sharp corners of the "bunny ears"
        (those two rectangles sticking out on the top) as part of the design.
        While this is obviously open to interpretation, I'm of the belief that
        those are actually supposed to be round, and the edges are there because
        the model is in low resolution. I have some experience with 3D modeling,
        and that is exactly how I would take a Graflex flashgun and represent it
        as a low-poly game asset. A nice example of the same problem is the
        curved line right below the bunny ears (see pictures below; marked by a
        red line) - this curve is also represented by only a few points, which
        creates sharp corners, but by looking at a Graflex flashgun, this is
        obviously supposed to be a smooth curve.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_3d_model)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Bunny Ears" />
      <ReplicaParagraph>
        Since I talked so much about this part already, let's take a look at how
        those were manufactured. I initially wanted to design them as a
        functioning part just like in a Graflex flashgun, but due to the
        different design of the emitter under them, this was not
        possible/reasonable. I instead divided the part into two pieces, both of
        which I created by bending sheets of metal, drilling a hole through the
        center and then filing them into the final shape. Those were then
        attached to the inner part of the hilt, which was 3D-printed. I'm not
        going to talk much about the 3D-printed parts here, because there's not
        much to say about them - just lots of sanding and sanding to get a nice
        finish.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_bunny_ears)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Emitter" />
      <ReplicaParagraph>
        While this seemed to be a straight-forward part at first, I was forced
        to split it into four different parts in order to be able to machine
        them on my lathe. The inner rod is made from brass and it is used to
        attach all the parts mentioned to the hilt. I mentioned before that it
        is different to the Graflex flashgun, and that is because the Graflex
        parts are pieces of bent metal that actually serve a purpose (for its
        original use as a flashgun), instead of a static part like the one on
        this lightsaber.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Emitter Shroud" />
      <ReplicaParagraph>
        This part, with the signature Graflex-shape at the end, required a lot
        of precise drilling. There are eleven holes running through the part,
        all of which had to be placed perfectly in order to meet with the
        internal parts (and because it would look bad to have them misaligned on
        the final piece). Once all those were finished, I had to cut off a lot
        of material in order to create the curved shape at the end and the
        missing bottom. Both of these were, again, carefully shaped by a file.
        The finishing touch was the hole and groove at the bottom - that one was
        milled to keep a straight line with hard edges.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter_shroud)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Buttons" />
      <ReplicaParagraph>
        There are two distinct buttons at the front of the hilt - one at the top
        and the other at the bottom. While a Graflex flashgun also has buttons
        in those areas, those are completely different, and this is where the
        Graflex similarities end. I tried to find thumbscrews that would have a
        similar shape, but I ultimately decided to machine those from scratch
        instead. The process for the two buttons was essentially the same -
        knurling, thread-cutting and finishing on a lathe.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_greeblies)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Control Box / Radiator" />
      <ReplicaParagraph>
        This part required a lot of milling to get the final dimensions of the
        piece. I called it a 'control box' while working on it, simply because
        that's what you can usually find on a lightsaber hilt, and... it's a
        box... so... Anyways, judging by the two grooves and the overall shape,
        I guess this is supposed to be some kind of radiator for cooling, which
        could make sense when talking about a lightsaber, but don't quote me on
        that.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_control_box)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Brass Pins" />
      <ReplicaParagraph>
        Those are probably the smallest parts that I had to machine for the
        lightsaber. They sit in a 3D-printed part that holds them in the
        circular Graflex-like hole at the front of the lightsaber. It's perhaps
        important to note that there's only one of the holes on the hilt, while
        Graflex flashgun has two - one on each side. This was quite a surprise
        to me, because although it has been quite some time since I played the
        game, I always assumed that the lightsaber was symmetrical - until I
        studied the game model more carefully. Graflex also has the holes at an
        angle to the rest of the parts, while this hilt does not (90° angles
        everywhere).
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_head_pins)} />

      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Crystal Chamber" />
      <ReplicaParagraph>
        This is probably the most interesting part of the lightsaber, because,
        usually, the internal parts of a lightsaber are covered. Not on
        Starkiller's lightsaber though, which seems to be missing a bottom part
        that would cover the crystal. I assume that such a part originally
        existed (story-wise), and perhaps it got damaged during one of his
        missions/training sessions, but that is not important right now. The
        cylindrical part that is around the crystal gave me some problems, and I
        actually had to make it twice, because I messed up the first version's
        rectangular holes.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_crystal_chamber)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The crystal was something that I never attempted before. I initially
        planned on creating it by making a mold in the shape of the crystal and
        casting it out of resin, but I ultimately went with a more
        straight-forward approach. I instead bought a set of (fake, assuming by
        the price) nuggets of red-colored Topaz. I picked one of the stones that
        was close to the lightsaber crystal dimension-wise, and shaped it using
        a Dremel rotary tool. This was way more difficult that I thought it
        would be, but I managed to shape it without breaking it in half on the
        first try.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_crystal)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        In the game model, the crystal is held in place by two rods on each
        side. With this in mind, I had to come up with a mechanism that would
        allow me to put the crystal inside the lightsaber after it is fully
        assembled, and ideally without too much trouble or tools required. I
        also wanted to avoid building the mechanism for a pre-determined size of
        the crystal, and instead come up with a universal solution for a crystal
        of any size. The result is a combination of two of my ideas - one of the
        rods is threaded and can be used for more permanent length adjustments,
        while the rod on the other side is held in a spring mechanism that
        allows it to change its length as needed. This is the best solution
        possible, because once the lightsaber is assembled, the crystal can be
        easily pushed in against the spring, which then reliably holds it in
        place - no tools or adjustments needed. This is difficult to explain via
        text, but the whole mechanism can be seen in action in the assembly
        video above.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_crystal_holder)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Once I solved the crystal-holding mechanism, I had to figure out a way
        to be able to attach the front of the lightsaber to the cylindrical
        chamber part. This was no easy task, because most of the space inside
        the cylinder is taken up by the crystal, and in the game model, the rods
        connecting the parts of the lightsaber are simply floating there in
        space. I ended up machining custom caps for three threaded rods, which
        allowed them to be held in the chamber by short screws, so the result is
        accurate to the game model. On the other end, the threaded rods are
        screwed into the front part of the hilt, which also gives me the ability
        to fix any angle between the two connected parts of the hilt... that is
        important, because otherwise the lightsaber could look like a banana.
        Looking back, I would probably redesign the assembly a bit, because the
        "banana problem" was far too common, and I often had to take everything
        apart to make little adjustments in order to make the hilt straight,
        which was extremely time consuming (and frustrating).
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_connection_rods)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        The base of the crystal chamber is covered by a handle part of the hilt.
        Compared to the rest of the lightsaber, this part was easy. To make it
        less easy, I wanted to come up with a way to attach the grips that would
        allow me to take them off the handle if wanted/needed. I hate using glue
        (for non-paper replicas), but the grips are far too slim to be held by
        screws, and I also didn't want to have any screw heads visible, because
        the game model has no such thing. In the end, I got the idea of drilling
        holes into the handle, to which I would press-fit pins protruding from
        the grips. Although it took me about twenty tries to design a pin of the
        best shape and size to hold firmly, but not break when trying to pull
        the grip out, I got there in the end and the solution worked amazingly
        well.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        The pommel features a threaded hole which is used to hold the crystal
        chamber inside the handle. The whole part was fairly easy to machine on
        a lathe, so no interesting stories of overcoming difficulties here.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Pre-Paint Assembly" />
      <ReplicaParagraph>
        Once all the parts were finished, it was satisfying to finally be able
        to put everything together and see the final lightsaber hilt. I was
        afraid of the lightsaber feeling too "flimsy" and fragile due to the
        fact that the entire front of the hilt is attached to the handle only by
        the three tiny rods. However, since I was constantly adjusting my
        initial solution during machining of the parts, I must say I'm pleased
        with the result, and the hilt feels as one piece. Once I checked that
        everything was to my liking, I then took the hilt apart and chemically
        blackened that parts of the lightsaber that are supposed to be black,
        and I weathered the hilt to resemble the state of the lightsaber in the
        game.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pre_paint)} />
      <ReplicaSpacer />

      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
