import CustomLightsaber01 from "./sw-lightsaber-custom-01";
import DL44LukeBespin from "./sw-dl44-luke-bespin";
import DLT19 from "./sw-dlt19";
import KiAdiMundisLightsaber from "./sw-kiadimundis-lightsaber-aotc";
import LightsaberLukeEp6V3 from "./sw-lightsaber-luke-ep6-v3-stunt";
import LightsaberLukeStuntEp6 from "./sw-lightsaber-luke-stunt";
import LightsaberObiwanEp4Motorized from "./sw-lightsaber-obiwan-ep4-motorized-stunt";
import LukesLightsaber from "./sw-lukes-lightsaber-rotj";
import ObiwanLightsaberEp1 from "./sw-lightsaber-obiwan-ep1";
import ObiwansLightsaber from "./sw-obiwans-lightsaber-anh";
import QuiGonLightsaberEp1 from "./sw-lightsaber-quigon-ep1";
import StarkillerLightsaberTFU from "./sw-lightsaber-starkiller-tfu";
import YodaLightsaberEp1 from "./sw-lightsaber-yoda-ep1";

// WIP REPLICAS WILL BE FILTERED OUT OF THE LIST OF REPLICAS
// AND WILL BE ACCESSIBLE THROUGH /replicas-wip URL
const replicas = [
  LightsaberObiwanEp4Motorized,
  DLT19,
  DL44LukeBespin,
  LightsaberLukeEp6V3,
  CustomLightsaber01,
  QuiGonLightsaberEp1,
  ObiwanLightsaberEp1,
  StarkillerLightsaberTFU,
  YodaLightsaberEp1,
  LightsaberLukeStuntEp6,
  KiAdiMundisLightsaber,
  ObiwansLightsaber,
  LukesLightsaber,
];

export default replicas;
