import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { ArrowDownload } from "@styled-icons/fluentui-system-filled";
import { Cart } from "@styled-icons/boxicons-solid";
import { CloseOutline } from "@styled-icons/evaicons-outline/";
import { FileList2 } from "@styled-icons/remix-line/";
import { Link } from "react-router-dom";
import { Menu } from "@styled-icons/boxicons-regular/";
import { QuestionMark } from "@styled-icons/boxicons-regular/";
import { getShoppingCart } from "store/selectors";
import { routes } from "config";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

//import { useScrollAppear } from 'hooks'

const MobileOnly = styled.div`
  flex: 1;
  display: none;
  ${(props) => props.theme.breakpoint.M} {
    display: block;
  }
`;
const DesktopOnly = styled.div`
  flex: 1;
  ${(props) => props.theme.breakpoint.M} {
    display: none;
  }
`;

const Static = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  width: 100%;
  background: ${(props) => props.theme.black}77;
  backdrop-filter: blur(20px);

  a {
    text-decoration: none;
  }
`;

const NavigationWindow = styled.div`
  height: calc(100vh - 50px);
  padding: 25px;
  overflow: hidden;
`;

const NavigationBar = styled.div<{ hide: boolean }>`
  --height: 50px;
  height: var(--height);
  border-bottom: 1px solid ${(props) => props.theme.white}11;
  padding: 0 4%;
  display: flex;
  align-items: center;

  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  ${({ hide }) =>
    hide &&
    css`
      margin-top: calc(-1 * var(--height));
      ${(props) => props.theme.breakpoint.M} {
        margin-top: 0;
      }
    `};
`;

const Row = styled.div<{ hide: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) => props.theme.breakpoint.M} {
    margin-left: 10px;
  }
`;

const LinkHome = styled.div`
  a {
    color: ${(props) => props.theme.white};
    background: none;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4em;

    &:hover {
      background: none;
    }
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const MenuLink = styled.div<{ isActive: boolean; isHighlighted?: boolean }>`
  color: ${(props) => props.theme.white}AA;
  --size: 20px;
  svg {
    width: var(--size);
    height: var(--size);
  }

  a {
    position: relative;
    background: none;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.1em;

    ${(props) => props.theme.breakpoint.M} {
      text-align: center;
    }

    &:hover {
      background: none;
      color: ${(props) => props.theme.white} !important;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        color: ${(props) => props.theme.white} !important;
      `};

    ${({ isHighlighted }) =>
      isHighlighted &&
      css`
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-radius: 5px;
        background: ${(props) => props.theme.white}33;

        ${(props) => props.theme.breakpoint.M} {
          gap: 5px;
        }
      `};
  }
`;

const CartProductCount = styled.div`
  color: ${(props) => props.theme.white};

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  padding-right: 5px;

  ${(props) => props.theme.breakpoint.M} {
    padding-right: 15px;
    font-size: 16px;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  min-width: 110px;
  right: calc(50% - 16px);
  top: 45px;

  pointer-events: none;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  --bg: ${(props) => props.theme.dark}88;
  color: ${(props) => props.theme.white}88;
  background: var(--bg);

  text-transform: uppercase;
  text-align: center;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 2em;

  opacity: 0;
  transition: all 0.2s ease;
  transition-delay: 0.1s;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 10px;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent var(--bg) transparent;
  }
  ${MenuLink}:hover & {
    opacity: 1;
  }
`;

const MenuItemSeparator = styled.div`
  width: 2px;
  height: 10px;
  background: ${(props) => props.theme.white}22;

  margin: 0 24px;
  ${(props) => props.theme.breakpoint.L} {
    margin: 0 14px;
  }
`;

const IconLink = styled.div`
  padding: 0 5px;
`;

const Hamburger = styled.button`
  backdrop-filter: none !important;
  svg {
    width: 25px !important;
    height: 25px !important;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }
`;

const MenuWrapperColumn = styled.div`
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  ${MenuLink} {
    a {
      font-size: 15px !important;
      padding: 15px 0;
    }
  }
`;

const MenuWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
  }
  svg {
    width: 25px !important;
    height: 25px !important;
    padding: 10px !important;
  }
`;

const HorizontalSeparator = styled.div`
  background: ${(props) => props.theme.white}55;
  margin: auto;
  width: 50%;
  height: 1px;
  margin-top: 8vh;
  margin-bottom: 10vh;
`;

type LinkType = { name?: string; icon?: any; slug: string; isCart?: boolean };

const Navigation = () => {
  //const scrollAppear = useScrollAppear({ triggerHeightPercentage: 1 })
  const location = useLocation();
  const isHome = location.pathname === "/";
  const hideOnTop = false; //isHome ? !scrollAppear : false

  const shoppingCart = useSelector(getShoppingCart);

  const [isOpen, setOpen] = useState<boolean>(false);

  const links: LinkType[] = [
    {
      name: "Replicas",
      slug: routes.replicas.slug,
    },
    {
      name: "E-Shop",
      slug: routes.eshop.slug,
    },
    {
      name: "AAE HUD Factory",
      slug: routes.aaeHudFactory.slug,
    },
    {
      name: "Contact",
      slug: routes.contact.slug,
    },
  ];

  const links2: LinkType[] = [
    {
      icon: <FileList2 />,
      slug: routes.terms.slug,
      name: "Terms & Conditions",
    },
    {
      icon: <QuestionMark />,
      slug: routes.faq.slug,
      name: "Frequently Asked Questions",
    },
    {
      icon: <ArrowDownload />,
      slug: routes.myDownloads.slug,
      name: "My Downloads",
    },
    {
      icon: <Cart />,
      slug: routes.cart.slug,
      name: "Shopping Cart",
      isCart: true,
    },
  ];

  //
  // RENDER
  //
  const renderName = () => {
    return (
      <LinkHome
        onClick={() =>
          isHome &&
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <Link to={routes.home.slug} onClick={() => setOpen(false)}>
          Jan Hamernik
        </Link>
      </LinkHome>
    );
  };

  const renderLink = (link: LinkType) => {
    const isIconLink = link.icon ? true : false;
    let productsInCartCount = 0;

    if (link.isCart) {
      productsInCartCount = shoppingCart?.length;
    }

    return (
      <MenuLink
        isActive={location.pathname === `/${link.slug}`}
        isHighlighted={productsInCartCount > 0}
      >
        <Link to={`/${link.slug}`}>
          {isIconLink ? link.icon : link.name}
          {isIconLink ? (
            <Tooltip>{`${link.name}${
              productsInCartCount > 0 ? ` (${productsInCartCount})` : ""
            }`}</Tooltip>
          ) : null}
          {productsInCartCount > 0 ? (
            <CartProductCount>{productsInCartCount}</CartProductCount>
          ) : null}
        </Link>
      </MenuLink>
    );
  };

  const renderMenuRow = () => {
    return (
      <>
        <MenuWrapper>
          {links.map((link, i) => (
            <div key={i}>
              {renderLink(link)}
              {i < links.length - 1 && <MenuItemSeparator />}
            </div>
          ))}
        </MenuWrapper>
        <MenuItemSeparator />
        <MenuWrapper>
          {links2.map((link, i) => (
            <IconLink key={i}>{renderLink(link)}</IconLink>
          ))}
        </MenuWrapper>
      </>
    );
  };

  const renderMenuColumn = () => {
    return (
      <>
        <MenuWrapperColumn>
          {links.map((link, i) => (
            <div key={i} onClick={() => setOpen(false)}>
              {renderLink(link)}
            </div>
          ))}
        </MenuWrapperColumn>

        <HorizontalSeparator />

        <MenuWrapperMobile>
          {links2.map((link, i) => (
            <IconLink key={i} onClick={() => setOpen(false)}>
              {renderLink(link)}
            </IconLink>
          ))}
        </MenuWrapperMobile>
      </>
    );
  };

  return (
    <Static>
      <NavigationBar hide={hideOnTop}>
        <DesktopOnly>
          <Row>
            {renderName()}
            <Spacer />
            {renderMenuRow()}
          </Row>
        </DesktopOnly>

        <MobileOnly>
          <Row>
            {renderName()}
            <Spacer />
            <Hamburger
              className="btn-icon-only"
              onClick={() => setOpen(!isOpen)}
            >
              {isOpen ? <CloseOutline /> : <Menu />}
            </Hamburger>
          </Row>
        </MobileOnly>
      </NavigationBar>

      <MobileOnly>
        <AnimateHeight height={isOpen ? "auto" : 0}>
          <NavigationWindow>{renderMenuColumn()}</NavigationWindow>
        </AnimateHeight>
      </MobileOnly>
    </Static>
  );
};

export default Navigation;
