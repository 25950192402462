import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (7) - Snape Insult - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub7-snape-insult',
  name: 'Snape Insult',
  gallery,
  video: 'https://www.youtube.com/watch?v=GYgArZ9Q-EQ',
  content: (
    <>
      <ReplicaParagraph>
        This version belongs back to the Prisoner of Azkaban. However, it does
        not feature any page designs - this is the version of the map that
        Severus Snape saw when he first encountered the Marauder's Map. That is,
        there's no map, just a small note from his old "friends" to keep his
        nose out of other people's business.
      </ReplicaParagraph>
    </>
  ),
}

export default data
