import styled from 'styled-components'

const Wrapper = styled.p`
  text-align: justify;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.4em;
`

const ReplicaParagraph = (props: { children?: any }) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ReplicaParagraph
