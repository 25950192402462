import replicas, { replicaCategories } from "assets/replicas";
import styled, { keyframes } from "styled-components";

import LayoutPage from "layouts/LayoutPage";
import ReplicaList from "./ReplicaList";
import SectionHeading from "components/SectionHeading";
import SectionSubHeading from "components/SectionSubHeading";
import Tabs from "components/Inputs/Tabs";
import background from "assets/replicas/harry-potter/hp-marauders-map/version-7/background_global.jpg";
import { checkIsNew } from "utils";
import { useState } from "react";

const Paragraph = styled.p`
  max-width: 800px;
  text-align: justify;
  margin-top: 4vh;
  margin-bottom: 4vh;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 150px;
`;

const SectionTitle = styled.div`
  margin-bottom: 30px;
`;

const SectionAnim = keyframes`
  from { 
    transform: translateY(200px);
  }
  to { 
  }
`;

const DisplaySection = styled.div`
  width: 100%;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0.27, 0, 1);
  animation-name: ${SectionAnim};
`;

enum DisplayModeEnum {
  "byCategory" = "By Category",
  "byDate" = "By Date",
}

const Replicas = (props?: { includeWIP?: boolean }) => {
  const [displayMode, setDisplayMode] = useState(DisplayModeEnum.byCategory);

  const newReplicas = replicas.filter((replica) => {
    const latestVersion = replica.versions[0];
    const isNew = checkIsNew(latestVersion.datePublished);
    return isNew;
  });

  return (
    <LayoutPage background={background}>
      <SectionHeading
        title="Prop Replicas"
        subtitle="High-End handmade prop replicas"
      />
      <Paragraph>
        Apart from visual effects, making prop replicas is another hobby that I
        pursued from an early age. It all started with Star Wars (just like my
        vfx “career”), because I have always wanted to own a lightsaber. However
        - I’m not satisfied with just owning the thing, I LOVE the process of
        doing the research and putting in the hard work to create the prop
        myself. I do not shy away from spending literally years perfecting the
        props.
      </Paragraph>

      <Tabs
        tabs={Object.values(DisplayModeEnum)}
        activeTab={displayMode}
        onTabClick={setDisplayMode}
      />

      {props?.includeWIP ? (
        <Section>
          <SectionTitle>
            <SectionSubHeading title="Work In Progress" />
          </SectionTitle>
          <ReplicaList wipOnly />
        </Section>
      ) : null}

      {displayMode === DisplayModeEnum.byDate && (
        <DisplaySection>
          <ReplicaList orderBy="date" />
        </DisplaySection>
      )}

      {displayMode === DisplayModeEnum.byCategory && (
        <DisplaySection>
          {newReplicas?.length > 0 ? (
            <Section>
              <SectionTitle>
                <SectionSubHeading title="New Replicas" />
              </SectionTitle>
              <ReplicaList newOnly orderBy="date" />
            </Section>
          ) : null}

          {Object.values(replicaCategories)
            .sort((a, b) => a.index - b.index)
            .map((category) => (
              <Section key={category.id}>
                <SectionTitle>
                  <SectionSubHeading
                    title={category.name}
                    subtitle={category.subtitle}
                  />
                </SectionTitle>

                <ReplicaList selectedCategoryId={category.id} />
              </Section>
            ))}
        </DisplaySection>
      )}
    </LayoutPage>
  );
};

export default Replicas;
