import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 12, month: 12, year: 2020 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This ancient document, also known as 4Q41 or 4QDeuteronomy, is one of
        the Dead Sea Scrolls - a massive DetailBundle of ancient Jewish and
        Hebrew religious manuscripts found in Qumran Caves in the Dead Sea area.
        This particular scroll is supposed to be the oldest existing copy of the
        Ten Commandments, which makes it a rather important piece of history.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Like all historically important documents, it is very well documented,
        which made my research easier. Reading about the discovery of the Dead
        Sea Scrolls was actually quite interesting. The Deuteronomy scroll is
        heavily damaged, which made it difficult to reproduce, especially the
        second (smaller) part, which includes a small piece of parchment
        connected with a string. The most difficult part was to make sure that
        it doesn't fall apart, which is probably the same problem that the
        original scroll has - I'll take points for realism for that, yey!
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Authentic Reproduction`,
            description: `The replica is based on the archival photos of the real scroll. I carefully replicated both sides of each part of the scroll and meticulously cut all the signature holes and broken pieces.`,
          },
          {
            title: `High-quality print`,
            description: `The scroll was printed on a high-end professional printer, using a high-quality water-resistant ink. The result looks quite natural, there's no pixelation or any other flaws that are often associated with printing. The size of the replica is true to the original document.`,
          },
          {
            title: `Authentic aging process`,
            description: `The replica has been properly aged to resemble the state of the original scroll. Every crease and wrinkle has been carefully added by hand.`,
          },
        ]}
      />

      <ReplicaParagraph>
        Overall, I'm pleased with the result. The most difficult thing now is to
        figure out a way to store the replica. Even though it's just a replica,
        it needs careful handling, so I cannot just throw into a drawer.
        Actually, the storage of the real Dead Sea Scrolls is also an
        interesting study. Such documents should not be framed like a
        photograph, because it will "squash" them and potentially damage them
        even more.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.L} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
