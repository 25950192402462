import { ReplicaType } from "types";
import Version1 from "./version-1";
import Version2 from "./version-2";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "advanced-potion-making",
  name: `Advanced Potion Making`,
  origin: "Harry Potter and the Half-Blood Prince",

  sort: {
    preferredIndex: 2,
  },

  category: replicaCategories.harryPotter,

  versions: [Version2, Version1],
};

export default data;
