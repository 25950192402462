import BilbosMapOfMiddleEarth from "./lotr-bilbos-map-of-middle-earth";
import DeedOfContract from "./lotr-deed-of-contract";
import OrcristScabbard from "./lotr-orcrist-scabbard";
import ThorinsLetterToBilbo from "./lotr-thorins-letter-to-bilbo";
import ThrorsMap from "./lotr-thrors-map";

// WIP REPLICAS WILL BE FILTERED OUT OF THE LIST OF REPLICAS
// AND WILL BE ACCESSIBLE THROUGH /replicas-wip URL
const replicas = [
  OrcristScabbard,
  DeedOfContract,
  ThrorsMap,
  BilbosMapOfMiddleEarth,
  ThorinsLetterToBilbo,
];

export default replicas;
