import { createDate, importAll, transformImages } from "utils";

import DailyProphet from "assets/replicas/harry-potter/hp-daily-prophets/hp-daily-prophet";
import ReplicaContent from "components/Replicas/ReplicaContent";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 30, month: 8, year: 2022 }),
  version: "1",
  isSeries: false,
  thumbnail,
  background,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <DailyProphet
        gallery={galleryImages}
        video="https://www.youtube.com/embed/Kx9WqC2p05w"
      />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
