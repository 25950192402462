import { sortReplicasByDate, sortReplicasByPreferredIndex } from "utils";

import Background from "assets/replicas/harry-potter/hp-marauders-map/version-7/background_global.jpg";
import Carousel from "components/Carousel";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import ReplicaItem from "components/Replicas/ReplicaItem";
import SectionHeading from "components/SectionHeading";
import replicas from "assets/replicas";
import { routes } from "config";
import styled from "styled-components";

/* import ButtonScrollToSection from 'components/Interaction/ButtonScrollToSection' */

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpacerMiddle = styled.div`
  height: 6vh;
  ${(props) => props.theme.breakpoint.M} {
    height: 6vh;
  }
`;

const Replicas = (props: { onNavClick: () => void }) => {
  const carouselItems = sortReplicasByPreferredIndex({
    replicas: sortReplicasByDate({ replicas }),
  }).map((replica) => <ReplicaItem replica={replica} noLabel />);

  return (
    <LayoutFullscreen background={Background} fadePercentage={70}>
      <Wrapper>
        <SectionHeading
          title="Prop Replicas"
          subtitle="High-quality authentic prop replicas from movies and real
            life."
          link={`/${routes.replicas.slug}`}
        />

        <SpacerMiddle />

        {/* padding is necessary for replicas with large thumbnails (absolute positioning results in these being clipped) */}
        <Carousel items={carouselItems} autoplay padding="30px 0 30px 0" />

        {/* <SpacerMiddle />
        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Explore More"
        /> */}
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Replicas;
