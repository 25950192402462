import Gallery, {
  GalleryImageType,
  GallerySize,
} from "components/Media/Gallery";

import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaSubVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import styled from "styled-components";
import { transformImages } from "utils";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpacerContent = styled.div`
  height: 2vh;
`;

const ReplicaSubVersionContent = (props: {
  replicaName: string;
  replicaSubVersion: ReplicaSubVersionType;
  version: string;
  gallerySize?: GallerySize;
}) => {
  const title = `"${props.replicaSubVersion.name}"`;
  const subtitle = `${props.replicaName} v${props.version}`;
  const galleryImages: GalleryImageType[] = transformImages(
    props.replicaSubVersion.gallery
  );

  return (
    <Wrapper>
      <ReplicaSubTitleAnchor
        title={title}
        subtitle={subtitle}
        anchor={props.replicaSubVersion.id}
      />

      {props.replicaSubVersion.content}
      <SpacerContent />

      {galleryImages?.length > 0 && (
        <Gallery images={galleryImages} size={props.gallerySize} />
      )}

      {props.replicaSubVersion.video && (
        <YouTubeVideo src={props.replicaSubVersion.video} />
      )}
    </Wrapper>
  );
};

export default ReplicaSubVersionContent;
