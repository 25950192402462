import { AdditionalProductDownloadType, AnyProductType } from "types";
import { getPurchaseBackground, getPurchasesFromPayPalOrders } from "utils";
import { useEffect, useState } from "react";

import CompactParagraph from "./components/CompactParagraph";
import LayoutPage from "layouts/LayoutPage";
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import PurchaseList from "./components/PurchaseList";
import PurchasePopUp from "./components/PurchasePopUp";
import background from "assets/background/background_planet-04_bottom.jpg";
import { getPayPalOrders } from "store/selectors";
import { routes } from "config";
import styled from "styled-components";
import useBreakpointValue from "hooks/useBreakpointValue";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  width: 94%;
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;
  margin-bottom: 50px;

  ${(props) => props.theme.breakpoint.S} {
    margin-top: -20px;
    margin-bottom: 30px;
  }
`;

const InfoTitle = styled.div`
  max-width: 500px;
  margin: auto;

  font-size: 18px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${(props) => props.theme.white}AA;
`;

const InfoText = styled.p`
  width: 100%;
  margin: 0;
  //text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const InfoParagraph = styled.div`
  color: ${(props) => props.theme.white}66;

  strong {
    font-weight: 500;
    color: ${(props) => props.theme.white}88;
  }
  a {
    font-weight: 500;
  }
`;

const NoDownloadsPage = styled.div`
  width: 94%;
  max-width: 500px;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center !important;
`;

type PurchaseType = {
  orderDate: Date;
  productsWithDownloadLinks: AnyProductType[];
  additionalDownloads: AdditionalProductDownloadType[];
};

const MyDownloads = () => {
  const location = useLocation();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const paypalOrders = useSelector(getPayPalOrders);
  const [purchases, setPurchases] = useState<PurchaseType[]>(undefined);

  const isMobile = useBreakpointValue({
    defaultValue: false,
    M: true,
  });

  useEffect(() => {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("popup") === "true") {
      setShowPopup(true);

      // Remove the query parameter from the URL without refreshing the page
      const newUrl = location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [location]);

  useEffect(() => {
    setPurchases(getPurchasesFromPayPalOrders(paypalOrders) || []);
  }, [paypalOrders]);

  const missingProductsText = (
    <>
      If you do not see a product you purchased, try to refresh this page. If it
      still doesn't appear,{" "}
      <Link to={routes.contact.slug} target="_blank">
        contact me via email
      </Link>
      , and I will send it to you manually. Please allow up to 24 hours for a
      response.
    </>
  );

  return (
    <>
      {purchases === undefined ? (
        <LayoutPage background={background}>
          <Loader />
        </LayoutPage>
      ) : !purchases?.length ? (
        <LayoutPage background={background}>
          <NoDownloadsPage>
            <Info>
              <InfoTitle>No Downloads Available</InfoTitle>

              <InfoText>
                <InfoParagraph>
                  It looks like you haven’t placed any orders yet. Once you make
                  a purchase, your downloadable products will appear here.
                </InfoParagraph>

                <InfoParagraph>{missingProductsText}</InfoParagraph>
              </InfoText>
            </Info>
          </NoDownloadsPage>
        </LayoutPage>
      ) : (
        <LayoutPage
          background={getPurchaseBackground({ purchases }) || background}
        >
          <Wrapper>
            <Info>
              <InfoText>
                <CompactParagraph
                  title="Download Your Products"
                  defaultIsOpen={!isMobile}
                >
                  The list of orders and products displayed here is temporary
                  and accessible only from this browser and device. It may
                  disappear in the future, so please download your products as
                  soon as possible.
                </CompactParagraph>

                <CompactParagraph
                  title="Missing Products"
                  defaultIsOpen={!isMobile}
                >
                  {missingProductsText}
                </CompactParagraph>

                <CompactParagraph
                  title="If You Encounter Issues"
                  defaultIsOpen={!isMobile}
                >
                  Start by checking the{" "}
                  <Link to={routes.faq.slug} target="_blank">
                    FAQ
                  </Link>{" "}
                  for potential solutions. If you still need assistance, please{" "}
                  <Link to={routes.contact.slug} target="_blank">
                    email
                  </Link>{" "}
                  me directly. Note that technical issues reported through
                  YouTube comments, personal social media, or other unofficial
                  channels will not be addressed.
                </CompactParagraph>
              </InfoText>
            </Info>

            <PurchaseList purchases={purchases} />
          </Wrapper>
        </LayoutPage>
      )}

      <PurchasePopUp isOpen={showPopup} setIsOpen={setShowPopup} />
    </>
  );
};

export default MyDownloads;
