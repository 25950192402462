import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import { Link } from "react-router-dom";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule_3.jpg";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Content = styled.div`
  width: 90%;
  max-width: 800px;
  text-align: justify;
  ${(props) => props.theme.breakpoint.S} {
    text-align: left;
  }
`;

const HelpDesk = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background} backgroundOpacity={0.7}>
      <Wrapper>
        <SectionHeading
          title="Help Desk"
          subtitle={["I have a problem, can you help me?", "Yes I can!"]}
        />

        <Spacer />
        <Content>
          <ul>
            <li>
              Try to find a solution in the{" "}
              <Link to={routes.faq.slug}>FAQ</Link> first, please.
            </li>
            <li>
              If you don't find your answer there,{" "}
              <Link to={routes.contact.slug}>contact me via email</Link> and we
              will find a solution together.
              <ul>
                <li>
                  Please take into account that I have to sleep sometimes, I'm
                  not on the internet or my computer 24/7, and I have a
                  full-time job. I will get back to your email as soon as
                  possible, usually within 36 hours (
                  <strong>do not panic</strong>, please).
                </li>
                <li>
                  If you want your questions answered, please keep our
                  conversation within emails. If you try to place your question
                  elsewhere (e.g. youtube comments, stalking my personal social
                  media or any other inappropriate place to solve technical
                  problems), you might not get an answer. Sending an email is
                  the guaranteed and preferred way, as it allows me to easily
                  keep track of our conversations.
                </li>
                <li>
                  Make sure that the description of your problem is{" "}
                  <strong>accurate, written in english</strong> (or czech, if
                  you speak it, not from google translator) and corresponding
                  with these terms {"&"} conditions.
                </li>
                <li>
                  I expect an active approach from you while trying to solve the
                  problem, as you are the only one who has access to your
                  computer.
                </li>
                <li>
                  I can only solve problems that are directly related to the
                  product, such as, but not limited to: "the download link is
                  not working", or "the files are damaged".
                </li>
                <li>
                  And finally, keep our conversation friendly and efficient,
                  please, there's no need for insults.
                </li>
              </ul>
            </li>
          </ul>
        </Content>

        <Spacer />

        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Continue Reading"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default HelpDesk;
