import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "lightsaber-obiwan-anh",
  name: `Lightsaber\nObi-Wan Kenobi Ep4`,
  origin: "Star Wars: Episode IV A New Hope",

  category: replicaCategories.starWars,

  versions: [Version1],
};

export default data;
