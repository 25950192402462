import { css } from 'styled-components'

//
// Default Styles
//

const textSelectionCss = css`
  background: ${(props) => props.theme.dark};
`

export const paragraphCss = css`
  color: ${(props) => props.theme.white}66;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4em;

  strong {
    font-weight: inherit;
    color: ${(props) => props.theme.white}88;
  }
`

export const defaultText = css`
  &,
  & * {
    ::-moz-selection {
      ${textSelectionCss}
    }
    ::selection {
      ${textSelectionCss}
    }
    ::-webkit-selection {
      ${textSelectionCss}
    }
  }

  p {
    ${paragraphCss}
  }

  .highlight {
    margin: 0 4px;
    padding: 2px 5px;
    background: ${(props) => props.theme.white}22;
    color: ${(props) => props.theme.white}AA;
  }
`
