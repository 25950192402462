import { Download as DownloadIcon } from "@styled-icons/evaicons-solid";
import styled from "styled-components";

const Download = styled.a`
  text-decoration: none;

  button {
    width: 100%;
    padding: 5px 12px;
    min-height: 56px;
    letter-spacing: 0.15em;
    line-height: 1.5em;
    background: ${(props) => props.theme.download}88;
    border-color: ${(props) => props.theme.download}22;
    &:hover {
      background: ${(props) => props.theme.download}AA;
    }
  }
`;

const ButtonDownload = (props: { label: string; downloadLink: string }) => {
  return (
    <Download href={props.downloadLink} target="_blank">
      <button>
        <DownloadIcon />
        {props.label}
      </button>
    </Download>
  );
};

export default ButtonDownload;
