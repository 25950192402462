import { AnyProductType, PurchaseType } from "types";
import { convertGoogleDriveLinkToDirectDownloadLink, formatDate } from "utils";

import ButtonDownload from "components/Interaction/ButtonDownload";
import ContentBox from "components/ContentBox";
import { Link } from "react-router-dom";
import products from "assets/products";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;
`;

const PurchaseContent = styled.div`
  padding: 15px;
  padding-top: 5px;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  p {
    margin: 0;
  }
`;

const ProductList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const ProductItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  row-gap: 20px;
  column-gap: 5%;
  padding: 5px 20px;
  background: ${(props) => props.theme.white}11;

  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column;
    padding: 15px 5px;
  }
`;

const ProductThumbnail = styled.img`
  --size: 120px;

  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  object-fit: contain;
`;

const ProductContentWrapper = styled.div`
  flex: 1;
  display: flex;

  flex-direction: column;
  align-items: stretch;
  row-gap: 8px;
  column-gap: 15px;
`;

const ProductContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 0px;
  column-gap: 15px;

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0px;
  }

  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    padding: 0 10px;
  }
`;

const ProductName = styled.div`
  div,
  a {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    line-height: 1.6em;
    color: ${(props) => props.theme.white}AA;
    margin: 5px 0;
  }

  a {
    text-decoration-line: underline;
    text-underline-offset: 4px;
    text-decoration-color: ${(props) => props.theme.white}22;

    &:hover {
      color: ${(props) => props.theme.white};
      text-decoration-color: ${(props) => props.theme.white}88;
    }
  }

  ${(props) => props.theme.breakpoint.S} {
    * {
      text-align: center !important;
    }
  }
`;

const ProductDescription = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.white}44;
`;

const ProductDownload = styled.div`
  margin: 15px 0;
  min-width: 240px;

  ${(props) => props.theme.breakpoint.S} {
    min-width: 0px;
  }

  button {
    width: auto;

    ${(props) => props.theme.breakpoint.M} {
      min-height: 0px;
    }
  }
`;

const NoDownload = styled.div`
  padding: 10px;
  white-space: pre-wrap;
  background: ${(props) => props.theme.red}22;
  color: ${(props) => props.theme.white}CC;
  line-height: 1.6em;
`;

const PurchaseList = (props: { purchases: PurchaseType[] }) => {
  const getDownloadLink = (link: string) => {
    return convertGoogleDriveLinkToDirectDownloadLink(link) || link;
  };

  return (
    <Wrapper>
      {props.purchases.map((purchase, i) => (
        <ContentBox key={i} title={formatDate(purchase.orderDate)} noPadding>
          <PurchaseContent>
            <ProductList>
              {purchase.productsWithDownloadLinks?.map((purchaseProduct) => {
                let product: AnyProductType = products.find(
                  (p) => p.id === purchaseProduct.id
                );

                if (product?.purchase) {
                  // use stored download link instead of using the current one
                  // WHY - TO HANDLE 'HACKING'
                  //     -> IT WOULD BE POSSIBLE TO PLACE ANY PRODUCT INTO THE STORED DATA (ID CAN BE TAKEN FROM URL SLUG)
                  //     -> IF THE DOWNLOAD LINK WOULD THEN BE FOUND BY PRODUCT ID, IT COULD BE HACKED THIS WAY
                  //     -> STORING THE DOWNLOAD LINK UPON PURCHASE AND THEN USING IT IN THE LIST IS SECURE
                  //        (BY ADDING ANY PRODUCT VIA 'HACK' IT WON'T KNOW THE GENUINE DOWNLOAD LINK, SO IT CANNOT BE SCAMMED THIS WAY)

                  product = { ...product, purchase: purchaseProduct.purchase };
                }

                return (
                  <ProductItem key={product.id}>
                    <ProductThumbnail
                      src={product.thumbnail}
                      alt={product.name}
                    />

                    <ProductContentWrapper>
                      <ProductContent>
                        <ProductName>
                          <Link
                            to={
                              "/" +
                              (product.isAAEHUDFactory
                                ? routes.aaeHudFactory
                                : routes.eshop
                              ).slug +
                              "/" +
                              product.id
                            }
                            target="_blank"
                          >
                            {product.name}
                          </Link>
                        </ProductName>

                        {product.purchase?.downloadLink ? (
                          <ProductDownload>
                            <ButtonDownload
                              label="Download Product"
                              downloadLink={getDownloadLink(
                                product.purchase?.downloadLink
                              )}
                            />
                          </ProductDownload>
                        ) : null}
                      </ProductContent>

                      {!product.purchase?.downloadLink ? (
                        <NoDownload>
                          The download link is currently unavailable. Please
                          contact me via{" "}
                          <Link to={"/" + routes.contact.slug} target="_blank">
                            email
                          </Link>{" "}
                          to receive the product manually.
                        </NoDownload>
                      ) : null}
                    </ProductContentWrapper>
                  </ProductItem>
                );
              })}

              {purchase.additionalDownloads?.map((download, i) => {
                return (
                  <ProductItem key={i}>
                    <ProductContentWrapper>
                      <ProductContent>
                        <ProductName>
                          <div>{download.name}</div>
                          <ProductDescription>
                            {download.description}
                          </ProductDescription>
                        </ProductName>

                        {download.downloadLink ? (
                          <ProductDownload>
                            <ButtonDownload
                              label="Download Product"
                              downloadLink={getDownloadLink(
                                download.downloadLink
                              )}
                            />
                          </ProductDownload>
                        ) : null}
                      </ProductContent>
                    </ProductContentWrapper>
                  </ProductItem>
                );
              })}
            </ProductList>
          </PurchaseContent>
        </ContentBox>
      ))}
    </Wrapper>
  );
};

export default PurchaseList;
