import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ElderWand from "assets/replicas/harry-potter/hp-elder-wand";
import { Link } from "react-router-dom";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWip1 = [];
let galleryWip2 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip1 = importAll(
    (require as any).context("./wip1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip2 = importAll(
    (require as any).context("./wip2", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 12, month: 8, year: 2017 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryWipImages1 = transformImages(galleryWip1);
  const galleryWipImages2 = transformImages(galleryWip2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is probably one of my most favourite wand designs. I love the
        bone-like appearance, it really gives the wand a sense of evil. But as
        eye-catching as it is, it is still functional. The shape of this wand is
        quite complex, so I knew from the beginning that it would take some time
        and skill to create it.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        For this wand, I've decided not to use air-dry clay, which I used for
        the Elder Wand . There were too many problems with it. I used FIMO clay
        instead. It's perfect for this type of work, and you don't have to wait
        for long periods of time for it to harden. You just bake it in the oven,
        which takes about half an hour, so that's good news. Mine doesn't have
        the phoenix feather core, but unless you break the wand, you'll never
        now... and I'm not planning on breaking the wand, so I should be
        alright.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />
      <ReplicaSubTitleAnchor title="Sculpting" />

      <ReplicaParagraph>
        The base of this wand is a piece of wood. Voldemort's wand isn't
        perfectly straight, so I had to cut out a few pieces here and there to
        achieve the imperfection. I then started sculpting the wand out of FIMO.
        I must say, after the air-dry clay, working with FIMO is a whole new
        level of easy. Since I didn't have to wait for it to dry by itself, I
        was able to create the final shape in three sections. First I made the
        front part of the handle, then I baked it so it's nice and hard, and so
        I don't ruin it while working on the rest of the wand. I then created
        the rear side of the handle, and, finally, the front 'claw'. Once it was
        all finished and baked, I used sand paper to refine the shape a bit.
        Baked FIMO is similar to soft plastic, so working with it is really
        easy. A very different experience from the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ElderWand.id}
          target="_blank"
        >
          Elder wand
        </Link>{" "}
        I created earlier.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryWipImages1} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Painting" />

      <ReplicaParagraph>
        The painting process was, just like with the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ElderWand.id}
          target="_blank"
        >
          Elder wand
        </Link>
        , the most difficult part for me. I'm not very experienced in this area,
        so it took me a couple of tries to get that bone-like color. Anyway, I
        started with white base and then tried to darken it with different
        shades of brown/yellow.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryWipImages2} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Though" />
      <ReplicaParagraph>
        I'm quite happy with the final result, I just need to work on my
        painting skills, it took me several tries to get it right. The decision
        to use FIMO instead of air-dry clay turned out to be a very good one,
        working with FIMO is amazing.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
