export enum SpriteDirection {
  "horizontal" = "horizontal",
  "vertical" = "vertical",
}

export type SpriteType = {
  static: any;
  sprite: any;
  frameCount: number;
  direction?: SpriteDirection;
};
