import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";
import styled, { css } from "styled-components";

import ButtonAddToCart from "components/Interaction/ButtonAddToCart";
import ContentBox from "components/ContentBox";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import productCategories from "assets/products/_categories";
import { routes } from "config";

enum ColumnType {
  "cart" = "cart",
  "software" = "software",
  "license" = "license",
}

const SectionInside = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 30px;

  & > * {
    flex: 1;
    min-width: 140px;
  }
  /*   ${(props) => props.theme.breakpoint.S} {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  } */
`;

const Column = styled.div<{ type?: ColumnType }>`
  display: flex;
  flex-direction: column;

  --index-cart: 1;
  --index-software: 0;
  --index-license: 2;

  --cart-min-width: auto;
  --text-min-width: auto;

  ${(props) => props.theme.breakpoint.M} {
    --index-cart: 0;
    --index-software: 1;
    --index-license: 2;

    --cart-min-width: 100%;
    --text-min-width: 200px;
  }

  ${(props) =>
    props.type === ColumnType.cart
      ? css`
          order: var(--index-cart);
          min-width: var(--cart-min-width);
        `
      : props.type === ColumnType.software
      ? css`
          order: var(--index-software);
          min-width: var(--text-min-width);
        `
      : props.type === ColumnType.license
      ? css`
          order: var(--index-license);
          min-width: var(--text-min-width);
        `
      : css``}
`;

const ColumnContent = styled.div<{ type?: ColumnType }>`
  width: 100%;
  max-width: 400px;

  margin: auto;
  text-align: center;

  a {
    text-decoration: none;
  }

  ${(props) =>
    props.type === ColumnType.cart
      ? css``
      : props.type === ColumnType.software
      ? css``
      : props.type === ColumnType.license
      ? css``
      : css``}
`;

const ListItem = styled.div`
  color: ${(props) => props.theme.white}AA;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;

  &:first-child {
    margin-top: 10px;
  }
`;

const ListSubItem = styled.div`
  color: ${(props) => props.theme.white}99;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.white}66;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  line-height: 16px;
`;

const DetailInfo = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isBundle = product.isBundle;

  //
  // SUB-RENDERS
  //
  const renderAddToCart = () => {
    return (
      <Column type={ColumnType.cart}>
        <ColumnContent type={ColumnType.cart}>
          <ButtonAddToCart product={product} />
        </ColumnContent>
      </Column>
    );
  };

  const renderSoftware = () => {
    const isAAEHUDFactoryAdditional =
      (product as ProductAAEHUDFactoryType).isAAEHUDFactory &&
      (product as ProductAAEHUDFactoryType).isAdditionalProduct;
    product = product as ProductType;

    if (product.softwareRequired?.length > 0) {
      return (
        <Column type={ColumnType.software}>
          <ColumnContent type={ColumnType.software}>
            {product.softwareRequired.map((software, i) => (
              <ListItem key={i}>{software}</ListItem>
            ))}
            <ListSubItem>Required</ListSubItem>
            <Description>
              {isAAEHUDFactoryAdditional ? (
                <>
                  Please note that this is not a standalone product and it can
                  only be used with one of the HUDs from the{" "}
                  <strong>
                    <Link to={"/" + routes.aaeHudFactory.slug}>
                      AAE HUD Factory.
                    </Link>
                  </strong>
                </>
              ) : product.blenderVersionRequired ? (
                "Please note that this project is old. To ensure the correct functionality, make sure to use the specified version of Blender to open it."
              ) : (
                "Please make sure that you can run the required version of the specified software, so you can fall back to it in case there are any problems."
              )}
            </Description>
          </ColumnContent>
        </Column>
      );
    }

    if (product?.categories?.includes(productCategories.file3DPrintable)) {
      return (
        <Column type={ColumnType.software}>
          <ColumnContent type={ColumnType.software}>
            <ListItem>3D Printable</ListItem>
            <ListSubItem>Designed For 3D Printing</ListSubItem>
            <Description>
              This product is designed with 3D printing in mind. More complex
              models are usually split into multiple easy-to-print parts that
              need to be assembled.
            </Description>
          </ColumnContent>
        </Column>
      );
    }

    return null;
  };

  const renderLicense = () => {
    product = product as ProductType;
    return (
      <Column type={ColumnType.license}>
        <ColumnContent type={ColumnType.software}>
          <HashLink
            to={`/${routes.terms.slug}#${routes.terms.anchors.license}`}
            smooth
          >
            <ListItem>{product.license.name}</ListItem>
            <ListSubItem>{product.license.usage}</ListSubItem>
          </HashLink>
          <Description>{product.license.description}</Description>
        </ColumnContent>
      </Column>
    );
  };

  //
  // RENDER PRODUCT
  //
  if (isBundle) {
    return (
      <ContentBox title="Buy & Details">
        <SectionInside>{renderAddToCart()}</SectionInside>
      </ContentBox>
    );
  }

  return (
    <ContentBox title="Buy & Details">
      <SectionInside>
        {renderAddToCart()}
        {renderSoftware()}
        {renderLicense()}
      </SectionInside>
    </ContentBox>
  );
};

export default DetailInfo;
