import LayoutPage from "layouts/LayoutPage";
import LoginForm from "components/LoginForm";
import background from "assets/background/background_planet-02_bottom.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 94%;
  max-width: 300px;
  margin: auto;
`;

const LoginPage = (props: { onLogin: () => void }) => {
  return (
    <LayoutPage background={background}>
      <Wrapper>
        <LoginForm onLogin={props.onLogin} />
      </Wrapper>
    </LayoutPage>
  );
};

export default LoginPage;
