import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import ObiwansLightsaberANH from "assets/replicas/star-wars/sw-obiwans-lightsaber-anh";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryDay0 = [];
let galleryDay1 = [];
let galleryDay2 = [];
let galleryDay3 = [];
let galleryDay4 = [];
let galleryDay5 = [];
let galleryDay6 = [];
let galleryDay7 = [];
let galleryDay8 = [];
let galleryDay9 = [];
let galleryDay10 = [];
let galleryDay11 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay0 = importAll(
    (require as any).context("./wip-day0", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay1 = importAll(
    (require as any).context("./wip-day1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay2 = importAll(
    (require as any).context("./wip-day2", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay3 = importAll(
    (require as any).context("./wip-day3", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay4 = importAll(
    (require as any).context("./wip-day4", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay5 = importAll(
    (require as any).context("./wip-day5", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay6 = importAll(
    (require as any).context("./wip-day6", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay7 = importAll(
    (require as any).context("./wip-day7", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay8 = importAll(
    (require as any).context("./wip-day8", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay9 = importAll(
    (require as any).context("./wip-day9", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay10 = importAll(
    (require as any).context("./wip-day10", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay11 = importAll(
    (require as any).context("./wip-day11", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 4, month: 7, year: 2018 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryDayImages0 = transformImages(galleryDay0);
  const galleryDayImages1 = transformImages(galleryDay1);
  const galleryDayImages2 = transformImages(galleryDay2);
  const galleryDayImages3 = transformImages(galleryDay3);
  const galleryDayImages4 = transformImages(galleryDay4);
  const galleryDayImages5 = transformImages(galleryDay5);
  const galleryDayImages6 = transformImages(galleryDay6);
  const galleryDayImages7 = transformImages(galleryDay7);
  const galleryDayImages8 = transformImages(galleryDay8);
  const galleryDayImages9 = transformImages(galleryDay9);
  const galleryDayImages10 = transformImages(galleryDay10);
  const galleryDayImages11 = transformImages(galleryDay11);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Before I started studying computer science, I've got my 'maturity
        diploma' (the secondary school exit exam) in Engineering –
        computer-aided design. Since I was a kid, besides visual effects, I
        always loved creating movie props and stuff like that, so when I first
        started learning how to use a metal lathe machine in high school, I
        yearned to get my hands on one, just so I could create something for
        myself. Not the usual boring school stuff, but something fun - a
        lightsaber, naturally. After ten years of waiting, I finally gained
        access to a lathe... so here we are.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I always loved Luke's lightsaber (originally Anakin's) from episode 4
        and 5, but one does not have to be an expert to figure out that to DIY
        something like that is nearly impossible... if you want to have all the
        details, that is. I have that lightsaber in my DetailBundle now, I know
        my limits, so I bought a replica of the Empire Strikes Back version. The
        next lightsaber that came to mind was Obi-Wan's from episode 4 , and
        Luke's from episode 6. Obi-Wan's is a little tricky, though, since it
        was assembled from real parts... but if I'm not mistaken, Luke's was
        actually created using a lathe. A perfect choice for the first
        lightsaber, I think.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Preparations" />

      <ReplicaParagraph>
        Just like with most of my projects, I started by sketching the prop.
        This is the phase in which I decide on how many parts I want to divide
        the lightsaber into, and how it would all come together. Once I have the
        idea of how I'd go about creating it, I usually create a CAD 3D model,
        so I can see if there could be any problems with my design, and I get to
        see what the final piece could look like.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {galleryDayImages0?.length > 0 && (
        <Gallery images={galleryDayImages0} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 1" />
      <ReplicaParagraph>
        I wanted to create a lightsaber before, so I already had most of the
        material I needed. I've decided to go with aluminium, which is perfect
        for projects like this. I didn't get very far the first day. I haven't
        used a lathe for more than six years, so I've decided to take it slow.
        Once I get to know this particular lathe, I'll be much faster, no doubt.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages1?.length > 0 && (
        <Gallery images={galleryDayImages1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 2" />
      <ReplicaParagraph>
        Today was a little bit more productive. I had the handle turned from
        before, so I continued with the handgrip - the ribbed part of the
        lightsaber. For the sake of precision, I've decided to mark all the
        grooves first. This way I'll know that I'm removing the material at the
        right distance from the face of the workpiece. I did my research, and it
        seems that the grooves on the original prop are not evenly distributed,
        and the angles are not symmetric. I don't like it very much, as it looks
        like someone made a mistake in measurements while turning the piece.
        I've decided to keep my grooves symmetric and with the same offset.
        After that, I moved on to work on the 'neck' part. The workpiece was a
        solid piece of aluminium, so I had to drill it first. I did this because
        I need to run a threaded rod through it. The rod will hold the whole
        lightsaber together. Once that was finished, I started working on the
        emitter (the 'head' of the lightsaber). I skipped one part, because it's
        made out of copper, and I still don't have the material. Anyway, the
        emitter is the last part of the saber, so it needs to have a thread
        inside of it. I didn't have much time left, so I didn't finish this part
        today.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2?.length > 0 && (
        <Gallery images={galleryDayImages2} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 3" />
      <ReplicaParagraph>
        Time to finish the emitter. It's not a very complicated shape to turn,
        so there were no problems. I also started working on its counterpart -
        the pommel. Turning it on a lathe is not difficult either. BUT, there's
        some milling involved in making this piece, and I don't have a milling
        machine... that's what I call a problem. Fortunately, you can do a
        little bit of milling on a lathe, too. On top of that, the lathe I'm
        using has a removable tool post, which you can replace with another
        chuck to hold your workpiece. But I still can't work on it, because I
        had to order a shaped end mill, and I'm still waiting for it to arrive.
        By the way, I'm sorry if my terminology doesn't make any sense, I
        studied this in Czech language, and it's difficult to translate
        technical terminology. Anyway, apart from milling, the pommel is
        finished. I managed to screw the 'neck' part up while polishing it on a
        lathe, so I had to do it again. Not a large setback. After that, I
        spray-painted the neck and the handgrip black. Once the paint dried, I
        cleaned the areas that are not supposed to be black. Finally, I put it
        all together to see if the threaded rod mechanism would hold everything
        together - it did.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages3?.length > 0 && (
        <Gallery images={galleryDayImages3} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 4" />
      <ReplicaParagraph>
        Today I started working on the control box. It's the first time I used
        the lathe as a milling machine, so the progress is kinda slow. I also
        found a tool that'll help me to create the 'arc' on one side of the box.
        It's not a correct tool for this type of work, but what the hell. As you
        can see, I didn't get very far with THIS lightsaber today - that's
        because I'm working on two lightsabers at once. I've decided to create
        Obi-Wan's as well, and there is (or will be) a thread about how I made
        it as well. Anyway, that's all for Luke's saber today.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages4?.length > 0 && (
        <Gallery images={galleryDayImages4} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 5" />
      <ReplicaParagraph>
        Today's all about the control box. I spent some time sanding the inner
        arc down, so now it sits perfectly on the handle. Once I got this out of
        the way, I milled the top of the box to the correct height. Then I
        drilled and milled a hole in the center of the box, so I can attach it
        to the handle using a screw. Before I can screw the box to the handle, I
        had to drill a hole in the handle first, and cut a thread for the screw.
        I keep using the lathe for drilling, because the hole is always in the
        center of the workpiece. Since everything fits together nicely, it's
        time to work on the details of the control box. I looked at a lot of
        images of the original prop, replicas and home-made replicas, and I've
        decided to go with my own design. It resembles the original prop as much
        as possible, but it's not the same. I did this mostly because of the
        main limitation that I have - I don't have access to a proper mill, so I
        had to work out an alternative that would be possible for me to create.
        Anyway, I started by adding a PVC plate to the top of the box. The plate
        is held in place by two screws. After that, I needed to create a 'rail'
        that would hold the signature copper plate on top of the control box in
        place. I took a copper sheet metal, cut the basic rectangular shape I
        needed and started bending it using a rubber hammer. The bends turned
        out looking much better than I expected. Once I drilled holes for the
        screws that hold the PCV piece, I could assemble it all together.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages5?.length > 0 && (
        <Gallery images={galleryDayImages5} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 6" />
      <ReplicaParagraph>
        I wasn't in the shop today, so I just spray-painted the copper plate to
        get the black stripes. I finally found a small shop with electrical
        supplies, where I could buy red and green triangular LEDs. Since I
        cannot mill the inside of the control box, I won't be doing any type of
        electrical work, the LEDs will be there just for decoration, they won't
        actually glow. The shop also had little M2 screws, which were also
        difficult to find.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages6?.length > 0 && (
        <Gallery images={galleryDayImages6} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 7" />
      <ReplicaParagraph>
        I realised that my design of the control box had one flaw - I had to
        unscrew the PVC plate (and the copper rail) every time I needed to
        remove the box from the handle. That's a lot of unnecessary work, so
        I've decided to drill another hole in the center of the PVC plate and
        the copper rail to gain access to the main screw. Once I got this out of
        the way, I started working on the final details. I added the small plate
        that will hold the LEDs, and a (fake) button I machined from aluminium
        and spray-painted it black. The plate is crewed to the box, but for the
        rest (LEDs and the button) I used glue.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages7?.length > 0 && (
        <Gallery images={galleryDayImages7} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 8" />
      <ReplicaParagraph>
        Yes! The mill for the pommel finally arrived. It took some time to get
        the angles right, because I had to rotate the work piece by hand, but I
        think the result looks precise enough.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages8?.length > 0 && (
        <Gallery images={galleryDayImages8} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 9" />
      <ReplicaParagraph>
        Today I received another package I've been waiting for. I wanted to
        create the D-ring from scratch as well, so I ordered some aluminium
        wire. The thing is, they don't sell these in short lengths, so I had to
        order 26 metres. At least I'll have some spare material, haha. I milled
        small holes into the pommel and attached the ring to it. I don't
        actually like it very much, the aluminium is too light, so I might redo
        this part in the future with a different material. I'm just waiting for
        a copper rod now, then I'll be able to finish the whole lightsaber.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages9?.length > 0 && (
        <Gallery images={galleryDayImages9} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 10" />
      <ReplicaParagraph>
        Copper rod finally arrived! (Along with brass rods, which I'll be using
        for{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ObiwansLightsaberANH.id}
          target="_blank"
        >
          Obi-Wan's lightsaber from New Hope
        </Link>
        . Getting my hands on these materials proved to be much more difficult
        than I anticipated. I've been waiting far too long for this. Anyway, I
        turned the last piece today. It fits together nicely with the rest of
        the parts. As a final touch, I've decided to give the end of the
        threaded rod more interesting shape. This way it's not that obvious that
        it's a threaded rod that's holding the lightsaber together. I will still
        probably tinker with a few things, but the lightsaber is more or less
        finished.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages10?.length > 0 && (
        <Gallery images={galleryDayImages10} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Assembly" />
      <ReplicaParagraph>
        Here's the final result and how I put it together. I'm quite pleased
        with the prop considering that I didn't use a lathe for more than six
        years, this is certainly not my last lightsaber.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=6TxMLIiLRfU" />
      <ReplicaSpacer />
      <ReplicaParagraph>
        Looking back, I think I've come a long way in building replicas... for
        comparison, here's the lightsaber in comparison with a lightsaber I
        built when I was about 12 years old:
        <ReplicaSpacer />
        {galleryDayImages11?.length > 0 && (
          <Gallery images={galleryDayImages11} />
        )}
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
