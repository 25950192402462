import styled from 'styled-components'

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 4vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;

  text-transform: uppercase;
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: ${props => props.theme.white}22;
`

const Footer = () => {
  return <ButtonWrapper>(c) Jan Hamernik</ButtonWrapper>
}

export default Footer
