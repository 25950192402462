import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1JUpQy5cOHxD5Rkw_RUz6YEDJBsq6RPNG/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Mark-5-Blueprints",
  name: "Mark 5 Blueprints",
  description: `The product contains 4 HD images. There are two slightly different poses, each one has two images - one with the front, side and rear view of the whole suit and one with the front, side and rear view of the endotech/underlayer of the suit. These images are in a very high resolution - 10200 x 6144.`,

  price: 10,
  previousPrice: 15,
  softwareRequired: [],

  categories: [productCategories.blueprint],
  groups: [productGroups.ironMan],
  license: licenses.editorial,

  images: gallery,
  videos: [
    {
      src: "https://www.youtube.com/watch?v=MMarOTLnx0M",
      note: "This product contains only blueprint renders of the 3D model presented in this video, not the mesh, rig, materials or textures.",
    },
  ],
  contents: [
    {
      text: "Blueprints - Pose 1 (front, side, rear)",
      format: fileFormats.jpg,
    },
    {
      text: "Blueprints - Pose 1 (front, side, rear)",
      format: fileFormats.jpg,
    },
    {
      text: "Blueprints - Pose 2 (front, side, rear)",
      format: fileFormats.jpg,
    },
    {
      text: "Blueprints - Pose 2 (front, side, rear)",
      format: fileFormats.jpg,
    },
  ],
  tags: ["2D Renders"],
};

export default data;
