import styled, { css } from "styled-components";
import { useEffect, useState } from "react";

import AnimateHeight from "react-animate-height";
import { ChevronRight } from "@styled-icons/boxicons-solid/";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Title = styled.div`
  display: inline;
  cursor: pointer;
  display: inline-block;

  font-weight: 500;
  color: ${(props) => props.theme.white}88;

  & > * {
    display: inline;
  }

  &:hover {
    color: ${(props) => props.theme.white}AA;
  }
`;

const CloseButton = styled.button<{ rotate: string }>`
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  background: none !important;
  -webkit-tap-highlight-color: transparent;

  svg {
    margin-left: -8px;
    margin-top: -3px;
    margin-right: 2px;
    color: inherit;

    transition: transform 0.2s ease;

    --size: 20px;
    width: var(--size);
    min-width: var(--size);
    max-width: var(--size);
    height: var(--size);
    min-height: var(--size);
    max-height: var(--size);

    ${({ rotate }) =>
      rotate === "true" &&
      css`
        transform: rotate(90deg);
      `}
  }
`;

const Content = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.white}77;

  a {
    font-weight: 500;
  }
`;

const CompactParagraph = (props: {
  title: string;
  defaultIsOpen?: boolean;
  children?: any;
}) => {
  const [isOpen, setIsOpen] = useState(props.defaultIsOpen);

  useEffect(() => {
    if (props.defaultIsOpen !== undefined) {
      setIsOpen(props.defaultIsOpen);
    }
  }, [props.defaultIsOpen]);

  return (
    <Wrapper>
      <Title onClick={() => setIsOpen(!isOpen)}>
        <CloseButton rotate={isOpen ? "true" : "false"}>
          <ChevronRight />
        </CloseButton>

        <div>{props.title}</div>
      </Title>

      <AnimateHeight height={isOpen ? "auto" : 0}>
        <Content>{props.children}</Content>
      </AnimateHeight>
    </Wrapper>
  );
};

export default CompactParagraph;
