import styled, { css } from 'styled-components'

import { HashLink } from 'react-router-hash-link'
import { ReplicaSubVersionType } from 'types'

const List = styled.div<{ large?: boolean; smallYGap?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0px;
  row-gap: 20px;
  margin: 40px -10px;

  a {
    margin: 0 10px;
  }

  ${({ large }) =>
    large &&
    css`
      a {
        margin-left: 20px;
        margin-right: 20px;
      }
    `}

  ${({ smallYGap }) =>
    smallYGap &&
    css`
      a {
        margin-top: -20px;
        margin-bottom: -20px;
      }
    `}
`

const Background = styled.div<{ large?: boolean }>`
  position: absolute;
  width: 70%;
  height: 60%;
  top: 20%;
  border-radius: 2px;
  background: ${props => props.theme.white};
  opacity: 0.1;
  transition: all 0.2s ease;

  ${({ large }) =>
    large &&
    css`
      top: 30%;
      width: 60%;
      height: 50%;
    `}
`

const Thumbnail = styled.img<{ large?: boolean }>`
  position: absolute;
  width: 90%;
  height: 80%;
  object-fit: contain;
  top: 0;

  --blur: 1rem;
  --offsetY: 1rem;
  --saturate: 0.8;
  filter: drop-shadow(
      0 var(--offsetY) var(--blur) ${props => props.theme.black}88
    )
    saturate(var(--saturate));
  transition: all 0.2s ease;

  ${({ large }) =>
    large &&
    css`
      top: -5%;
      width: 100%;
      height: 100%;
    `}
`

const Title = styled.div`
  position: absolute;
  bottom: 6%;
  width: 80%;
  height: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;

  --letterSpacing: 0.1em;
  letter-spacing: var(--letterSpacing);
  text-indent: var(--letterSpacing);

  color: ${props => props.theme.white};
  opacity: 0.7;
`

const Item = styled.div<{ large?: boolean }>`
  background: none;
  border: none;
  outline: none;
  position: relative;
  width: 210px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    ${Background} {
      opacity: 0.2;
    }
    ${Thumbnail} {
      --blur: 0.4rem;
      --offsetY: 0.5rem;
      --saturate: 1;
    }
  }

  ${props => props.theme.breakpoint.M} {
    margin: 0 -30px;
  }

  ${({ large }) =>
    large &&
    css`
      width: 250px;
      height: 250px;
      padding-top: 10%;
    `}
`

const ReplicaSubVersionList = (props: {
  replicaSubVersions: ReplicaSubVersionType[]
  large?: boolean
  smallYGap?: boolean
}) => {
  //
  // RENDER
  //
  return (
    <List large={props.large} smallYGap={props.smallYGap}>
      {props.replicaSubVersions.map((replicaSubVersion, i) => (
        <HashLink key={i} to={`#${replicaSubVersion.id}`} smooth>
          <Item large={props.large}>
            <Background large={props.large} />
            <Thumbnail src={replicaSubVersion.thumbnail} large={props.large} />
            <Title>{replicaSubVersion.name}</Title>
          </Item>
        </HashLink>
      ))}
    </List>
  )
}

export default ReplicaSubVersionList
