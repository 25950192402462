import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
  VideoType,
} from "types";

import ContentBox from "components/ContentBox";
import YouTubeVideoGallery from "components/Media/YouTubeVideoGallery";

const DetailVideos = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isBundle = product.isBundle;
  let videos: VideoType[] = [];

  if (!isBundle) {
    product = product as ProductType;
    videos = product.videos;
  }

  if (isBundle) {
    product = product as ProductBundleType;
    product.products.forEach((p) => {
      if (p.videos?.length) {
        videos = [...videos, ...p.videos];
      }
    });
  }
  return videos?.length ? (
    <ContentBox title="Videos">
      <YouTubeVideoGallery videos={videos} />
    </ContentBox>
  ) : null;
};

export default DetailVideos;
