import styled from 'styled-components'
import ProductItem from 'components/ProductItem'
import { AnyProductType } from 'types'

import { CloseOutline } from '@styled-icons/evaicons-outline/'

const Products = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
`

const ProductItemWrapper = styled.div`
  transform: scale(0.4);
  margin: -80px;

  ${props => props.theme.breakpoint.M} {
    margin: -80px -60px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
`

const Underscored = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px dotted ${props => props.theme.white}66;

  ${props => props.theme.breakpoint.M} {
    font-size: 0.9em;
  }
`

const Button = styled.button`
  padding: 10px;
  background: ${props => props.theme.red}88;
  border-color: ${props => props.theme.red}22;
  &:hover {
    background: ${props => props.theme.red}AA;
  }

  ${props => props.theme.breakpoint.M} {
    padding: 5px;
  }
`

const Price = styled.div`
  color: ${props => props.theme.white}DD;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 500;
  span {
    margin-bottom: 2px;
    font-size: 0.8em;
    opacity: 0.8;
  }
  ${props => props.theme.breakpoint.M} {
    font-size: 16px;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const ProductListCart = (props: {
  products: AnyProductType[]
  removeFromCart?: (product: AnyProductType) => void
}) => {
  //
  // RENDER
  //
  return props.products ? (
    <Products>
      {props.products.map((product, i) => (
        <Row key={i}>
          {props.removeFromCart && (
            <Button onClick={() => props.removeFromCart(product)}>
              <CloseOutline />
            </Button>
          )}

          <ProductItemWrapper>
            <ProductItem product={product} thin hideInfo />
          </ProductItemWrapper>

          <Underscored>
            {product.name}
            <Spacer />
            <Price>
              <span>$</span>
              {product.price}
            </Price>
          </Underscored>
        </Row>
      ))}
    </Products>
  ) : null
}

export default ProductListCart
