import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "sw-lightsaber-quigon-ep1",
  name: `Lightsaber\nQui-Gon Jinn Ep1`,
  origin: "Star Wars: Episode I - The Phantom Menace",

  category: replicaCategories.starWars,

  versions: [Version1],
};

export default data;
