import { css } from 'styled-components'

//
// Default Styles
//

const commonCss = css`
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  text-transform: capitalize;
  text-align: center;
  white-space: pre-wrap;
`

export const defaultHeadings = css`
  h1 {
    ${commonCss}
    font-size: calc(16px + 1vw);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  h2 {
    ${commonCss}
    font-size: calc(16px + 0.7vw);
    line-height: calc(30px + 0.7vw);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  h3 {
    ${commonCss}
    font-size: calc(10px + 0.5vw);
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    opacity: 0.7;
  }
  h4 {
    ${commonCss}
    font-size: calc(9px + 0.3vw);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    opacity: 0.7;
  }
`
