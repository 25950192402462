const theme = {
  white: "#ffffff",
  dark: "#444444",
  black: "#000000",
  red: "#f03c3c",
  green: "#7bff64",
  greenPurchase: "#1a8507",
  cyan: "#40b3ff",

  download: "#007ee5",
};

export default theme;
