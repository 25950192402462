import { importAll } from 'utils'

import video from './src/Versions - Comparisons - Layouts.mp4'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: { gallery: any; video: any } = {
  gallery,
  video,
}

export default data
