import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaQuote from "components/Replicas/ReplicaQuote";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaStats from "components/Replicas/ReplicaStats";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import imageBooks from "./image - books.png";
import thumbnail from "./thumbnail.png";

let gallery = [];
let gallery_Cover = [];
let gallery_OriginalVsTranslated = [];

let gallery_StoryThreeBrothers1 = [];
let gallery_StoryThreeBrothers2 = [];
let gallery_StoryPot1 = [];
let gallery_StoryFountain1 = [];
let gallery_StoryHeart1 = [];
let gallery_StoryBabbitty1 = [];
let gallery_StoryHand1 = [];
let gallery_StoryBard1 = [];
let gallery_StoryOther1 = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_Cover = importAll(
    (require as any).context("./gallery - cover", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_OriginalVsTranslated = importAll(
    (require as any).context(
      "./gallery - original vs translated",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryThreeBrothers1 = importAll(
    (require as any).context(
      "./gallery - story - three brothers (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryThreeBrothers2 = importAll(
    (require as any).context(
      "./gallery - story - three brothers (2)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryPot1 = importAll(
    (require as any).context(
      "./gallery - story - pot (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryFountain1 = importAll(
    (require as any).context(
      "./gallery - story - fountain (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryHeart1 = importAll(
    (require as any).context(
      "./gallery - story - heart (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryBabbitty1 = importAll(
    (require as any).context(
      "./gallery - story - babbitty (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryHand1 = importAll(
    (require as any).context(
      "./gallery - story - hand (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryBard1 = importAll(
    (require as any).context(
      "./gallery - story - bard (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_StoryOther1 = importAll(
    (require as any).context(
      "./gallery - story - other (1)",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 11, month: 2, year: 2023 }),
  datePublished: createDate({ day: 19, month: 2, year: 2023 }),
  version: "2",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaQuote source="Harry Potter and the Deathly Hallows">
        "'To Miss Hermione Jean Granger, I leave my copy of The Tales of Beedle
        the Bard, in the hope that she will find it entertaining and
        instructive.'" Scrimgeour now pulled out of the bag a small book that
        looked as ancient as the copy of Secrets of the Darkest Art upstairs.
        Its binding was stained and peeling in places. Hermione took it from
        Scrimgeour without a word. She held the book in her lap and gazed at it.
      </ReplicaQuote>

      <ReplicaParagraph>
        The Tales of Beedle the Bard is a book of stories written for young
        witches and wizards. This particular copy originally belonged to Albus
        Dumbledore, who passed it onto Hermione Granger after his death.
        Although it is just a children's book, it proved to be an important lead
        that introduced the trio to the so-called Deathly Hallows - the
        Resurrection Stone, the Elder wand and the Cloak of Invisibility.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=nUQViBztCfg" />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Just like with all of my replicas, the goal was to create a book that
        would feel real and that you could read from start to finish without
        breaking the illusion. However, this time the result is quite different
        from my other replicas - since the contents of this book are stories,
        the final result is an *actual* real-life book that can be read just for
        the stories inside, no illusions needed. There are also over fifty
        original illustrations throughout the book, but more on that below. The
        pages are covered by an intricate gold-embellished and debossed cover
        that's true to the film prop and aged appropriately.
      </ReplicaParagraph>

      <ReplicaStats
        labels={[
          "immersive and fully readable",
          "includes tales written by J. K. Rowling",
          "9 original tales written by me",
          "over 50 original '3D' illustrations",
          "gold embellishments",
          "100% handmade",
          "and much more!",
        ]}
        img={imageBooks}
      />

      <ReplicaSubTitleAnchor title={`Intricate Book Cover`} />

      <ReplicaParagraph>
        The cover of the book proved to be the biggest challenge of the whole
        project, and I'd like to point out that everything was done manually by
        me. There were several difficult and time-consuming processes that
        needed to be perfectly combined in order to create the final cover. The
        most difficult process was debossing fine details into the cover boards.
        I analyzed all the reference images for the book that I could find, and
        at first I thought that the debossing was simply a printed effect.
        However, there were a few shots that (to me) looked like the shapes were
        actually three dimensional, so I made the decision to make my life much
        more difficult and try and figure out a way to do this by myself. After
        months of experimenting and perfecting the process, I was able to
        finally produce the final debossed cover.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_Cover)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Original vs Translated`} />

      <ReplicaQuote source="Harry Potter and the Deathly Hallows">
        He glanced vaguely around the room, at the piles of parchment and books,
        but Hermione said, "I've got a copy, Mr. Lovegood, I've got it right
        here." And she pulled out The Tales of Beedle the Bard from the small,
        beaded bag. "The original?" inquired Xenophilius sharply, and when she
        nodded, he said, "Well then, why don't you read it out aloud? Much the
        best way to make sure we all understand."
      </ReplicaQuote>

      <ReplicaParagraph>
        My original plan was to make a replica of the version of The Tales of
        Beedle the Bard that can be seen in the film. This is the version that I
        will refer to as the 'original'. Those who have read the book (Harry
        Potter and the Deathly Hallows) will surely notice some inaccuracies
        between how the children's book is described versus how it was portrayed
        in the film. Judging by the descriptions sprinkled throughout the book,
        everything, including the cover, should be written in ancient runes.
        However, in the film-version, only the stories are written in runes -
        everything else is in english. When creating my replicas, the film
        designs almost always take precedence, which is why I've decided that my
        "original" version would also be a mix of runes and english.
      </ReplicaParagraph>

      <ReplicaParagraph>
        Later in the process, I've decided to also create an entirely
        english-written version (I will talk about my reasoning later). This
        meant that I ended up with two versions of the book, which is not so
        different from real-life book publishing, where often times different
        versions are published at the same time - usually translated for
        different countries. I call these two versions of The Tales of Beedle
        the Bard the "Original Edition" and the "Translated Edition", and this
        designation can also be found at the start of each book.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_OriginalVsTranslated)} />
      <ReplicaSpacer />

      <ReplicaBigTitle title={`Beedle's Stories`} />

      <ReplicaQuote source="Harry Potter and the Deathly Hallows">
        "And as for this book." Said Hermione, "The Tales of Beedle the Bard …
        I've never even heard of them!" "You've never heard of The Tales of
        Beedle the Bard?" said Ron incredulously. "You're kidding, right?" "No,
        I'm not," said Hermione in surprise. "Do you know them then?" "Well, of
        course I do!" Harry looked up , diverted. The circumstance of Ron having
        read a book that Hermione had not was unprecedented. Ron, however,
        looked bemused by their surprise. "Oh come on! All the old kids' stories
        are supposed to be Beedle's aren't they? 'The Fountain of Fair Fortune'
        … 'The Wizard and the Hopping Pot'… 'Babbitty Rabbitty and her Cackling
        Stump'…" "Excuse me?" said Hermione giggling. "What was the last one?"
        "Come off it!" said Ron, looking in disbelief from Harry to Hermione.
        "You must've heard of Babbitty Rabbitty –" "Ron, you know full well
        Harry and I were brought up by Muggles!" said Hermione. "We didn't hear
        stories like that when we were little, we heard 'Snow White and the
        Seven Dwarves' and 'Cinderella' –" "What's that, an illness?" asked Ron.
        "So these are children's stories?" asked Hermione, bending again over
        the runes.
      </ReplicaQuote>

      <ReplicaParagraph>
        As far as I understand, The Tale of the Three Brothers was the only
        story that was written at the time the{" "}
        <i>Harry Potter and the Deathly Hallows</i> book was released. Later, J.
        K. Rowling came back and wrote four new stories based on the titles
        mentioned in the book. Together with The Tale of the Three Brothers,
        those five stories were then labeled "The Tales of Beedle the Bard" and
        released as a real-life "muggle" book. This book is obviously completely
        different from the film prop, but it was essential to include those
        written stories in my replica. I also designed and created illustrations
        for each of the stories to complete the look defined by The Tale of the
        Three Brothers in the original film prop.
      </ReplicaParagraph>
      <ReplicaParagraph>
        In my research, I found out that some of the pages for these stories
        were also designed for the prop, but other than the illustrations, I
        departed from those designs, because they used alchemy symbols instead
        of runes, which doesn't make much sense. As for the illustrations that
        were designed for the original prop, I took the liberty of separating
        them into two layers to stay true to the style defined by the
        illustrations of The Tale of the Three Brothers. Please note that
        everything in my replicas is usually created entirely from scratch -
        even though the original illustrations exist, the ones in my replica
        were (re)created by me from scratch.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title={`The Tale of the Three Brothers`} />

      <ReplicaQuote source="Harry Potter and the Deathly Hallows">
        "But what are the Deathly Hallows?" asked Hermione. Xenophilius set
        aside his empty teacup. "I assume that you are familiar with 'The Tale
        of the Three Brothers'?" Harry said, "No," but Ron and Hermione both
        said, "Yes." Xenophilius nodded gravely. "Well, well, Mr. Potter, the
        whole thing starts with 'The Tale of the Three Brothers' ... I have a
        copy somewhere ..."
        <br />
        ...
        <br />
        "Er... all right," said Hermione nervously. She opened the book, and
        Harry saw that the symbol they were investigating headed the top of the
        page as she gave a little cough, and began to read. "'There were once
        three brothers who were traveling along a lonely, winding road at
        twilight –'"
      </ReplicaQuote>

      <ReplicaParagraph>
        The Tale of the Three Brothers is ultimately the most important story in
        The Tales of Beedle the Bard. Throughout the book, Hermione spends a lot
        of time trying to figure out the meaning of a mysterious symbol inked on
        the first page of the story. This, of course, turned out to be the
        symbol of the Deathly Hallows later in the book. The "believers" pursued
        the three magical objects described in The Tale of the Three Brothers,
        believing they actually existed... and they were not entirely wrong. As
        for the film prop, although the pages for the story are not shown in the
        movie, at least four pages, including two illustrations, were actually
        designed. On that point - I was pleasantly surprised when I noticed that
        the illustrations are actually layered, which gives them a more "3D"
        feel. I absolutely love this approach, and I made all of my original
        illustrations with the same style in mind. Although the pages cannot be
        seen in the film, there is a scene where Hermione is holding the book
        open in order to read the story - I used that to approximate the page
        number on which the story needed to be in order for my replica to be
        accurate to the scene in the film.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryThreeBrothers1)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I don't know if the remaining pages of The Tale of the Three Brothers
        were designed or not, but I spent weeks researching the book and was
        unable to find any reference or piece of information that would suggest
        that they exist. Anyway, since the whole story is known, I designed the
        rest of the pages and illustrations myself to complete the story. Below
        is an example of one such illustration designed by me. It depicts the
        end of the story where Death takes the last brother. This illustration
        went through several completely different iterations, until I settled
        with the final one. I tried to come up with an interesting way of
        depicting the contrast between the two figures - Death and the departing
        brother.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryThreeBrothers2)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`The wizard and the Hopping Pot`} />

      <ReplicaQuote source="The wizard and the Hopping Pot, The Tales of Beedle The Bard">
        “My granddaughter is afflicted by a crop of warts, sir,” she told him.
        “Your father used to mix a special poultice in that old cooking pot –”
        “Begone!” cried the son. “What care I for your brat’s warts?” And he
        slammed the door in the old woman’s face. At once there came a loud
        clanging and banging from his kitchen. The wizard lit his wand and
        opened the door, and there, to his amazement, he saw his father’s old
        cooking pot: it had sprouted a single foot of brass, and was hopping on
        the spot, in the middle of the floor, making a fearful noise upon the
        flagstones.
      </ReplicaQuote>

      <ReplicaParagraph>
        The title of this story has been explicitly mentioned in the{" "}
        <i>Harry Potter and the Deathly Hallows</i> book by Ron. According to J.
        K. Rowling, at the time of writing the <i>Deathly Hallows</i>, only the
        title of this tale existed. Later, she went back and wrote a story that
        would fit the title, and thus the tale of The Wizard and the Hopping Pot
        was born. As for the illustrations of this story, one (the first one
        below) was designed for the original prop. However, I've only been able
        to find "flat" versions of the original image, but, extrapolating from
        the Tale of the Three Brothers reference images, I turned the
        illustration into a layered "3D" image. The remaining three
        illustrations of this story were created by me from scratch to capture
        the important points of the story.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryPot1)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`The Fountain of Fair Fortune`} />

      <ReplicaQuote source="The Fountain of Fair Fortune, The Tales of Beedle The Bard">
        High on a hill in an enchanted garden, enclosed by tall walls and
        protected by strong magic, flowed the Fountain of Fair Fortune. Once a
        year, between the hours of sunrise and sunset on the longest day, a
        single unfortunate was given the chance to fight their way to the
        Fountain, bathe in its waters and receive Fair Fortune for evermore.
      </ReplicaQuote>

      <ReplicaParagraph>
        Just like the previous tale, the title "The Fountain of Fair Fortune"
        was explicitly mentioned in the{" "}
        <i>Harry Potter and the Deathly Hallows book</i>, and the story was
        later invented to fit the title. The illustrations in this story also
        received the same treatment as before - one of the illustrations was
        designed for the original prop, and the rest was created by me from
        scratch. When creating these illustrations, it was most important to
        capture important story-points in a way that would be interesting to
        look at. However, the tricky part of his process is the fact that the
        formatting of the text could get in the way - you can only pick parts of
        the story to illustrate that are on the page next to the illustration.
        However, since there are only approximately five short paragraphs on the
        page, sometimes it was difficult to find something that would spark an
        idea for an illustration.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryFountain1)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`The Warlock's Hairy Heart`} />

      <ReplicaQuote source="The Warlock's Hairy Heart, The Tales of Beedle The Bard">
        There was once a handsome, rich and talented young warlock, who observed
        that his friends grew foolish when they fell in love, gambolling and
        preening, losing their appetites and their dignity. The young warlock
        resolved never to fall prey to such weakness, and employed Dark Arts to
        ensure his immunity.
      </ReplicaQuote>

      <ReplicaParagraph>
        This is the only official story that was not mentioned in the{" "}
        <i>Harry Potter and the Deathly Hallows</i> book. Out of the five
        official tales, this one is the darkest. While researching this subject,
        I often came upon notes that (in the Harry Potter universe) Beedle's
        stories were supposed to be quite dark, and the regular witch and wizard
        would usually know only a revised more child-friendly version. I think
        this story gives us a taste of some of the darker aspects of Beedle's
        writing. Once again, one of the illustrations was designed for the
        original prop (the last one below), and the rest was created by me.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryHeart1)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor
        title={`Babbitty Rabbitty and her Cackling Stump`}
      />

      <ReplicaQuote source="Babbitty Rabbitty and her Cackling Stump, The Tales of Beedle The Bard">
        One morning, as the charlatan and the foolish King were twirling their
        twigs, and hopping in circles, and chanting meaningless rhymes, a loud
        cackling reached the King’s ears. Babbitty the washerwoman was watching
        the King and the charlatan from the window of her tiny cottage, and was
        laughing so hard she soon sank out of sight, too weak to stand.
      </ReplicaQuote>

      <ReplicaParagraph>
        Babbitty Rabbitty and her Cackling Stump is the last official tale
        written by J. K. Rowling. Just like most of the official tales above,
        the title of this tale was also mentioned in the{" "}
        <i>Harry Potter and the Deathly Hallows</i> book. At the risk of
        repeating myself - the first illustration was designed for the original
        prop, and the rest was up to me. For some reason, I found it difficult
        to illustrate this tale, because most of the text just didn't translate
        into images in my mind as easily as the previous stories, but in the
        end, I was able to overcome this and come up with a few original
        illustrations.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryBabbitty1)} />
      <ReplicaSpacer />

      <ReplicaBigTitle title={`9 Stories Written By Me`} />

      <ReplicaParagraph>
        When I was researching the original prop, it quickly became obvious that
        the five stories written by J. K. Rowling were not nearly enough to fill
        the book of the size that is depicted in the film. There were just too
        many pages, even with the illustrations taken into account. To solve
        this problem, I have decided to write my own stories to fill the rest of
        the book. At first, I considered taking advantage of the fact that the
        book is written in runes, and thus the text doesn't have to make sense,
        but since I still had to come up with the entire story in order to
        create all the illustrations with little bits of coherent text under
        them, it was easier to just write the stories in full.
      </ReplicaParagraph>

      <ReplicaParagraph>
        Since I put a lot of effort into inventing and writing my own original
        stories, I felt that it was a shame to have all the work hidden behind
        the runes - and that's how the 'English Edition' came to be. It can be
        easily read from cover to cover, just like a regular children's book. On
        top of that, since all the text actually makes sense, even the runes in
        the 'Original Edition' can be translated back into the original text,
        which makes it all the more real.
      </ReplicaParagraph>

      <ReplicaQuote source="J. K. Rowling,  The Tales of Beedle The Bard">
        In The Tales of Beedle the Bard, on the other hand, we meet heroes and
        heroines who can perform magic themselves, and yet find it just as hard
        to solve their problems as we do. Beedle’s stories have helped
        generations of wizarding parents to explain this painful fact of life to
        their young children: that magic causes as much trouble as it cures.
        <br />
        ...
        <br />
        Mrs Bloxam believed that The Tales of Beedle the Bard were damaging to
        children because of what she called “their unhealthy preoccupation with
        the most horrid subjects, such as death, disease, bloodshed, wicked
        magic, unwholesome characters and bodily effusions and eruptions of the
        most disgusting kind”.
      </ReplicaQuote>

      <ReplicaParagraph>
        When coming up with new stories, I always tried to stay true to the
        description of Beedle's stories and writing style. It was mentioned that
        Beedle's tales were often times barely suited for the ears of young
        children. Since I'm a fan of old horror stories written by the likes of
        Bram Stoker (Dracula), Howard Phillips Lovecraft, Robert Ervin Howard or
        Henry Kuttner, for example, a lot of the stories I wrote took
        inspiration from this genre of books. That being said, the goal wasn't
        to write horror stories, but rather borrow a few elements from the genre
        and ultimately come up with a darker story that would also teach the
        reader an important lesson. This style of writing is, in my mind, on
        point with how Beedle's tales are described. However, not all the
        stories I wrote are dark, there are a few that are more similar to
        regular fairytales.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title={`The Story of the Wooden Hand`} />

      <ReplicaQuote source="Jan Hamernik,  The Story of the Wooden Hand (original story)">
        "I have lost everything I had!" Lignus said. "My reputation, the respect
        of my neighbours and my hand!" And he showed her the stump where his
        hand used to be. The kindly old witch pitied Lignus, for he clearly
        valued his reputation above all, not knowing that people from the
        village were laughing behind his back at his absurd stories of glory.
        She picked up a pine branch that was lying on the ground, brandished her
        wand, and conjured a wooden hand out of it. "Please accept this gift,
        for it will help you gain the respect of your neighbours," she said.
        Lignus eagerly accepted and set off to return to the village without a
        word of thanks.
      </ReplicaQuote>

      <ReplicaParagraph>
        This tale was completely created by me, from the initial idea to
        finalizing the story and illustrating it. If I were to give a brief
        summary of the plot, the tale's about Lignus, a boastful wizard who
        excelled at wizarding duels in his youth. However, he soon stopped
        training in magical arts, and, instead, spent his time in the local inn,
        where he boasted about his made-up accomplishments. One day, he insulted
        a great duelist, and by a fault of his own, lost his hand in a duel.
        This was the price for his arrogance. Later in the story, an old witch
        conjured a new hand for him out of a pine branch. She told him that it
        would help him regain the respect of his neighbors, but Lignus mistook
        this to mean that it would make him invincible. On his way back,
        however, the wooden hand teaches reluctant Lignus that there are much
        more important things in life than his made-up reputation. In the end,
        he returns to the village as a changed man.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryHand1)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`Dead Bard's Magical Harp`} />

      <ReplicaQuote source="Jan Hamernik,  Dead Bard's Magical Harp (original story)">
        "Are you a man of a good heart?" the bard asked. "I assure you that I
        am!" the warlock said confidently. The bard was tired, sleepy, and he
        did not know the warlock and his bad reputation. "I will believe that
        you would not deceive me." he said, and thus agreed. The next day, the
        rich warlock sent his servant to bring the bard to his house, where the
        birthday celebration took place. "Enchant us with your beautiful music,
        bard!" the warlock demanded. The bard noded and struck the strings of
        his harp, letting it play the music it deemed appropriate for the
        warlock's heart. An ear-splitting sound broke the silence.
      </ReplicaQuote>

      <ReplicaParagraph>
        This is one of the darker stories I wrote for this book. Again, the
        entire tale was written and illustrated by me, based on my own ideas.
        The plot revolves around a bard, who was renowned for his beautiful
        music. However, it was not the bard who played the music, but his
        magical harp. It came up with the music based on the heart of the witch
        or wizard it was playing for, and it controlled the bard's body like a
        puppet to struck the strings until the music ended. One day, a rich
        warlock of bad heart convinced the bard to play for him on his birthday
        celebration. However, when the time came for the bard to play, instead
        of beautiful music, the harp started making horrible sounds, which
        insulted the warlock.
      </ReplicaParagraph>
      <ReplicaParagraph>
        That night, the bard was murdered in his sleep. His body was buried in
        the warlock's cellar, and the harp was thrown to a scrap pile. Later, an
        unsuspecting student of music used the strings from the harp to play
        during one of the warlock's celebrations. As soon as he started playing,
        the harp's strings once again started to control its master's body. As
        the student continued to play, the celebration was interrupted by
        increasingly louder thumps. In a twist of events, the harp was
        controlling the dead body of the bard, which made it's way up from the
        cellar, and exposed the warlock as a murderer. The people hung him on a
        nearby tree and gave the bard - and his magical harp - a proper burial.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryBard1)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title={`... and 7 other stories`} />

      <ReplicaQuote source="Jan Hamernik,  Sandon and the Three Tasks (original story)">
        The lake was surrounded by steep stony walls, and it was accessible only
        through a narrow tunnel. It got its name because nobody that ever broke
        the surface of its waters ever came back alive. Nobody ever reached the
        island in the middle, for the entrance tunnel was too narrow to bring a
        boat, and all swimmers had drowned. When Sandon reached the lake, it
        seemed to be fairly peaceful. However, as he was examining the water,
        suddenly a fish-like hand sprang from the lake and tried to grab him and
        drag him under the water. He was able to jump back just in time to save
        himself. It was now obvious to him that the lake was the home of
        Merpeople, and they drowned all who entered the water. However, Sandon
        was a wizard, so he was able to conjure himself a small boat and he
        safely got to the island in the middle of the lake. There he took one of
        the golden roses and returned to the King.
      </ReplicaQuote>

      <ReplicaParagraph>
        On top of the two stories mentioned above, I wrote seven other original
        stories for the book. All of them fully illustrated, of course. Where it
        made sense and where it served the story, I tried to include things from
        the Harry Potter universe. For example, the tale Sandon and the Three
        Tasks mentions Merpeople. There's also a story that heavily relies on
        Polyjuice Potion (although not mentioned by name), a short horror story
        that has a giant Acromantula in it, a story with a mountain troll and
        much more. In principle, I tried to follow the rules of magic set by the
        Harry Potter universe and avoid the usual tropes of fairytales that do
        not fit these rules - for example, there are no talking animals, magic
        without wands etc.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery_StoryOther1)} />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
