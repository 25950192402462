import { ProductContentsType, ProductType } from "types";

import ContentBox from "components/ContentBox";
import React from "react";
import { paragraphCss } from "styles/GlobalStyle/Text";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: -20;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  & > * {
    flex: 1;

    &:first-child {
      ${(props) => props.theme.breakpoint.M} {
        flex: 0;
        width: 30%;
      }
      ${(props) => props.theme.breakpoint.S} {
        width: 70%;
      }
    }
  }
  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column;
    gap: 20px;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  max-height: 300px;
  object-fit: contain;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  ${paragraphCss}
  font-size: 13px;
  font-weight: 400;

  color: ${(props) => props.theme.white}88;
  display: flex;
  align-items: center;
  margin: 5px 0;

  &::before {
    --size: 6px;

    content: " ";
    width: var(--size);
    min-width: var(--size);
    max-width: var(--size);
    height: var(--size);
    min-height: var(--size);
    max-height: var(--size);
    border-radius: 50%;
    background: ${(props) => props.theme.white}22;
    margin-right: 10px;
  }
`;

const ListItemText = styled.span`
  display: inline-block;
`;

const ListItemDivider = styled.span`
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 5px;

  ${paragraphCss}
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${(props) => props.theme.white}55;

  &::after {
    content: ":";
  }
`;

const FormatName = styled.span`
  display: inline;
  margin-left: 6px;
  opacity: 0.6;
`;

const DetailProduct = (props: { product: ProductType }) => {
  let product = props.product;

  const renderItemText = (c: ProductContentsType) => {
    return (
      <ListItem>
        <ListItemText>
          {c.text}
          {c.format?.name ? (
            <FormatName>{`(${c.format.name})`}</FormatName>
          ) : null}
        </ListItemText>
      </ListItem>
    );
  };

  const renderItemDivider = (c: ProductContentsType) => {
    return <ListItemDivider>{c.text}</ListItemDivider>;
  };

  return (
    <ContentBox title="What's Inside?">
      <Wrapper>
        <Thumbnail src={product.thumbnail} />
        <List>
          {product.contents.map((c, i) => (
            <React.Fragment key={i}>
              {c.isDivider ? renderItemDivider(c) : renderItemText(c)}
            </React.Fragment>
          ))}
        </List>
      </Wrapper>
    </ContentBox>
  );
};

export default DetailProduct;
