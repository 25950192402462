import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule.jpg";
import { purchaseProcess } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Content = styled.div`
  width: 90%;
  max-width: 800px;
  text-align: justify;
  ${(props) => props.theme.breakpoint.S} {
    text-align: left;
  }
`;

const PurchaseGuide = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background}>
      <Wrapper>
        <SectionHeading
          title="Purchase Guide"
          subtitle={[
            "I want to buy a product, why should I do?",
            "When will I receive the product?",
          ]}
        />

        <Spacer />
        <Content>{purchaseProcess}</Content>

        <Spacer />

        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Continue Reading"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default PurchaseGuide;
