import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import styled from "styled-components";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 26, month: 8, year: 2019 }),
  version: "5.2",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        For about a month, v5.1 was supposed to be the final version... well it
        wouldn't be me if it was actually true. I accidentally found the type of
        paper I was looking for for ages, so I immediately had to shake things
        up. The difference between 5.2 and 5.1 is minimal, mainly just the paper
        and a little different render settings. The difference in paper is not
        only the color, but how authentic it feels when you hold the map in your
        hands. It is similar to the paper I used for version 4, but that one
        seemed a little bit too dark for my liking, which is why I moved to
        regular white paper for version 5.0 and 5.1... but that just seemed a
        bit too light and I had to print the texture, which I don't quite like
        and that's why I kept my eyes open for the "perfect paper" (plot twist -
        the next version doesn't use this type of paper anymore).
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={galleryImages} size={GallerySize.S} />

      <SpacerContent />
      <YouTubeVideo src="https://www.youtube.com/watch?v=584U53u4UHA" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
