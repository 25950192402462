import styled, { css, keyframes } from 'styled-components'

import AnimateHeight from 'react-animate-height'
import { Close } from 'styled-icons/remix-line'
import { InfoCircle } from 'styled-icons/fa-solid'
import { Link } from 'react-router-dom'
import { routes } from 'config'
import { useState } from 'react'

const Animation_Wrapper = keyframes`
  from { 
    margin-top: -200px;
    opacity: 0;
  }
  to { 
  }
`

const Wrapper = styled.div`
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0.27, 0, 1);
  animation-name: ${Animation_Wrapper};

  position: fixed;
  top: 6vh;
  left: 4vw;
  right: 4vw;
  pointer-events: none;

  display: flex;
  justify-content: flex-end;

  ${(props) => props.theme.breakpoint.M} {
    justify-content: center;
    top: 6.5vh;
  }
`

const Animation_Content = keyframes`
  from { 
    opacity: 0.2;
  }
  to { 
  }
`

const Content = styled.div`
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-iteration-count: 16;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0, 0.27, 0, 1);
  animation-name: ${Animation_Content};

  max-width: 350px;

  pointer-events: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${(props) => props.theme.dark};
  border-radius: 2px;
`

const Header = styled.div<{ onClick?: () => void }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s ease;

  ${(props) =>
    !!props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background: ${(props) => props.theme.white}11;
      }
    `}
`

const CloseButton = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  color: ${(props) => props.theme.white}88;
  align-self: flex-end;
  padding: 10px;
  padding-left: 2px;

  svg {
    width: 30px;
  }

  &:hover {
    color: ${(props) => props.theme.white};
  }
`

const InfoButton = styled.div`
  cursor: pointer;
  padding-left: 15px;
  padding-right: 12px;
  svg {
    width: 20px;
  }
`

const Title = styled.div`
  flex: 1;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;

  color: ${(props) => props.theme.white};
`

const Text = styled.div`
  padding: 14px;
  padding-bottom: 24px;
  font-size: 13px;
  line-height: 180%;
`

const NotificationReplicas = (props: {
  isVisible: boolean
  onClose: () => void
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return props.isVisible ? (
    <Wrapper>
      <Content>
        <Header onClick={!isOpen ? () => setIsOpen(!isOpen) : undefined}>
          <InfoButton onClick={() => setIsOpen(!isOpen)}>
            <InfoCircle />
          </InfoButton>

          <Title>Replicas For Sale?</Title>

          <CloseButton onClick={props.onClose}>
            <Close />
          </CloseButton>
        </Header>

        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <Text>
            If you want to know more about my replicas, feel free to drop me an{' '}
            <Link to={'/' + routes.contact.slug} target="_blank">
              email
            </Link>
            . The replicas cannot be found in my e-shop.
          </Text>
        </AnimateHeight>
      </Content>
    </Wrapper>
  ) : null
}

export default NotificationReplicas
