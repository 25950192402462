import Gallery, { GallerySize } from "components/Media/Gallery";
import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";

import ContentBox from "components/ContentBox";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
  margin-bottom: 40px;
`;
const GalleryWrapper = styled.div`
  align-self: center;
  max-width: 800px;
`;

const Title = styled.p`
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
  margin-bottom: -30px;

  a {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.white}44;

    &:hover {
      text-decoration-color: currentColor;
    }
  }
`;

const Paragraph = styled.p`
  margin: 0;
  text-align: justify;
`;

const DividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
`;
const Divider = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${(props) => props.theme.white}22;
`;

const DetailText = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  const text = props.product?.text;

  if (!text || !text.length) {
    return null;
  }

  return (
    <ContentBox title="Additional Information">
      <Wrapper>
        {text.map((textObj, i) => {
          if (textObj.type === "divider") {
            return (
              <DividerWrapper key={i}>
                <Divider />
              </DividerWrapper>
            );
          }
          if (textObj.type === "text") {
            return <Paragraph key={i}>{textObj.text}</Paragraph>;
          }
          if (textObj.type === "node") {
            return <Paragraph key={i}>{textObj.node}</Paragraph>;
          }
          if (textObj.type === "node-title") {
            return <Title key={i}>{textObj.node}</Title>;
          }
          if (textObj.type === "image") {
            return (
              <GalleryWrapper key={i}>
                <Gallery
                  images={[{ src: textObj.image, name: props.product.name }]}
                  size={GallerySize.FullWidth}
                />
              </GalleryWrapper>
            );
          }
          return null;
        })}
      </Wrapper>
    </ContentBox>
  );
};

export default DetailText;
