import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (2) - Prisoner of Azkaban - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub2-prisoner-of-azkaban',
  name: 'Prisoner of Azkaban',
  gallery,
  video: 'https://www.youtube.com/watch?v=dYA0ifiZNcQ',
  content: (
    <>
      <ReplicaParagraph>
        This is a replica of the early prop that was used while filming the
        Prisoner of Azkaban. From what I heard (and seen in the film), the final
        prop was not yet finished when they filmed some of the scenes. For
        example, the scene where Lupin is giving the map back to Harry at the
        end of the Prisoner of Azkaban, you can clearly see that the design is
        quite different from the final map that we all know and love - there are
        basically no pages designed (only the Hogwarts grounds = lines) and the
        middle part of the map, which actually has a completely different design
        from the final one in the close-up shots with Peter Pettigrew.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Harry is also holding this version of the prop in the scene where he
        finds Peter Pettigrew on the map. In the very first shot (Harry sitting
        on the bed), he is holding this version of the prop. The designed pages
        then appear in the close-up shots that were clearly filmed later when
        the designs were finished - but - those designs are not in the version
        of the prop that Harry is holding in the establishing shot.
      </ReplicaParagraph>
    </>
  ),
}

export default data
