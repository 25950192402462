import { Check2 } from "styled-icons/bootstrap";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.black}99;
`;

const PopUp = styled.div`
  position: relative;
  width: 80%;
  max-width: 500px;

  ${(props) => props.theme.breakpoint.S} {
    min-height: 60vh;
  }

  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.white}33;

  background: ${(props) => props.theme.black}66;
  backdrop-filter: blur(10px);

  display: flex;
`;

const PopUpContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px 6%;
`;

const Icon = styled.div`
  background: ${(props) => props.theme.green};
  border-radius: 50%;
  padding: 10px;

  --size: 40px;
  width: var(--size);
  min-width: var(--size);
  max-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  max-height: var(--size);

  svg {
    color: ${(props) => props.theme.black};
    width: 100%;

    margin-bottom: -10px;
  }
`;

const Title = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  color: ${(props) => props.theme.white};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 360px;

  p {
    color: ${(props) => props.theme.white}88;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
  }

  button {
    min-width: 50%;
  }
`;

const PurchasePopUp = (props: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}) => {
  return props.isOpen ? (
    <Wrapper onClick={() => props.setIsOpen(false)}>
      <PopUp>
        <PopUpContent>
          <Icon>
            <Check2 />
          </Icon>

          <Title>Payment Successful</Title>

          <Content>
            <p>
              You can now download your purchased products. Please take a moment
              to read the information at the top of the page.
            </p>

            <button>OK</button>
          </Content>
        </PopUpContent>
      </PopUp>
    </Wrapper>
  ) : null;
};

export default PurchasePopUp;
