import styled, { keyframes } from "styled-components";

import LayoutPage from "layouts/LayoutPage";
import ProductList from "./ProductList";
import { ProductsIncludeBundles } from "utils";
import SectionHeading from "components/SectionHeading";
import SectionSubHeading from "components/SectionSubHeading";
import Tabs from "components/Inputs/Tabs";
import background from "assets/background/background_mk2.jpg";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import { useState } from "react";

const Paragraph = styled.p`
  max-width: 800px;
  text-align: justify;
  margin-top: 4vh;
  margin-bottom: 4vh;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 80px;
`;

const SectionTitle = styled.div`
  margin-bottom: 30px;
`;

const SectionAnim = keyframes`
  from { 
    transform: translateY(200px);
  }
  to { 
  }
`;

const DisplaySection = styled.div`
  width: 100%;
  animation-duration: 0.2s;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0, 0.27, 0, 1);
  animation-name: ${SectionAnim};
`;

enum DisplayModeEnum {
  "byCategory" = "By Category",
  "byGroup" = "By Group",
}

const EShop = () => {
  const [displayMode, setDisplayMode] = useState(DisplayModeEnum.byCategory);

  const renderBundlesSection = () => {
    return (
      <Section key="bundles">
        <SectionTitle>
          <SectionSubHeading title="Bundles" />
        </SectionTitle>
        <ProductList includeBundles={ProductsIncludeBundles.only} />
      </Section>
    );
  };

  return (
    <LayoutPage background={background}>
      <SectionHeading
        title="E-Shop"
        subtitle="3D Printables, 3d models, Visual effects"
      />
      <Paragraph>
        Here you'll find a variety of meticulously crafted 3D models and
        projects featuring fully rigged and textured 3D models, primarily
        created in Blender. Recently, I've expanded the selection to include
        3D-printable models of various props, ideal for straightforward printing
        and assembly. Additionally, explore a range of 2D effects and intricate
        HUD projects, essential for film visual effects and digital productions.
      </Paragraph>

      <Tabs
        tabs={Object.values(DisplayModeEnum)}
        activeTab={displayMode}
        onTabClick={setDisplayMode}
      />

      {displayMode === DisplayModeEnum.byCategory ? (
        <DisplaySection key="byCategory">
          {Object.values(productCategories).map((category) => {
            return (
              <Section key={category.id}>
                <SectionTitle>
                  <SectionSubHeading title={category.name} />
                </SectionTitle>
                <ProductList
                  categoryId={category.id}
                  includeBundles={ProductsIncludeBundles.exclude}
                />
              </Section>
            );
          })}
          {renderBundlesSection()}
        </DisplaySection>
      ) : displayMode === DisplayModeEnum.byGroup ? (
        <DisplaySection key="byGroup">
          {Object.values(productGroups).map((group) => {
            return (
              <Section key={group.id}>
                <SectionTitle>
                  <SectionSubHeading title={group.name} />
                </SectionTitle>
                <ProductList
                  groupId={group.id}
                  includeBundles={ProductsIncludeBundles.exclude}
                />
              </Section>
            );
          })}
          {renderBundlesSection()}
        </DisplaySection>
      ) : null}
    </LayoutPage>
  );
};

export default EShop;
