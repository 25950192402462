export const isGoogleDriveLink = (url: string): boolean => {
  return url.includes("drive.google.com/");
};

export const convertGoogleDriveLinkToDirectDownloadLink = (
  url: string
): string => {
  if (isGoogleDriveLink(url)) {
    // Regular expression to extract file ID from Google Drive share link
    // eslint-disable-next-line no-useless-escape
    const fileIdMatch = url.match(/\/d\/([^\/]+)/);

    if (fileIdMatch && fileIdMatch[1]) {
      const fileId = fileIdMatch[1];

      const directLink = `https://drive.google.com/uc?export=download&id=${fileId}`;
      return directLink;
    }
  }

  return undefined;
};
