import { ReplicaType } from "types";
import Version1 from "./version-1";
import Version2 from "./version-2";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "thrors-map",
  name: `Thrór's Map`,
  origin: "The Hobbit / The Lord of the Rings",

  category: replicaCategories.lotr,

  versions: [Version2, Version1],
};

export default data;
