import Gallery, { GallerySize } from "components/Media/Gallery";
import { ReplicaVersionType, ThumbnailSize } from "types";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWip3dPrint = [];
let galleryWipPaint = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip3dPrint = importAll(
    (require as any).context("./wip-3d-print", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWipPaint = importAll(
    (require as any).context("./wip-paint", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: true,
  page: undefined,
  dateCreated: createDate({ day: 8, month: 3, year: 2024 }),
  datePublished: createDate({ day: 7, month: 8, year: 2024 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
  thumbnailSize: ThumbnailSize.L,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Orcrist, renowned as the Goblin-cleaver and crafted by the skilled hands
        of the Elves of Gondolin during the First Age, holds a significant place
        in the lore of Middle-earth, particularly in Tolkien's "The Hobbit."
        Discovered by Thorin Oakenshield and his companions amidst the troll's
        hoard on their journey to the Lonely Mountain, Orcrist stood alongside
        Glamdring and Sting as one of the three legendary swords found in the
        trolls' cave.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.XL} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        As a proud owner of the United Cutlery replica of Orcrist, I eagerly
        sought out its missing counterpart: the scabbard. However, my search
        proved fruitless, as it seemed the scabbard was no longer in production
        and unavailable for purchase. Disappointment set in, until I made a
        surprising discovery: the official scabbard replica was not crafted from
        metal, as I had assumed, but from plastic. This realization sparked an
        idea — if the official scabbard could be replicated in plastic, perhaps
        I could fashion my own. And so, armed with determination and a newfound
        sense of possibility, I set out to create a scabbard worthy of Orcrist.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaParagraph>
        Unlike many of my previous projects, I decided to take a different
        approach with this one by starting with a pre-purchased 3D model instead
        of creating it from scratch. Given the intricate shape of the scabbard
        and my limited free time, I aimed to avoid spending countless hours
        replicating it from reference images. After searching for a 3D model
        that appeared accurate, I purchased one, only to find it didn't quite
        fit my United Cutlery replica. Following numerous iterations and weeks
        of tinkering and test prints, I eventually crafted a scabbard that not
        only looks authentic but also fits my Orcrist perfectly. Remarkably, the
        sword fits so snugly that it remains securely attached when lifted by
        the handle, yet allows for effortless drawing. Additionally, upon
        drawing, the sword produces a satisfying distinct ringing sound
        reminiscent of cinematic portrayals of swords.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="3D Printing" />

      <ReplicaParagraph>
        The 3D printing process required a meticulous effort to eliminate all
        print and seam marks. Given that the final finish would be glossy metal,
        any imperfections would be glaringly visible. Thus, I painstakingly
        ensured that all surfaces were perfectly smooth. However, this task
        proved challenging, particularly with the intricate leaf ornaments and
        runes, which required careful sanding. In the end, my efforts paid off,
        resulting in a flawless scabbard ready for painting.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWip3dPrint)}
        size={GallerySize.S}
      />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Painting" />

      <ReplicaParagraph>
        Once the scabbard shape was finalized, the most challenging task lay
        ahead: achieving a convincing metal finish. Transforming plastic into a
        material with realistic reflections akin to metal posed a formidable
        challenge. Moreover, I quickly realized the need to ensure that the
        metal finish of the scabbard seamlessly matched that of the sword guard,
        given their proximity when the sword is sheathed. My initial focus was
        on finding the best method to achieve a metal finish, regardless of the
        sword's finish. This endeavor consumed several days and a considerable
        investment in various brands of metal paints, airbrush equipment, and
        any other techniques I could find. After numerous trials, I finally
        achieved a result that met my standards.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWipPaint)} size={GallerySize.S} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        However, the next hurdle proved even more daunting: adjusting the tint
        of the metal finish to harmonize with the sword. Surprisingly, this task
        proved to be more challenging than achieving the metal finish itself, as
        all my attempts resulted in a dulling of the metal sheen. Despite the
        setbacks, I persisted in experimenting with different methods until I
        eventually reached the desired outcome. The process was painstaking and
        consumed significant time and resources, and to this day, I remain
        somewhat mystified as to how I managed to achieve it.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
