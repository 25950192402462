import Background from "assets/background/background_aae_hud_factory_features.jpg";
import LayoutPage from "layouts/LayoutPage";
import SectionHeading from "components/SectionHeading";
import { routes } from "config";
import styled from "styled-components";

const Small = styled.span`
  position: relative;
  font-size: 0.35em;
  font-weight: 600;
  letter-spacing: 0.3em;
  opacity: 0.8;
  bottom: -4px;
`;

const SubHeading = styled.h3`
  margin-top: 7vh;
  margin-bottom: 3vh;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 700px;
`;

const FeatureHeading = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.white}AA;
`;

const Warning = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.red};
  margin: 0 4px;
`;

const AAEHUDFactoryFeatures = () => {
  return (
    <LayoutPage background={Background}>
      <Wrapper>
        <SectionHeading
          title={
            <>
              AAE HUD Factory<Small>v2.0</Small>
            </>
          }
          subtitle={[
            "Endless possibilities, no special skills required",
            "PROJECT FEATURES:",
          ]}
          link={`/${routes.aaeHudFactory.slug}`}
          linkTitle="Go Back"
        />
        <SubHeading>AAE HUD Factory v2.0</SubHeading>

        <FeatureHeading>Compatibility:</FeatureHeading>
        <ul>
          <li>
            Due to some major changes, the previous versions of the HUD packages
            are
            <Warning>not compatible with this version.</Warning> It was
            necessary in order to introduce some new much-needed features.
          </li>
        </ul>

        <FeatureHeading>New Features:</FeatureHeading>
        <ul>
          <li>
            Free tutorials
            <ul>
              <li>all tutorials are now available for free</li>
              <li>
                instead of long explanations of how it works, you can just
                download all the tutorials for free and see everything for
                yourself
              </li>
            </ul>
          </li>

          <li>
            New tutorial format
            <ul>
              <li>all the tutorials are now created as a web-page</li>
              <li>
                tutorials include written steps and short video tutorials (about
                130 of them)
              </li>
              <li>this way you can both read and see what to do</li>
              <li>
                you can search for the exact place inside the tutorial that
                you're interested in (which would be difficult to do with a
                video-only tutorial)
              </li>
            </ul>
          </li>

          <li>
            Workflow hints inside compositions
            <ul>
              <li>
                You can now find little 'tutorials' in the bottom right corner
                of important workflow compositions, telling you how to use them
              </li>
            </ul>
          </li>

          <li>
            Widget Docs
            <ul>
              <li>
                no more boring video tutorials just showing you the obvious
                drivers
              </li>
              <li>
                open the HUD's widget doc (offline web-page), select the widget
                you're interested in and see all the drivers and how they work
                (demonstrated using animated GIFs)
              </li>
            </ul>
          </li>

          <li>
            GUI Panel
            <ul>
              <li>
                no more running scripts manually, simple installation and use
                directly in After Effects' UI
              </li>
              <li>much more functions</li>
              <li>designed to 'just work'</li>
            </ul>
          </li>

          <li>
            Four ways to 3D track your front-view footage:
            <ul>
              <li>
                3D Camera Tracker
                <ul>
                  <li>
                    track your footage automatically using the integrated Camera
                    Tracker
                  </li>
                </ul>
              </li>
              <li>
                2D Tracker, 3 Points
                <ul>
                  <li>
                    track 3 points (eyes and nose) using the integrated 2D
                    Tracker
                  </li>
                  <li>
                    the final 3D track (position, scale, 3D rotation) is
                    automatically calculated from those points
                  </li>
                </ul>
              </li>
              <li>
                2D Tracker, 2 Points
                <ul>
                  <li>track 2 points (eyes) using the integrated 2D Tracker</li>
                  <li>
                    the final 3D track (position, 2D rotation) is automatically
                    calculated from those points
                  </li>
                </ul>
              </li>
              <li>
                2D Tracker, 1 Point
                <ul>
                  <li>track 1 point using the integrated 2D Tracker</li>
                  <li>
                    the final 3D track (position only) is automatically
                    calculated from the point
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            Final 3D track adjustments
            <ul>
              <li>
                you can limit the amount of 3D rotation the HUD takes from the
                3D track (very useful)
              </li>
              <li>
                you can add 'lag' to the final track, this will introduce a
                delay between the movement of the HUD and the actual movement of
                the actors head.
              </li>
            </ul>
          </li>

          <li>
            HUD INTERFACE (Front View) - HUD Drivers
            <ul>
              <li>
                easily adjust the final HUD rotation, position and scale using
                simple drivers
              </li>
            </ul>
          </li>

          <li>
            HUD INTERFACE (Front View) - DOF Drivers
            <ul>
              <li>
                the amount of depth of field blur can now be easily adjusted
                using these drivers
              </li>
            </ul>
          </li>

          <li>
            Eye Reflections
            <ul>
              <li>
                completely new rig, much more drivers, variability and more
                realistic look
              </li>
              <li>
                two different sources of the reflections:
                <ul>
                  <li>
                    3D (Slow, 'True' Reflections)
                    <ul>
                      <li>
                        this renders the HUD interface for each eye separately
                        and creates 'true' eye reflections
                      </li>
                      <li>
                        most notable when using reticle-type widgets (these are
                        close to the eye)
                      </li>
                      <li>
                        it is slower due to the additional HUD rendering (you
                        can pre-render it)
                      </li>
                      <li>the reflections don't include applied HUD effects</li>
                    </ul>
                  </li>
                  <li>
                    2D (Fast, Fake Reflections)
                    <ul>
                      <li>
                        the main HUD interface composition is used to create
                        'fake' reflections = faster
                      </li>
                      <li>
                        useful for HUDs that are colored using the 'HUD
                        Compositing' project
                      </li>
                      <li>
                        includes HUD effects and everything else that you apply
                        to the final shot
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            Dynamic HUD Lighting
            <ul>
              <li>
                completely new rig, much more drivers, variability and more
                realistic look
              </li>
              <li>eye color is preserved</li>
              <li>eyes react to light much more realistically</li>
              <li>complete control over the footage</li>
              <li>
                two different sources of the light:
                <ul>
                  <li>
                    3D (Slow, 'True' Light Interaction)
                    <ul>
                      <li>
                        this renders the HUD interface from the actor's POV and
                        creates 'true' light map
                      </li>
                      <li>
                        it is slower due to the additional HUD rendering (you
                        can pre-render it)
                      </li>
                      <li>it doesn't include applied HUD effects</li>
                    </ul>
                  </li>
                  <li>
                    2D (Fast, Fake Light Interaction)
                    <ul>
                      <li>
                        the main HUD interface composition is used to create
                        'fake' light map = faster
                      </li>
                      <li>
                        useful for HUDs that are colored using the 'HUD
                        Compositing' project
                      </li>
                      <li>
                        includes HUD effects and everything else that you apply
                        to the final shot
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            User-view HUD Interface completely redesigned
            <ul>
              <li>
                user-view HUD is now divided into two compositions:
                <ul>
                  <li>
                    2D
                    <ul>
                      <li>
                        here you can find widgets that are always in front of
                        the camera
                      </li>
                      <li>
                        these usually form the base HUD interface that is always
                        the same
                      </li>
                      <li>you can 2D track reticles and enable them here</li>
                    </ul>
                  </li>
                  <li>
                    3D
                    <ul>
                      <li>this adds 3D widgets to the 2D HUD</li>
                      <li>puts elements into the 3D space of the footage</li>
                      <li>requires 3D camera track</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            2D rigs for reticles
            <ul>
              <li>
                in the previous version, the only way to use a reticle was to 3D
                track your footage and put the reticle into the 3D space - this
                is problematic when the Camera Tracker doesn't work, also not
                good for moving targets
              </li>
              <li>
                using the 2D rig, you can easily use the 2D Tracker to track a
                2D point in your footage and have the reticle follow it.
              </li>
              <li>
                the 3D rig stayed, you can choose between the 2D and 3D space
                based on your video.
              </li>
            </ul>
          </li>

          <li>
            Redesigned HUD compositing, widgets and effects
            <ul>
              <li>
                HUD compositing can now be adjusted (contrast, glow, highlights,
                ...)
              </li>
              <li>new widget/effect drivers, much more control</li>
              <li>
                redesigned to give you more freedom and better looking results
              </li>
            </ul>
          </li>

          <li>
            Widget Overlap
            <ul>
              <li>
                you can now have overlapping widgets (a widget can hide another
                widget if it's behind it)
              </li>
              <li>
                adjustable 'transparency alpha' = how much you can see through
                the widget.
              </li>
            </ul>
          </li>

          <li>
            Widget 'Glow' Driver
            <ul>
              <li>easily light up your widgets</li>
            </ul>
          </li>

          <li>
            MAIN COLOR And SECONDARY COLOR added to the color theme
            <ul>
              <li>
                each widget now uses the 'main color' from the color theme
              </li>
              <li>
                this way, if you've got a blue HUD and a red HUD, you can easily
                combine the two without any issues with colors - in the red HUD,
                blue widgets will be automatically repainted to red
              </li>
            </ul>
          </li>

          <li>
            'CUSTOM HUD EFFECTS' Composition
            <ul>
              <li>
                this composition was added to the 'effects' part of the
                workflow, to give you a chance to apply your own effects to the
                HUD (distortion etc.)
              </li>
            </ul>
          </li>

          <li>
            Pre-Rendering has its place in the workflow
            <ul>
              <li>you can pre-render the HUD interface inside the workflow</li>
              <li>
                you can also pre-render the realistic 3D eye reflections and
                dynamic HUD lighting
              </li>
            </ul>
          </li>

          <li>
            Smoother Motion Blur
            <ul>
              <li>
                more 'samples' used to calculate the blur = smoother results
              </li>
            </ul>
          </li>

          <li>
            Widget as a guide layer
            <ul>
              <li>
                GUI panel has the option to use a selected widget only as a
                guide
              </li>
              <li>
                a guide can be seen inside the composition it's in, nowhere else
              </li>
            </ul>
          </li>

          <li>
            Auto-Renaming Duplicate Elements
            <ul>
              <li>
                when you try to import and connect a HUD compositing project,
                widget or effect, if it's already inside the project, it will be
                automatically renamed.
              </li>
            </ul>
          </li>

          <li>
            Updated 3D Track Script
            <ul>
              <li>
                in the previous version, you had to run the script manually
                while having the camera selected
              </li>
              <li>
                now it's available as a function of the GUI panel, no need to
                select anything at all
              </li>
            </ul>
          </li>

          <li>
            Remove 3D Track (GUI Panel)
            <ul>
              <li>resets the final track to default values</li>
              <li>
                useful when you want to use a different method of tracking the
                actor's face
              </li>
            </ul>
          </li>

          <li>
            Change framerate (GUI Panel)
            <ul>
              <li>easily change the framerate (fps) of the whole project</li>
            </ul>
          </li>

          <li>
            'Rename' Script (GUI Panel)
            <ul>
              <li>HUD compositing files can now be renamed</li>
              <li>HUD effects can now be renamed as well</li>
            </ul>
          </li>

          <li>
            Change Project Dimensions (GUI Panel)
            <ul>
              <li>resize the whole project</li>
              <li>e.g. set compositions from 1920x1080 to 1280x720</li>
              <li>
                <Warning>warning: this feature is experimental</Warning>
              </li>
            </ul>
          </li>

          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLDmPOM8B-C3KG_M23kk9Ei4dVNJOAvz-h"
              target="blank"
            >
              <strong>Watch v2.0 videos on YouTube</strong>
            </a>
          </li>
        </ul>

        {/* <!-- -------------------- -->
            <!-- -------------------- -->
            <!-- AAE HUD FACTORY V1.0 -->
            <!-- -------------------- -->
            <!-- -------------------- --> */}
        <SubHeading>AAE HUD Factory v1.0</SubHeading>

        <FeatureHeading>Features:</FeatureHeading>
        <ul>
          <li>no 3rd party plugins needed</li>
          <li>
            3D tracking using the integrated Camera Tracker (for both front and
            user view scenes)
          </li>
          <li>After Effects 'Classic 3D' renderer</li>
          <li>
            depth of field blur (using the built-in After Effects cameras)
          </li>
          <li>dynamic 3D curvature of widget layers</li>
          <li>realistic eye reflections</li>
          <li>dynamic lighting based on the HUD interface</li>
          <li>
            global HUD settings to update all widgets at once (altitude value,
            power etc.)
          </li>
          <li>
            adjustable color theme to easily change color palette of the whole
            HUD
          </li>
          <li>
            video tutorials for every part of the project and every single
            widget/effect
          </li>
          <li>
            video tutorials on how to create front and user view videos from
            scratch
          </li>
          <li>easily import all the widgets at once, in a matter of seconds</li>
          <li>multiple copies of any widget allowed (renaming needed)</li>
          <li>combine widgets from different HUD packages into one</li>
          <li>
            Custom HUD to turn your own 2D designs into a full-fledged HUD
            interface
          </li>
          <li>final shot available at any time during the process</li>

          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLDmPOM8B-C3LTPN32mxVG6sPVvR7aaIm8"
              target="blank"
            >
              <strong>Watch v1.0 videos on YouTube</strong>
            </a>
          </li>
        </ul>
      </Wrapper>
    </LayoutPage>
  );
};

export default AAEHUDFactoryFeatures;
