import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (4) - Half-Blood Prince - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub4-half-blood-prince',
  name: 'Half-Blood Prince',
  gallery,
  video: 'https://www.youtube.com/watch?v=y_iO-VLM3pc',
  content: (
    <>
      <ReplicaParagraph>
        I was glad to see a comeback of the prop in the Half-Blood Prince. In
        the film, we can actually see just one page - the seventh floor (a
        different design from the one designed for the Order of the Phoenix
        prop). That being said, there are still some pictures to be found on the
        internet, so it is not that hard to find out what the rest of the map
        looked like. There's the additional Quidditch Pitch on the Hogwarts
        Grounds (this was probably designed way back for the Goblet of Fire),
        for example.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Now I have to go back to the official replica. I'm just expressing my
        opinions formed from what I was able to find out, but if I'm not
        mistaken, the designs that are featured in the official replica were
        actually drawn back in the production of the Prisoner of Azkaban. This
        was probably quite late in the process, because they didn't make it into
        the film - however, they're the latest iteration of the design, so that
        is why these were used for the official replica. This also means that
        all the later versions of the prop (except the Order of the Phoenix) use
        these designs as well - there's just the problem that they're never seen
        on screen.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Armed with this information, I made the bold decision and included these
        designs into the later props (starting with this one), because they are
        the correct design that was used for the original props. Please note
        that I have never scanned or used downloaded pictures of the official
        prop to include them in my replicas - everything in my replicas was
        written by my hand.
      </ReplicaParagraph>
    </>
  ),
}

export default data
