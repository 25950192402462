import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import theme from 'styles/theme'

const LightboxWrapper = (props: { children: any; singleImage?: boolean }) => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper
        options={{
          settings: {
            autoplaySpeed: 3000,
            transitionSpeed: 900,
            boxShadow: 'none',
            disableKeyboardControls: false,
            disablePanzoom: props.singleImage ? true : false,
            disableWheelControls: props.singleImage ? true : false,
            hideControlsAfter: 3000,
            lightboxTransitionSpeed: 0.3,
            lightboxTransitionTimingFunction: 'linear',
            overlayColor: `${theme.black}DD`,
            slideAnimationType: 'slide',
            slideSpringValues: [300, 50],
            slideTransitionSpeed: 0.6,
            slideTransitionTimingFunction: 'linear',
            usingPreact: false,
          },

          buttons: {
            backgroundColor: '#0000',
            iconColor: `${theme.white}`,
            showAutoplayButton: props.singleImage ? false : true,
            showNextButton: props.singleImage ? false : true,
            showPrevButton: props.singleImage ? false : true,
            showThumbnailsButton: props.singleImage ? false : true,
            showDownloadButton: false,
          },

          caption: {
            showCaption: props.singleImage ? false : true,
            captionAlignment: 'start',
            captionColor: `${theme.white}`,
            captionContainerPadding: '0',
            captionFontFamily: 'inherit',
            captionFontSize: '0px', // HIDES FILENAME
            captionFontStyle: 'inherit',
            captionFontWeight: 'inherit',
            captionTextTransform: 'inherit',
          },

          thumbnails: {
            showThumbnails: props.singleImage ? false : true,
            thumbnailsAlignment: 'center',
            thumbnailsContainerBackgroundColor: 'transparent',
            thumbnailsContainerPadding: '0',
            thumbnailsGap: '0 1px',
            thumbnailsIconColor: `${theme.white}`,
            thumbnailsOpacity: 0.4,
            thumbnailsPosition: 'bottom',
            thumbnailsSize: ['100px', '80px'],
          },
        }}
      >
        {props.children}
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default LightboxWrapper
