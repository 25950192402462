import { ProductType } from "types";
import background from "./background.jpg";
import fileFormats from "assets/misc/fileFormats";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1yQYEjQVC51GetUKU1Oiodcd8rJHSJ-Wb/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Mark-3-Suit-Cycles",
  name: "Mark 3 Suit (Cycles)",
  description: `The product contains a .blend file with 3d model of the Mark 3 Suit and armor-display platform. The suit has very high level of detail, there is also the whole under layer! It is attached to IK/FK rig and its systems can be easily controled and animated thanks to the sliders next to the 3d view. Almost every piece of the suit is uv unwrapped and included in the main 12,288x12,288 texture. The materials are also rigged, so you can easily activate different damage textures. It is optimized for 'Cycles', all textures are packed inside the .blend file.`,

  price: 59,
  previousPrice: 99,
  blenderVersionRequired: true,
  softwareRequired: ["Blender 2.69"],

  categories: [productCategories.rigged3DModel],
  groups: [productGroups.ironMan],
  license: licenses.editorial,

  images: gallery,
  videos: [
    {
      src: "https://www.youtube.com/watch?v=7cPI3CO3fEY",
      note: 'The suit in this video is using "Blender Render" engine, this product was updated to use Cycles render engine.',
    },
  ],

  contents: [
    { text: "Fully Rigged Mark 3 Suit", format: fileFormats.blend },
    {
      text: "3D Model - Armor-display platform (inside the suit project)",
      format: fileFormats.blend,
    },
    { text: "Project Documentation", format: fileFormats.pdf },
    {
      text: "about",
      isDivider: true,
    },
    { text: "Contains both IK and FK rig" },
    {
      text: "Sliders to easily deploy functions of the suit (flaps, open the helmet etc.)",
    },
    { text: "Textures packed inside the .blend project" },
    { text: "Made for Cycles render engine" },
  ],
  tags: ["Rigged", "Cycles Materials", "Textures"],
};

export default data;
