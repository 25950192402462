import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import LukeLightsaberROTJ from "assets/replicas/star-wars/sw-lukes-lightsaber-rotj";
import ObiwansLightsaberANH from "assets/replicas/star-wars/sw-obiwans-lightsaber-anh";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryDay0 = [];
let galleryDay1 = [];
let galleryDay2 = [];
let galleryDay3 = [];
let galleryDay4 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay0 = importAll(
    (require as any).context("./wip-day0", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay1 = importAll(
    (require as any).context("./wip-day1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay2 = importAll(
    (require as any).context("./wip-day2", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay3 = importAll(
    (require as any).context("./wip-day3", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay4 = importAll(
    (require as any).context("./wip-day4", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 28, month: 7, year: 2018 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryDayImages0 = transformImages(galleryDay0);
  const galleryDayImages1 = transformImages(galleryDay1);
  const galleryDayImages2 = transformImages(galleryDay2);
  const galleryDayImages3 = transformImages(galleryDay3);
  const galleryDayImages4 = transformImages(galleryDay4);

  const galleryDayImages2_sub1 = [...galleryDayImages2].splice(0, 9);
  const galleryDayImages2_sub2 = [...galleryDayImages2].splice(9, 15);
  const galleryDayImages2_sub3 = [...galleryDayImages2].splice(24, 8);
  const galleryDayImages2_sub4 = [...galleryDayImages2].splice(32, 14);
  const galleryDayImages3_sub1 = [...galleryDayImages3].splice(0, 12);
  const galleryDayImages3_sub2 = [...galleryDayImages3].splice(12, 27);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Since I made two lightsabers already (
        <Link
          to={"/" + routes.replicas.slug + "/" + LukeLightsaberROTJ.id}
          target="_blank"
        >
          Luke's lightsaber from Return of the Jedi
        </Link>
        {" and "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ObiwansLightsaberANH.id}
          target="_blank"
        >
          Obi-Wan's lightsaber from New Hope
        </Link>
        ), I've decided to keep the ball rolling and do another one. This is one
        of the 'clan sabers', which is supposed to be a common design that was
        used by several different Jedi at the same time. I like to associate a
        name with a lightsaber, so I picked Ki-Adi-Mundi, who was carrying a
        lightsaber like this in the Attack of the Clones.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.L} />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Preparations" />

      <ReplicaParagraph>
        Just like all of my projects, it all started on a piece of paper. I have
        a notebook where I keep all of my ideas about lightsabers and stuff
        around them. Once I had an idea about how I'd go about making this one,
        I created a 3D model to see if there could be any problems. It is not
        unusual for me to change some things along the way,, but mostly I just
        stay true to my 3D model.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {galleryDayImages0?.length > 0 && (
        <Gallery images={galleryDayImages0} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 1" />
      <ReplicaParagraph>
        First day of actually making something. I've established a workflow,
        where I make the pommel first, and then continue up to the lightsaber
        emitter (the 'head' of the lightsaber). I do this, because the pommel is
        the supporting part in holding the whole lightsaber together. Once the
        pommel is finished, I can simply stack the other pieces on top of it.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Next part is the 'booster', as I like to call it. I kept the terminology
        from when I was making{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ObiwansLightsaberANH.id}
          target="_blank"
        >
          Obi-Wan's lightsaber from New Hope
        </Link>
        . I didn't finish it completely, just the stuff I can do on a lathe. I
        still need to do some drilling, but that's for another day. I then
        skipped one part, because it involves a lot of milling, and I have to
        carefully think it through, because I don't have access to a milling
        machine. I can do a little bit of milling on the lathe, but it's very
        limited so have to be smart about it.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Anyway, I continued and finished the handgrip part. Then I skipped some
        more parts, but this time it was because I didn't have the material I
        needed. I started working on one of the most complicated parts of the
        lightsaber, which is the 'neck' part. I found a knife with a small
        radius, but it's not nearly enough, I'll have to finish this another
        day.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages1?.length > 0 && (
        <Gallery images={galleryDayImages1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 2" />
      <ReplicaParagraph>
        I planned a lot of milling and drilling for today. I started by
        finishing the booster part, which needed some drilling and thread
        cutting. I still don't have the pieces that will go into these holes,
        but I usually leave the little stuff for the last day of making, so it's
        all done for now.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2_sub1?.length > 0 && (
        <Gallery images={galleryDayImages2_sub1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />
      <ReplicaParagraph>
        Now for the part I skipped the other day. I carefully planned all my
        cuts and milling angles for this part. I don't really like the milling
        process on the lathe I'm using. I'm eyeballing most of it, so it's not a
        very precise process - but for most of the work I do, it's precise
        enough. Anyway, it took some time, but I finished it. It's by no means
        perfect, but it'll work until I can get my hands on a milling machine
        and a rotational chuck. I usually paint the pieces as a last step in the
        building process, but this time I wanted to see the final version as
        soon as possible - just in case I had to do the milling again. I
        spray-painted the parts black and then I removed the excessive paint on
        a lathe. I'm fairly happy with the result. The pommel still needed some
        milling/drilling, so I've decided to finish that as well.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2_sub2?.length > 0 && (
        <Gallery images={galleryDayImages2_sub2} size={GallerySize.S} />
      )}
      <ReplicaSpacer />
      <ReplicaParagraph>
        Now was finally time to finish the 'neck' part. I had to cut the radius
        without a knife of that shape. It took forever, but by removing the
        material by hand and using a metal file to get rid of the edges, I
        finally achieved what I was looking for. I could then cut the part from
        the rest of the aluminium rod and finish the other end of the part.
        Creating this part took way longer than I expected, but I'm very happy
        with the result.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2_sub3?.length > 0 && (
        <Gallery images={galleryDayImages2_sub3} size={GallerySize.M} />
      )}
      <ReplicaSpacer />
      <ReplicaParagraph>
        There's a part between the handgrip and the neck part I just finished
        that I still didn't make, so that was the next piece on my list. I still
        had some time after I finished this part, so I started working on the
        last piece (the emitter) as well. I managed to finish all the outer
        shapes of this part. I still need to cut a thread inside of it, so I can
        screw it onto a threaded rod that holds the lightsaber together, but
        that's for another day. It was a busy day, but I could finally see the
        main body of the lightsaber together.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2_sub4?.length > 0 && (
        <Gallery images={galleryDayImages2_sub4} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 3" />
      <ReplicaParagraph>
        I continued where I had to stop the day before. That means I finished
        the inner parts of the emitter and cut the thread that goes through the
        whole piece. Then I could finally screw it onto the threaded rod with
        the rest of the parts and I could finally hold it in my hand as one
        piece. Now I could finally cut the threaded rod to its final length. To
        make it less obvious that it holds the lightsaber together, I cut a
        little shape at the end of the rod. This really made a difference in the
        final assembly.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages3_sub1?.length > 0 && (
        <Gallery images={galleryDayImages3_sub1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />
      <ReplicaParagraph>
        This was the last piece of the body of the lightsaber. Now was the time
        to make the buttons and other stuff that goes on top of the saber. The
        piece I wanted to do next is supposed to be made from brass. I found a
        brass screw, so I didn't have to order a brass rod online and wait for
        it. I couldn't use the screw as it was, because of its dimensions, but I
        still saved a lot of time. I ended up with a nice brass screw for my
        lightsaber. I wanted to make the next part from aluminium, but it proved
        to be too soft for the diameter I needed. That's why I ended up using
        another screw, which I repurposed for my needs. I had some time left, so
        I made the Covertec wheel as well. It was quite tricky to make this, but
        I managed to finish the whole part.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages3_sub2?.length > 0 && (
        <Gallery images={galleryDayImages3_sub2} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 4" />
      <ReplicaParagraph>
        The last day of making this! I finally received the tool I needed to do
        the knurling on the last screw. I first did the knurling, mainly to test
        it, and then I cut the final shape and the thread. I noticed that
        there's a screw in the head of the main screw, so I drilled a hole for
        it as well. I then cut the thread so I could put the little screw in
        there. That was basically it, I could then cut the piece to its final
        length and add it to my lightsaber. The last piece that needed to be
        done was the control box. After that, the lightsaber was finally
        finished.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages4?.length > 0 && (
        <Gallery images={galleryDayImages4} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Assembly" />
      <ReplicaParagraph>
        Well, that's a third lightsaber finished. I'd love to keep going and
        make more and more lightsabers, but, unfortunately, it is consuming way
        too much time. I'm amazed at how fast I was able to make this lightsaber
        in comparison to the previous two. Here's the final assembly if you're
        interested in how I put all the files together:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=HLbXTg_PfHE" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
