import Background from "assets/background/background_aae_hud_factory_section_about.jpg";
import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import SectionHeading from "components/SectionHeading";
import SectionSubHeading from "components/SectionSubHeading";
import YouTubeVideo from "components/Media/YouTubeVideo";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const SpacerTop = styled.div`
  height: 5vh;
`;
const SpacerBottom = styled.div`
  height: 8vh;
`;
const Spacer = styled.div`
  height: 2vh;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1700px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 6%;

  ${(props) => props.theme.breakpoint.M} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${(props) => props.theme.breakpoint.M} {
    width: 100%;

    &:first-child {
      margin-bottom: 8vh;
    }
  }
`;

const Video = styled.div`
  width: 100%;
`;

const About = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={Background} backgroundOpacity={0.7}>
      <Wrapper>
        <SectionHeading
          title="HUDs Made Easy"
          subtitle={[
            "Simple and easy-to-use workflow",
            "Impressive results fast",
          ]}
        />

        <SpacerTop />

        <Content>
          <Column>
            <SectionSubHeading
              title="Front-View Scenes"
              description="These are the 'impossible camera' shots of the character's face. This is where the 3D elements of the HUDs really shine. The workflow for this type of shot requires 3D tracking (don't worry, I've prepared 4 easy ways that you can track your footage without too much work), importing the HUD widgets, animating and the final mix. All of this is simple, you can find out how it's done for FREE - just go to any HUD package and download the FREE TUTORIALS."
            />
            <Spacer />
            <Video>
              <YouTubeVideo src="https://www.youtube.com/watch?v=ZtxhIszAECk" />
            </Video>
          </Column>

          <Column>
            <SectionSubHeading
              title="User-View Scenes"
              description="These are shots from the character's point of view. The workflow is even more simple than it is for the front-view scenes. 3D tracking is even easier, and sometimes not even necessary! The only time you need to 3D track your footage is when you want to put elements into the 3D space of the shot. But again, there are tutorials for that, and they're FREE, so go ahead and download them!"
            />
            <Spacer />
            <Video>
              <YouTubeVideo src="https://www.youtube.com/watch?v=yScK8IcR4_Q" />
            </Video>
          </Column>
        </Content>

        <SpacerBottom />
        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Get Creative"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default About;
