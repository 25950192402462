import Contact from "components/Contact";
import LayoutPage from "layouts/LayoutPage";
import background from "assets/background/background_planet-02_bottom.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;
`;

const ContactPage = () => {
  return (
    <LayoutPage background={background}>
      <Wrapper>
        <Contact />
      </Wrapper>
    </LayoutPage>
  );
};

export default ContactPage;
