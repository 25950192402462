import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 9, month: 12, year: 2020 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Originally inspired by watching the National Treasure with Nicolas Cage,
        which features this important document, I finally tried to replicate a
        real-life historical document, instead of a movie prop. The United
        States Declaration of Independence is the perfect link between the two
        worlds (real-life documents and movie props), since it is a real-life
        document that is also a movie prop. However, movies played no role in
        the process of making this - I tried to replicate the actual document,
        not a prop.
      </ReplicaParagraph>
      <ReplicaParagraph>
        Like always, extensive research was needed. Now here's the key
        difference from movie props - while searching for reference images and
        other information, there's actually a lot of documentation available,
        which made my life a lot easier. After all, there are not many documents
        that are this important, so it is only natural that the document is
        carefully archived. Movie props do not get this kind of treatment
        (obviously), because they are usually a studio property and are
        historically irrelevant.
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Authentic Reproduction`,
            description: (
              <>
                <p>
                  The replica is based on the archival photos of the real
                  Declaration of Independence. Nowadays, only replicas are
                  displayed in museums. This is to prevent further damaging of
                  the original document. The text has almost completely faded in
                  some places, which is something I certainly wanted to include
                  in my replica.
                </p>
                <p>
                  While there is abundance of reference images for the front
                  side, the back side of the document is not so heavily
                  documented. I managed to find a low-res reference image and
                  carefully replicate it to fit my needs. This included writing
                  the text (I was surprised to see that there is a text on the
                  back side) by hand and then carefully matching it to be
                  unrecognizable from the original - I dare say this was
                  successful.
                </p>
              </>
            ),
          },
          {
            title: `High-quality print`,
            description: `The declaration was printed on a high-end professional printer, using a high-quality water-resistant ink. The result looks quite natural, there's no pixelation or any other flaws that are often associated with printing. The size of the replica is true to the original document.`,
          },
          {
            title: `Authentic aging process`,
            description: `The replica has been properly aged to resemble the state of the original Declaration of Independence. Every crease and wrinkle has been carefully added by hand.`,
          },
        ]}
      />

      <ReplicaParagraph>
        Finally, I rolled the document and secured it with a piece of natural
        string. This is mostly because of storage purposes - I'm not going to
        frame the replica, and I'm certainly not going to fold it, so keeping it
        in a roll is (for me) the best way to store it. Also, I created a
        fictional archive label to make it look more museum-like.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
