import { paypal } from 'config'

import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import PayPalButtonBase from './ButtonBase'

const PayPalButton = (props: any) => {
  const paypalOptions = {
    'client-id': paypal.sandbox
      ? paypal.clientId.sandbox
      : paypal.clientId.production,
    currency: 'USD',
    intent: 'capture',
  }

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <PayPalButtonBase {...props} />
    </PayPalScriptProvider>
  )
}

export default PayPalButton
