import Background from "assets/background/background_mk2.jpg";
import Carousel from "components/Carousel";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import ProductItem from "components/ProductItem";
import SectionHeading from "components/SectionHeading";
import products from "assets/products";
import { routes } from "config";
import styled from "styled-components";

/* import ButtonScrollToSection from 'components/Interaction/ButtonScrollToSection' */

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  height: 6vh;
  ${(props) => props.theme.breakpoint.M} {
    height: 6vh;
  }
`;

const Shop = (props: { onNavClick: () => void }) => {
  const carouselItems = products.map((product) => (
    <ProductItem product={product} />
  ));

  return (
    <LayoutFullscreen background={Background} fadePercentage={70}>
      <Wrapper>
        <SectionHeading
          title="E-Shop"
          subtitle="3D Printables, 3d models, Visual effects"
          link={`/${routes.eshop.slug}`}
        />

        <Spacer />

        <Carousel items={carouselItems} autoplay offset={2} />

        {/* <Spacer />
        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Explore More"
        /> */}
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Shop;
