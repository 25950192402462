import styled from 'styled-components'

const Wrapper = styled.div`
  height: 2vh;
`

const ReplicaSpacer = (props: { children?: any; source?: string }) => {
  return <Wrapper></Wrapper>
}

export default ReplicaSpacer
