/* eslint-disable react-hooks/exhaustive-deps */

import { ArrowLeft, ArrowRight } from "@styled-icons/bootstrap";
import styled, { css } from "styled-components";
import { useEffect, useRef, useState } from "react";

import { useOnScreen } from "hooks";
import { useSwipeable } from "react-swipeable";

const Wrapper = styled.div`
  width: 95%;
  max-width: 1200px;
`;

const ArrayWrapper = styled.div<{ padding?: string }>`
  --fadePercentage: 30%;
  width: 100%;
  padding: ${(props) => props.padding || "none"};
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );
  mask-image: linear-gradient(
    90deg,
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );

  ${(props) => props.theme.breakpoint.L} {
    --fadePercentage: 20%;
  }
  ${(props) => props.theme.breakpoint.M} {
    --fadePercentage: 15%;
  }
`;

const ArrayStatic = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  gap: 12px;
  place-items: center;

  ${(props) => props.theme.breakpoint.L} {
    grid-template-columns: repeat(auto-fit, 200px);
  }
`;

const Array = styled.div<{ index: number; itemWidth: number }>`
  display: flex;
  align-items: center;
  transition: all 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);

  ${({ index, itemWidth }) =>
    (index || index === 0) &&
    itemWidth &&
    css`
      margin-left: calc(50% - ${itemWidth / 2}px);
      transform: translateX(${-index * itemWidth}px);
    `};
`;

const ArrayRepeater = styled.div<{
  repeatIndex: number;
  itemWidth: number;
  itemCount: number;
}>`
  ${({ repeatIndex, itemWidth, itemCount }) =>
    (repeatIndex || repeatIndex === 0) &&
    itemWidth &&
    itemCount &&
    css`
      transform: translateX(${repeatIndex * (itemWidth * itemCount)}px);
    `};
`;

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2vh;
  button {
    margin: 10px;
  }
`;

const Item = styled.div`
  flex: 1;
  padding: 0 10px;
`;

const Carousel = (props: {
  items: any[];
  offset?: number;
  autoplay?: boolean;
  autoplayDirection?: "left" | "right";
  autoplayWaitTime?: number;
  navigationOnTop?: boolean;
  padding?: string; // this is to be used for replicas with large thumbnails, for example (they're absolutely positioned and overflow:hidden clips them without padding)
}) => {
  const [autoplayOnScrollTriggered, setAutoplayOnScrollTriggered] =
    useState<boolean>(false);
  const refOnScreen = useRef();
  const onScreen = useOnScreen(refOnScreen);

  const displayItems = [...props.items, ...props.items, ...props.items];
  const itemCount = props.items.length;

  const [index, setIndex] = useState<number>(itemCount + (props.offset || 0));
  const [repeatIndex, setRepeatIndex] = useState<number>(itemCount);

  const itemRef = useRef();
  const [itemWidth, setItemWidth] = useState<number>(0);

  const [autoplay, setAutoPlay] = useState<boolean>(props.autoplay || false);
  const autoplayDirection: "left" | "right" =
    props.autoplayDirection || "right";
  const autoplayWaitTime: number = props.autoplayWaitTime || 4000;

  //
  // STATE UPDATES
  //
  useEffect(() => {
    if (itemRef && itemRef.current) {
      setItemWidth((itemRef as any).current.clientWidth);
    }
  }, [itemRef]);

  useEffect(() => {
    const newRepeatIndex = Math.floor(index / itemCount) - 1;
    setRepeatIndex(newRepeatIndex);
  }, [index, itemCount]);

  //
  // NAVIGATION
  //
  const navigateLeft = () => {
    setIndex(index - 1);
    setAutoPlay(false);
  };
  const navigateRight = () => {
    setIndex(index + 1);
    setAutoPlay(false);
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: navigateRight,
    onSwipedRight: navigateLeft,
  });

  //
  // AUTOPLAY
  //
  const indexRef = useRef(index);
  indexRef.current = index;
  const autoplayRef = useRef(autoplay);
  autoplayRef.current = autoplay;

  const doAutoPlayRecursive = () => {
    if (autoplayRef.current) {
      const newIndex =
        autoplayDirection === "right"
          ? indexRef.current + 1
          : indexRef.current - 1;

      setIndex(newIndex);
      return setTimeout(() => doAutoPlayRecursive(), autoplayWaitTime);
    }
  };

  useEffect(() => {
    if (onScreen && !autoplayOnScrollTriggered) {
      const timer = setTimeout(() => doAutoPlayRecursive(), autoplayWaitTime);
      setAutoplayOnScrollTriggered(true);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [onScreen]);

  //
  // RENDER
  //
  const renderNavigation = () => {
    return (
      <Navigation>
        <button className="btn-icon-only" onClick={navigateLeft}>
          <ArrowLeft />
        </button>
        <button className="btn-icon-only" onClick={navigateRight}>
          <ArrowRight />
        </button>
      </Navigation>
    );
  };

  const renderItemCount2 = () => {
    return (
      <ArrayStatic>
        {props.items.map((component, i) => (
          <Item key={i}>{component}</Item>
        ))}
      </ArrayStatic>
    );
  };

  const renderItemCountRest = () => {
    return (
      <>
        {props.navigationOnTop && renderNavigation()}
        <ArrayWrapper {...swipeHandlers} padding={props.padding}>
          <ArrayRepeater
            repeatIndex={repeatIndex}
            itemWidth={itemWidth}
            itemCount={itemCount}
          >
            <Array index={index} itemWidth={itemWidth}>
              {displayItems.map((component, i) => (
                <Item ref={i === 0 ? itemRef : null} key={i}>
                  {component}
                </Item>
              ))}
            </Array>
          </ArrayRepeater>
        </ArrayWrapper>
        {!props.navigationOnTop && renderNavigation()}
      </>
    );
  };

  return (
    <Wrapper ref={refOnScreen}>
      {itemCount <= 3 ? renderItemCount2() : renderItemCountRest()}
    </Wrapper>
  );
};

export default Carousel;
