import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import LukeLightsaberROTJ from "assets/replicas/star-wars/sw-lukes-lightsaber-rotj";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_3d_model = [];
let galleryWIP_clamp_card = [];
let galleryWIP_clamp_lever = [];
let galleryWIP_emitter = [];
let galleryWIP_greeblies = [];
let galleryWIP_handle = [];
let galleryWIP_neck_grenade = [];
let galleryWIP_paint = [];
let galleryWIP_pommel = [];
let galleryWIP_pre_paint = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_3d_model = importAll(
    (require as any).context("./gallery-wip-3d-model", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_clamp_card = importAll(
    (require as any).context(
      "./gallery-wip-clamp-card",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_clamp_lever = importAll(
    (require as any).context(
      "./gallery-wip-clamp-lever",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_handle = importAll(
    (require as any).context("./gallery-wip-handle", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_neck_grenade = importAll(
    (require as any).context(
      "./gallery-wip-neck-grenade",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_paint = importAll(
    (require as any).context("./gallery-wip-paint", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pre_paint = importAll(
    (require as any).context(
      "./gallery-wip-pre-paint-assembly",
      false,
      /\.(png|jpe?g)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 9, month: 1, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This lightsaber is a replica of quite an interesting prop. It can be
        seen mostly in the Return of the Jedi (Ep6) being used by Luke
        Skywalker. However, it is important to note that it is not the
        official/canonical design of Luke's new green lightsaber, but merely a
        stunt saber (hence why it is usually being referred to as "Luke v2"
        lightsaber). I said that it can be seen "mostly" in Ep6, because this
        prop was, in fact, first used all the way back in Ep4 by Alec Guinness
        (Obi-Wan Kenobi) - also just as a stunt saber.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=ywFxOT1CfPA"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Although it is not the canonical design of Luke's green lightsaber, it
        is interesting to note that this hilt actually has much more screen time
        than the canonical one. For example, Luke can be seen using this hilt on
        the desert skiff when escaping Jabba the Hutt, when he's fighting Vader
        at the end of the film, and it is also the hilt that is given to Vader
        upon Luke's surrender on Endor. In fact, there seems to be just a single
        shot throughout the whole film where Luke's not using this hilt, but the
        one that is canon - it is the close-up shot of Vader inspecting the
        lightsaber. The canonical hilt was actually the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + LukeLightsaberROTJ.id}
          target="_blank"
        >
          first lightsaber I ever replicated
        </Link>
        .
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Assembly Design" />
      <ReplicaParagraph>
        Since the lightsaber is a complex assembly of custom machined parts, all
        of my work started on a computer. While designing the parts, it was
        important to me to keep the outer look of the final assembly as close to
        the original prop as possible, while also making sure that I'm not
        creating parts that would be unnecessarily complicated to machine out of
        metal. I carefully split the lightsaber into manageable parts, while
        keeping the overall look of the prop the same. I also did this because I
        love props that can be dismantled into individual components, which
        makes them, in my opinion, feel much more realistic.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_3d_model)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Neck and 'Grenade' Parts" />
      <ReplicaParagraph>
        I started with the easiest parts first, just to get the hang of it once
        more (it has been years since the last lightsaber I made). While these
        parts have complicated shapes, I consider them "easy" from the
        point-of-view that these are not critical functional components (like
        the emitter, for example, which holds the lightsaber assembly together),
        and they are purely rotational pieces - meaning that they do not require
        milling, drilling, etc.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck_grenade)} />

      <ReplicaSubTitleAnchor title="Emitter" />
      <ReplicaParagraph>
        Next on the list was the emitter, which is a bit more complicated. On
        top of the lathe work, there are also two threaded holes for set screws,
        and the whole emitter also has to serve as a nut to a threaded rod that
        is the core of the lightsaber.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_emitter)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        The handle is a very simple-looking part, but I had to re-engineer my
        original design and split it into two pieces, one of which also required
        some milling work. I designed it this way, so I'd be able to put the
        Graflex clamp over the piece without having to damage the clamp by
        cutting the two little pieces of metal that are bent inwards. On top of
        the milling work, there is also a (threaded) hole drilled at a precise
        angle to ensure the correct positioning of the screw that goes into it
        against the clamp.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_handle)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        There are two small parts/screws on the lightsaber that have quite a
        unique design. First is a knurled screw with a conical top. To save some
        time, I initially planned to take a machine screw of the correct size
        and modify it on a lathe. However, I wasn't able to find one that would
        have such a tall head that would allow for the conical top, so I ended
        up machining the whole part from scratch. The other part is known as the
        "mystery chunk", which I also ended up machining from scratch. This one
        was particularly tricky due to the grooves on top of the head.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_greeblies)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        The pommel was the part I was most afraid of. There are several
        difficult/risky operations that require precision, and any failure would
        mean starting again from scratch. I'm happy to report that I managed to
        not screw it up, so that was a relief. The operations needed to produce
        this piece included milling angled grooves at a precise angle between
        each two. Drilling a hole for a d-ring that had to be precisely
        positioned on a small angled surface, drilling a threaded hole at a
        precise angle to the grooves, etc.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_pommel)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Graflex Clamp Lever" />
      <ReplicaParagraph>
        Making the custom Graflex clamp lever and the whole mechanism was
        perhaps the most challenging part of the build. I wanted to see how far
        I could push the limits of what I'm able to create from scratch. Since I
        already had a Graflex clamp that I was going to use for this project, I
        originally intended to make only the lever, which is different to the
        standard lever that comes with Graflex clamps. The clamp on this
        specific prop is also missing one of the metal bars on the side. In
        order to replace the lever and remove the metal bar, I'd have to damage
        the clamp, which I wanted to avoid. This meant that I ended up making
        the whole lever mechanism from scratch, so I could replace the original
        parts without damaging them. These are probably the smallest functional
        parts I ever machined. The original lever is secured by what seems to be
        a rivet, but since I like mechanical joints that can be disassembled, I
        opted for using a tiny screw and a nut, both of which I modified to look
        more like the original parts (sadly, I do not have photos of this part
        of the process).
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_clamp_lever)} />

      <ReplicaSubTitleAnchor title="Clamp Card" />
      <ReplicaParagraph>
        I do not have much experience with chemical processes (because I'm not a
        fan of processes that I cannot control), so this was a whole new skill
        that I had to learn. It took a lot of tries to get the results I wanted,
        but I managed to make my own clamp card from scratch. I recognize that
        it's perhaps not perfect, but at this point it's close-enough for me. I
        may give it another try in the future.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_clamp_card)} />

      <ReplicaSubTitleAnchor title="Pre-Paint Assembly" />
      <ReplicaParagraph>
        After all the parts were finished, it was time for the final assembly. I
        have, of course, disassembled and reassembled the lightsaber countless
        times during the making process, but it is always satisfying seeing it
        all come together into a finished piece. Everything looked good, so the
        lightsaber was ready for painting.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pre_paint)} />

      <ReplicaSubTitleAnchor title="Painting and Weathering" />
      <ReplicaParagraph>
        The original prop bears marks of heavy usage, which can be seen on the
        fading black paint with chipped pieces revealing the shiny metal
        beneath. I have seen people use stencils to achieve screen-accurate
        paint job, but I have decided to go another route. I used a paint that
        created a thicker layer that could then be chipped of. It also gave the
        surfaces a nice texture, which was a surprise to be sure, but a welcome
        one. So, after covering the saber in a layer of paint, I then went and
        chipped away little bits and pieces one by one, until it resembled the
        original. Although it is not in the photos below, I also adjusted the
        color of the neck a bit, because the original seems to be trying to
        imitate copper/brass. I did some more weathering after the painting,
        until I was satisfied with the result.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_paint)} />

      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
