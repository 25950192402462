import ContentBox from "components/ContentBox";
import HowToUseRig from "assets/misc/faq/how-to-use-rig-01.jpg";
import Images from "components/Media/Images";
import LayoutPage from "layouts/LayoutPage";
import ProxyLayer from "assets/misc/faq/proxy-layer.jpg";
import RigNotWorking from "assets/misc/faq/rig-not-working-01.png";
import ScriptsDisabled1 from "assets/misc/faq/scripts-disabled-01.png";
import ScriptsDisabled2 from "assets/misc/faq/scripts-disabled-02.jpg";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule_3.jpg";
import { purchaseProcess } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 8vh;
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 20px;
  }
`;

const QuestionHeading = styled.div`
  display: flex;
  align-items: center;
  strong {
    min-width: 35px;
    font-size: 1.2em;
    &:before {
      content: "#";
      margin-right: 2px;
    }
  }
`;

const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-align: justify;
  }
`;

const Spacer = styled.div`
  height: 8vh;
`;

const FrequentlyAskedQuestions = () => {
  let i = 0;

  return (
    <LayoutPage background={background}>
      <Wrapper>
        <SectionHeading
          title="Frequently Asked Questions"
          subtitle={["Solutions to common problems"]}
        />

        <Spacer />

        <Questions>
          <ContentBox
            closed
            title={
              <QuestionHeading>
                <strong>{(i = i + 1)}</strong>How to buy a product? When will I
                receive it? What if I didn't receive it?
              </QuestionHeading>
            }
          >
            {purchaseProcess}
          </ContentBox>

          <ContentBox
            closed
            title={
              <QuestionHeading>
                <strong>{(i = i + 1)}</strong>Drivers are not working (scripts
                auto-run disabled), what do I do?
              </QuestionHeading>
            }
          >
            <QuestionContent>
              <p>
                If you're experiencing trouble with the rig or sliders/drivers
                that are rigged to some function of the model (and you're using
                the required version of Blender), the problem is most probably
                with scripts - they're not running. Blender will display a
                warning like this on the top of the GUI:
              </p>

              <Images images={[ScriptsDisabled1]} />

              <p>
                To solve the problem for the moment, you can simply click on the{" "}
                <span className="highlight">Reload Trusted</span> button, and
                everything should start working. If you don't want to do this in
                the future, go to{" "}
                <span className="highlight">File {">"} User Preferences</span>{" "}
                and check the{" "}
                <span className="highlight">Auto Run Python Scripts</span>{" "}
                checkbox:
              </p>

              <Images images={[ScriptsDisabled2]} />

              <p>
                Then click on the 'Save User Settings' button, close the window
                and close Blender. Now try and open it again, it should work.
              </p>
              <p>
                Old versions of Blender can occasionally crash when manually
                clicking the 'Reload Trusted' button (I have not found out why).
                This issue can also be fixed by updating the user settings to
                auto-run scripts as described above, and then reopening the
                project.
              </p>
            </QuestionContent>
          </ContentBox>

          <ContentBox
            closed
            title={
              <QuestionHeading>
                <strong>{(i = i + 1)}</strong>How to use the rig? How to switch
                between the IK/FK rig?
              </QuestionHeading>
            }
          >
            <QuestionContent>
              <p>
                If you're having trouble figuring out how to use the suit rig,
                follow this short step-by-step tutorial. Some of the products
                may already have the rig in the pose mode - if it is so, you can
                skip the first three steps.
              </p>

              <Images images={[HowToUseRig]} />
            </QuestionContent>
          </ContentBox>

          <ContentBox
            closed
            title={
              <QuestionHeading>
                <strong>{(i = i + 1)}</strong>Why are parts of the model
                misplaced?
              </QuestionHeading>
            }
          >
            <QuestionContent>
              <p>
                If you're experiencing unusual positioning of parts of the
                model, you are most likely using an unsupported version of
                Blender to open the project. Unfortunately, Blender's backwards
                compatibility is imperfect, which can sometimes lead to slight
                bugs in complex rigging.
              </p>
              <p>
                To fix this, go to the product page where you will find the
                required version of Blender needed to open the project
                correctly. If you see something similar to the picture below,
                you are most probably using a different version of Blender.
              </p>

              <Images images={[RigNotWorking]} />
            </QuestionContent>
          </ContentBox>

          <ContentBox
            closed
            title={
              <QuestionHeading>
                <strong>{(i = i + 1)}</strong>Why is the model in low
                resolution?
              </QuestionHeading>
            }
          >
            <QuestionContent>
              <p>
                If you see what looks like a low-poly version of the 3D model,
                you are probably viewing the proxy model (a low-poly model for
                faster work). Most of my projects are divided into multiple
                layers, which are described in the product's documentation. The
                actual model is in a different layer than the proxy one. For
                example, here is the layout of the Mark 2 Suit:
              </p>

              <Images images={[ProxyLayer]} />
            </QuestionContent>
          </ContentBox>
        </Questions>
      </Wrapper>
    </LayoutPage>
  );
};

export default FrequentlyAskedQuestions;
