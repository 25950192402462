import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "dead-sea-scrolls-all-souls-deuteronomy",
  name: `All Souls Deuteronomy`,
  origin: "Historical Document replica, Dead Sea Scrolls",

  category: replicaCategories.history,

  versions: [Version1],
};

export default data;
