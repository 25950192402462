import { ReplicaCategoriesType } from "types";

const categories: ReplicaCategoriesType = {
  harryPotter: {
    id: "harry-potter",
    name: "Harry Potter",
    index: 0,
  },
  starWars: {
    id: "star-wars",
    name: `Star Wars`,
    subtitle: `Back when it belonged to George Lucas`,
    index: 1,
  },
  lotr: {
    id: "lord-of-the-rings",
    name: "The Lord Of The Rings",
    subtitle: `Also includes The Hobbit`,
    index: 2,
  },
  history: {
    id: "history",
    name: "History",
    index: 3,
  },
};

const replicaCategories: typeof categories = { ...categories };
export default replicaCategories;
