import { Adobeaftereffects } from "@styled-icons/simple-icons/";
import ContentBox from "components/ContentBox";
import LightboxWrapper from "components/Media/LightboxWrapper";
import { ProductAAEHUDFactoryType } from "types";
import { clickableImageCss } from "styles/GlobalStyle/Images";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: -20;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 35px;
  &:last-child {
    margin-bottom: 20px;
  }
`;

const Columns = styled.div`
  width: 90%;
  display: flex;

  & > * {
    width: 50%;
  }

  li {
    margin-bottom: 0;
    margin-top: 2px;
    color: ${(props) => props.theme.white}66;
    font-size: 12px;
    font-weight: 400;
  }
`;

const Title = styled.h4`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 14px;

  svg {
    width: 16px;
    height: 16px;
  }

  &:after {
    position: absolute;
    bottom: -16px;
    content: "";
    width: 10%;
    height: 1px;
    background: ${(props) => props.theme.white}66;
  }
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.white}99;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Description = styled.div`
  color: ${(props) => props.theme.white}66;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: justify;
`;

const Widgets = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 4px;

  img {
    ${clickableImageCss}
    max-width: 100%;
    min-width: 50px;
    width: 10%;
    flex-grow: 1;
    box-sizing: border-box;
    justify-content: space-between;
    text-align: center;
    object-fit: cover;
  }
`;

const DetailAAEHUDFactory = (props: { product: ProductAAEHUDFactoryType }) => {
  let product = props.product;
  const isAdditionalProduct = product.isAdditionalProduct;

  return (
    <ContentBox title="What's Inside?">
      <Wrapper>
        <Section>
          <Title>
            <Adobeaftereffects />
            {product.widgets.length} {product.name}
            {isAdditionalProduct ? "" : " Widgets"}
          </Title>

          <LightboxWrapper>
            <Widgets>
              {product.widgets?.length > 0 &&
                product.widgets.map(({ src, name }, i) => (
                  <img key={i} src={src} alt={name || ""} loading="lazy" />
                ))}
            </Widgets>
          </LightboxWrapper>

          <Description>
            A DetailBundle of {product.widgets.length} HUD widgets that can be
            easily imported, adjusted and animated inside the 'HUD Interface'
            project. Using the provided GUI panel, you can easily rename any
            widget, change curvature and much more!
          </Description>

          <Columns>
            <div>
              <Subtitle>Features:</Subtitle>
              <ul>
                {[
                  "Easy import",
                  "Everything controlled via simple drivers",
                  "Dynamic 3D Curvature",
                  "Easy Color Management",
                  "Combine and repaint widgets from different HUDs",
                ]
                  .filter((text) => text.length)
                  .map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
              </ul>
            </div>
            <div>
              <Subtitle>Tutorials:</Subtitle>
              <ul>
                {[
                  "Widget Docs",
                  "(animated GIFs explaining drivers)",
                  `${
                    product.isCustomHUD
                      ? "Learn how you can design your own HUD"
                      : ""
                  }`,
                ]
                  .filter((text) => text.length)
                  .map((text, i) => (
                    <li key={i}>{text}</li>
                  ))}
              </ul>
            </div>
          </Columns>
        </Section>

        {!isAdditionalProduct && (
          <Section>
            <Title>
              <Adobeaftereffects />
              HUD Interface
            </Title>

            <Description>
              This is the main project. It is included inside every HUD package
              and it contains everything you need for creating both front and
              user-view HUD scenes. You can imagine it as the "engine" on which
              all the HUDs run.
            </Description>

            <Columns>
              <div>
                <Subtitle>Features:</Subtitle>
                <ul>
                  {[
                    "3D Tracking (4 simple ways to track your front-view footage)",
                    "Complex Rigging (HUD rig, eye movement, DOF, ...)",
                    "Eye Reflections",
                    "Dynamic HUD Lighting",
                    "Global HUD Settings",
                    "Color Theme",
                    "Final Compositing",
                    "Front/User View Video Workflow",
                    "And much more!",
                  ]
                    .filter((text) => text.length)
                    .map((text, i) => (
                      <li key={i}>{text}</li>
                    ))}
                </ul>
              </div>
              <div>
                <Subtitle>Tutorials:</Subtitle>
                <ul>
                  {[
                    "Installation",
                    "Introduction",
                    "Drivers",
                    "GUI Panel",
                    "HUD Interface Project",
                    "HUD Settings",
                    "HUD Elements",
                    "Video From Scratch (Front View)",
                    "Video From Scratch (User View)",
                  ]
                    .filter((text) => text.length)
                    .map((text, i) => (
                      <li key={i}>{text}</li>
                    ))}
                </ul>
              </div>
            </Columns>
          </Section>
        )}
      </Wrapper>
    </ContentBox>
  );
};

export default DetailAAEHUDFactory;
