import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import ThorinsLetterToBilbo from "assets/replicas/lord-of-the-rings/lotr-thorins-letter-to-bilbo";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 5, month: 6, year: 2020 }),
  version: "2",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is probably my most favorite paper prop from the Hobbit.
        Ridiculously long, this is the contract between Bilbo Baggins and Thorin
        and Company, which he signs at the beginning of The Hobbit: An
        Unexpected Journey. In the book, no such contract exists, instead,
        everything is summarized in a letter that the Company leaves for Bilbo
        to find in the morning (I actually created my version of the letter -
        <Link
          to={"/" + routes.replicas.slug + "/" + ThorinsLetterToBilbo.id}
          target="_blank"
        >
          Thorin's Letter to Bilbo
        </Link>
        ). However, one must simply love all the absurd and repetitive clauses
        from this overly long rendition of the contract, so I'm certainly not
        complaining.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=B7KQu35JIak" />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I have painstakingly recreated every little detail of the original prop.
        This is my second take on the replica, but I started again from scratch,
        like I almost always do with new versions of my replicas. Taking it a
        step further than the previous version, these are the main features of
        the prop:
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Written by hand`,
            description: `The whole document was written by my hand. To get as close to the original as possible, this approach required many re-writings. However, judging by the results, I think these were definitely worth the time.`,
          },
          {
            title: `The exact same paper as the original prop`,
            description: `I found the exact type of paper that was used for the original prop, and had it imported to my country - for a very large fee. Apparently, this paper is simply not available in here... what a shame. Anyways, the paper itself is also quite expensive, but the quality and texture of the paper was definitely worth every cent.`,
          },
          {
            title: `High-quality print`,
            description: `The contract was printed on a high-end professional printer, using a high-quality water-resistant ink. The result looks quite natural, there's no pixelation or any other flaws that are often associated with printing.`,
          },
          {
            title: `Authentic aging process`,
            description: `The document was manually aged, making each copy unique and authentic. Thanks to this, the contract feels and looks old, even at a very close look. While working on this replica, I even managed to improve my current paper-aging process and learn a few new tricks along the way.`,
          },
          {
            title: `Real wax seals`,
            description: `The contract consists of 5 parts, all of which are sewn together using a natural string. Each such joint is then sealed using a real sealing wax.`,
          },
        ]}
      />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
