import {
  Deviantart,
  Facebook,
  Instagram,
  Youtube,
} from "@styled-icons/boxicons-logos/";

import SectionHeading from "components/SectionHeading";
import { common } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    flex: 1;
    min-width: 300px;
  }
`;

const Column = styled.div`
  max-width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Email = styled.h4`
  text-transform: lowercase;
`;

const Paragraph = styled.p`
  text-align: justify;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  svg {
    width: 20px;
    padding: 10px;
    color: ${(props) => props.theme.white}AA;

    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
`;

const Spacer = styled.div`
  height: 6vh;
`;

const SpacerContent = styled.div`
  height: 2vh;
`;

const SpacerBottom = styled.div`
  height: 10vh;
`;

const Contact = () => {
  const social = [
    { icon: <Facebook />, link: common.contact.facebook },
    { icon: <Youtube />, link: common.contact.youtube },
    { icon: <Instagram />, link: common.contact.instagram },
    { icon: <Deviantart />, link: common.contact.deviantArt },
  ];

  return (
    <Wrapper>
      <Column>
        <SectionHeading title="Contact" subtitle={["Need something?"]} />
        <Spacer />
        <Email>{common.contact.email}</Email>
        <SpacerContent />
        <Paragraph>
          I prefer email communication because it’s easier to manage with my
          busy schedule. I almost never respond to social media or YouTube
          comments/messages, so if you’re looking for a response, email is the
          best way to reach me!
        </Paragraph>
        <SpacerBottom />
        <SocialWrapper>
          {social.map((social, i) => (
            <a key={i} href={social.link} target="_blank" rel="noreferrer">
              {social.icon}
            </a>
          ))}
        </SocialWrapper>
      </Column>
    </Wrapper>
  );
};

export default Contact;
