import { ReplicaType } from "types";
import replicaCategories from "assets/replicas/_categories";
import replicas_HarryPotter from "./harry-potter";
import replicas_LordOfTheRings from "./lord-of-the-rings";
import replicas_Misc from "./misc";
import replicas_StarWars from "./star-wars";

// WIP REPLICAS WILL BE FILTERED OUT OF THE LIST OF REPLICAS
// AND WILL BE ACCESSIBLE THROUGH /replicas-wip URL
const replicasAll = [
  ...replicas_HarryPotter,
  ...replicas_StarWars,
  ...replicas_LordOfTheRings,
  ...replicas_Misc,
];

//
// FILTER OUT WIP VERSIONS (those shouldn't be public - careful, versions are also listed in replica detail page etc.)
//
const replicas = [];
const replicasWIP = [];

replicasAll.forEach((replica) => {
  const replica_public: ReplicaType = { ...replica, versions: [] };

  replica.versions?.forEach((version) => {
    if (version.isWIP) {
      // push each WIP replica version as an individual replica for easier previewing
      replicasWIP.push({
        ...replica,
        id: `${replica.id}_${version.version}`,
        versions: [version],
      } as ReplicaType);
    } else {
      replica_public.versions.push(version);
    }
  });

  if (replica_public.versions.length > 0) {
    replicas.push(replica_public);
  }
});

export default replicas;
export { replicaCategories, replicasWIP };
