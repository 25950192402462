import { encodePassword, isValidPassword, savePassword } from "utils";

import styled from "styled-components";
import { useState } from "react";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Input = styled.input`
  align-self: stretch;
  outline: none;
  border: none;

  background: ${(props) => props.theme.white}99;
  color: ${(props) => props.theme.black};
  border-radius: 2px;
  padding: 15px;

  font-size: 16px;
  font-weight: 600;
`;

const LoginForm = (props: { onLogin: () => void }) => {
  const [password, setPassword] = useState("");

  const onSubmit = () => {
    if (isValidPassword(password)) {
      savePassword(encodePassword(password));
      props.onLogin();
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        type="password"
        placeholder="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <button type="submit">Login</button>
    </Form>
  );
};

export default LoginForm;
