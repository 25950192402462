import { AnyProductType, PayPalOrderType } from "types";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {
  addPayPalOrder as addPayPalOrderAction,
  clearCart as clearCartAction,
} from "store/actions";
import { useEffect, useState } from "react";

import { paypal } from "config";
import { routes } from "config";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const PayPalButtonBase = (props: {
  productsInCart: AnyProductType[];
  pricePayPalFees: number;
  priceTotal: number;
  termsAgreement: boolean;
  setErrorMessage: (value: any) => void;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { productsInCart, pricePayPalFees, priceTotal, termsAgreement } = props;
  const [{ isPending }] = usePayPalScriptReducer();

  const [orderFinished, setOrderFinished] = useState<PayPalOrderType>(null);

  //
  // FORCE RE-RENDER (on cart updates)
  //
  const [forceCounter, setForceCounter] = useState<number>(0);

  useEffect(() => {
    setForceCounter(forceCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsInCart]);

  //
  // FINISHED ORDERS
  //
  const addPayPalOrder = (order) => dispatch(addPayPalOrderAction(order));
  const clearCart = () => dispatch(clearCartAction());

  useEffect(() => {
    if (orderFinished) {
      addPayPalOrder(orderFinished);

      // remove products from cart (sagas action)
      clearCart();

      // redirect to download page with popup enabled
      history.push(`/${routes.myDownloads.slug}?popup=true`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFinished]);

  //
  // PAYPAL BUTTON
  //
  const createPayPalButton = () => {
    const items = [];
    /* ITEMS */
    productsInCart.forEach((product) => {
      const price = product.price;
      items.push({
        name: product.name,
        quantity: "1",
        unit_amount: {
          currency_code: paypal.currency,
          value: `${price.toFixed(2)}`,
        },
      });
    });
    items.push({
      name: "Transaction Fee",
      quantity: "1",
      unit_amount: {
        currency_code: paypal.currency,
        value: `${pricePayPalFees.toFixed(2)}`,
      },
    });

    /* CREATE ORDER */
    const createOrder = (data, actions) => {
      // Set up the transaction
      return actions.order.create({
        application_context: {
          brand_name: "Jan Hamernik",
          shipping_preference: "NO_SHIPPING",
        },
        purchase_units: [
          {
            description: "Purchase from Jan Hamernik",
            amount: {
              currency_code: paypal.currency,
              value: `${priceTotal.toFixed(2)}`,
              // BREAKDOWN IS NEEDED WHEN PASSING ITEMS (otherwise it will throw an error)
              breakdown: {
                item_total: {
                  currency_code: paypal.currency,
                  value: `${priceTotal.toFixed(2)}`,
                },
              },
            },
            items: items,
          },
        ],
      });
    };

    /* ORDER PAID */
    const onApprove = async (data, actions) => {
      return actions.order.capture().then(function (details: PayPalOrderType) {
        try {
          const order = {
            ...details,
            products: productsInCart.map((p) => {
              return {
                /* clear data, because the store is persisted (do not waste space) */
                ...p,
                background: null,
                description: "",
                images: [],
                videos: [],
                tags: [],
                license: null,
                categories: [],
                softwareRequired: [],
              };
            }),
          } as PayPalOrderType;

          setOrderFinished(order);
        } catch (e) {
          console.log("(onApprove Error: ", e);
        }
      });
    };

    /* ORDER CANCELLED */
    const onCancel = () => {
      console.log({
        title: "Payment Canceled",
        text: "Your payment has been canceled.",
        type: "success",
      });
      props.setErrorMessage(
        <>
          <div>Transaction Cancelled</div>
        </>
      );
    };

    /* ORDER ERROR */
    const onError = (error) => {
      console.log("(onError: ", error);
      console.log({
        title: "Payment Error",
        text: "There was a problem with your PayPal transaction.",
        type: "error",
      });
      props.setErrorMessage(
        <>
          <div>There was a problem with your PayPal transaction</div>
          <div>{JSON.stringify(error)}</div>
        </>
      );
    };

    return (
      <PayPalButtons
        disabled={!termsAgreement}
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
        onError={onError}
        forceReRender={forceCounter as any}
      />
    );
  };

  //
  // RENDER
  //
  return (
    <>
      {isPending ? <div className="spinner" /> : null}
      {createPayPalButton()}
    </>
  );
};

export default PayPalButtonBase;
