import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (1) - Hobbit - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v2-sub1-hobbit',
  name: 'Hobbit',
  gallery,
  video: null,
  content: (
    <>
      <ReplicaParagraph>
        This is probably the most recognizable version of the map, because it is
        an essential part of the plot in The Hobbit: An Unexpected Journey. My
        guess is that even though the map was briefly shown in the Lord of the
        Rings: The Fellowship of the Ring, it was redrawn for the new films.
        This would explain the differences between the two designs, but more on
        this later. Achieving the same stains as the original prop required
        multiple different aging techniques.
      </ReplicaParagraph>
    </>
  ),
}

export default data
