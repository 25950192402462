import styled from 'styled-components'
import { VideoType } from 'types'
import YouTubeVideo from 'components/Media/YouTubeVideo'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  column-gap: 4px;
  row-gap: 14px;
`

const VideoWrapper = styled.div`
  max-width: 100%;
  min-width: 200px;
  width: 40%;
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
`

const YouTubeVideoGallery = (props: { videos: VideoType[] }) => {
  return (
    <Wrapper>
      {props.videos?.length > 0 &&
        props.videos.map(({ src, note }, i) => (
          <VideoWrapper key={i}>
            <YouTubeVideo src={src} note={note} />
          </VideoWrapper>
        ))}
    </Wrapper>
  )
}

export default YouTubeVideoGallery
