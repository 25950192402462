import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "daily-prophet-hp3-escape-from-azkaban",
  name: `The Daily Prophet\n'Escape from Azkaban!'`,
  origin: "Harry Potter and the Prisoner of Azkaban",

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
