import { Link } from "react-router-dom";
import { buttonCss } from "styles/GlobalStyle/Buttons";
import { routes } from "config";
import styled from "styled-components";

const CustomLink = styled(Link)`
  ${buttonCss}
`;

const ButtonLink = (props: { to: string; children?: any }) => {
  return <CustomLink to={routes.eshop.slug}>{props.children}</CustomLink>;
};

export default ButtonLink;
