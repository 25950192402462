import { routes } from "config";
import styled from "styled-components";
import LayoutFullscreen from "layouts/LayoutFullscreen";
/* import ButtonScrollToSection from 'components/Interaction/ButtonScrollToSection' */

import Background from "assets/background/background_aae_hud_factory.jpg";
import { productsAAEHUDFactory } from "assets/products";

import Carousel from "components/Carousel";
import ProductItem from "components/ProductItem";
import SectionHeading from "components/SectionHeading";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpacerTop = styled.div`
  height: 0vh;
  ${(props) => props.theme.breakpoint.M} {
    height: 6vh;
  }
`;

const SpacerMiddle = styled.div`
  height: 6vh;
  ${(props) => props.theme.breakpoint.M} {
    height: 6vh;
  }
`;

const Small = styled.span`
  position: relative;
  font-size: 0.35em;
  font-weight: 600;
  letter-spacing: 0.3em;
  opacity: 0.8;
  bottom: -4px;
`;

const AAEHUDFactory = (props: { onNavClick: () => void }) => {
  const carouselItems = productsAAEHUDFactory.map((product) => (
    <ProductItem product={product} wide={true} />
  ));

  return (
    <LayoutFullscreen background={Background}>
      <Wrapper>
        <SpacerTop />
        <SectionHeading
          title={
            <>
              AAE HUD Factory<Small>v2.0</Small>
            </>
          }
          subtitle="Realistic HUDs with eye reflections and dynamic lighting"
          link={`/${routes.aaeHudFactory.slug}`}
        />

        <SpacerMiddle />

        <Carousel items={carouselItems} autoplay offset={1} />

        {/* <SpacerMiddle />
        <ButtonScrollToSection onClick={props.onNavClick} label="About Me" /> */}
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default AAEHUDFactory;
