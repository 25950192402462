import { useEffect, useState } from "react";

import { breakpoints } from "styles/breakpoints";

interface BreakpointValues<T> {
  defaultValue: T;
  XL?: T;
  L?: T;
  M?: T;
  S?: T;
  XS?: T;
}

const useBreakpointValue = <T,>(values: BreakpointValues<T>): T => {
  const [currentValue, setCurrentValue] = useState<T>(values.defaultValue);

  useEffect(() => {
    const mediaQueries = [
      { query: `(max-width: ${breakpoints.XS})`, value: values.XS },
      { query: `(max-width: ${breakpoints.S})`, value: values.S },
      { query: `(max-width: ${breakpoints.M})`, value: values.M },
      { query: `(max-width: ${breakpoints.L})`, value: values.L },
      { query: `(max-width: ${breakpoints.XL})`, value: values.XL },
    ];

    const updateValue = () => {
      for (const mq of mediaQueries) {
        if (mq.value !== undefined && window.matchMedia(mq.query).matches) {
          setCurrentValue(mq.value);
          return;
        }
      }
      setCurrentValue(values.defaultValue);
    };

    // Set initial value
    updateValue();

    // Add listeners for each media query
    mediaQueries.forEach((mq) => {
      if (mq.value !== undefined) {
        const mediaQueryList = window.matchMedia(mq.query);
        mediaQueryList.addEventListener("change", updateValue);
      }
    });

    // Cleanup listeners on component unmount
    return () => {
      mediaQueries.forEach((mq) => {
        if (mq.value !== undefined) {
          const mediaQueryList = window.matchMedia(mq.query);
          mediaQueryList.removeEventListener("change", updateValue);
        }
      });
    };
  }, [values]);

  return currentValue;
};

export default useBreakpointValue;
