import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ElderWand from "assets/replicas/harry-potter/hp-elder-wand";
import { Link } from "react-router-dom";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import VoldemortsWand from "assets/replicas/harry-potter/hp-voldemorts-wand";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWip1 = [];
let galleryWip2 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip1 = importAll(
    (require as any).context("./wip1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWip2 = importAll(
    (require as any).context("./wip2", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 17, month: 8, year: 2018 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryWipImages1 = transformImages(galleryWip1);
  const galleryWipImages2 = transformImages(galleryWip2);

  //const galleryDayImages2_sub1 = [...galleryDayImages2].splice(0, 9)

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        One simply cannot leave this one behind when creating Harry Potter
        wands. I like that they dropped the design from the first two films,
        where all the wands looked basically the same. If I'm not mistaken, it
        was Alfonso Cuarón (director) who came up with the idea to use new and
        personalized wand designs for the Prisoner of Azkaban.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I had a wonderful experience using FIMO clay for the sculpting of my{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ElderWand.id}
          target="_blank"
        >
          Elder Wand
        </Link>{" "}
        , so I used it for this one as well. I'm not very skilled in sculpting
        natural shapes - both in real life and 3D graphics. I prefer hard-edged
        modeling. Organic shapes give you the freedom to sculpt everything by
        hand without the need of precise measurements, which is very nice. On
        the other hand, it's easy to screw it up.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />
      <ReplicaSubTitleAnchor title="Sculpting" />

      <ReplicaParagraph>
        There's a wooden stick in the base of this replica. I do this with all
        of my wand replicas, because the wand stays fairly flexible - just like
        wood. This time I covered the entire wooden stick in FIMO clay, so I
        could add some imperfections to the surface more easily. I baked this so
        I woudn't have to worry about touching the thing, and I used this as a
        base for the wand. I then started working on the wand handle. I first
        added the transition between the handle and the rest of the wand. I'm
        starting to like this type of modeling more and more. I baked this part
        again, so I could work on the front of the handle. It's almost like
        saving your progress in a game - if you don't like what you're doing
        with the next part, you can simply scrape it off of the wand and do it
        again. I finished the front side of the handle, and then (after another
        baking) the rear side and added some final details. I think it turned
        out looking quite good - it resembles a twig, which is exactly what I
        wanted.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {galleryWipImages1?.length > 0 && (
        <Gallery images={galleryWipImages1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Painting" />
      <ReplicaParagraph>
        I used brown FIMO clay, but I didn't really like the color, so I started
        the painting process from scratch. I painted the whole wand black, as a
        base. Then, just like I did with my{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ElderWand.id}
          target="_blank"
        >
          Elder Wand
        </Link>
        , I started adding different shades of brown paint. After a few passes,
        it started to look like wood.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {galleryWipImages2?.length > 0 && (
        <Gallery images={galleryWipImages2} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Though" />
      <ReplicaParagraph>
        The design proved to be very difficult to sculpt, but I think it looks
        good in the end. This is the third wand I created, the first one was the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + ElderWand.id}
          target="_blank"
        >
          Elder Wand
        </Link>
        , and the second one was{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + VoldemortsWand.id}
          target="_blank"
        >
          Voldemort's Wand
        </Link>
        . Anyway, the FIMO clay turned out to be really good for this type of
        replicas.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
