import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "tales-of-beedle-the-bard",
  name: `The Tales of Beedle the Bard`,
  origin: "Harry Potter and the Deathly Hallows",

  sort: {
    preferredIndex: 1,
  },

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
