import styled, { css } from "styled-components";

const Wrapper = styled.div<{ reverse: boolean }>`
  margin-top: 10%;
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  gap: 10%;

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${(props) => props.theme.breakpoint.M} {
    gap: 5%;
    font-size: 0.9em;
  }
  ${(props) => props.theme.breakpoint.S} {
    flex-direction: column;
  }

  & > *:first-child {
    flex: 0.6;
    min-width: 260px;
  }
  & > *:last-child {
    flex: 1;
    ${(props) => props.theme.breakpoint.S} {
      max-width: min(90%, 400px);
    }
  }

  img {
    width: 100%;
  }
`;

const Col = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 20px;
  margin-bottom: 10%;
  opacity: 0.6;
  font-style: italic;

  ${(props) =>
    props.reverse &&
    css`
      text-align: left;
      align-items: flex-start;
    `}

  ${(props) => props.theme.breakpoint.M} {
    gap: 15px;
  }
  ${(props) => props.theme.breakpoint.S} {
    margin-top: 10%;
    align-items: center;
  }
`;

const ReplicaStats = (props: {
  labels: any[];
  img: any;
  reverse?: boolean;
}) => {
  return (
    <Wrapper reverse={props.reverse}>
      <Col reverse={props.reverse}>
        {props.labels.map((label) => (
          <div>{label}</div>
        ))}
      </Col>
      <div>
        <img src={props.img} alt="stack" />
      </div>
    </Wrapper>
  );
};

export default ReplicaStats;
