import styled, { css } from "styled-components";

import { useImageLoaded } from "hooks";

const Image = styled.img<{ isLoaded: boolean }>`
  transition: all 0.2s ease-out;

  ${(props) =>
    props.isLoaded
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}
`;

const ImageLoader = (props: { src: string; alt?: string }) => {
  const { imageRef, imageLoaded } = useImageLoaded();

  return (
    <Image
      src={props.src}
      alt={props.alt || ""}
      loading="lazy"
      ref={imageRef}
      isLoaded={imageLoaded}
    />
  );
};

export default ImageLoader;
