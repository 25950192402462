import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_3d_model = [];
let galleryWIP_emitter = [];
let galleryWIP_greeblies = [];
let galleryWIP_cover = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_3d_model = importAll(
    (require as any).context("./gallery-wip-3D-model", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_cover = importAll(
    (require as any).context("./gallery-wip-cover", false, /\.(png|jpe?g)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 1, month: 7, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is the lightsaber of Qui-Gon Jinn, Obi-Wan Kenobi's master in Star
        Wars Episode 1. While it seems to be a simple design, there is one part
        of the hilt that is seriously difficult to machine - the handle. The
        problem is that the grooves on the handle are not straight lines going
        from one side to the other, their path is circular. This part would be a
        nightmare to try and machine using conventional tools, but I was saved
        by the fact that it doesn't need to be made out of metal - so I 3D
        printed it.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.M} />
      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=cyuX9w20Vto"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Assembly Design" />
      <ReplicaParagraph>
        Research on this hilt was rather short in comparison with the other
        lightsabers I created. This was mostly due to the fact that the
        lightsaber only appears in one film, so there are no variations of the
        same hilt throughout the trilogy that I'd need to take into
        consideration. I'm used to running a threaded rod from the emitter to
        the pommel through the body of the lightsaber, which holds everything
        together, however, the design of this hilt doesn't allow it. The handle
        grooves go so deep that there's simply not enough room for the threaded
        rod, so I had to design the assembly around this problem.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_3d_model)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Emitter" />
      <ReplicaParagraph>
        The emitter is similar in design to the one on Obi-Wan Kenobi's
        lightsaber from Episode 1. With a tapped hole at one end, the emitter
        serves as a nut that holds together the entire front section of the
        lightsaber. I said before that the threaded rod cannot run throughout
        the entire hilt, so the rod ends before the handle grooves start, and
        the pommel was treated as a separate problem to solve.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Handle Cover" />
      <ReplicaParagraph>
        While the intricately shaped handle part was 3D printed, the sleeve that
        goes around it had to be made out of metal. This was a fairly
        straightforward part for the lathe, on which I simply machined a tube of
        the required dimensions. Then came the most difficult part - using a set
        of files to shape the distinct profile of the part and the holes at the
        bottom. There's also a precisely drilled hole for the Covertec wheel,
        which also holds the sleeve in place when the hilt is assembled.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_cover)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        There's basically just one button on the entire lightsaber (apart from
        the Covertec wheel), which I ended up machining from scratch. This was
        partly due to the fact that I do not have the original hardware part
        that was used for the films, but mostly because I designed the button to
        go quite deep and reinforce the handle assembly. I machined it on a
        lathe, which included knurling and cutting threads.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_greeblies)} />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
