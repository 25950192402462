import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "daily-prophet-hp4-bulgaria-vs-ireland",
  name: `The Daily Prophet\n'Bulgaria Vs Ireland'`,
  origin: "Harry Potter and the Goblet of Fire",

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
