import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, transformImages } from "utils";

import Differences from "./differences";
import { HashLink } from "react-router-hash-link";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import ReplicaSubVersionContent from "components/Replicas/ReplicaSubVersionContent";
import ReplicaSubVersions from "components/Replicas/ReplicaSubVersionList";
import { ReplicaVersionType } from "types";
import V1Hobbit from "./versions/Version (1) - Hobbit";
import V2Lotr from "./versions/Version (2) - LOTR";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import globalReplicaData from "../";
import thumbnail from "./versions/Version (2) - LOTR/src/Version (2) - LOTR - Thumbnail.png";

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 7, month: 11, year: 2020 }),
  version: "2",
  isSeries: true,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const anchors = {
    differences: "v2-differences",
  };

  const replicaSubVersions = [V1Hobbit, V2Lotr];

  const DifferencesGallery = transformImages(Differences.gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is my second take on replicating the map of The Lonely Mountain of
        Erebor, known mainly from The Hobbit: An Unexpected Journey, and briefly
        seen in The Lord of the Rings: The Fellowship of the Ring. The map was
        made by Thrór, the King under the Mountain, who gave it to his son
        Thráin, who tried to enter Erebor himself, but was captured. Finally,
        through Gandalf, the map got to Thorin, Thrór's grandson, who managed to
        enter and recover Erebor with the help of the Company, including Bilbo
        Baggins.
      </ReplicaParagraph>
      <ReplicaParagraph>
        This time I tried to take the prop replica to the next level - including
        drawing everything by hand and using the very same paper that was used
        for the original prop. At first glance, the map from The Hobbit may look
        identical to the map featured in The Lord of the Rings, but that would
        be a mistake - the two designs are actually almost completely different!{" "}
        <HashLink to={"#" + anchors.differences} smooth>
          These differences are, of course, explained below
        </HashLink>
        .
      </ReplicaParagraph>

      <ReplicaSubVersions
        replicaSubVersions={replicaSubVersions}
        large
        smallYGap
      />

      <ReplicaParagraph>
        The version 2 of Thrór's Map is superior to the previous version in
        every single aspect - from graphics design to the final printing and
        aging process. Let me take you through some of the most notable changes:
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Written by hand`,
            description: `Everything on the map was written by my hand. To get as close to the original as possible, this approach required many re-drawings. However, judging by the results, I think these were definitely worth the time.`,
          },
          {
            title: `The exact same paper as the original prop`,
            description: `I found the exact type of paper that was used for the original prop, and had it imported to my country - for a very large fee. Apparently, this paper is simply not available in here... what a shame. Anyways, the paper itself is also quite expensive, but the quality and texture of the paper was definitely worth every cent.`,
          },
          {
            title: `High-quality print`,
            description: `The map was printed on a high-end professional printer, using a high-quality water-resistant ink. The result looks quite natural, there's no pixelation or any other flaws that are often associated with printing.`,
          },
          {
            title: `Authentic aging process`,
            description: `The map was manually aged, making each copy unique and authentic. Thanks to this, the map feels and looks old, even at a very close look. While working on this replica, I even managed to improve my current paper-aging process and learn a few new tricks along the way.`,
          },
        ]}
      />

      {/*
       **   VERSION SECTIONS
       */}
      {replicaSubVersions.map((replicaSubVersion, i) => (
        <ReplicaSubVersionContent
          key={i}
          replicaSubVersion={replicaSubVersion}
          replicaName={globalReplicaData.name}
          version={VERSION_DATA.version}
          gallerySize={GallerySize.L}
        />
      ))}

      {/*
       **   VIDEO
       */}
      <ReplicaSpacer />
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=ur6SD0Ve5E4" />

      {/*
       **   SECTION - SUB-VERSION DIFFERENCES
       */}
      <ReplicaSubTitleAnchor
        title="What's the Difference?"
        subtitle={`v${VERSION_DATA.version} versions`}
        anchor={anchors.differences}
      />
      <ReplicaParagraph>
        As I have mentioned a few times now, the movies use different versions
        of the map. While the maps look very similar, there's no doubt that they
        are different. They do share the same "elements" (the mountain, compass
        rose etc.), but their placement is a bit different - for example, the
        compass rose in the Hobbit version is a bit smaller that the one from
        The Lord of the Rings version. The texts are generally the same, with
        one notable difference at the bottom of the map (the white arrow at the
        bottom of the picture below points to this). The Hobbit version also
        includes 'Ravenhill', which is missing from The Lord of the Rings
        version.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {DifferencesGallery?.length > 1 && (
        <Gallery images={[DifferencesGallery[1]]} />
      )}
      <ReplicaSpacer />

      <ReplicaParagraph>
        The most notable difference is the drawing of the mountain, however.
        This was the first difference that I noticed when researching the map
        and watching the movies. Not only are the two mountains drawn in a
        different style, but the shape of the mountain is also different, as
        well as the path of the river that flows around Dale. If you're still
        not convinced that the maps are different in almost every aspect, here
        are the two designs overlaid - the white design is from the Hobbit, and
        the black design is from The Lord of the Rings. Were the designs
        identical, the white one would've covered the black design completely,
        but this is not the case:
      </ReplicaParagraph>

      <ReplicaSpacer />
      {DifferencesGallery?.length > 0 && (
        <Gallery images={[DifferencesGallery[0]]} />
      )}
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
