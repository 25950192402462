import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import Video from 'components/Media/Video'
import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'

import thumbnail from './src/Version (6) - Ultimate Map - Thumbnail.png'
import videoVolvelle from './src/Version (6) - Ultimate Map - Volvelle.mp4'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v6-sub6-ultimate-map',
  name: 'Ultimate Map',
  gallery,
  video: 'https://www.youtube.com/watch?v=gFm5l7MTz_w',
  content: (
    <>
      <ReplicaParagraph>
        I love making the replicas as close to the films as possible, but I also
        love designing my own pages and extending the map even more. Since I
        already replicated the props from the individual films, this version
        gave me the free reign to do whatever I want, while not being burdened
        by the horrible fact that I'm not adhering to the films. For example,
        the intro correctly says "Moony", instead of "Mooney" like it does in
        the films. I included the pages from the film versions I liked the most
        - mostly the screen-used designs, but there's also the seventh floor
        design with the 3D Room of Requirement (from the Order of the Phoenix).
      </ReplicaParagraph>
      <ReplicaParagraph>
        I redesigned some of the film pages to fit the Hogwarts architecture
        more. On top of that, I designed some new pages from scratch (I didn't
        use any of my own custom designs from the previous replicas like v5 or
        v4). My aim was to include some of the important parts of the Hogwarts
        castle - the library or the greenhouses, for example, but I also
        included the map of the whole castle on one page. My previous custom
        designs usually didn't include any flaps or 3D stairs, but this time I
        didn't shy away from making the map really complex. I didn't want to
        just re-use the flap format from the original film prop, I invented some
        new interesting features of my own. I also wanted to put something
        "functional" into the map - that is why I included a fully working lunar
        volvelle!
      </ReplicaParagraph>

      <Video src={videoVolvelle} autoPlay heightPercentage="45%" />

      <ReplicaParagraph>
        The volvelle can be found on the page that shows the Astronomy Tower
        (where Dumbledore died), hidden under a flap with 3D stairs. This was
        one of the most challenging parts of the design, because I had to learn
        how a volvelle works and how to construct it. I also wanted to design
        mine to fit into the page design without being noticed at the first
        glance. "Why a volvelle?" You might ask... well, I really like the way
        it looks and works, but it also seems fitting - the volvelle can be used
        to calculate the phases of the sun and moon, which, to me, seemed to be
        quite useful, since one of the marauders was a werewolf.
      </ReplicaParagraph>
    </>
  ),
}

export default data
