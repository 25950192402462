import { Cross } from "@styled-icons/entypo/";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div<{ aspectRatioPercentage?: number }>`
  width: 100%;
  overflow: hidden;
  padding-bottom: ${(props) => props.aspectRatioPercentage || 56.25}%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const Note = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;

  backdrop-filter: blur(20px);
  background: ${(props) => props.theme.red}77;
  color: ${(props) => props.theme.white}EE;
  font-size: 11px;
  font-weight: 600;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    background: none !important;
    backdrop-filter: none !important;
  }
`;

const YouTubeVideo = (props: {
  src: string;
  autoplay?: boolean;
  hideControls?: boolean;
  note?: string;
  dimensions?: {
    width: number;
    height: number;
  };
}) => {
  const aspectRatioPercentage = props.dimensions
    ? (props.dimensions.height / props.dimensions.width) * 100
    : undefined;

  const autoplay = !!props.autoplay;
  const mute = autoplay;
  const loop = autoplay;
  const controls = !props.hideControls;

  const [videoPlaying, setVideoPlaying] = useState(props.autoplay);

  const [noteVisible, setNoteVisible] = useState(true);

  return (
    <Wrapper
      aspectRatioPercentage={videoPlaying ? aspectRatioPercentage : undefined}
    >
      <ReactPlayer
        url={props.src}
        playing={autoplay}
        muted={mute}
        loop={loop}
        controls={controls}
        width={853}
        height={
          aspectRatioPercentage ? (480 / 100) * aspectRatioPercentage : 480
        }
        onPlay={() => setVideoPlaying(true)}
      />

      {props.note && noteVisible && (
        <Note>
          <div>{props.note}</div>
          <button
            className="btn-icon-only"
            onClick={() => setNoteVisible(false)}
          >
            <Cross />
          </button>
        </Note>
      )}
    </Wrapper>
  );
};

export default YouTubeVideo;
