import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { ChevronLeft } from "@styled-icons/boxicons-solid/";
import { useState } from "react";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.white}22;
  backdrop-filter: blur(10px);
`;

const Header = styled.div`
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
  padding-left: 15px;
`;

const CloseButton = styled.button<{ rotate: string }>`
  svg {
    transition: all 0.2s ease;
    ${({ rotate }) =>
      rotate === "true" &&
      css`
        transform: rotate(-90deg);
      `}
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5em;
  color: ${(props) => props.theme.white}BB;
`;

const Content = styled.div<{ allowOverflow: boolean; noPadding: boolean }>`
  z-index: 1;

  ${(props) =>
    props.allowOverflow
      ? css``
      : css`
          overflow: hidden;
        `}

  ${(props) =>
    props.noPadding
      ? css`
          padding: 0px;
        `
      : css`
          padding: 25px;
        `}
`;

const ProductItem = (props: {
  children: any;
  title: any;
  closed?: boolean;
  allowOverflow?: boolean;
  noPadding?: boolean;
}) => {
  const [isOpen, setOpen] = useState<boolean>(
    typeof props.closed === "boolean" ? !props.closed : true
  );

  return (
    <Wrapper>
      <Header onClick={() => setOpen(!isOpen)}>
        <Title>{props.title}</Title>
        <CloseButton
          className="btn-icon-only"
          rotate={isOpen ? "true" : "false"}
        >
          <ChevronLeft />
        </CloseButton>
      </Header>

      <AnimateHeight height={isOpen ? "auto" : 0}>
        <Content
          allowOverflow={isOpen && props.allowOverflow}
          noPadding={props.noPadding}
        >
          {props.children}
        </Content>
      </AnimateHeight>
    </Wrapper>
  );
};

export default ProductItem;
