import AnimatedSprite, {
  AnimatedSpritePlay,
} from "components/Media/AnimatedSprite";
import React, { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { ReplicaType, ReplicaVersionType } from "types";
import replicas, { replicasWIP } from "assets/replicas";

import Carousel from "components/Carousel";
import LayoutPage from "layouts/LayoutPage";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaItem from "components/Replicas/ReplicaItem";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import SectionHeading from "components/SectionHeading";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const VersionPages = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const VersionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const VersionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vh;
  margin-bottom: 4vh;

  ${(props) => props.theme.breakpoint.M} {
    margin-top: 0;
  }
`;
const NameTitle = styled.h2`
  margin-top: 10vh;
  span {
    opacity: 0.4;
    font-size: 0.7em;
  }
`;
const VersionTitle = styled.h2`
  margin-top: -8px;
  span {
    opacity: 0.5;
    font-size: 0.6em;
  }
  strong {
    font-weight: 500;
  }
`;

const Thumbnail = styled.div`
  img {
    height: 300px;

    object-fit: contain;

    --blur: 1rem;
    --offsetY: 1rem;
    --saturate: 0.8;
    filter: drop-shadow(
        0 var(--offsetY) var(--blur) ${(props) => props.theme.black}88
      )
      saturate(var(--saturate));

    display: none;
    ${(props) => props.theme.breakpoint.M} {
      display: block;
    }
  }
`;

const ClosedVersion = styled.h2`
  cursor: pointer;
  width: 98%;
  padding: 1%;
  margin: 10px 0;
  background: ${(props) => props.theme.black}55;
  color: ${(props) => props.theme.white}99;
  border-radius: 2px;
  transition: all 0.2s ease;

  span {
    font-size: 0.5em;
    letter-spacing: 0.2em;

    &:first-child {
      opacity: 0.4;
    }
  }

  &:hover {
    background: ${(props) => props.theme.black}77;
    color: ${(props) => props.theme.white}AA;
  }
`;

const EShopDetail = () => {
  const { id } = useParams();
  const [openVersionIndexes, setOpenVersionIndexes] = useState<number[]>([]);

  let replica = replicas.find((r) => r.id === id);
  let isWIP = false;

  if (!replica) {
    // If the replica doesn't exist, try to find it in WIP replicas
    replica = replicasWIP.find((r) => r.id === id);
    isWIP = true;
  }

  if (!replica) {
    return <Redirect to={"/" + routes.replicas.slug} />;
  }

  const currentVersion = replica?.versions[0];
  const hasMultipleVersions = replica?.versions?.length > 1;

  const previousVersions: ReplicaVersionType[] = hasMultipleVersions
    ? [...replica?.versions?.slice(1).reverse()]
    : [];

  const similarReplicas: ReplicaType[] = replicas.filter(
    (r) => r.category === replica.category && r.id !== replica.id
  );

  //
  // RENDER FUNCTIONS
  //
  const renderNameTitle = () => {
    return (
      <NameTitle>
        <span>{`${replica.name}`}</span>
      </NameTitle>
    );
  };
  const renderVersionTitle = (version: ReplicaVersionType) => {
    return (
      <VersionTitle>
        <span>
          <strong>{`v${version.version}`}</strong>
          {`${version.isSeries ? " (Series)" : ""}`}
        </span>
      </VersionTitle>
    );
  };
  const renderPage = (version: ReplicaVersionType) => {
    return <VersionContent>{version.page}</VersionContent>;
  };

  const renderPreviousVersion = (version: ReplicaVersionType, i) => {
    const isOpen = openVersionIndexes.includes(i);

    return isOpen ? (
      <VersionWrapper>
        {renderNameTitle()}
        {renderVersionTitle(version)}
        {renderPage(version)}
      </VersionWrapper>
    ) : (
      <ClosedVersion
        onClick={() => setOpenVersionIndexes([...openVersionIndexes, i])}
      >
        <span>{`${replica.name}`}</span>
        <span>{`  v${version.version}`}</span>
      </ClosedVersion>
    );
  };

  //
  // RENDER
  //
  return (
    <LayoutPage
      background={currentVersion.background}
      backgroundVideo={currentVersion.backgroundVideo}
      backgroundVideoFade={0.15}
    >
      <Wrapper>
        {isWIP ? (
          <ReplicaSubTitleAnchor title="Work In Progress" level={2} />
        ) : null}

        <SectionHeading
          title={
            <div>
              {replica.name}
              {hasMultipleVersions && renderVersionTitle(currentVersion)}
            </div>
          }
          subtitle={replica.origin}
          link={`/${routes.replicas.slug}`}
          linkTitle={"Prop Replicas"}
        />

        <Thumbnail>
          <AnimatedSprite
            src={replica.versions[0].thumbnail}
            alt={replica.name}
            animation={{
              play: AnimatedSpritePlay.infinite,
              frameDuration_Milliseconds: 50,
            }}
          />
        </Thumbnail>

        <VersionPages>
          <VersionWrapper>{renderPage(currentVersion)}</VersionWrapper>

          {/*
           **   PREVIOUS VERSIONS
           */}
          {previousVersions?.length ? (
            <>
              <ReplicaBigTitle title="Previous Versions" />
              <ReplicaParagraph>
                For archiving purposes, below you will find the previous
                versions of this replica. My goal is to keep the description of
                the latest version (above) concise and independent of the
                previous versions, so some of the information below may be
                repeated. However, if you're interested in the history of this
                replica, perhaps to see what changed between the individual
                versions, feel free to check out the sections below.
              </ReplicaParagraph>

              <ReplicaSpacer />
              <ReplicaSpacer />

              {previousVersions?.map((version, i) => (
                <React.Fragment key={i}>
                  {renderPreviousVersion(version, i)}
                </React.Fragment>
              ))}
            </>
          ) : null}

          {/*
           **   SIMILAR REPLICAS
           */}
          {similarReplicas?.length ? (
            <>
              <ReplicaSpacer />
              <ReplicaSpacer />
              <ReplicaSpacer />
              <ReplicaSpacer />
              <ReplicaBigTitle title="You may also like" />

              {/* padding is necessary for replicas with large thumbnails (absolute positioning results in these being clipped) */}
              <Carousel
                items={[...similarReplicas].map((replica) => (
                  <ReplicaItem replica={replica} />
                ))}
                autoplay
                padding="30px 0 30px 0"
              />
            </>
          ) : null}
        </VersionPages>
      </Wrapper>
    </LayoutPage>
  );
};

export default EShopDetail;
