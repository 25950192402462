import { useState } from 'react'

import LayoutSections from 'layouts/LayoutSections'
import { routes } from 'config'

import Intro from './Sections/Intro'
import Replicas from './Sections/Replicas'
import Shop from './Sections/Shop'
import AAEHUDFactory from './Sections/AAEHUDFactory'
import Contact from './Sections/Contact'
import About from './Sections/About'

const Home = () => {
  const [activeId, setActiveId] = useState(routes.home.anchors.intro)
  const [scrollId, setScrollId] = useState(null)

  const sections = [
    {
      label: 'Intro',
      component: (
        <Intro onNavClick={() => setScrollId(routes.home.anchors.replicas)} />
      ),
      id: routes.home.anchors.intro,
    },
    {
      label: 'Replicas',
      component: (
        <Replicas onNavClick={() => setScrollId(routes.home.anchors.eshop)} />
      ),
      id: routes.home.anchors.replicas,
    },
    {
      label: 'VFx Shop',
      component: (
        <Shop
          onNavClick={() => setScrollId(routes.home.anchors.aaeHudFactory)}
        />
      ),
      id: routes.home.anchors.eshop,
    },
    {
      label: 'AAE HUD Factory',
      component: (
        <AAEHUDFactory
          onNavClick={() => setScrollId(routes.home.anchors.about)}
        />
      ),
      id: routes.home.anchors.aaeHudFactory,
    },
    {
      label: 'About',
      component: (
        <About onNavClick={() => setScrollId(routes.home.anchors.contact)} />
      ),
      id: routes.home.anchors.about,
    },
    {
      label: 'Contact',
      component: <Contact />,
      id: routes.home.anchors.contact,
    },
  ]

  return (
    <LayoutSections
      sections={sections}
      activeId={activeId}
      setActiveId={setActiveId}
      scrollId={scrollId}
      setScrollId={setScrollId}
    />
  )
}

export default Home
