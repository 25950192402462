import PrintableGraflexFlashgun from "assets/products/3d-printables/SW-Printable-Graflex-Flashgun";
import PrintableLightsaberAnakinEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Anakin-Ep3";
import PrintableLightsaberDookuEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Dooku-Ep3";
import PrintableLightsaberLukeEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep4";
import PrintableLightsaberLukeEp5 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep5";
import PrintableLightsaberLukeEp6_Hero from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-hero";
import PrintableLightsaberLukeEp6_V2 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-v2";
import PrintableLightsaberLukeEp6_V3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Luke-Ep6-v3";
import PrintableLightsaberMaceEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Mace-Ep3";
import PrintableLightsaberObiWanEp3 from "assets/products/3d-printables/SW-Printable-Lightsaber-Obi-Wan-Ep3";
import PrintableLightsaberObiWanEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Obi-Wan-Ep4";
import PrintableLightsaberVaderEp4 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep4";
import PrintableLightsaberVaderEp5 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep5";
import PrintableLightsaberVaderEp6 from "assets/products/3d-printables/SW-Printable-Lightsaber-Vader-Ep6";
import PrintableMPPFlashgun from "assets/products/3d-printables/SW-Printable-MPP-Flashgun";
import { ProductBundleType } from "types";
import { createBundle } from "../bundle-generator";

const bundles: ProductBundleType[] = [
  createBundle({
    id: "Printable-Lightsaber-Episode-3",
    name: "Episode 3 Lightsabers",
    products: [
      PrintableLightsaberDookuEp3,
      PrintableLightsaberObiWanEp3,
      PrintableLightsaberMaceEp3,
      PrintableLightsaberAnakinEp3,
    ],
  }),

  createBundle({
    id: "Printable-Lightsaber-Episode-4",
    name: "Episode 4 Lightsabers",
    products: [
      PrintableLightsaberLukeEp4,
      PrintableLightsaberObiWanEp4,
      PrintableLightsaberVaderEp4,
    ],
  }),

  createBundle({
    id: "Printable-Lightsaber-Episode-5",
    name: "Episode 5 Lightsabers",
    products: [PrintableLightsaberLukeEp5, PrintableLightsaberVaderEp5],
  }),

  createBundle({
    id: "Printable-Lightsaber-Episode-6",
    name: "Episode 6 Lightsabers",
    products: [PrintableLightsaberLukeEp6_Hero, PrintableLightsaberVaderEp6],
  }),

  createBundle({
    id: "Printable-Lightsaber-Luke-Ep4+Ep5",
    name: "Luke Ep4 & Ep5 Lightsaber",
    generatedThumbnail: {
      reverseOrder: true,
    },
    products: [PrintableLightsaberLukeEp4, PrintableLightsaberLukeEp5],
  }),

  createBundle({
    id: "Printable-Lightsaber-Luke-Ep6-Versions",
    name: "Luke Ep6 Lightsaber Versions",
    products: [
      PrintableLightsaberLukeEp6_V2,
      PrintableLightsaberLukeEp6_Hero,
      PrintableLightsaberLukeEp6_V3,
    ],
  }),

  createBundle({
    id: "Printable-Lightsaber-Vader-Ep4-6",
    name: "Vader Ep4-6 Lightsaber",
    products: [
      PrintableLightsaberVaderEp4,
      PrintableLightsaberVaderEp5,
      PrintableLightsaberVaderEp6,
    ],
  }),

  createBundle({
    id: "Printable-Lightsaber-Vader-Ep4+Ep5",
    name: "Vader Ep4 & Ep5 Lightsaber",
    products: [PrintableLightsaberVaderEp4, PrintableLightsaberVaderEp5],
  }),

  createBundle({
    id: "Printable-Lightsabers-Original-Trilogy",
    name: "Original Trilogy Lightsabers",
    discountPercentage: 40,

    products: [
      PrintableLightsaberLukeEp4,
      PrintableLightsaberObiWanEp4,
      PrintableLightsaberVaderEp4,
      PrintableLightsaberLukeEp5,
      PrintableLightsaberVaderEp5,
      PrintableLightsaberLukeEp6_Hero,
      PrintableLightsaberVaderEp6,
    ],
  }),

  createBundle({
    id: "Printable-Lightsaber-Graflex-MPP-Flashgun",
    name: "Graflex & MPP Flashgun",
    products: [PrintableGraflexFlashgun, PrintableMPPFlashgun],
  }),
];

export default bundles;
