import { css } from "styled-components";

//
// Default Styles
//

const resetButtonCss = css`
  border: none;
  outline: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    background: none;
  }
`;

export const defaultButtonCss = css`
  ${resetButtonCss}
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;

  --letterSpacing: 0.25em;
  letter-spacing: var(--letterSpacing);
  text-indent: var(--letterSpacing);
  text-align: center;

  color: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.white}44;
  border-radius: 1px;

  transition: all 0.2s ease;

  &:hover {
    background: ${(props) => props.theme.white}22;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const buttonCss = css`
  ${defaultButtonCss}
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;

  &.btn-icon-only {
    ${resetButtonCss}
    padding: 10px;
    border-radius: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
    color: ${(props) => props.theme.white}AA;
    &:hover {
      color: ${(props) => props.theme.white};
    }
  }
`;

export const defaultButtons = css`
  button {
    ${buttonCss}
  }
`;
