import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import styled from "styled-components";

let images = [];
try {
  images = importAll(
    (require as any).context("./images", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 10, month: 10, year: 2005 }),
  version: "0",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const progressImages = transformImages(images);

  const images1 = [...progressImages].splice(0, 2);
  const images2 = [...progressImages].splice(2, 2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is going to get awkward, as I'm not very happy with the previous
        versions of the replica, haha. But that's exactly why I kept going and
        making it over and over again. I attempted to make the first replica
        when I was about 12 years old - that's when the Prisoner of Azkaban film
        came out. I was immediately captivated by the look of the Marauder's
        Map. I say 'replica', but the quality is equal to my 12-year-old self
        abilities, as you can see. That's why I call it version 0 - I don't even
        count it as a proper take on the replica.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images1} />
      <SpacerContent />

      <ReplicaParagraph>
        From reading the book, I pictured the map as a boring piece of
        parchment. This is actually the whole deal of the map - that it's not
        meant to look suspicious, which the film version most certainly does.
        But I get it. To display every corner of Hogwarts on a single piece of
        parchment is not very doable, and to make it look appealing for a film
        on top of that is more or less impossible... and I just love the way
        they handled this thing. The beautiful design and the idea behind the
        folding structure of the map, nice work Minalima! I also love that the
        design allowed them to add new pages in the later films, without
        affecting the appearance of the map, genius.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images2} />
      <SpacerContent />

      <ReplicaParagraph>
        As you can see, my first "replica" looks ridiculous - mainly from the
        inside, as I didn't look at any reference images, and the film was not
        on a DVD yet, so I couldn't use it as a reference. But I have to cut my
        younger self some slack - I was just a kid, and I actually kinda like
        the intro drawing. I guess I found an image of the closed map, so I used
        at least some reference.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
