import Gallery, { GallerySize } from "components/Media/Gallery";
import { importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaQuote from "components/Replicas/ReplicaQuote";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import YouTubeVideo from "components/Media/YouTubeVideo";

let galleryAssetsFontsRaw = [];
let galleryAssetsLayoutRaw = [];
let galleryAssetsGeneratorsRaw = [];
let galleryAssetsGamesRaw = [];
let galleryAssetsAdsRaw = [];
let galleryAssetsWantedRaw = [];
let galleryAssetsNewsRaw = [];
let galleryAssetsBookArticlesRaw = [];
let galleryAssetsPhotosRaw = [];
let galleryAssetsPrintRaw = [];

try {
  galleryAssetsFontsRaw = importAll(
    (require as any).context("./assets-fonts", false, /\.(png|jpe?g|svg)$/)
  );
  galleryAssetsLayoutRaw = importAll(
    (require as any).context("./assets-layout", false, /\.(png|jpe?g|svg)$/)
  );
  galleryAssetsGeneratorsRaw = importAll(
    (require as any).context(
      "./assets-generators",
      false,
      /\.(png|jpe?g|svg|gif)$/
    )
  );
  galleryAssetsGamesRaw = importAll(
    (require as any).context("./assets-games", false, /\.(png|jpe?g|svg|gif)$/)
  );
  galleryAssetsAdsRaw = importAll(
    (require as any).context("./assets-ads", false, /\.(png|jpe?g|svg|gif)$/)
  );
  galleryAssetsNewsRaw = importAll(
    (require as any).context("./assets-news", false, /\.(png|jpe?g|svg|gif)$/)
  );
  galleryAssetsWantedRaw = importAll(
    (require as any).context("./assets-wanted", false, /\.(png|jpe?g|svg|gif)$/)
  );
  galleryAssetsBookArticlesRaw = importAll(
    (require as any).context(
      "./assets-book-articles",
      false,
      /\.(png|jpe?g|svg|gif)$/
    )
  );
  galleryAssetsPhotosRaw = importAll(
    (require as any).context("./assets-photos", false, /\.(png|jpe?g|svg|gif)$/)
  );
  galleryAssetsPrintRaw = importAll(
    (require as any).context("./assets-print", false, /\.(png|jpe?g|svg|gif)$/)
  );
} catch (e) {
  console.warn(e);
}

const DailyProphetMakingOf = (props: { gallery: any[]; video: any }) => {
  const galleryAssetsFonts = transformImages(galleryAssetsFontsRaw);
  const galleryAssetsLayout = transformImages(galleryAssetsLayoutRaw);
  const galleryAssetsGenerators = transformImages(galleryAssetsGeneratorsRaw);
  const galleryAssetsGames = transformImages(galleryAssetsGamesRaw);
  const galleryAssetsAds = transformImages(galleryAssetsAdsRaw);
  const galleryAssetsWanted = transformImages(galleryAssetsWantedRaw);
  const galleryAssetsNews = transformImages(galleryAssetsNewsRaw);
  const galleryAssetsBookArticles = transformImages(
    galleryAssetsBookArticlesRaw
  );
  const galleryAssetsPhotos = transformImages(galleryAssetsPhotosRaw);
  const galleryAssetsPrint = transformImages(galleryAssetsPrintRaw);

  return (
    <>
      <ReplicaParagraph>
        A fully readable newspaper that mimics the original prop that can be
        seen in the Harry Potter films. It is designed to be fully immersive,
        and as such it can be read from start to finish without breaking the
        illusion of being a genuine newspaper - from relevant articles and
        advertisements to weather forecast, games and much more. While the
        replica follows the look of the original prop, 95% of the contents,
        articles and pages had to be completely made up/designed by me.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={props.gallery} size={GallerySize.L} />
      <ReplicaSpacer />
      <YouTubeVideo src={props.video} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The newspapers are often times shown only very briefly in the films,
        sometimes all I had was a glimpse of the first page. I had to write
        articles that would match the headlines and the length of the text to
        fit the original designs, because the original texts were mostly just a
        few copy-pasted sentences with no relevance to the newspaper itself. I
        then had to extrapolate from what was shown and invent the rest of the
        newspaper that would fit the same design patterns and that would feature
        headlines and articles relevant to the plot and to the wizarding world
        at the time of the newspaper making its appearance in the story. When it
        was relevant, I also tried to include headlines, photos and articles
        that are specifically mentioned in the Harry Potter books.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaParagraph>
        After an extensive research into all the various versions and issues of
        The Daily Prophet, I have decided to start by creating an asset library
        of all the elements that occur in the newspapers. While this took months
        to achieve, I greatly benefited from it later when designing the
        individual issues of The Daily Prophet, because I had all the elements
        ready to pick and choose from.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Fonts" subtitle="Asset Library" />

      <ReplicaParagraph>
        One of the most difficult things to get right were the fonts. The
        original props do not rely on a handful of fonts, but on a quite a large
        number of them. Perhaps the most challenging part of the whole process
        was identifying and acquiring all the fonts that were used. Not only for
        the headlines and articles, but also fonts used in the advertisements,
        games, etc. Some of the fonts are also quite expensive, which could have
        easily ballooned up my "font budget" sky high. To avoid this, I
        carefully considered how often the font appears in The Daily Prophet,
        and if it was not enough to be reasonable to buy it, I either tried to
        find a free font that was similar and tweaked it a bit, or I recreated
        either the whole font or just the word I was looking for myself. This
        was often the case for all the various advertisements, which often times
        used a font for just one or a few words. In the end, I acquired{" "}
        <strong>over 200 fonts</strong>.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsFonts} size={GallerySize.M} />

      <ReplicaSubTitleAnchor title="Layout Elements" subtitle="Asset Library" />

      <ReplicaParagraph>
        In order to create all the different versions of The Daily Prophet, I
        studied all the original props/designs, and I identified parts of the
        layout that are present in each issue of The Daily Prophet. These
        included things such as The Daily Prophet logo and title with gold
        leafed letters, for example. It is perhaps important to note that the
        design of The Daily Prophet evolved throughout the films. The most
        notable changes occurred between the Chamber of Secrets (the second
        film) and the Order of the Phoenix (the fifth film). The last few films
        used a very similar design with fewer differences, and the general
        layout elements mostly stayed the same - such as The Daily Prophet logo.
        Here is an example to better illustrate the evolution of the style (all
        the designs depicted were reproduced by me):
      </ReplicaParagraph>

      <Gallery images={galleryAssetsLayout} />

      <ReplicaParagraph>
        The layout elements also included all the differently styled borders,
        lines and "filters" (for lack of a better word) to distort large black
        areas, common headlines, such as "Breaking News", and other elements
        such as alchemy symbols and much more. It was very important to
        distinguish the assets in my library based on the evolution of The Daily
        Prophet to which they belong. This made it easy to avoid using an
        element that belongs to the design style of the Order of the Phoenix in
        a newspaper that was designed for the Prisoner of Azkaban, for example.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor
        title="Asset Generators"
        subtitle="Asset Library"
      />

      <ReplicaParagraph>
        There are many kinds of elements that are featured throughout the
        individual issues of The Daily Prophet that are the same element in
        nature, but should be unique for each issue. Let's explain what I mean
        by taking the weather forecast as an example. I noticed that the
        original props often reused parts of the layout, meaning that different
        issues of The Daily Prophet often had the same weather etc. In order to
        make each issue of my replicas more grounded in reality, I have decided
        to departure from the originals here and give each issue of The Daily
        Prophet its own brand new and unique information. Since I knew that this
        was something that I was going to be using for all the issues of The
        Daily Prophet that I ever decide to create, I made the decision to do
        everything the hard way - with the expectation that it will pay off in
        the long run (it did).
      </ReplicaParagraph>

      <ReplicaParagraph>
        While I could have designed a weather map and update it for each issue,
        the programmer in me chose not to. Instead, I have written a generator
        that was able to give me a limitless number of different weather maps,
        all with the correct design and no additional work required. Thanks to
        this, I was able to then create 50 random weather maps in a matter of
        seconds. Later, every time I was working on a specific issue of The
        Daily Prophet, I simply picked a weather map I liked and used it without
        any additional effort needed. I applied the same principle for virtually
        any element of such nature that occurs in The Daily Prophet, such as all
        the different tokens, games (more on that later), lottery and similar.
        All of these variations were generated with zero additional effort and
        in extremely high quality:
      </ReplicaParagraph>

      <Gallery images={galleryAssetsGenerators} />

      <ReplicaSubTitleAnchor title="Games" subtitle="Asset Library" />

      <ReplicaParagraph>
        The Daily Prophet features a lot of different games, which are in nature
        very similar to what I explained above about "asset generators". In
        short, they work on the very same principle - meaning that I have
        created a generator for each individual game. However, since these
        generators were unbelievably more complex than the previous ones, and I
        wanted to talk a bit about the games, I have decided to put them in
        their own sub-section.
      </ReplicaParagraph>

      <ReplicaParagraph>
        So, what's the problem with these generators? The difficulty lies in the
        nature of the final asset = a game. The games that were invented for The
        Daily Prophet were not invented with a set of rules (and solving the
        games would most likely involve magic). As such, they cannot be solved
        in the "muggle world". While the lack of rules made me free to do
        whatever I wanted, it also created an obstacle - how do I create a
        random game, without a set of rules, that still looks like the actual
        game? With soooo much trouble, it turns out... especially crosswords of
        different shapes. The process of creating these game generators included
        a lot of experimenting and tweaking the formula (sometimes even starting
        again from scratch), but in the end, I was able to generate random games
        of each kind, without any further problems.
      </ReplicaParagraph>

      <ReplicaParagraph>
        Some of you reading this might have a keen eye for detail and notice
        that there sometimes might be slight differences between the games
        featured in the original props and the games in my replicas. This is a
        deliberate departure from the originals in the pursuit of my goal to
        make each newspaper unique. I'm talking mostly about the various
        crossword puzzles, where you might notice that my replicas can sometimes
        have the same overall shape of the puzzle, but different black-and-white
        fields of the crossword. Why? I noticed that some of the games were
        reused throughout the props, and while I always included the original
        design of the game the first time it appeared, I opted for using a
        different design whenever I encountered it the second time, meaning that
        two issues of The Daily Prophet would otherwise have two identical
        crosswords.
      </ReplicaParagraph>

      <ReplicaParagraph>
        I also wanted to mention that while I replicated all the games that I
        found throughout the different issues of The Daily Prophet, I also
        wanted to give it a try and design one myself. I call it "Find the
        Treasure", and it's basically a circular maze puzzle with a bit of a
        magical twist. Here are some examples of the games I created with my
        supa-smart generators (the "Find the Treasure" game invented by me is
        the first one on the left):
      </ReplicaParagraph>

      <Gallery images={galleryAssetsGames} />

      <ReplicaSubTitleAnchor title="Advertisements" subtitle="Asset Library" />

      <ReplicaParagraph>
        I guess not a lot of people are particularly fond of advertisements, but
        the ones featured in The Daily Prophet are actually quite a lot of fun!
        Fun to look at, at least, because this part of creating the asset
        library was quite tedious. Basically every advertisement uses a few
        different fonts, often times only for one word, making it quite
        difficult to find the original font. If that turned out to be
        impossible, after hours and hours of searching, I would sometimes
        recreate the font/word manually. The same goes for basically all of the
        images used to create these ads. In total, I must have spent months
        looking through old books and google images, trying to find the original
        artwork that was used to create the advertisement in question. This,
        too, often times ended up with me manually recreating the artwork
        myself.
      </ReplicaParagraph>

      <ReplicaParagraph>
        I already mentioned that The Daily Prophet evolved throughout the years,
        which is something that the advertisement designs also had to
        accommodate for. Based on my research, it seems that The Daily Prophets
        from the first four films did not rely heavily on the use of adverts to
        fill the pages with something visually interesting. After I had finished
        putting together all the advertisements from the later films, I then
        came back and redesigned a few of them to fit the design styles of the
        previous films, so I could use them when working on The Daily Prophets
        from this era. I also designed a few adverts myself, but I can't
        actually remember if I ever used them in any of the Daily Prophet
        replicas I made. I also took some of the advertisements that announced
        competitions with prizes and created a few ads with the similar design
        offering different prizes, different competitions etc. All in all, I
        painstakingly (re)created{" "}
        <strong>over 120 different advertisements</strong>.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsAds} />

      <ReplicaSubTitleAnchor
        title="Wanted Posters / Rewards"
        subtitle="Asset Library"
      />

      <ReplicaParagraph>
        At a certain point of the story, The Daily Prophet started to feature
        wanted posters of the witches and wizards that the Ministry of Magic was
        looking for. The very first wanted poster appeared in The Prisoner of
        Azkaban, when the Ministry was looking for Sirius Black. Although we
        cannot see the inside of any of the issues of The Daily Prophet that
        appear in the Prisoner of Azkaban, the later films establish that wanted
        posters are sometimes featured in The Daily Prophet. I assumed that the
        search for Black was important enough to warrant such a thing as well,
        so I made the decision to put Black's wanted poster into some of the
        replicas that are from the time period when The Ministry of Magic was
        still looking for Black. That's a long way of saying that I had to
        create the wanted poster as well. And not just for Sirius Black, I
        created a wanted poster for almost every Death Eater as well. I
        replicated the ones that actually exist in the films (such as Bellatrix
        Lestrange or Alecto Carrow), and designed the ones that do not (e.g.
        Augustus Rookwood, Rabastan and Rodolphus Lestrange etc.). For the
        issues of The Daily Prophet from the Order of the Phoenix, I also
        updated the Sirius Black wanted poster to match the new ones that were
        originally designed for the Death Eaters.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsWanted} />

      <ReplicaSubTitleAnchor
        title="Readable and Relevant News"
        subtitle="Newspaper Content"
      />

      <ReplicaParagraph>
        Once I had the asset library ready (or semi-ready, I had to go back a
        few times and expand it even more), it was finally time for the fun
        stuff - creating replicas of the actual props. For this part of the
        process, I first tried to gather as much reference images as possible.
        This proved to be a difficult task, because there are tons of images
        that depict fan-made replicas instead of the "real thing", and they vary
        in quality. Since my goal was to replicate the originals, I wanted to
        avoid accidentally using a fan-made replica as a reference point for my
        replicas. This meant that I mostly limited my references to those
        sources that could be relied upon = the films, prints offered by the
        original designers of the Harry Potter props, and images of the props
        from different expos.
      </ReplicaParagraph>

      <ReplicaParagraph>
        My goal was to replicate everything that could be seen in the films and
        invent the rest to turn it into a full-fledged newspaper. I noticed (if
        I'm not mistaken), that the original props reused a lot of pages (or
        parts of pages) inside the props, which is something I wanted to avoid.
        I don't mean this as a critique of the originals, I'm aware that these
        are movie props and designing an entire newspaper for a scene where only
        the front page is briefly in the shot is just nonsense (and no studio
        would pay for that), so I'm not trying to put down the originals by
        mentioning this. This means that while there are sometimes pictures of
        the inside of the original props, I did not take these as something that
        was set in stone. I tried to include those designs in my replicas when
        possible, but only if it meant that the replicas will still have a
        unique content - if the same design appeared in multiple props, I did
        not copy-paste them. In short, only the things that were explicitly
        shown in the films were considered unchangeable.
      </ReplicaParagraph>

      <ReplicaParagraph>
        When working on the individual issues of The Daily Prophet, I first had
        to replicate all the parts that can be seen in the films, and then
        invent the rest. Mind you, replicating the film designs was also not as
        straightforward as it may seem. The texts of the original props are
        mostly unreadable in the films, but if you take a look at some of the
        high-def images of the props, it is clear that the text is not relevant
        to the plot or the headlines on the page. From what I was able to read,
        it seems to be a copy-pasted article from the Goblet of Fire. You can
        clearly make out words such as 'Defence Against the Dark Arts' etc.,
        mentioning Mad-Eye Moody and Hagrid's Blast-Ended Skrewts. Again, this
        is absolutely expected of a movie prop, because inventing hundreds of
        articles that the viewer won't even be able to read is just not viable.
        I like the fact that they used an actual article from the books, instead
        of Lorem Ipsum, meaning that at first glance, without actually reading
        the article, it seems to be a genuine report. However, my goal was to
        create an "immersive replica" that would be able to hold the illusion
        even upon closer inspection. This meant that I had to invent my own
        texts of the articles, that would not only fit the headlines
        accompanying them, but also the length and spacing of the original text.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsNews} />

      <ReplicaParagraph>
        With all the film-parts of the design ready, I then had to fill in the
        rest of the newspaper with relevant pages. To achieve this, I studied
        all the reference images I had available to establish the overall
        structure of the newspapers - where the games are, what kind of articles
        are where, how many photos are usually included, where the horoscope is,
        advertisement placement and so on. This also had to make sense with the
        list of contents on the second page of the newspaper, which lists all
        the different topics with page numbers that had to correspond with the
        actual placement of these in the final newspaper. Once all of this was
        established, I then went through the pages and made up the content. I
        tried to keep the headlines and articles a bit funny (while not going
        over-the-top), because that seems to be a thing for The Daily Prophet,
        and I also took great care to correctly put in only such information
        that would be available at the time of the particular issue being
        printed. This meant that there could be an occasional mention that
        Sirius Black is still on the loose in the issues from the Goblet of Fire
        or the Order of the Phoenix, mysterious disappearances and making fun of
        Potter and Dumbledore before it is revealed that Voldemort's return is,
        in fact, the truth, referencing past events, such as the appearance of
        the Dark Mark at the Quidditch World Cup in the later issues and much
        much more.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor
        title="Articles from the Books"
        subtitle="Newspaper Content"
      />

      <ReplicaQuote source="Harry Potter and the Goblet of Fire">
        “Listen to this:{" "}
        <strong>
          ‘If the terrified wizards and witches who waited breathlessly for news
          at the edge of the wood expected reassurance from the Ministry of
          Magic, they were sadly disappointed. A Ministry official emerged some
          time after the appearance of the Dark Mark alleging that nobody had
          been hurt, but refusing to give any more information. Whether this
          statement will be enough to quash the rumors that several bodies were
          removed from the woods an hour later, remains to be seen.’
        </strong>{" "}
        Oh really,” said Mr. Weasley in exasperation, handing the paper to
        Percy. “Nobody was hurt. What was I supposed to say?{" "}
        <strong>
          'Rumors that several bodies were removed from the woods'
        </strong>
        ... well, there certainly will be rumors now she’s printed that.”
      </ReplicaQuote>

      <ReplicaParagraph>
        While I made up the majority of the articles in my replicas of The Daily
        Prophet, I also researched each issue and whether it appeared in the
        Harry Potter books. A lot of the issues of The Daily Prophet that are
        featured in the films are relevant to important story points, and The
        Daily Prophet is sometimes described and read from in the books. Often
        times there are slight differences between the headlines in the books
        and in the films - such as 'SCENES OF TERROR AT THE QUIDDITCH WORLD CUP'
        (book) vs 'TERROR AT THE QUIDDITCH WORLD CUP' (film). In such cases the
        film design always took precedence, but I also tried to include the book
        wording of the headline somewhere inside the replica as well, as to have
        both versions there. I also included other headlines that were
        explicitly mentioned, but are missing in the film design.
      </ReplicaParagraph>

      <ReplicaParagraph>
        As for the articles, I took the text from the book and expanded it to
        create a longer article. For example, Mr. Weasley did not read the
        entire article in the quote above, he merely mentions the 'Rumors that
        several bodies were removed from the woods' part, while not reading the
        article aloud. In such cases, I had to invent the continuation of the
        article and put the mentioned sentence in there. This happened often
        with the book articles, because the characters often times do not read
        the articles, but just shout out parts of them in disbelief. By doing
        this, I wanted to achieve a replica that would look like the film prop,
        but that would also be true to the books and the articles in the story.
      </ReplicaParagraph>

      <ReplicaQuote source="Harry Potter and the Order of the Phoenix">
        TRAGIC DEMISE OF MINISTRY OF MAGIC WORKER
        <br />
        <strong>
          St. Mungo’s Hospital promised a full inquiry last night after Ministry
          of Magic worker Broderick Bode, 49, was discovered dead in his bed,
          strangled by a potted-plant. Healers called to the scene were unable
          to revive Mr. Bode, who had been injured in a workplace accident some
          weeks prior to his death. Healer Miriam Strout, who was in charge of
          Mr. Bode’s ward at the time of the incident, has been suspended on
          full pay and was unavailable for comment yesterday, but a spokeswizard
          for the hospital said in a statement, “St. Mungo’s deeply regrets the
          death of Mr. Bode, whose health was improving steadily prior to this
          tragic accident. “We have strict guidelines on the decorations
          permitted on our wards but it appears that Healer Strout, busy over
          the Christmas period, overlooked the dangers of the plant on Mr.
          Bode’s bedside table. As his speech and mobility improved, Healer
          Strout encouraged Mr. Bode to look after the plant himself, unaware
          that it was not an innocent Flitterbloom, but a cutting of Devil’s
          Snare, which, when touched by the convalescent Mr. Bode, throttled him
          instantly. “St. Mungo’s is as yet unable to account for the presence
          of the plant on the ward and asks any witch or wizard with information
          to come forward.”
        </strong>
        <br />
        “Bode . . .” said Ron. “Bode. It rings a bell. . . .” “We saw him,”
        Hermione whispered. “In St. Mungo’s, remember? He was in the bed
        opposite Lockhart’s, just lying there, staring at the ceiling.
      </ReplicaQuote>

      <ReplicaParagraph>
        The TRAGIC DEMISE OF MINISTRY OF MAGIC WORKER article quoted above is
        yet another example of an article that is important to the story in the
        books, but is never mentioned in the films. When I was creating this
        specific issue of The Daily Prophet, I made sure to include it as well.
        You can see it in the second example below (both of these were designed
        by me from scratch):
      </ReplicaParagraph>

      <Gallery images={galleryAssetsBookArticles} />

      <ReplicaSubTitleAnchor title="Photos" subtitle="Newspaper Content" />

      <ReplicaParagraph>
        There are quite a few photos featured in The Daily Prophet throughout
        the films. While there were a few times when I could find the original
        photo that the artists used to create the photo for the newspaper prop,
        this was very often not the case. I usually had to improvise and
        reconstruct the photos myself, sometimes from more than five other
        images, adding a hat or something similar to a photo, or changing the
        perspective of the image I found to fit the photo in the film, etc. I
        also designed photos for the articles and headlines that I invented to
        fill the pages. These often times required tweaks to remove modern
        devices and similar things from pre-existing photos, or constructing
        whole new scenes from different assets, creating a fire in the shape of
        a serpent for an article about Fiendfyre destroying a building and
        similar.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsPhotos} />

      <ReplicaSubTitleAnchor
        title="Printed In Full Size and Manually Aged"
        subtitle="Final Replicas"
      />

      <ReplicaParagraph>
        Once the designing part of the process was finished, it was time to
        finally print the pages, manually age each one and put them together to
        produce the final Daily Prophet replica. The replicas do not have any
        glued seams, everything is printed on a large format printer as one
        piece. After going through my reference pictures, I noticed that the
        edges (top and bottom) of The Daily Prophet were sometimes not cut in a
        straight line. If you take a look at the production of real-life
        newspapers, they too are not cut in a straight line. This is done by
        design to accommodate the fibers of the paper and get a cleaner cut
        without tearing the paper. This detail is often omitted from the props,
        but there are pictures to be found where the edges are not cut straight,
        and I've decided to include that in my replicas to give them a more
        realistic look. However, while the real-life newspapers are cut in a
        zig-zag line, the reference images of The Daily Prophets with this
        detail seem to be a blend of semi-cut and torn edges. It was difficult
        to find a reliable way of reproducing this kind of cut, but in the end I
        was able to develop my supa-secret formula to achieve just that. The
        final step was adding gold accents and final distressing of the
        newspaper and the replica was finished.
      </ReplicaParagraph>

      <Gallery images={galleryAssetsPrint} />
    </>
  );
};

export default DailyProphetMakingOf;
