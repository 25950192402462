import { ProductContentsType, ProductTextType } from "types";

import IMG_Clamps from "./SW-Printable-Graflex-Flashgun/info/Clamps.png";
import { Link } from "react-router-dom";
import LukeEp4LightsaberPreview from "./SW-Printable-Lightsaber-Luke-Ep4/preview.png";
import LukeEp5LightsaberPreview from "./SW-Printable-Lightsaber-Luke-Ep5/preview.png";
import LukeEp6LightsaberPreview_Hero from "./SW-Printable-Lightsaber-Luke-Ep6-hero/preview.png";
import LukeEp6LightsaberPreview_V2 from "./SW-Printable-Lightsaber-Luke-Ep6-v2/preview.png";
import LukeEp6LightsaberPreview_V3 from "./SW-Printable-Lightsaber-Luke-Ep6-v3/preview.png";
import anakinEp3LightsaberPreview from "./SW-Printable-Lightsaber-Anakin-Ep3/preview.png";
import fileFormats from "assets/misc/fileFormats";
import obiwanEp3LightsaberPreview from "./SW-Printable-Lightsaber-Obi-Wan-Ep3/preview.png";
import obiwanEp4LightsaberPreview from "./SW-Printable-Lightsaber-Obi-Wan-Ep4/preview.png";
import vaderEp4LightsaberPreview from "./SW-Printable-Lightsaber-Vader-Ep4/preview.png";
import vaderEp5LightsaberPreview from "./SW-Printable-Lightsaber-Vader-Ep5/preview.png";
import vaderEp6LightsaberPreview from "./SW-Printable-Lightsaber-Vader-Ep6/preview.png";

const SHARED_PRINTABLE_INFO: {
  prices: {
    lightsaber: number;
    lightsaberPartLarge: number;
  };
  contents: ProductContentsType[];
  tags: string[];
  texts: {
    graflexClampLogo: ProductTextType;
    lukeEp4Ep5Lightsabers: ProductTextType;
    lukeEp6Lightsabers: ProductTextType;
    vaderEp4to6Lightsabers: ProductTextType;
    obiwanEp3Ep4Lightsabers: ProductTextType;
    anakinEp3Ep4Lightsabers: ProductTextType;
  };
  getPropDescription: (propName: string) => string;
  getDiagramDescription: () => string;
} = {
  prices: {
    lightsaber: 12,
    lightsaberPartLarge: 10,
  },
  contents: [
    {
      text: "3D model (assembled)",
      format: fileFormats.stl,
    },
    {
      text: "3D models (individual easy-to-print pieces)",
      format: fileFormats.stl,
    },
    {
      text: "About",
      isDivider: true,
    },
    { text: "The 3D model is divided into multiple easy-to-print pieces." },
    { text: "Refer to the Assembly Diagram above for more information." },
    { text: "Optimized for easy 3D printing." },
    { text: "Some parts may require glue for assembly." },
  ],

  tags: ["3D Printable"],

  texts: {
    graflexClampLogo: [
      {
        type: "node",
        node: (
          <>
            The 3D model includes two versions of the Graflex clamp body. One
            version includes the Graflex logo, while the other does not. It is
            not necessary to print both versions to assemble the prop; you only
            need one. You can choose whether to print the version with or
            without the logo:
          </>
        ),
      },
      {
        type: "image",
        image: IMG_Clamps,
      },
    ],

    lukeEp4Ep5Lightsabers: [
      {
        type: "node",
        node: (
          <>
            At first glance, Luke's lightsaber from Episode 4 and Episode 5 may
            look identical, but there are quite a few differences. Both of the
            lightsabers are based on a{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Graflex-Flashgun"
            >
              Graflex flashgun
            </Link>
            , which is why they look similar (and they should - canonically it
            is still the same hilt in both of the movies). The differences are
            in the greeblies. You may notice that the clamp card, T-tracks and
            the D-ring is completely different on each version of the
            lightsaber. On top of that, the Graflex clamp is rotated into a
            different position in Episode 5, the "beer tab" at the top has been
            removed and the large button at the top has been added to the bottom
            of the lightsaber as well, replacing the original glass eye.
          </>
        ),
      },

      /* LUKE EP4 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep4"
            >
              Episode 4
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp4LightsaberPreview,
      },

      /* LUKE EP5 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep5"
            >
              Episode 5
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp5LightsaberPreview,
      },
    ],

    lukeEp6Lightsabers: [
      {
        type: "node",
        node: (
          <>
            There are three versions of Luke's lightsaber associated with
            Episode 6, so it is helpful to describe the differences to help you
            decide which version you're looking for.
          </>
        ),
      },

      /* LUKE EP6 LIGHTSABER - V2 */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep6-V2"
            >
              'Vee-Two'
            </Link>
          </>
        ),
      },
      {
        type: "node",
        node: (
          <>
            This is the lightsaber prop that perhaps has the most screen time in
            Episode 6. Notice that it is quite different from the 'Hero' version
            of the prop and very similar to Obi-Wan's lightsaber from Episode 4.
            This is no accident, as rumor has it that when they were filming
            Episode 6, it was noted that Luke lost his{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep5"
            >
              Graflex lightsaber
            </Link>{" "}
            in Episode 5. As a result, they ended up using one of the stunt
            sabers originally used for Obi-Wan in Episode 4. Since this was just
            a stunt saber, there are still quite a few differences between this
            saber and{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Obi-Wan-Ep4"
            >
              Obi-Wan's Ep4 hero prop
            </Link>
            , which was assembled from real-life parts that were too complex to
            duplicate for a stunt saber."
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp6LightsaberPreview_V2,
      },

      /* LUKE EP6 LIGHTSABER - HERO */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep6-Hero"
            >
              'Hero'
            </Link>
          </>
        ),
      },
      {
        type: "node",
        node: (
          <>
            After the filming of Episode 6 wrapped up, the official hero prop
            was designed (as far as I know) and later inserted into the film in
            a single close-up shot when Vader is given Luke's lightsaber after
            his surrender. The prop in that shot is still not the final hero
            version, but it is quite close. The 'Hero' version is the final
            iteration of the design. The main difference is that it no longer
            uses a Graflex clamp and has a custom control box instead.
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp6LightsaberPreview_Hero,
      },

      /* LUKE EP6 LIGHTSABER - V3 */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep6-V3"
            >
              'Vee-Three'
            </Link>
          </>
        ),
      },
      {
        type: "node",
        node: (
          <>
            The history behind this version is quite a mystery. From what I was
            able to gather, after the production of Episode 6 was finished,
            Luke's lightsaber was needed for promotional purposes. For some
            reason, it appears they took one of the 'V2' aluminum castings and
            painted it in the same color scheme as the hero prop. Notice that
            this version still uses a Graflex clamp and, additionally, has holes
            drilled into it. It is believed these holes served as access points
            for screws holding the motor of the original motorized stunt hilt,
            which had a spinning blade.
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp6LightsaberPreview_V3,
      },
    ],

    vaderEp4to6Lightsabers: [
      {
        type: "node",
        node: (
          <>
            At first glance, Vader's lightsaber from Episode 4 to Episode 6 may
            look identical, but there are quite a few differences. The
            lightsabers from Ep4 and Ep5 are based on an{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-MPP-Flashgun"
            >
              MPP flashgun
            </Link>
            , which is why they look similar (and they should - canonically it
            is still the same hilt in both of the movies). The differences are
            in the greeblies. The hilt from Episode 5 has additional wires
            added, the T-tracks are a bit longer, and apparently, the clamp
            lever broke off at some point.
          </>
        ),
      },

      /* VADER EP4 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Vader-Ep4"
            >
              Episode 4
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: vaderEp4LightsaberPreview,
      },

      /* VADER EP5 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Vader-Ep5"
            >
              Episode 5
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: vaderEp5LightsaberPreview,
      },

      /* VADER EP6 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Vader-Ep6"
            >
              Episode 6
            </Link>
          </>
        ),
      },
      {
        type: "node",
        node: (
          <>
            The lightsaber from Episode 6, however, is completely different.
            From what I gathered, the original props were discarded or lost
            after the filming of Episode 5. For Episode 6, the prop makers took
            one of the old{" "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Graflex-Flashgun"
            >
              Graflex-based
            </Link>{" "}
            hilts and heavily modified it to resemble Vader's canonical hilt.
            There is no point in trying to describe all the differences because
            it is simply a completely different hilt altogether compared to the
            previous two.
          </>
        ),
      },
      {
        type: "image",
        image: vaderEp6LightsaberPreview,
      },
    ],

    obiwanEp3Ep4Lightsabers: [
      {
        type: "node",
        node: (
          <>
            At first glance, Obi-Wan's lightsaber from Episode 3 and Episode 4
            may look identical, but there are quite a few differences. In fact,
            the two hilts are completely different. The original hero prop for
            Obi-Wan in Episode 4 was assembled from real-life parts, while the
            hilt for Episode 3 is an idealized version of the original design
            and was manufactured specifically for the film.
          </>
        ),
      },

      /* OBI-WAN EP3 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Obi-Wan-Ep3"
            >
              Episode 3
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: obiwanEp3LightsaberPreview,
      },

      /* OBI-WAN EP4 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Obi-Wan-Ep4"
            >
              Episode 4
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: obiwanEp4LightsaberPreview,
      },
    ],

    anakinEp3Ep4Lightsabers: [
      {
        type: "node",
        node: (
          <>
            Canonically, the lightsaber that Luke receives in Episode 4 is the
            very same hilt that Anakin wields in Episode 3. At first glance,
            they may look identical, but there are quite a few differences. In
            fact, the two hilts are completely different. The original hero prop
            for Luke in Episode 4 was assembled from real-life parts, while the
            hilt for Episode 3 is an idealized version of the original design
            and was manufactured specifically for the film.
          </>
        ),
      },

      /* OBI-WAN EP3 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Anakin-Ep3"
            >
              Episode 3
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: anakinEp3LightsaberPreview,
      },

      /* OBI-WAN EP4 LIGHTSABER */
      {
        type: "node-title",
        node: (
          <>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/e-shop/Printable-Lightsaber-Luke-Ep4"
            >
              Episode 4
            </Link>
          </>
        ),
      },
      {
        type: "image",
        image: LukeEp4LightsaberPreview,
      },
    ],
  },

  getPropDescription: (propName: string) => {
    return `This 3D model features ${propName} and has been specifically designed for 3D printing. The goal was to create a model that is as accurate as possible to the original prop while being relatively easy to print.`;
  },
  getDiagramDescription: () => {
    return `Feel free to explore the assembly diagram below to see how to assemble the prop. All part names correspond to the names of the .stl files, making it easier for you to assemble everything without needing to figure out where each part goes.`;
  },
};

export default SHARED_PRINTABLE_INFO;
