import { paypal, routes } from "config";

import { Link } from "react-router-dom";

export const purchaseProcess: any = (
  <ol>
    <li>
      Pick a product.
      <ul>
        <li>Go to the product page and click on the 'Add to Cart' button.</li>
        <li>
          New buttons will appear - one that allows you to remove the product
          from the cart, and the other that will take you to the checkout page.
          Alternatively, you can click on the cart icon in the top menu.
        </li>
      </ul>
    </li>

    <li>
      Proceed to checkout.
      <ul>
        <li>
          Check that the products in the cart are the ones you want to purchase.
        </li>
        <li>
          Check the total amount - this is the final price you will pay. Please
          note that the PayPal transaction fees are calculated (
          {paypal.fees.fixedFee}
          {paypal.currency} + {paypal.fees.percentageFee}%) and included in the
          final price.
        </li>
        <li>
          Confirm that you agree with the{" "}
          <Link to={routes.terms.slug} target="_blank">
            Terms and Conditions
          </Link>
          .
        </li>
        <li>Click on the PayPal button to proceed with the payment.</li>
      </ul>
    </li>

    <li>
      PayPal Checkout.
      <ul>
        <li>
          The entire transaction is secured by PayPal checkout - I do not have
          access to the information you provide here; this is 100% PayPal.
        </li>
        <li>Check that the items you're paying for are correct.</li>
        <li>
          At any point, you can close the window to cancel the payment before
          you send the funds.
        </li>
        <li>Complete the payment.</li>
      </ul>
    </li>

    <li>
      Download your products.
      <ul>
        <li>
          After the payment is processed, you will be redirected to the{" "}
          <Link to={routes.myDownloads.slug} target="_blank">
            My Downloads
          </Link>{" "}
          page, where your purchased products will be available for instant
          download.
        </li>
        <li>
          The list of orders and products displayed there is temporary and
          accessible only from the browser and device you used for the purchase.
          The list may disappear in the future, so please download your products
          as soon as possible.
        </li>
      </ul>
    </li>
  </ol>
);
