import { css } from 'styled-components'

//
// Default Styles
//
const imgSelectionCss = css`
  background: ${props => props.theme.white}66;
`

export const clickableImageCss = css`
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`

export const defaultImages = css`
  img {
    ::-moz-selection {
      ${imgSelectionCss}
    }
    ::selection {
      ${imgSelectionCss}
    }
    ::-webkit-selection {
      ${imgSelectionCss}
    }
  }

  img.clickable {
    ${clickableImageCss}
  }
`
