import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "bilbos-map-of-middle-earth",
  name: `Bilbo's Map of Middle Earth`,
  origin: "The Lord of The Rings: The Fellowship of the Ring",

  category: replicaCategories.lotr,

  versions: [Version1],
};

export default data;
