import LayoutPage from "layouts/LayoutPage";
import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: black;
  color: ${(props) => props.theme.white}88;
`;

const Title = styled.h2`
  color: #3b7bbf;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 200px;
  background: none;
  outline: none;
  padding: 10px;
  font-size: 24px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.white}22;
  color: ${(props) => props.theme.white}88;
`;

const FinalPrice = styled.div`
  margin-top: 20px;
  font-size: 30px;
  text-align: center;
  color: ${(props) => props.theme.white};
`;

const PayPalCalculatorPage = (props) => {
  const PAYPAL_FEE_STATIC_USD = 0.49;
  const PAYPAL_FEE_DYNAMIC_PERCENTAGE = 4.9;

  const [shipping, setShipping] = useState(25);
  const [price, setPrice] = useState(0);

  return (
    <LayoutPage>
      <Wrapper>
        <Title>{`Paypal Calculator`}</Title>

        <InputWrapper>
          <Label>Shipping (USD):</Label>
          <Input
            type="number"
            value={shipping}
            onChange={(e) => setShipping(parseInt(e.target?.value))}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>Price (USD):</Label>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(parseInt(e.target?.value))}
          />
        </InputWrapper>

        <InputWrapper>
          <FinalPrice>
            {Math.ceil(
              ((shipping || 0) + (price || 0)) *
                (1 + PAYPAL_FEE_DYNAMIC_PERCENTAGE / 100) +
                PAYPAL_FEE_STATIC_USD
            )}
            {" USD"}
          </FinalPrice>
        </InputWrapper>
      </Wrapper>
    </LayoutPage>
  );
};

export default PayPalCalculatorPage;
