import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import { Link } from "react-router-dom";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule_2.jpg";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Content = styled.div`
  width: 90%;
  max-width: 800px;
  text-align: justify;
  ${(props) => props.theme.breakpoint.S} {
    text-align: left;
  }
`;

const License = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background} backgroundOpacity={0.7}>
      <Wrapper>
        <SectionHeading
          title="License"
          subtitle={["What can I do with the product?", "What is not allowed?"]}
        />

        <Spacer />
        <Content>
          <ul>
            <li>
              <strong>
                All of the products are sold under Royalty-Free license unless
                stated otherwise.
              </strong>
              <ul>
                <li>
                  You are NOT permitted to sell, trade or resell the product for
                  any purpose. You will not allow the Product, whether
                  downloaded, accessed, or otherwise obtained by you to be used
                  by any other party.
                </li>
                <li>
                  You are NOT permitted to sell a derivative work or an extended
                  work, for example:
                  <ul>
                    <li>
                      You cannot redesign my 3D models and sell them (for any
                      purpose, 3D printing included).
                    </li>
                    <li>
                      You can create your own HUD design, but you cannot sell it
                      together with my Custom HUD from the AAE HUD Factory - you
                      cannot sell anything that was created by me. If you want
                      to sell your design, you can sell the textures that you
                      created, but the HUD interface project with all the
                      rigging and compositing must be bought from me, as I'm the
                      original creator.
                    </li>
                  </ul>
                </li>
                <li>
                  A product may include 3D models, textures, imagery, software
                  projects, tutorials, animation, materials/shaders, shapes and
                  vector graphics, training materials, building components,
                  sound effects and videos.
                </li>
                <li>
                  After purchasing a product, you can use it without the need to
                  pay any additional license fees or royalties for each use of
                  the product.
                </li>
                <li>
                  You can use the purchased product for as long as you like, in
                  multiple projects, as long as you comply with the license
                  agreement.
                </li>
              </ul>
            </li>

            <li>
              <strong>
                If you purchase as an employee of a corporate entity
              </strong>
              , sharing the product with other employees of your corporate
              entity is allowed.
              <ul>
                <li>
                  You are responsible for any distribution, use, or misuse by a
                  recipient of the product.
                </li>
                <li>
                  Sharing the product with external people or entities is only
                  allowed in the following situations, and with the following
                  restrictions:
                  <ul>
                    <li>
                      In the production of a project owned by you, if you are
                      working in collaboration with external parties and there
                      is a need to share the product for the development and
                      production of your project, sharing the product with those
                      external parties is allowed. Any external party that
                      receives the product may only use it under your project
                      and must take reasonable care to secure and limit access
                      to the product to that purpose.
                    </li>
                    <li>
                      In the production of a project owned by another entity -
                      your client - if you are working as a contractor and need
                      to share the product with your client, sharing the product
                      is allowed.
                    </li>
                    <li>
                      For all other use by any party, the product must be
                      purchased again to create a new license agreement
                      governing that use.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <strong>Editorial-Use Restriction.</strong> The following
              restrictions apply to any product affixed with an 'Editorial-Use'
              notice on its product page.
              <ul>
                <li>
                  Products with this notice may depict certain subject matter,
                  such as a manufactured product under a brand name, that may be
                  protected by another party's intellectual property rights.
                </li>
                <li>
                  Products published with the Editorial label may only be used
                  in an editorial manner, relating to events that are newsworthy
                  or of public interest, and may not be used for any commercial,
                  promotional, advertising or merchandising use.
                </li>
                <li>
                  This restriction does not apply if you have the needed
                  authorization to use the intellectual property for your
                  project, such as if you are the intellectual property owner
                  yourself, or an intellectual property owner's advertising
                  team, hired party, or licensee purchasing that company's
                  product.
                </li>
              </ul>
            </li>

            <li>
              <strong>Permitted uses of the product:</strong>
              <ul>
                <li>
                  You may use the product within film, movies, television, news,
                  video projects, computer games, pre-visualizations, research,
                  advertising, social media, website designs, wallpapes and
                  similar. If you have a specific project that needs approval,{" "}
                  <Link to={routes.contact}>contact me via email</Link>, please.
                </li>
                <li>
                  You may buy a product and use it with as many clients as you
                  want.
                </li>
                <li>
                  If you need to give the product files to a client, you need to
                  purchase a license for each client that gets the product files
                  (as if they bought the product from me themselves).
                </li>
                <li>
                  You are allowed to modify the products, but keep in mind that
                  no matter how much you modify a product, it is still subject
                  to the rules of this license.
                </li>
              </ul>
            </li>

            <li>
              <strong>Restrictions on permitted uses of the product:</strong>
              <ul>
                <li>
                  You may NOT redistribute my materials as part of any design
                  template, After Effects template, stock photography, video or
                  clip art for distribution or licensing through any online
                  stock media website.
                </li>
                <li>
                  You may NOT import, upload, reproduce, make available,
                  publish, transmit, distribute or sublicense any of the
                  products within any virtual goods.
                </li>
                <li>
                  You may NOT publish or distribute the product through another
                  website, for example as a part of a design template.
                </li>
                <li>
                  Group buying is NOT permitted. You may NOT aggregate funds to
                  purchase a product with one or more parties, each member must
                  purchase individually.
                </li>
                <li>
                  Images used for the promotion of the products, such as the
                  images on the product's page or preview videos on youtube (or
                  similar sites) cannot be used for your own projects.
                </li>
                <li>
                  You may include the product in your computer game, virtual
                  worlds, simulations and computer applications, but the
                  inclusion of the product within any such project is limited to
                  uses where the product is contained in an interactive
                  experience for the user and not made available outside of the
                  project. For example, you can include a 3D model as a playable
                  character inside a game, but not as a separate 3D model that
                  can be accessed outside the game.
                </li>
                <li>
                  You must take all reasonable standard measures to prevent
                  other parties from gaining access to the product. It must be
                  contained in proprietary formats so that it cannot be opened
                  in a publicly available software or framework, or extracted
                  without reverse engineering.
                </li>
                <li>
                  You may NOT include the product in projects that have the
                  general functionality for importing and/or exporting the
                  product. An example of such a prohibited use is to include the
                  product in a library withing a project.
                </li>
                <li>
                  You may use the products to make physical creations such as 3D
                  printed works. Such use is limited to personal use and gifts.
                </li>
                <li>
                  It is NOT allowed to buy a 3D model and then resell 3D prints
                  of that model.
                </li>
                <li>
                  You may NOT use the product for any harassing, pornographic,
                  obscene or racist purpose, or to infringe any party's
                  intellectual property rights.
                </li>
              </ul>
            </li>

            <li>
              <strong>
                Your right and license to the product is perpetual, unless
                terminated as described below:
              </strong>
              <ul>
                <li>
                  Once the license is terminated, you and any recipients of the
                  product must cease use, distribution, and destroy all copies
                  of the product.
                </li>
                <li>
                  Any payment reversal of a purchase for any reason immediately
                  terminates all rights granted under this agreement.
                </li>
                <li>
                  Such reversal includes receiving a charge back notice from
                  your bank or credit card cancelling your purchase.
                </li>
              </ul>
            </li>
          </ul>
        </Content>

        <Spacer />

        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Continue Reading"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default License;
