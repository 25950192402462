import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "daily-prophet-hp7-dumbledores-dark-secrets-revealed",
  name: `The Daily Prophet\n'Dumbledore's Dark Secrets Revealed'`,
  origin: "Harry Potter and the Deathly Hallows",

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
