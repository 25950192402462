import { ReplicaType } from "types";
import Version1 from "./version-1";
import Version2 from "./version-2";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "deed-of-contract",
  name: `Deed of Contract`,
  origin: "The Hobbit: An Unexpected Journey",

  category: replicaCategories.lotr,

  versions: [Version2, Version1],
};

export default data;
