import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "us-declaration-of-independence",
  name: `US Declaration of Independence`,
  origin: "Historical Document replica",

  category: replicaCategories.history,

  versions: [Version1],
};

export default data;
