import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "lotr-orcrist-scabbard",
  name: `Orcrist Scabbard`,
  origin: "The Hobbit: An Unexpected Journey",

  category: replicaCategories.lotr,

  versions: [Version1],
};

export default data;
