import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import MaraudersMap from "assets/replicas/harry-potter/hp-marauders-map";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaQuote from "components/Replicas/ReplicaQuote";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let images = [];
let imagesSpells = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  images = importAll(
    (require as any).context("./images", false, /\.(png|jpe?g|svg)$/)
  );
  imagesSpells = importAll(
    (require as any).context("./imagesSpells", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 20, month: 12, year: 2019 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const imagesMapped = transformImages(images);
  const imagesSpellsMapped = transformImages(imagesSpells);

  const images1 = [...imagesMapped].splice(0, 4);
  const images2 = [...imagesMapped].splice(4, 4);
  const images3 = [...imagesMapped].splice(8, 1);
  const images4 = [...imagesMapped].splice(9, 1);
  const images5 = [...imagesMapped].splice(10, 1);
  const images6 = [...imagesMapped].splice(11, 1);
  const images7 = [...imagesMapped].splice(12, 1);
  const images8 = [...imagesMapped].splice(13, 2);
  const images9 = [...imagesMapped].splice(15, 1);
  const images10 = [...imagesMapped].splice(16, 2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Together with the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + MaraudersMap.id}
          target="_blank"
        >
          Marauder's Map
        </Link>
        , this is one of my most favourite props from the Harry Potter universe.
        However, since I always strive to create the most accurate replicas,
        replicating this book meant not only that I'd have to write a whole
        book, but also fill it with Prince's notes... which is obviously not an
        easy task, and that is the main reason I didn't even try to create this
        replica for a very long time. I did not invent every line of the book
        text myself, of course, because I simply do not have enough knowledge
        about alchemy, antidotes or about the fictional potion-making from the
        Harry Potter universe, so there was a lot of research and modifying
        information to fit the Harry Potter universe. However, I dare say I
        managed to put together a book that could pass as an actual school book
        that can be read from cover to cover.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        There are not many reference pictures online, but I managed to find the
        table of contents of the original prop and basically fill the book using
        the table of contents as an outline. The result is a book that can be
        read from cover to cover, and at the same time, it contains all the page
        designs that you can see in the movie, making it an accurate replica.
        But writing the book is just half of the process - the other half was to
        go through each page and put Prince's notes on top of it. All of these
        notes are handwritten using a graphics tablet - this allows me to have
        control over everything (color of the ink, thickness etc.), while being
        able to print the book with the same quality every single time. Since
        all the notes are handwritten and they actually make sense in the
        context of the book, this was a VERY time-consuming process. There are
        over 300 pages and each of them contains Prince's notes... I stopped
        having fun somewhere around page 20, but it was worth it.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor
        title="Based on both the movie and the book"
        anchor="v1-based-on"
      />

      <ReplicaParagraph>
        The replica is both movie and book accurate - I used the movie as a
        design reference and replicated all the pages that were shown to us.
        Like the intro page and the table of contents, for example:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images1} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        But I also wanted the replica to be book accurate as well. I went over
        the Half-Blood Prince book and took notes on what to include in my
        replica and where to put it.
      </ReplicaParagraph>

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Well, by turning to page ten of Advanced Potion-Making. We have a little
        over an hour left to us, which should be time for you to make a decent
        attempt at the Draught of Living Death.
      </ReplicaQuote>

      <ReplicaParagraph>
        What does it mean? Well, it means that if you turn to page 10 in my
        replica, you will indeed find the instructions on how to brew the
        Draught of Living Death. The design of this page is shown in the movie,
        so I used both movie and book as a reference for this one:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images2} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry pulled out his trusty copy of Advanced Potion-Making and turned to
        the chapter on antidotes. There was Golpalott’s Third Law, stated word
        for word as Hermione had recited it, but not a single illuminating note
        in the Prince’s hand to explain what it meant. Apparently the Prince,
        like Hermione, had had no difficulty understanding it. “Nothing,” said
        Harry gloomily.
      </ReplicaQuote>

      <ReplicaParagraph>
        This part was never shown in the movie, but I included it in my replica
        using the text above as a reference:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images3} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        More to avoid watching this irritating sight than anything else, Harry
        bent over the Half-Blood Prince’s book and turned a few pages with
        unnecessary force. And there it was, scrawled right across a long list
        of antidotes: Just shove a bezoar down their throats.
      </ReplicaQuote>

      <ReplicaParagraph>
        And indeed, if you turn a few pages, you will also stumble upon a long
        list of antidotes and the 'Just shove a bezoar down their throats' note.
        I intentionally didn't put it across the list of antidotes, because it
        would make it unreadable, but I think it still works:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images4} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        He bent a little lower over Advanced Potion-Making and continued to make
        notes on Everlasting Elixirs, occasionally pausing to decipher the
        Prince’s useful additions to Libatius Borage’s text.
      </ReplicaQuote>

      <ReplicaParagraph>
        Everlasting Elixirs you say? Here you go:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images5} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I tried to include everything from the Half-Blood Prince book. These are
        just some random examples, let's take a look at the potions in this
        book:
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Potions" anchor="v1-potions" />

      <ReplicaParagraph>
        You already know about the Draught of Living Death, but there are much
        more potions included in this book. For example, I found some images of
        the Polyjuice Potion pages from Harry Potter expo and replicated them:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images6} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Hermione’s well-practiced hand hit the air before anybody else’s;
        Slughorn pointed at her. “It’s Veritaserum, a colorless, odorless potion
        that forces the drinker to tell the truth,” said Hermione. “Very good,
        very good!” said Slughorn happily.
      </ReplicaQuote>

      <ReplicaParagraph>
        Veritaserum can be found in the table of contents of the original prop,
        and Slughorn also said "These are the kind of thing you ought to be able
        to make after completing your N.E.W.T.s.", so it makes sense to include
        it in the replica:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images7} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        Harry pulled his copy of Advanced Potion-Making out of his bag and
        looked up Felix Felicis. “Blimey, it’s seriously complicated,” he said,
        running an eye down the list of ingredients. “And it takes six months...
        You’ve got to let it stew....” “Typical,” said Ron.
      </ReplicaQuote>

      <ReplicaParagraph>
        Since Felix Felicis is supposed to be "seriously complicated", I decided
        to spread the instructions over 4 pages. Also, I assume Snape (the
        Half-Blood Prince) was quite poor, so he probably couldn't afford to
        experiment much with this potion - that is why I decided that most of
        his notes would focus on the "why" of things, not "how to make it
        better" - he probably didn't have many (if any) chances to brew the
        potion, so he probably didn't come up with many alterations to the
        original instructions.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images8} />
      <ReplicaSpacer />

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        ... clapping his hands together as he stared down into the sunshine
        yellow contents of Harry’s cauldron. “Euphoria, I take it? And what’s
        that I smell? Mmmm . . . you’ve added just a sprig of peppermint,
        haven’t you? Unorthodox, but what a stroke of inspiration, Harry, of
        course, that would tend to counterbalance the occasional side effects of
        excessive singing and nose-tweaking. . . . I really don’t know where you
        get these brain waves, my boy . . . unless —” Harry pushed the
        Half-Blood Prince’s book deeper into his bag with his foot..
      </ReplicaQuote>

      <ReplicaParagraph>
        The Elixir to Induce Euphoria (together with the 'sprig of peppermint'
        tip from the Prince) can also be found in my replica:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images9} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The table of contents of the original prop also includes the Wolfsbane
        Potion... however, I was seriously considering NOT to include it in my
        replica... why? I'm proud to say that I did my research thoroughly - and
        the Wolfsbane Potion is supposed to be relatively new! This is what I
        found:
      </ReplicaParagraph>

      <ReplicaQuote source="harrypotter.fandom.com">
        After graduating from Hogwarts School of Witchcraft and Wizardry,
        Damocles Belby went on to become a Potioneer and invented the Wolfsbane
        Potion sometime after the mid-1970s.
      </ReplicaQuote>

      <ReplicaParagraph>
        How can this potion be included in a textbook that was published long
        before the Wolfsbane Potion was even invented? According to the
        Half-Blood Prince, the Advanced Potion-Making book is much older than
        that:
      </ReplicaParagraph>

      <ReplicaQuote source="Harry Potter and the Half-Blood Prince">
        There he turned its pages, searching, until he finally found, at the
        front of the book, the date that it had been published. It was nearly
        fifty years old. Neither his father, nor his father’s friends, had been
        at Hogwarts fifty years ago.
      </ReplicaQuote>

      <ReplicaParagraph>
        Since the sixth book takes place around the year 1996, simple math tells
        you that the book was published long before the 1970s, when the
        Wolfsbane Potion was invented. I ended up including it in my replica,
        because most people will probably miss this little fact, but they might
        notice that it is in the original table of contents, but not in mine, so
        there you go:
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={images10} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        These are just a few examples, there are much much more potions in the
        replica.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Spells" anchor="v1-spells" />

      <ReplicaParagraph>
        The book is filled with Prince's notes on a number of spells and charms.
        My replica includes all the spells referenced in the Half-Blood Prince
        (Sectumspempra, Langlock, Muffliato, Levicorpus, Liberacorpus, ...) and
        more:
      </ReplicaParagraph>

      <ReplicaSpacer />
      {imagesSpellsMapped?.length > 0 && (
        <Gallery images={imagesSpellsMapped} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Thought" anchor="v1-final-thought" />

      <ReplicaParagraph>
        It was a great deal of work, but I think the result was definitely worth
        it. There is still room for improvement, though, because there is one
        thing that the replica is missing - the title and other parts on the
        cover of the book should be silver foiled. I'm able to do this with
        paper, but the cover is made from a textile canvas, which proved
        difficult for this type of silver foiling. Since I didn't find a better
        way to do it, I'm leaving it open for now. Aaaand that's it, another
        passion project finished.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=bpOTEba62VY" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
