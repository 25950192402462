import styled, { css } from "styled-components";

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 80px;
  border-bottom: 1px solid ${(props) => props.theme.white}11;

  ${(props) => props.theme.breakpoint.S} {
    width: 100%;
  }
`;

const Tab = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  width: 40%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.white}99;
  padding: 10px 5px;
  transition: all 0.2s ease;
  margin-bottom: -1px;

  ${(props) =>
    props.isActive &&
    css`
      color: ${(props) => props.theme.white}CC;
      border-bottom: 1px solid ${(props) => props.theme.white}88;
    `}
`;

const Tabs = <T,>(props: {
  tabs: T[];
  activeTab: T;
  onTabClick: (tab: T) => void;
}) => {
  return (
    <Wrapper>
      {props.tabs.map((tab) => (
        <Tab
          key={tab}
          onClick={() => props.onTabClick(tab)}
          isActive={props.activeTab === tab}
        >
          {tab}
        </Tab>
      ))}
    </Wrapper>
  );
};

export default Tabs;
