import LayoutFullscreen from "layouts/LayoutFullscreen";
import { Link } from "react-router-dom";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_molecule_4.jpg";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Content = styled.div`
  width: 90%;
  max-width: 800px;
  text-align: justify;
  ${(props) => props.theme.breakpoint.S} {
    text-align: left;
  }
`;

const PurchaseGuide = () => {
  return (
    <LayoutFullscreen background={background} backgroundOpacity={0.7}>
      <Wrapper>
        <SectionHeading
          title="Warranties / Refunds"
          subtitle={["What I expect from you", "Can you return virtual goods?"]}
        />

        <Spacer />
        <Content>
          <ul>
            <li>
              You warrant that you have full right, power, legal capacity and
              authority to enter into and perform this agreement or that you
              have obtained any third party consent needed to do so.
            </li>
            <li>
              You will not use any of the products except pursuant to the terms
              of this agreement. Should you use a product in an unauthorized
              way, you agree to any reasonable fee or penalty.
            </li>
            <li>
              For obvious reasons, I do not offer refunds on virtual goods.
              However, your complete satisfaction is very important to me, so do
              not hesitate to{" "}
              <Link to={routes.contact.slug}>contact me via email</Link> about
              any problems you might encounter.
            </li>
            <li>
              I do not warrant that the product will meet your requirements or
              expectations.{" "}
              <strong>
                Read the product description carefully before making a purchase
              </strong>
              , please. If you need more information,{" "}
              <Link to={routes.contact.slug}>contact me via email</Link>,
              please.
            </li>
          </ul>
        </Content>

        <Spacer />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default PurchaseGuide;
