import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_3d_model = [];
let galleryWIP_emitter = [];
let galleryWIP_emitter_cover = [];
let galleryWIP_greeblies = [];
let galleryWIP_handle = [];
let galleryWIP_pommel = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_3d_model = importAll(
    (require as any).context("./gallery-wip-3d-model", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter_cover = importAll(
    (require as any).context(
      "./gallery-wip-emitter-cover",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_handle = importAll(
    (require as any).context("./gallery-wip-handle", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 22, month: 1, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This was a small (literally) side project that I finished over the span
        of just two weeks. The design of Yoda's lightsaber has an interesting
        origin story, and it first appeared not in Episode 1, the film, but in
        its Visual Dictionary. Similarly to the very first lightsaber props from
        Episode 4, Yoda's lightsaber was based on a real-life flashgun. I
        created this whole replica from scratch, but it was important to me to
        try and make it seem like a functional object with internal parts (just
        like the flashgun).
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.L} />
      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=I-0BKeQqx8I"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Yoda's lightsaber was later digitally recreated and made its first
        appearance in Attack of the Clones (Ep2), establishing its design in the
        Star Wars canon. That being said, it seems to me that there are some
        differences between the later iterations of Yoda's lightsaber and the
        original prop featured in the Visual Dictionary for Episode 1 (which is
        the one I chose to replicate).
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Assembly Design" />
      <ReplicaParagraph>
        Just like all of my lightsaber replicas, it all started by virtually
        designing the lightsaber. As always, I tried to divide the prop into
        manageable parts, that, when assembled, would form the outer design of
        the original prop. I took some liberties when designing the internal
        parts to fit my assembly design, because those won't be visible from the
        outside. This lightsaber has a lot of plastic parts, and although my
        initial plan was to create these out of metal, I ultimately decided to
        3D print them to keep the material composition of the prop the same as
        the original. Those parts are not documented below, because the
        post-processing process is fairly boring.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_3d_model)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        This is probably the simplest part I ever machined... the metal part is
        a simple tube turned to the correct diameter and length, easy-peasy. I
        then gave the metal a shiny finish and attached the grips to complete
        the part. Initially, I wanted to create some kind of mechanical joint
        for the grips, but laziness won and I used a double-sided tape instead.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle)} />

      <ReplicaSubTitleAnchor title="Emitter Body" />
      <ReplicaParagraph>
        For me, this was the most interesting part of the whole build. I used a
        rather thick metal tube as a base for the part, because it gave the prop
        more weight, and it was much easier to secure the internal parts in
        place. All the holes were carefully drilled and shaped using a file.
        Once the intricate metal part was finished, it was time to put in the
        internal parts.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter)} />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        The little parts and screws are always a bit of a challenge to turn on a
        lathe, because they tend to bend easily once the diameter gets small
        enough. Luckily, I was able to find two machine screws that had the
        ideal dimensions, meaning that I could just modify them, instead of
        making everything from scratch. That being said, I still had to make one
        of the buttons/screws completely from scratch, but what can you do...
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_greeblies)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Blade Emitter Shroud" />
      <ReplicaParagraph>
        This was another first for me that I was very excited to try out -
        creating parts by bending a metal sheet. The emitter shroud started out
        as a simple piece of metal sheet, that I had to wrap my head around and
        figure out a way to precisely bend and cut/file the workpiece into the
        final shape. Long story short, through a set of custom-made bend
        templates, I was able to produce the final part that looks, in my humble
        opinion, damn good.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_emitter_cover)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        The pommel was machined to hold the lightsaber assembly together using a
        threaded rod. This was probably the most complicated part to turn on a
        lathe for this build, including knurling and two threaded holes that go
        through the core of the part. Overall nothing too complicated, though.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel)} />

      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
