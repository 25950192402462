import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Subtitle = styled.p`
  font-size: 9px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${props => props.theme.white}44;

  width: 80%;
`

const SpacerTitle = styled.div`
  height: 2vh;
`

const SectionHeading = (props: {
  title: any
  subtitle?: string | string[]
  linkTitle?: string
  link?: string
}) => {
  return (
    <Wrapper>
      <h2>{props.title}</h2>
      {props.subtitle &&
        (typeof props.subtitle === 'string' ? (
          <Subtitle className="subtitle">{props.subtitle}</Subtitle>
        ) : (
          <>
            {(props.subtitle as string[]).map((s, i) => (
              <Subtitle key={i} className="subtitle">
                {s}
              </Subtitle>
            ))}
          </>
        ))}
      {props.link && (
        <>
          <SpacerTitle />
          <Link to={props.link} className="link-as-button">
            {props.linkTitle || `show all`}
          </Link>
        </>
      )}
    </Wrapper>
  )
}

export default SectionHeading
