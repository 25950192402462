import Background from "assets/background/background_aae_hud_factory_section_additional.jpg";
import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import ProductItem from "components/ProductItem";
import SectionSubHeading from "components/SectionSubHeading";
import { productsAAEHUDFactory } from "assets/products";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Spacer = styled.div`
  height: 3vh;
`;

const ProductsAdditional = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={Background} backgroundOpacity={0.7}>
      <Wrapper>
        <SectionSubHeading
          title="Additional Products"
          description="These are NOT STANDALONE products, you need one of the HUD packages to
          use these products with. If you want to take your videos to the next
          level, these will help you. HUD effects can be used to create
          interesting animations like HUD glitches, lag, distortion, and
          similar. You can also add things like broken glass, or a layer of ice
          to your scene, which is always an interesting addition to the final
          shot."
        />
        <ProductsWrapper>
          {productsAAEHUDFactory
            .filter((product) => product.isAdditionalProduct)
            .map((product, i) => (
              <ProductItem key={i} product={product} wide />
            ))}
        </ProductsWrapper>

        <Spacer />
        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Find out more"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default ProductsAdditional;
