import { ArrowDown } from "@styled-icons/bootstrap";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background: ${(props) => props.theme.black}44;
  }
`;

const ArrowIcon = styled.div`
  margin-top: 20px;
  color: ${(props) => props.theme.white}88;
  svg {
    width: 20px;
  }
`;

const ButtonSectionScroll = (props: {
  label?: string;
  onClick: () => void;
}) => {
  return (
    <ButtonWrapper>
      <button onClick={props.onClick}>{props.label || "explore"}</button>
      <ArrowIcon>
        <ArrowDown />
      </ArrowIcon>
    </ButtonWrapper>
  );
};

export default ButtonSectionScroll;
