import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import { Link } from "react-router-dom";
import LukeLightsaberROTJ from "assets/replicas/star-wars/sw-lukes-lightsaber-rotj";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryDay0 = [];
let galleryDay1 = [];
let galleryDay2 = [];
let galleryDay3 = [];
let galleryDay4 = [];
let galleryDay5 = [];
let galleryDay6 = [];
let galleryDay7 = [];
let galleryDay8 = [];
let galleryDay9 = [];
let galleryDay10 = [];
let galleryDay11 = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay0 = importAll(
    (require as any).context("./wip-day0", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay1 = importAll(
    (require as any).context("./wip-day1", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay2 = importAll(
    (require as any).context("./wip-day2", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay3 = importAll(
    (require as any).context("./wip-day3", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay4 = importAll(
    (require as any).context("./wip-day4", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay5 = importAll(
    (require as any).context("./wip-day5", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay6 = importAll(
    (require as any).context("./wip-day6", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay7 = importAll(
    (require as any).context("./wip-day7", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay8 = importAll(
    (require as any).context("./wip-day8", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay9 = importAll(
    (require as any).context("./wip-day9", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay10 = importAll(
    (require as any).context("./wip-day10", false, /\.(png|jpe?g|svg)$/)
  );
  galleryDay11 = importAll(
    (require as any).context("./wip-day11", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 10, month: 7, year: 2018 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);
  const galleryDayImages0 = transformImages(galleryDay0);
  const galleryDayImages1 = transformImages(galleryDay1);
  const galleryDayImages2 = transformImages(galleryDay2);
  const galleryDayImages3 = transformImages(galleryDay3);
  const galleryDayImages4 = transformImages(galleryDay4);
  const galleryDayImages5 = transformImages(galleryDay5);
  const galleryDayImages6 = transformImages(galleryDay6);
  const galleryDayImages7 = transformImages(galleryDay7);
  const galleryDayImages8 = transformImages(galleryDay8);
  const galleryDayImages9 = transformImages(galleryDay9);
  const galleryDayImages10 = transformImages(galleryDay10);
  const galleryDayImages11 = transformImages(galleryDay11);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Here's my second lightsaber build! I actually started working on this
        simultaneously with my{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + LukeLightsaberROTJ.id}
          target="_blank"
        >
          Luke Lightsaber ROTJ replica
        </Link>
        . I must say I always loved this design more than Luke's ROTJ, probably
        because it was built from real parts. I can't really put my finger on
        it, but I just think that this saber looks much more "real"... like
        something that could actually work. Anyways, thanks to this, the parts
        are also much more difficult to manufacture.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.M} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I'm the kind of person who not only wants to own a prop/replica, but who
        also loves to make it himself. I have to admit, for a long time, I
        didn't believe I would be able to finish this one. Also - I LOVE looking
        at work-in-progress pictures of prop building, so, if you're like me,
        here's how I made my replica of this saber.
      </ReplicaParagraph>

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Preparations" />

      <ReplicaParagraph>
        As always, I started this project with a little bit of sketching to
        figure out how I'd go about making it. This lightsaber is very
        complicated, so it took some time to actually figure out how to make it
        all work. There are so many parts... Anyway, I knew from the beginning
        that since I was going to use a Graflex clamp, I wanted to make a
        functional 'graflex mechanism' that will allow me to take the lightsaber
        apart just like Luke's Graflex saber. After the sketching, I made a CAD
        3D model to see if I didn't miss anything.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryDayImages0} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 1" />
      <ReplicaParagraph>
        Today I made four parts on a lathe. The first piece I made will be a
        part of the pommel - I divided the pommel into three parts, for easier
        manufacturing. Just like{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + LukeLightsaberROTJ.id}
          target="_blank"
        ></Link>
        , this one will be held together using a threaded rod. Well, two pieces
        of threaded rod, because, as I said, I want to be able to take the
        lightsaber apart. That means I have to divide it into two main parts. I
        also made a second part of the pommel. This one doesn't have a thread
        running through it, just a hole. That means that I've got 2/3 of the
        pommel finished. I can't make the last part of the pommel at the moment,
        because I'm waiting for a shaped face mill to arrive. Anyway, I
        continued to work on another part, the one that will be locked inside
        the Graflex clamp. It's also not fully finished, yet. I still have to
        cut out the locking mechanism for the clamp, but that's for another day.
        The last part I made is the 'grenade' section of the saber. I finished
        just the operations I could do on a lathe, but again, I'm waiting for a
        special mill to arrive, so I can create the V grooves of the grenade.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages1?.length > 0 && (
        <Gallery images={galleryDayImages1} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 2" />
      <ReplicaParagraph>
        There wasn't much going on today. I just marked the Graflex clamp 'slot'
        with a golden marker and cut it out with my dremmel tool. It fits
        perfectly into the clamp. By the way, the clamp I'm using at the moment
        is from my Luke's Graflex lightsaber replica (which I bought), it won't
        be a part of this saber.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages2?.length > 0 && (
        <Gallery images={galleryDayImages2} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 3" />
      <ReplicaParagraph>
        Today I manufactured a part of the emitter (the 'head' of the
        lightsaber). Again, the emitter is divided into two parts, it's not one
        piece. I can't create the other part at the moment, because I don't have
        aluminium rod of the diameter I need.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages3?.length > 0 && (
        <Gallery images={galleryDayImages3} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 4" />
      <ReplicaParagraph>
        I finally got the aluminium rod of the required diameter. This is the
        second part of the emitter. I've been waiting for this, it's practically
        the first part of the lightsaber that is fully finished. It's kinda
        scary, turning a piece like this. The thickness of the 'tube' is just
        1mm, so you really need to be precise, no excuses here. Anyway, I'm very
        happy with this part, it looks better than I expected... I might be
        using this phrase a lot, from now on, because I honestly didn't think
        I'd be able to make some of the parts.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages4?.length > 0 && (
        <Gallery images={galleryDayImages4} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 5" />
      <ReplicaParagraph>
        Today was exciting! I love doing all this stuff by myself, because I get
        to try a lot of new things. I've decided not to spray-paint the black
        parts of the lightsaber, but to 'paint' them black using fire. It worked
        well. The part I didn't put into the fire for too long created a very
        nice texture on the surface. This is exactly the type of look I've been
        looking for, it will look nice on the emitter. But I'm still not done, I
        need my black parts as well. All the parts that I 'painted' this way
        were just some random spare parts that I either screwed up, or had lying
        around because I had no use for them. I wouldn't take a risk like this
        with the final parts. Anyway, I left some parts in the fire for much
        longer than the first one, and they got a nice black layer.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages5?.length > 0 && (
        <Gallery images={galleryDayImages5} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 6" />
      <ReplicaParagraph>
        I'm still waiting for a brass rod to continue with the grenade part of
        the saber. I was surprised to find out that there was some brass lying
        around in the workshop. And I was even more surprised when I found a
        piece of brass that was perfect for one of the lightsaber parts. I
        finally received the milling tools I ordered, so I also finished one of
        the most difficult parts of the saber. As you can see, this piece is
        quite tricky to do by hand. The milling took me about 5 hours, because I
        had to manually adjust the angle of the part (without a rotational
        chuck). It was quite boring, actually. The milling was never meant to
        create the final shape. I had to take a metal file and finish the shape
        that I needed manually. This part of the process took me about two
        hours. I wouldn't want to go through this a second time. Anyway, that's
        another piece that turned out looking quite good.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages6?.length > 0 && (
        <Gallery images={galleryDayImages6} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 7" />
      <ReplicaParagraph>
        A lot of milling today... Since I got all the milling tools I needed,
        I've decided to finally finish some of the long unfinished parts. As you
        might remember, I started with the pommel parts on a lathe, so it's only
        fitting that I finally finish the whole pommel. I started with cutting a
        hole inside of the rod for a perfect fit with the pommel parts that I
        already have. The milling was (after the previous part the other day)
        fairly fast and easy, so no problems there either. It turned out looking
        (again) much better than I expected. Once the pommel was finished, I've
        decided to finish the grenade part as well. All that was missing were
        the v-grooves around the 'ribbed' area, and now I finally have the tools
        I need. I was affraid that the tool would break, but everything went
        smoothly. I must say, I love how this part turned out. It's almost a
        shame to paint it black later.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages7?.length > 0 && (
        <Gallery images={galleryDayImages7} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 8" />
      <ReplicaParagraph>
        Yes! I finally received the long awaited materials! Copper for Luke's
        saber and brass for this one. So I jumped right into machining the
        missing grenade part. As usual, I ordered two times more material than I
        actually needed (just to make sure that I don't have to wait for another
        order in case I screw something up). So, I had to cut the piece of brass
        I needed first. Then I drilled a hole in it, so the threaded rod could
        go right through. The part fits perfectly into the grenade section I
        already have. The lightsaber finally starts to look like something, but
        there's still a long road ahead. And I still need to finish one of the
        most complicated parts of the saber, but more on that later.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages8?.length > 0 && (
        <Gallery images={galleryDayImages8} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 9" />
      <ReplicaParagraph>
        It's time to work on the most complex part of the saber... but before
        that, I used my dremmel tool to cut the slot for the Graflex clamp. I'm
        still using the clamp from my Luke ESB replica. Anyway, everything
        works, so it's time to finish the grenade part made from brass. My first
        plan was to cut the shape out of a solid piece of brass with my dremmel
        tool. After careful consideration, I've decided to mill out the basic
        shape first, and then use dremmel just for the final touches. It took
        forever, but once I was finished, I pressed this part into the brass
        ring that I've created some time ago. I didn't test this first, but it
        worked perfectly. The ring can be screwed onto the main brass part I
        created earlier. Finally! The most complex part is finished. It's mainly
        this part I was most afraid of, and I didn't think I'd be able to make
        it.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages9?.length > 0 && (
        <Gallery images={galleryDayImages9} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 10" />
      <ReplicaParagraph>
        Almost all the parts are finished, it's time to paint this thing. I
        tried the technique I described earlier - turning the parts black using
        fire. The result is not exactly what I expected it to be. The pieces
        have a lot of crevices, where the color didn't stuck very well, but it's
        good enough. I didn't try to paint brass this way before, so it was a
        little bit of risk. But not to worry, the polished brass didn't change
        at all in the fire, haha. Anyway, I'll have to use a different method to
        weather and age the prop.
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages10?.length > 0 && (
        <Gallery images={galleryDayImages10} size={GallerySize.M} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Day 11" />
      <ReplicaParagraph>
        Today was dedicated to the small things I've been putting aside for
        later. Now that I've finished all the parts, there's no later anymore,
        so here I am. I've decided to spray-paint the parts first, so I can let
        them dry while I'm working on something different. The fire gave the
        parts a good base and texture. I then started cleaning the paint off,
        using a sandpaper and a lathe. I had to repeat this process a few times
        until I was happy with the result. Anyway, while I was waiting for the
        paint to dry, I drilled a hole inside the lower part of the saber. I
        then cut a thread in it, so I can put a screw there, and secure the
        'booster' part from rotating. Since I added the Graflex clamp mechanism,
        I needed those parts to really stick together.
      </ReplicaParagraph>
      <ReplicaParagraph>
        After that, I had to make the two transistors that are attached to the
        clamp. I always knew that I want these to be screwed into place, so I
        can easily take the lightsaber apart. I noticed that there are versions
        of the lightsaber, where the two transistors have sort of a socket...
        but I've also seen a version where there's just one socket, or even
        none. I've chosen to create just one socket, because I like the way it
        looks (and, as a benefit, it's easier to make just one part, instead of
        two). Once I had the transistors finished, I had to attach them to the
        clamp. Well, technically, they're not attached to the clamp, but to the
        upper and lower part of the lightsaber instead. To do this, I had to
        drill a hole inside each of the parts, and cut a thread for the
        transistor. The very last thing I did on this was drilling holes for the
        D-ring and installing it. All done!
      </ReplicaParagraph>
      <ReplicaSpacer />
      {galleryDayImages11?.length > 0 && (
        <Gallery images={galleryDayImages11} size={GallerySize.S} />
      )}
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Final Assembly" />
      <ReplicaParagraph>
        One last note - I initially planned to create all the parts myself -
        including the Graflex clamp. The more parts I finished, the better it
        looked and I thought that creating the clamp was not going to be easy,
        and the result would probably degrade the quality of the lightsaber.
        That's why I ordered a replica Graflex clamp online, including the
        bubble strip (which I also wanted to create myself). And here's the
        final assembly:
      </ReplicaParagraph>
      <ReplicaSpacer />
      <YouTubeVideo src="https://www.youtube.com/watch?v=XUfWjYc0Bpg" />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
