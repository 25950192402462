import styled, { css } from "styled-components";

import { HashLink } from "react-router-hash-link";
import YouTubeVideo from "components/Media/YouTubeVideo";

const Wrapper = styled.div<{ pageCount: number }>`
  width: 100%;
  margin-top: 20px;

  ${(props) =>
    props.pageCount > 5 &&
    css`
      margin-bottom: 30px;
    `}
`;

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;

  font-size: 1.1em;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.6em;
  color: ${(props) => props.theme.white};
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 3%;
`;

const Columns = styled.div`
  margin-top: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  ${(props) => props.theme.breakpoint.M} {
    flex-direction: column;
    align-items: stretch;
  }
`;

const Pages = styled.div`
  & > *:first-child {
    margin-left: 24px;
    margin-bottom: 10px;
    opacity: 0.4;
  }

  ul {
    ${(props) => props.theme.breakpoint.M} {
      margin-left: 26px;
    }
  }

  li {
    margin: 0;

    a {
      div {
        margin: 0;
        padding: 6px 0px;
      }

      font-size: 1em;
      color: ${(props) => props.theme.white}88;
      text-decoration-line: underline;
      text-underline-offset: 4px;
      text-decoration-color: ${(props) => props.theme.white}22;

      &:hover {
        color: ${(props) => props.theme.white};
        text-decoration-color: ${(props) => props.theme.white}88;
      }
    }
  }
`;

const Video = styled.div`
  width: 50%;
  max-width: 500px;

  ${(props) => props.theme.breakpoint.M} {
    width: 100%;
    max-width: 100%;
  }
`;

const Anchor = styled.div`
  position: absolute;
  top: -70px;
`;

const ReplicaMaraudersMapPages = (props: {
  title: string;
  content?: any;
  youtube: string;
  anchor?: string;
  pages: { name: string; anchor?: string }[];
}) => {
  //
  // RENDER
  //
  return (
    <Wrapper pageCount={props.pages?.length}>
      {props.anchor && <Anchor id={props.anchor} />}
      <Title>{props.title}</Title>

      <Content>
        {props.content || null}

        <Columns>
          <Pages>
            <div>Pages:</div>
            <ul>
              {props.pages.map((page, i) => (
                <li key={i}>
                  <HashLink to={"#" + page.anchor} smooth>
                    <div>{page.name}</div>
                  </HashLink>
                </li>
              ))}
            </ul>
          </Pages>

          <Video>
            <YouTubeVideo src={props.youtube} />
          </Video>
        </Columns>
      </Content>
    </Wrapper>
  );
};

export default ReplicaMaraudersMapPages;
