import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "daily-prophet-hp1-break-in-at-gringotts",
  name: `The Daily Prophet\n'Break In at Gringotts'`,
  origin: `Harry Potter and the Philosopher's Stone`,

  category: replicaCategories.harryPotter,

  versions: [Version1],
};

export default data;
