import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_3d_model = [];
let galleryWIP_emitter = [];
let galleryWIP_emitter_body = [];
let galleryWIP_greeblies = [];
let galleryWIP_handle = [];
let galleryWIP_neck = [];
let galleryWIP_pommel = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_3d_model = importAll(
    (require as any).context("./gallery-wip-3D-model", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter = importAll(
    (require as any).context("./gallery-wip-emitter", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_emitter_body = importAll(
    (require as any).context(
      "./gallery-wip-emitter-body",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_handle = importAll(
    (require as any).context("./gallery-wip-handle", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_neck = importAll(
    (require as any).context("./gallery-wip-neck", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_pommel = importAll(
    (require as any).context("./gallery-wip-pommel", false, /\.(png|jpe?g)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 18, month: 6, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is the lightsaber used by Obi-Wan Kenobi throughout Episode 1, when
        he was still Qui-Gon Jinn's Padawan. It is vastly different from his
        signature lightsaber design from Episode 4 (and its idealized version
        from Episode 3), and its variation was also used in Episode 2. The
        lightsabers for Episode 1 were not put together from pre-existing items,
        like in the original trilogy, which made its machining a bit easier for
        me. However, there were still some tricky parts - the pommel in
        particular - that kept me away from this lightsaber until I felt that I
        had enough experience and confidence in my machining skills.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.L} />
      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=-F2i4EkwLww"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Assembly Design" />
      <ReplicaParagraph>
        The first thing, right after research, that I do when working on a
        lightsaber, is designing the final assembly on a computer. I do not have
        access to all the fancy tools that I'd like to have, so this really
        helps me to design the lightsaber in a way that I'll be able to machine
        all the parts without any problems. I then 3D print the parts that are
        supposed to be plastic/non-metal, and machine the metal parts mostly on
        my lathe.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_3d_model)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Emitter" />
      <ReplicaParagraph>
        The lightsaber parts are held together by a threaded rod running through
        its entire body. The emitter serves as a nut on the front end of the
        lightsaber and holds the threaded rod in place. I usually run the rod
        through the emitter to the outside, where it becomes a part of the
        design, but it didn't really work with this lightsaber, so it stays
        hidden.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Emitter Body" />
      <ReplicaParagraph>
        This was one of the most difficult parts of the entire project. A very
        precise drilling was required in order to create holes for LEDs and
        other greeblies that are supposed to be attached to the lightsaber. This
        needed to be done at a precise angle in order for the greeblies to meet
        the internal part to which they are then attached. On top of that, I
        then had to cut out a lot of material and file it into shape to get the
        crown-like shape of the part.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_emitter_body)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        The original lightsaber prop used a lot of hardware greeblies that were
        not specifically designed for the Episode 1 lightsabers, but were real
        existing parts. I was able to find the required LED housings and the
        brass valve adapter (if I'm not mistaken), so the only thing that I
        needed to create myself was the red button. The process included
        knurling and thread cutting, but all in all nothing special.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_greeblies)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Handle" />
      <ReplicaParagraph>
        The handle is, in its concept, a fairly straight-forward part of the
        lightsaber. There is a metal core, which goes inside a plastic grip, and
        all of it is covered by the outer metal part. That part, however, has a
        giant hole on top/sides, which required a lot of work with a file in
        order to get the final shape. The walls of the "tube" are also quite
        thick, so the process was a lengthy one.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_handle)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Neck" />
      <ReplicaParagraph>
        The "neck" of the lightsaber is what I call the area that connects the
        handle and emitter body. After some research, I discovered that there
        were quite a few intricate cuts, which I had to carefully recreate. I
        was forced to split it into two parts, because I did not have the right
        tool for this type of work, but I don't think that you would notice it
        on the final hilt.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_neck)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Pommel" />
      <ReplicaParagraph>
        This is the part I feared the most, and which kept me away from this
        lightsaber for quite a long time. After a lot of planning, I was able to
        machine it without any problems, and it turned out better than I ever
        expected. Just like before, I cut the undesired material away, and then
        used a file to carefully shape the "star". I then had to drill a hole
        into each tip of the star, which once again required a lot of precision.
        It was also nerve wracking, because one misaligned hole could have
        easily ruined hours of work in a matter of seconds.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_pommel)} />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
