import styled, { css } from "styled-components";

import ImageLoader from "./ImageLoader";
import LightboxWrapper from "components/Media/LightboxWrapper";
import { clickableImageCss } from "styles/GlobalStyle/Images";

export type GalleryImageType = { src: string; name?: string };

export enum GallerySize {
  "S" = "S",
  "M" = "M",
  "L" = "L",
  "XL" = "XL",
  "XXL" = "XXL",
  "FullWidth" = "FullWidth",
}

const Wrapper = styled.div<{
  size: GallerySize;
  isSingleImage: boolean;
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 4px;

  ${({ isSingleImage }) =>
    isSingleImage &&
    css`
      justify-content: center;
    `}

  img {
    ${clickableImageCss}

    --imagesPerRow: 4;
    --singleImageMaxWidth: 65%;

    min-width: calc(
      (100% - 4px * (var(--imagesPerRow) - 1)) / var(--imagesPerRow)
    );
    max-width: 100%;

    flex: 1;
    box-sizing: border-box;
    justify-content: space-between;
    text-align: center;
    object-fit: cover;

    //filter: saturate(0.9);
    transition: all 0.2s ease;

    ${({ isSingleImage }) =>
      isSingleImage &&
      css`
        max-width: var(--singleImageMaxWidth);
      `}

    ${({ size }) =>
      size === GallerySize.FullWidth
        ? css`
            --imagesPerRow: 1;
            --singleImageMaxWidth: 100%;
          `
        : size === GallerySize.XXL
        ? css`
            --imagesPerRow: 2;
            --singleImageMaxWidth: 65%;
          `
        : size === GallerySize.XL
        ? css`
            --imagesPerRow: 3;
            --singleImageMaxWidth: 65%;
          `
        : size === GallerySize.L
        ? css`
            --imagesPerRow: 4;
            --singleImageMaxWidth: 65%;
          `
        : size === GallerySize.M
        ? css`
            --imagesPerRow: 6;
            --singleImageMaxWidth: 65%;
          `
        : size === GallerySize.S
        ? css`
            --imagesPerRow: 8;
            --singleImageMaxWidth: 50%;
          `
        : null}
  }
`;

const GalleryComponent = (props: {
  images: GalleryImageType[];
  size?: GallerySize;
}) => {
  return props.images?.length > 0 ? (
    <LightboxWrapper>
      <Wrapper size={props.size} isSingleImage={props.images?.length === 1}>
        {props.images?.length > 0 &&
          props.images.map(({ src, name }, i) => (
            <ImageLoader key={i} src={src} alt={name || ""} />
          ))}
      </Wrapper>
    </LightboxWrapper>
  ) : null;
};

export default GalleryComponent;
