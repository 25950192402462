import Gallery, { GallerySize } from "components/Media/Gallery";
import { ReplicaVersionType, ThumbnailSize } from "types";
import { createDate, importAll, transformImages } from "utils";

import DL44LukeBespin from "../../sw-dl44-luke-bespin";
import { Link } from "react-router-dom";
import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import background from "./background.jpg";
import { routes } from "config";
import thumbnail from "./thumbnail.png";

let gallery = [];
let gallery_wip_ammoPortEnclosureBody = [];
let gallery_wip_ammoPortEnclosureBox = [];
let gallery_wip_constructionImprovements = [];
let gallery_wip_constructionImprovementsScrew = [];
let gallery_wip_handleDisc = [];
let gallery_wip_weathering = [];
let gallery_wip_tTracks = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  gallery_wip_ammoPortEnclosureBody = importAll(
    (require as any).context(
      "./gallery-wip-ammo-port-enclosure-body",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_ammoPortEnclosureBox = importAll(
    (require as any).context(
      "./gallery-wip-ammo-port-enclosure-box",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_constructionImprovements = importAll(
    (require as any).context(
      "./gallery-wip-construction-improvements",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_constructionImprovementsScrew = importAll(
    (require as any).context(
      "./gallery-wip-construction-improvements-screw-replacement",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_handleDisc = importAll(
    (require as any).context(
      "./gallery-wip-handle-disc",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_weathering = importAll(
    (require as any).context(
      "./gallery-wip-weathering",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  gallery_wip_tTracks = importAll(
    (require as any).context(
      "./gallery-wip-t-tracks",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: true,
  page: undefined,
  dateCreated: createDate({ day: 20, month: 2, year: 2024 }),
  datePublished: createDate({ day: 7, month: 8, year: 2024 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
  thumbnailSize: ThumbnailSize.L,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        Ever stumbled upon something unexpected that led to a fun side project?
        That's exactly how my journey with the DLT-19 Replica Blaster began. I
        was locked in a heroic battle with what felt like a serious illness
        (okay, maybe it was just a stubborn cold, but let's not dwell on
        unimportant details), when I stumbled upon a Denix replica of the MG 34
        – the very gun used to create the original props for Star Wars Episode
        4: A New Hope. As a spontaneous distraction from my "epic" ordeal, I
        snagged it and embarked on the quest of converting the MG 34 replica
        into the DLT-19 blaster. Surprisingly, the project turned out to be
        relatively straightforward. Unlike the{" "}
        <Link
          to={"/" + routes.replicas.slug + "/" + DL44LukeBespin.id}
          target="_blank"
        >
          DL-44 blaster
        </Link>
        , which demands a scope and other intricate parts, the DLT-19 required
        fewer components for conversion, all of which I was able to make myself.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} size={GallerySize.L} />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />

      <ReplicaSubTitleAnchor title="Ammo Port Enclosure" />

      <ReplicaParagraph>
        Crafting the box attachment for my DLT-19 Replica Blaster proved to be
        one of the most challenging aspects of the project. Initially, I had to
        devise a secure method for affixing it to the gun—a process I'll delve
        into shortly. Then came the task of machining the box itself to meet my
        specifications. Ensuring durability was paramount to me, so I opted for
        a robust attachment mechanism of my own design using two screws,
        effectively rendering accidental detachment impossible. The box itself
        is crafted from an Aluminum U profile, with its sides securely adhered
        using an exceptionally strong metal adhesive. While initially skeptical
        of this approach, the seams marks were almost invisible after I sanded
        the sides down, and they are entirely invisible after the final
        weathering.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_ammoPortEnclosureBox)}
        size={GallerySize.L}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        In my pursuit of durability, I scoured the internet to explore how
        others had tackled similar challenges. However, none of the solutions I
        found quite fit the bill. Many appeared too flimsy and didn't meet my
        standards for durability. Ensuring the longevity of my replicas is
        always a priority for me—I want to be confident that the box won't
        accidentally detach when handed to someone unfamiliar with its
        construction. Moreover, most of the solutions I encountered involved
        modifying the original part, which didn't align with my preference for
        non-destructive methods. Ultimately, I devised a novel approach: I
        replaced the entire internal component within the ammo port with a
        custom-designed piece tailored specifically to accommodate the box. This
        allowed me to maintain the integrity of the original part while ensuring
        exceptional durability. The resulting attachment is so robust that one
        can confidently lift the entire gun solely by grasping the box—a
        remarkable achievement, particularly given the weight of the machine
        gun.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_ammoPortEnclosureBody)}
        size={GallerySize.L}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        This approach also provided me with the opportunity to address the other
        side of the gun — the one opposite the attached box. Since I couldn't
        find any satisfactory reference photos of how the original prop appeared
        from this angle, I took the liberty of creating my own "idealized"
        version. Guns often feature serial numbers and designations, so I
        decided to incorporate these elements as well. I added a serial number
        to the side, along with the "DLT-19" designation, ensuring they
        seamlessly integrated with the overall aesthetic. It was crucial to me
        that the final finish matched the surrounding metal parts perfectly,
        ensuring a cohesive and authentic appearance.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Construction Improvements" />

      <ReplicaParagraph>
        While the Denix replica is overall well-crafted, I found its assembly to
        be lacking. Whenever I handled the gun, I noticed the barrel moving
        within the body, resulting in unwanted ringing noises. This made the gun
        feel flimsy, prompting me to disassemble it and devise a solution. I
        crafted a custom washer to secure the barrel in place, addressing the
        issue of instability. Additionally, I installed a tension washer at the
        bottom of the gun, where a screw holds the main parts together.
        Previously, this screw tended to loosen over time, causing the parts to
        wiggle. With the tension washer in place, this problem is now a thing of
        the past.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_constructionImprovements)}
        size={GallerySize.FullWidth}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        One aspect of the ammo port that I found particularly bothersome was the
        method used to secure the top cover in place—a pin that required a
        hammer to remove every time I needed to disassemble it. To address this
        inconvenience, I found a machine screw with the required diameter and a
        rounded head resembling the original pin. I carefully shortened the
        screw to the precise length needed and used it to replace the pin. To
        secure it in place, I utilized a flat nut, resulting in minimal changes
        to the overall appearance while vastly improving the ease of
        disassembly. Once satisfied with the setup, I chemically blackened the
        screw to match the original color theme, ensuring a seamless integration
        with the rest of the replica.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_constructionImprovementsScrew)}
        size={GallerySize.XL}
      />

      <ReplicaSubTitleAnchor title="Handle Disc" />

      <ReplicaParagraph>
        Crafting the mysterious disc presented its own set of challenges.
        Initially, I held out hope of finding a pre-made screw with the
        necessary dimensions or something similar, but my search yielded no
        results. Undeterred, I turned to my trusty lathe and decided to machine
        the disc from scratch. I also had to figure out how to securely attach
        the disc to the grip of the blaster. After some consideration, I settled
        on a method involving drilling through the grip and attaching the disc
        via a machine screw from the inside. This ensured that the disc wouldn't
        fall off.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_handleDisc)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="T-Tracks" />

      <ReplicaParagraph>
        The T-Tracks were the only parts I initially considered purchasing
        instead of making. However, given the required dimensions and the
        potential cost of acquiring the necessary amount of T-Tracks at the
        right lengths, I ultimately decided to craft them myself. Having access
        to the required dimensions enabled me to replicate the T-Tracks
        accurately. When it came to securing the T-Tracks, I observed that the
        original prop used a wire running around them for additional stability.
        Initially, I contemplated devising a more refined method, aiming for a
        solution that felt less "crude." However, after careful consideration, I
        ultimately decided to replicate the original approach and used wire to
        additionally secure the T-Tracks in place.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_tTracks)}
        size={GallerySize.M}
      />

      <ReplicaSubTitleAnchor title="Weathering" />

      <ReplicaParagraph>
        Once all the parts were assembled and the construction of the gun proved
        sturdy with my adjustments, it was time to bring everything together
        with the final touch of weathering. This stage also involved darkening
        the wooden stock to match the original prop. In the end, I'm extremely
        pleased with the outcome, with the DLT-19 feeling like an authentic
        piece of weaponry.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(gallery_wip_weathering)}
        size={GallerySize.FullWidth}
      />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
