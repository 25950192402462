import { checkIsNew, sortReplicasByDate } from "utils";
import replicas, { replicasWIP } from "assets/replicas";

import ReplicaItem from "components/Replicas/ReplicaItem";
import styled from "styled-components";

const Replicas = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  justify-content: center;
  gap: 12px;
  place-items: center;

  ${(props) => props.theme.breakpoint.L} {
    grid-template-columns: repeat(auto-fit, 200px);
  }
  ${(props) => props.theme.breakpoint.S} {
    grid-template-columns: repeat(auto-fit, 180px);
  }
`;

const ReplicaList = (props: {
  selectedCategoryId?: string;
  newOnly?: boolean;
  wipOnly?: boolean;
  orderBy?: "date";
}) => {
  const getDisplayedReplicas = () => {
    if (props.wipOnly) {
      return [...replicasWIP];
    }

    let displayedReplicas = [...replicas];

    if (props.selectedCategoryId) {
      displayedReplicas = displayedReplicas.filter(
        (replica) => replica.category.id === props.selectedCategoryId
      );
    }

    if (props.newOnly) {
      displayedReplicas = displayedReplicas.filter((replica) => {
        const latestVersion = replica.versions[0];
        const isNew = checkIsNew(latestVersion.datePublished);
        return isNew;
      });
    }

    if (props.orderBy === "date") {
      // then sort by dateCreated (in case multiple replicas are published on the same date, they should be sorted by dateCreated)
      displayedReplicas = sortReplicasByDate({ replicas: displayedReplicas });
    }

    return displayedReplicas;
  };

  return (
    <Replicas>
      {getDisplayedReplicas().map((replica, i) => (
        <ReplicaItem key={i} replica={replica} noLabel={props.newOnly} />
      ))}
    </Replicas>
  );
};

export default ReplicaList;
