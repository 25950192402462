import { ProductType, SpriteDirection } from "types";

import { Link } from "react-router-dom";
import SHARED_PRINTABLE_INFO from "../shared-info";
import background from "./background.png";
import explodedView_Labels from "./exploded-view_labels.png";
import explodedView_Sprite from "./exploded-view_sprite.png";
import explodedView_Thumbnail from "./exploded-view_thumbnail.png";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

let gallery = [];
/* try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
} */

const data: ProductType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1jZKZfYDfBxDLv1hzM5NOIE_pqLFpSrQJ/view?usp=sharing",
  },
  thumbnail,
  background,
  id: "Printable-Graflex-Flashgun",
  name: "Graflex Flashgun",
  description: () => (
    <>
      This 3D model features a Graflex flashgun, which was used to create Luke's
      lightsaber in{" "}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to="/e-shop/Printable-Lightsaber-Luke-Ep4"
      >
        Episode 4
      </Link>
      {" and "}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to="/e-shop/Printable-Lightsaber-Luke-Ep5"
      >
        Episode 5
      </Link>
      . It has been specifically designed for 3D printing. The goal was to
      create a model that is as accurate as possible to the original part while
      being relatively easy to print.{" "}
      {SHARED_PRINTABLE_INFO.getDiagramDescription()}
    </>
  ),

  price: SHARED_PRINTABLE_INFO.prices.lightsaberPartLarge,
  softwareRequired: [],

  categories: [productCategories.file3DPrintable],
  groups: [productGroups.starWars],
  license: licenses.personal,

  images: gallery,
  videos: [],
  explodedView: {
    static: explodedView_Thumbnail,
    sprite: explodedView_Sprite,
    frameCount: 11,
    direction: SpriteDirection.vertical,
    labels: explodedView_Labels,
  },

  contents: [...SHARED_PRINTABLE_INFO.contents],
  tags: [...SHARED_PRINTABLE_INFO.tags],
  text: [...SHARED_PRINTABLE_INFO.texts.graflexClampLogo],
};

export default data;
