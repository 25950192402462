import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";
import styled, { css } from "styled-components";

import ButtonDownload from "components/Interaction/ButtonDownload";
import ContentBox from "components/ContentBox";

const SectionInside = styled.div<{ isAAEHF: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  & > * {
    flex: 1;
    min-width: 140px;
  }

  ${({ isAAEHF }) =>
    isAAEHF &&
    css`
      & > * {
        min-width: 260px;
      }
    `}
`;

const Title = styled.h4`
  text-align: center;
  margin-bottom: -10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
  & > * {
    width: 40%;
    min-width: 280px;

    ${(props) => props.theme.breakpoint.S} {
      width: 100%;
      min-width: auto;
    }
  }
`;

const DetailDownload = (props: {
  product: ProductBundleType | ProductType | ProductAAEHUDFactoryType;
}) => {
  let product = props.product;
  const isAAEHUDFactory = product.isAAEHUDFactory;
  const isCustomHUD = (product as ProductAAEHUDFactoryType).isCustomHUD;

  if (isAAEHUDFactory) {
    return (
      <ContentBox title="Downloads">
        <SectionInside>
          <Title>Free Tutorials</Title>
          <Buttons>
            <ButtonDownload
              label="AAE HUD Factory v2"
              downloadLink="https://drive.google.com/file/d/1dIh2qhjntrhF2jWmNDHCZYx66X_3tUpK/view?usp=sharing"
            />
            {isCustomHUD && (
              <ButtonDownload
                label="How to design a HUD"
                downloadLink="https://drive.google.com/file/d/1dfHqxwV3ttULlquXtxYqrOo2TjGOefhv/view?usp=sharing"
              />
            )}
          </Buttons>
        </SectionInside>
      </ContentBox>
    );
  }

  return null;
};

export default DetailDownload;
