import { SpriteType } from "./General";

export type ReplicaCategoryType = {
  id: string;
  name: string;
  subtitle?: string;
  index: number; // for sorting
};

export interface ReplicaCategoriesType {
  [key: string]: ReplicaCategoryType;
}

// e.g. 'Early Prop'
export type ReplicaSubVersionType = {
  id: string;
  name: string;
  thumbnail: any;
  gallery?: any[];
  video?: string;
  content?: any;
};

// e.g. 'v6'
export type ReplicaVersionType = {
  isWIP?: boolean;
  thumbnail?: string | SpriteType;
  thumbnailSize?: ThumbnailSize;
  background?: any;
  backgroundVideo?: any;
  version: string;
  isSeries?: boolean;
  page: any; // react component
  dateCreated?: Date;
  datePublished?: Date;
};

export enum ThumbnailSize {
  "L" = "L",
}

export type ReplicaType = {
  id: string;
  name: string;
  origin: string;
  sort?: {
    preferredIndex?: number;
  };

  category: ReplicaCategoryType;

  versions: ReplicaVersionType[];
};
