import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ heightPercentage: string; blend: boolean }>`
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  ${({ heightPercentage }) =>
    heightPercentage &&
    css`
      padding-bottom: ${heightPercentage};
    `}

  video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;

    ${({ blend }) =>
      blend &&
      css`
        mix-blend-mode: screen;
      `}
  }
`

const Video = (props: {
  src: string
  autoPlay?: boolean
  heightPercentage?: string
  blend?: boolean
}) => {
  return (
    <Wrapper heightPercentage={props.heightPercentage} blend={props.blend}>
      <video
        width="750"
        height="500"
        autoPlay={props.autoPlay}
        muted={props.autoPlay}
        loop={props.autoPlay}
      >
        <source src={props.src} type="video/mp4" />
      </video>
    </Wrapper>
  )
}

export default Video
