import AAEHUDFactory from "pages/AAE-HUD-Factory";
import AAEHUDFactoryFeatures from "pages/AAE-HUD-Factory-Features";
import Cart from "pages/Cart";
import Contact from "pages/Contact";
import EShop from "pages/E-Shop";
import EShopDetail from "pages/E-Shop-Detail";
import FrequentlyAskedQuestions from "pages/Frequently-Asked-Questions";
import Home from "pages/Home";
import MyDownloads from "pages/My-Downloads";
import PayPalCalculatorPage from "pages/PayPalCalculator";
import ReplicaDetail from "pages/Replicas-Detail";
import Replicas from "pages/Replicas";
import ReplicasWIP from "pages/Replicas-WIP";
import TermsAndConditions from "pages/Terms-And-Conditions";

export type RouteType = {
  slug: string;
  page: any;
  isSubRoute?: boolean;
  anchors?: any;
};

export type RoutesType = {
  terms: RouteType;
  faq: RouteType;
  cart: RouteType;
  eshop: RouteType;
  eshopProduct: RouteType;
  myDownloads: RouteType;
  paypalCalculator: RouteType;
  aaeHudFactory: RouteType;
  aaeHudFactoryProduct: RouteType;
  aaeHudFactoryFeatures: RouteType;
  replicas: RouteType;
  replicasWIP: RouteType;
  replicaDetail: RouteType;
  contact: RouteType;
  home: RouteType;
  unknown: RouteType;
};

export const routes: RoutesType = {
  eshop: {
    slug: "e-shop",
    page: <EShop />,
  },

  eshopProduct: {
    slug: "e-shop/:id",
    page: <EShopDetail />,
    isSubRoute: true,
  },

  terms: {
    slug: "terms",
    page: <TermsAndConditions />,
    anchors: {
      intro: "intro",
      purchaseGuide: "purchaseGuide",
      helpDesk: "helpDesk",
      license: "license",
      warrantiesAndRefunds: "warrantiesAndRefunds",
    },
  },

  faq: {
    slug: "faq",
    page: <FrequentlyAskedQuestions />,
  },

  cart: {
    slug: "cart",
    page: <Cart />,
  },

  aaeHudFactory: {
    slug: "aae-hud-factory",
    page: <AAEHUDFactory />,
    anchors: {
      intro: "intro",
      products: "products",
      productsAdditional: "productsAdditional",
      about: "about",
      demo: "demo",
    },
  },

  aaeHudFactoryProduct: {
    slug: "aae-hud-factory/:id",
    page: <EShopDetail />,
    isSubRoute: true,
  },

  aaeHudFactoryFeatures: {
    slug: "aae-hud-factory-features",
    page: <AAEHUDFactoryFeatures />,
  },

  replicas: {
    slug: "replicas",
    page: <Replicas />,
  },

  replicasWIP: {
    slug: "replicas-wip",
    page: <ReplicasWIP />,
  },

  replicaDetail: {
    slug: "replicas/:id",
    page: <ReplicaDetail />,
    isSubRoute: true,
  },

  contact: {
    slug: "contact",
    page: <Contact />,
  },

  myDownloads: {
    slug: "my-downloads",
    page: <MyDownloads />,
  },

  paypalCalculator: {
    slug: "paypal-fees",
    page: <PayPalCalculatorPage />,
  },

  // 'home' HAS TO BE THE LAST!
  home: {
    slug: "",
    page: <Home />,
    anchors: {
      intro: "intro",
      replicas: "replicas",
      eshop: "e-shop",
      aaeHudFactory: "aae-hud-factory",
      about: "about",
      contact: "contact",
    },
  },

  unknown: {
    slug: null,
    page: <Home />,
  },
};
