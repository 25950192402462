import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import ReplicaBigTitle from "components/Replicas/ReplicaBigTitle";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import { ReplicaVersionType } from "types";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
let galleryWIP_denix_barrel = [];
let galleryWIP_denix_grips = [];
let galleryWIP_scope_hole = [];
let galleryWIP_flash_suppressor = [];
let galleryWIP_greeblies = [];
let galleryWIP_weathering = [];

try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
  galleryWIP_denix_barrel = importAll(
    (require as any).context(
      "./gallery-wip-denix-barrel",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_denix_grips = importAll(
    (require as any).context(
      "./gallery-wip-denix-grips",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_scope_hole = importAll(
    (require as any).context(
      "./gallery-wip-denix-scope-mount-hole",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_flash_suppressor = importAll(
    (require as any).context(
      "./gallery-wip-flash-suppressor",
      false,
      /\.(png|jpe?g)$/
    )
  );
  galleryWIP_greeblies = importAll(
    (require as any).context("./gallery-wip-greeblies", false, /\.(png|jpe?g)$/)
  );
  galleryWIP_weathering = importAll(
    (require as any).context(
      "./gallery-wip-weathering",
      false,
      /\.(png|jpe?g)$/
    )
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 2, month: 7, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This prop replica is not in the same vein as my usual projects, in that
        it is not something that I made entirely from scratch. I made some of
        the parts, though, and I think it could be an interesting read. This is
        the blaster that Luke Skywalker uses in Episode 5, when he comes to
        Cloud City on the planet Bespin (hence the name of the prop) to save his
        friends.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(gallery)} />
      <ReplicaSpacer />

      <ReplicaBigTitle title="Making Of" />
      <ReplicaParagraph>
        When I was a child, I was always much more focused on lightsabers, so
        blasters never really received too much attention from me. At least not
        to the same extent as lightsabers. However, since I started to look into
        them, I found wealth of information about the different DL-44 (the
        blaster in question) versions throughout the films and I was immediately
        intrigued. The original prop used a real gun, Mauser C96, as a base for
        the prop. This gives the blaster a realistic gun-like appearance and
        makes it look authentic. Like many other people starting with DL-44s, I
        ordered a Denix full-metal replica of the gun, so I could make my own
        blaster.
      </ReplicaParagraph>

      <ReplicaParagraph>
        I was surprised how many different iterations of DL-44s there are
        throughout the films, so I had to decide which version I'll be making.
        My original plan was to try and machine all the parts myself, including
        the scope, scope mount, etc. I originally intended to create Han's most
        famous version of the blaster, but I quickly realized that making the
        scope by myself would probably prove almost impossible without the right
        tools. In the end, I ordered a replica kit of those parts, together with
        a pre-drilled Denix Mauser C96, so now I have that particular blaster in
        my DetailBundle. However, what about the Denix gun I bought earlier?
      </ReplicaParagraph>

      <ReplicaParagraph>
        I still wanted to make at least some parts of the blaster myself, but I
        went looking for a different version of the prop - why try and create
        something I already have, when I can try my hand at different DL-44
        version and get something new? I ultimately ended up with Luke's Bespin
        DL-44 version in my sights (pun very much intended), which has parts
        that are much easier to machine. I still had to order a replica of the
        scope, because that is a cast part, unless I'm mistaken. Casting metal
        parts is unfortunately above my possibilities, but I wanted to do the
        rest on my own.
      </ReplicaParagraph>

      <ReplicaSubTitleAnchor title="Flash Suppressor" />
      <ReplicaParagraph>
        This was perhaps the most difficult part of the build. Unless I'm
        mistaken, this was actually a real part that the prop makers used for
        the original DL-44 prop. Due to the conical part of the suppressor, I
        had to carefully consider how I'd go about making it on a lathe. If you
        skip a part of the process, you might not be able to go back to it,
        because there's no longer a cylindrical part that the lathe chuck could
        easily grab.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={transformImages(galleryWIP_flash_suppressor)}
        size={GallerySize.S}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        I spent a lot of time looking for the last bit of information - how is
        the flash suppressor actually attached to the barrel? Some versions of
        the DL-44 use a screw to hold the suppressor in place, but there's no
        such thing on this version. After my research, I concluded that the part
        is not held in place by anything - it's just a press fit, which is made
        possible due to the fact that the barrel is conical. However, I wasn't
        comfortable with experimenting with different inner diameters of the
        suppressor to make sure I got a perfect fit. I solved this by
        intentionally machining the hole going through the suppressor a bit
        larger, and then 3D printing a custom "washer" of sorts, which I easily
        adjusted to fit the barrel perfectly using a file. When the washer is
        pressed onto the barrel, it actually expands a bit, which secures the
        suppressor in place. The resulting grip between the gun, the washer and
        the suppressor was surprisingly strong - so strong, in fact, that I do
        not press the suppressor onto the barrel too strongly, because I'm
        afraid I wouldn't be able to take it apart without damaging the parts.
        Also, to make this work, I had to cut off the front sights on the tip of
        the barrel.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_denix_barrel)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Greeblies" />
      <ReplicaParagraph>
        There are these tiny plastic parts glued onto the left side of the gun
        that were kitbashed by the original prop makers. I modelled these as
        precisely as I could after doing some research, and then I 3D printed
        them. There's a tiny difference from the original prop in that there is
        supposed to be some text on the larger greeblie. I actually modelled it,
        but even with the smallest nozzle I could find, I wasn't able to get a
        clear and readable print out of it, so I went with a version without the
        text. I think/hope that I'll be able to live with my decision.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_greeblies)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Wooden Grips" />
      <ReplicaParagraph>
        So far, I have been talking about parts that needed to be made from
        scratch, but now we come to the parts where I had to modify the gun
        (which is how I'll be referring to the Denix replica). I noticed that
        the grips are quite dark on Luke's blaster, much darker than the
        original wooden grips that come with the gun replica. I bought some dark
        mordant for dyeing wood, and applied it to the grips. I was initially
        happy with the results (photographed below), but I later took some
        brown-ish paint a fixed the color a bit, because it wasn't exactly what
        I was looking for.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_denix_grips)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Scope Mount" />
      <ReplicaParagraph>
        I mentioned earlier that the scope assembly is a bought replica kit. I
        still needed to drill a hole into the body of the gun and tap it in
        order to attach the scope to the gun, though. I was nervous about this
        operation, because there's only one chance to get it right, but the
        material turned out to offer less resistance than I was expecting. Once
        this was finished, I was able to mount the scope onto the gun. Since it
        is held in place by just one screw, the scope tended to rotate a bit. I
        thought about different solutions, for example, some people said they
        used a second smaller screw, or glue, to hold it in place. However, I
        found that if I tighten the screw with the scope rotated, and then
        rotate the scope into the correct position (using a bit of force), it
        will tighten the screw even more, and it doesn't move at all - problem
        solved.
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_scope_hole)} />
      <ReplicaSpacer />

      <ReplicaSubTitleAnchor title="Weathering and Painting" />
      <ReplicaParagraph>
        Ever since I discovered chemical blackening, I try to avoid painting
        things as much as possible, because the results are simply incomparable
        when you're trying to achieve a weathered look of metallic parts. I
        started by blackening the suppressor and then taking most of the black
        off using a fine sandpaper, which gave me the weathered look I was
        hoping for. I applied the same process to all the other metal parts of
        the scope, however, the cast part gave me some trouble so I ended up
        painting it black in the end. I originally wasn't happy that I had to
        paint it, but it turned out that the combination of the first blackened
        layer with the paint was exactly what I was looking for, who would have
        thought...
      </ReplicaParagraph>
      <ReplicaSpacer />
      <Gallery images={transformImages(galleryWIP_weathering)} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Once I was finished with the individual parts, I assembled everything
        together. I then gave the blaster a final weathering pass, using small
        files and different sandpapers, which made it all feel like a single
        piece - Luke's Bespin DL-44.
      </ReplicaParagraph>
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
