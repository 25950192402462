import { createGlobalStyle } from 'styled-components'

import { defaultHeadings } from './Headings'
import { defaultButtons } from './Buttons'
import { defaultText } from './Text'
import { defaultLinks } from './Links'
import { defaultLists } from './Lists'
import { defaultImages } from './Images'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;

    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: ${props => props.theme.white};
    background: ${props => props.theme.black};
    
    /* SCROLLBAR */
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: ${props => props.theme.white}22;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${props => props.theme.white}88;
      &:hover {
      background: ${props => props.theme.white}99;
      }
    }
  }

  ${defaultHeadings}
  ${defaultButtons}
  ${defaultText}
  ${defaultLinks}
  ${defaultLists}
  ${defaultImages}
`

export default GlobalStyle
