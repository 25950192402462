import AdvancedPotionMaking from "./hp-advanced-potion-making";
import DailyProphet_HP1_BreakInAtGringotts from "./hp-daily-prophets/hp-daily-prophet-hp1-break-in-at-gringotts";
import DailyProphet_HP2_FlyingFordAngliaMystifiesMuggles from "./hp-daily-prophets/hp-daily-prophet-hp2-flying-ford-anglia-mystifies-muggles";
import DailyProphet_HP3_EscapeFromAzkaban from "./hp-daily-prophets/hp-daily-prophet-hp3-escape-from-azkaban";
import DailyProphet_HP3_GrandPrizeWinnerVisitsEgypt from "./hp-daily-prophets/hp-daily-prophet-hp3-grand-prize-winner-visits-egypt";
import DailyProphet_HP3_SiriusBlackSighted from "./hp-daily-prophets/hp-daily-prophet-hp3-sirius-black-sighted";
import DailyProphet_HP4_BulgariaVsIreland from "./hp-daily-prophets/hp-daily-prophet-hp4-bulgaria-vs-ireland";
import DailyProphet_HP4_SpecialEditionTriwizardTournament from "./hp-daily-prophets/hp-daily-prophet-hp4-special-edition-triwizard-tournament";
import DailyProphet_HP4_TerrorAtTheQuidditchWorldCup from "./hp-daily-prophets/hp-daily-prophet-hp4-terror-at-the-quidditch-world-cup";
import DailyProphet_HP5_DumbledoreDaftOrDangerous from "./hp-daily-prophets/hp-daily-prophet-hp5-dumbledore-daft-or-dangerous";
import DailyProphet_HP5_HeWhoMustNotBeNamedReturns from "./hp-daily-prophets/hp-daily-prophet-hp5-he-who-must-not-be-named-returns";
import DailyProphet_HP5_MassBreakoutFromAzkaban from "./hp-daily-prophets/hp-daily-prophet-hp5-mass-breakout-from-azkaban";
import DailyProphet_HP5_TheBoyWhoLies from "./hp-daily-prophets/hp-daily-prophet-hp5-the-boy-who-lies";
import DailyProphet_HP6_BridgeCollapseDeathTollRises from "./hp-daily-prophets/hp-daily-prophet-hp6-bridge-collapse-death-toll-rises";
import DailyProphet_HP6_DeathEaterTerrorContinues from "./hp-daily-prophets/hp-daily-prophet-hp6-death-eater-terror-continues";
import DailyProphet_HP7_DarkMarkSparksPanic from "./hp-daily-prophets/hp-daily-prophet-hp7-dark-mark-sparks-panic";
import DailyProphet_HP7_DumbledoresDarkSecretsRevealed from "./hp-daily-prophets/hp-daily-prophet-hp7-dumbledores-dark-secrets-revealed";
import DailyProphet_HP7_HarryPotterUndesirableNo1 from "./hp-daily-prophets/hp-daily-prophet-hp7-harry-potter-undesirable-no-1";
import DailyProphet_HP7_NewHeadmasterForHogwarts from "./hp-daily-prophets/hp-daily-prophet-hp7-new-headmaster-for-hogwarts";
import ElderWand from "./hp-elder-wand";
import HarrysWand from "./hp-harrys-wand";
import MaraudersMap from "./hp-marauders-map";
import TalesOfBeedleTheBard from "./hp-tales-of-beedle-the-bard";
import VoldemortsWand from "./hp-voldemorts-wand";

// WIP REPLICAS WILL BE FILTERED OUT OF THE LIST OF REPLICAS
// AND WILL BE ACCESSIBLE THROUGH /replicas-wip URL
const replicas = [
  MaraudersMap,
  TalesOfBeedleTheBard,
  AdvancedPotionMaking,
  DailyProphet_HP7_NewHeadmasterForHogwarts,
  DailyProphet_HP7_HarryPotterUndesirableNo1,
  DailyProphet_HP7_DumbledoresDarkSecretsRevealed,
  DailyProphet_HP7_DarkMarkSparksPanic,
  DailyProphet_HP6_DeathEaterTerrorContinues,
  DailyProphet_HP6_BridgeCollapseDeathTollRises,
  DailyProphet_HP5_HeWhoMustNotBeNamedReturns,
  DailyProphet_HP5_MassBreakoutFromAzkaban,
  DailyProphet_HP5_DumbledoreDaftOrDangerous,
  DailyProphet_HP5_TheBoyWhoLies,
  DailyProphet_HP4_SpecialEditionTriwizardTournament,
  DailyProphet_HP4_TerrorAtTheQuidditchWorldCup,
  DailyProphet_HP4_BulgariaVsIreland,
  DailyProphet_HP3_SiriusBlackSighted,
  DailyProphet_HP3_EscapeFromAzkaban,
  DailyProphet_HP3_GrandPrizeWinnerVisitsEgypt,
  DailyProphet_HP2_FlyingFordAngliaMystifiesMuggles,
  DailyProphet_HP1_BreakInAtGringotts,
  HarrysWand,
  ElderWand,
  VoldemortsWand,
];

export default replicas;
