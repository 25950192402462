import { paypal, routes } from "config";
import { useDispatch, useSelector } from "react-redux";

import { Check } from "@styled-icons/boxicons-regular/";
import Checkbox from "react-custom-checkbox";
import LayoutPage from "layouts/LayoutPage";
import { Link } from "react-router-dom";
import PayPalButton from "components/PayPalButton";
import ProductListCart from "components/ProductListCart";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_hand.jpg";
import { getShoppingCart } from "store/selectors";
import { removeFromCart as removeFromCartAction } from "store/actions";
import styled from "styled-components";
import theme from "styles/theme";
import { useState } from "react";

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 0px;

  ${(props) => props.theme.breakpoint.M} {
    width: 95%;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
`;

const Totals = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid ${(props) => props.theme.white}66;
  gap: 10px;

  & > * {
    opacity: 0.5;
    &:last-child {
      opacity: 1;
    }
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
  color: ${(props) => props.theme.red};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding-top: 15px;
  gap: 10px;
`;

const CheckboxWrapper = styled.div`
  & * {
    cursor: pointer !important;
  }
`;

const Title = styled.h4`
  margin-top: 20vh;
`;

const Price = styled.div`
  color: ${(props) => props.theme.white}DD;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
  font-weight: 500;
  span {
    margin-bottom: 2px;
    font-size: 0.8em;
    opacity: 0.8;
  }
`;

const PayPalButtonWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  max-width: 400px;
`;

const Spacer = styled.div`
  height: 60px;
`;

const EShop = () => {
  const dispatch = useDispatch();
  const productsInCart = useSelector(getShoppingCart);
  const removeFromCart = (product) => dispatch(removeFromCartAction(product));

  const [termsAgreement, setTermsAgreement] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<any>(null);

  //
  // PRICE CALCULATION
  //
  const priceProductsTotal =
    Math.round(
      productsInCart.map((p) => p.price).reduce((a, b) => a + b, 0) * 100
    ) / 100;

  const priceDiscount = 0;
  const priceDiscounted = priceProductsTotal - priceDiscount;

  const paypalPercentageFee =
    priceDiscounted / (1 - paypal.fees.percentageFee / 100) - priceDiscounted;

  const pricePayPalFees =
    Math.round((paypalPercentageFee + paypal.fees.fixedFee) * 100) / 100;

  const priceTotal =
    Math.round((priceDiscounted + pricePayPalFees) * 100) / 100;

  //
  // BACKGROUND
  //
  let pageBackground = background;
  if (productsInCart?.length) {
    for (const product of productsInCart) {
      if (product.background) {
        pageBackground = product.background;
        break;
      }
    }
  }

  //
  // RENDER
  //
  return (
    <LayoutPage background={pageBackground}>
      <Wrapper>
        <SectionHeading
          title="Shopping Cart"
          subtitle="Shop for visual effects, 3d models, rigged projects etc."
          link={"/" + routes.eshop.slug}
          linkTitle="E-Shop"
        />
        {!productsInCart?.length ? (
          <>
            <Title>Cart is empty</Title>
          </>
        ) : (
          <>
            <Spacer />

            <ProductListCart
              products={productsInCart}
              removeFromCart={removeFromCart}
            />

            <Totals>
              <Row>
                <span>Products Total:</span>
                <Price>
                  <span>$</span>
                  {priceProductsTotal}
                </Price>
              </Row>
              <Row>
                <span>PayPal Fees:</span>
                <Price>
                  <span>$</span>
                  {pricePayPalFees}
                </Price>
              </Row>
              <Row>
                <span>Total:</span>
                <Price>
                  <span>$</span>
                  {priceTotal}
                </Price>
              </Row>
            </Totals>
            <PaymentWrapper>
              <CheckboxWrapper>
                <Checkbox
                  icon={<Check size={20} />}
                  checked={termsAgreement}
                  onChange={setTermsAgreement}
                  borderColor={termsAgreement ? theme.dark : theme.red}
                  borderWidth={1}
                  borderRadius={2}
                  size={25}
                  style={{ transition: "all 0.2s ease" }}
                  label={
                    <div>
                      I agree with the{" "}
                      <Link
                        to={"/" + routes.terms.slug}
                      >{`Terms & Conditions`}</Link>
                    </div>
                  }
                  labelStyle={{ marginLeft: "15px", opacity: 0.6 }}
                />
              </CheckboxWrapper>

              {errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}

              <PayPalButtonWrapper>
                <PayPalButton
                  productsInCart={productsInCart}
                  pricePayPalFees={pricePayPalFees}
                  priceTotal={priceTotal}
                  termsAgreement={termsAgreement}
                  setErrorMessage={setErrorMessage}
                />
              </PayPalButtonWrapper>
            </PaymentWrapper>
          </>
        )}
      </Wrapper>
    </LayoutPage>
  );
};

export default EShop;
