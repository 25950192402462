import { ReplicaType } from "types";
import Version1 from "./version-1";
import replicaCategories from "assets/replicas/_categories";

const data: ReplicaType = {
  id: "sw-lightsaber-starkiller-tfu",
  name: `Lightsaber\nStarkiller / Galen Marek`,
  origin: "Star Wars: The Force Unleashed",

  category: replicaCategories.starWars,

  versions: [Version1],
};

export default data;
