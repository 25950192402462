import { Redirect, useLocation, useParams } from "react-router-dom";

import DetailDownload from "./DetailDownload";
import DetailExplodedView from "./DetailExplodedView";
import DetailGallery from "./DetailGallery";
import DetailHeading from "./DetailHeading";
import DetailIncludedInBundles from "./DetailIncludedInBundles";
import DetailInfo from "./DetailInfo";
import DetailRelatedProducts from "./DetailRelatedProducts";
import DetailText from "./DetailText";
import DetailVideos from "./DetailVideos";
import DetailWhatsInside from "./DetailWhatsInside";
import LayoutPage from "layouts/LayoutPage";
import { ProductAAEHUDFactoryType } from "types";
import products from "assets/products";
import { routes } from "config";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const EShopDetail = () => {
  const { id } = useParams();
  const location = useLocation();

  let product = products.find((p) => p.id === id);
  if (!product) {
    return location.pathname.includes(routes.aaeHudFactory.slug) ? (
      <Redirect to={"/" + routes.aaeHudFactory.slug} />
    ) : (
      <Redirect to={"/" + routes.eshop.slug} />
    );
  }

  const isAAEHUDFactory = product.isAAEHUDFactory;
  const isCustomHUD = (product as ProductAAEHUDFactoryType).isCustomHUD;

  return (
    <LayoutPage
      key={id}
      background={product.background}
      backgroundVideo={product.backgroundVideo}
      backgroundVideoFade={isAAEHUDFactory ? (isCustomHUD ? 0.1 : 0.15) : 0}
    >
      <Wrapper>
        <DetailHeading product={product} />
        <DetailInfo product={product} />
        <DetailExplodedView product={product} />
        <DetailWhatsInside product={product} />
        <DetailVideos product={product} />
        <DetailGallery product={product} />
        <DetailDownload product={product} />
        <DetailText product={product} />
        <DetailIncludedInBundles product={product} />
        <DetailRelatedProducts product={product} />
      </Wrapper>
    </LayoutPage>
  );
};

export default EShopDetail;
