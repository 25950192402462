import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import SectionHeading from "components/SectionHeading";
import background from "assets/background/background_planet-03_bottom.jpg";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;
`;

const Spacer = styled.div`
  height: 3vh;
  ${(props) => props.theme.breakpoint.S} {
    height: 8vh;
  }
`;

const Content = styled.p`
  width: 90%;
  max-width: 800px;
  text-align: justify;
`;

const Intro = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={background}>
      <Wrapper>
        <SectionHeading
          title="Terms And Conditions"
          subtitle={[
            "Everything about purchases, license, problem solving, ...",
            "Important to know",
          ]}
        />

        <Spacer />
        <Content>
          {`BY CLICKING “I agree with the Terms & Conditions", PURCHASING A
          PRODUCT OR OTHERWISE ACCESSING OR USING ANY OF THE SERVICES AND
          PRODUCTS, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED
          TO BE BOUND BY THESE TERMS AND CONDITIONS. You also represent that you
          have the legal authority to accept the terms on behalf of yourself and
          any party you represent in connection with your use of any services.
          If you do not agree to the terms, you are not authorized to use any
          services. If you are an individual who is entering into these terms on
          behalf of an entity, you represent and warrant that you have the power
          to bind that entity, and you hereby agree on that entity’s behalf to
          be bound by these terms, with the terms “you,” and “your” applying to
          you, that entity, and other users accessing the services on behalf of
          that entity.`}
        </Content>

        <Spacer />

        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="Continue Reading"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Intro;
