import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => props.theme.breakpoint.M} {
    margin-top: -30px;
  }
`;

const ReplicaContent = (props: { children: any }) => {
  return <Wrapper>{props.children}</Wrapper>;
};

export default ReplicaContent;
