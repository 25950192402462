import styled from 'styled-components'
import LightboxWrapper from 'components/Media/LightboxWrapper'

const Wrapper = styled.div`
  flex: 1;
  margin: 10px 0;

  img {
    width: 100%;
  }
`

const Image = (props: { images: string[] }) => {
  return (
    <LightboxWrapper>
      <Wrapper>
        {props.images.map((src, i) => (
          <img key={i} className="clickable" src={src} alt="jan hamernik" />
        ))}
      </Wrapper>
    </LightboxWrapper>
  )
}

export default Image
