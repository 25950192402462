import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import styled from "styled-components";

let images = [];
try {
  images = importAll(
    (require as any).context("./images", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 3, month: 6, year: 2011 }),
  version: "1",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const progressImages = transformImages(images);

  const images1 = [...progressImages].splice(0, 2);
  const images2 = [...progressImages].splice(2, 2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        If you've seen the rest of my webpage, you know that I specialize mainly
        in visual effects and 3D graphics. As time went by, I became more
        skilled in 2D graphics, and some images of the official replica
        resurfaced on the internet. They were not nearly as good as you can find
        now, no scans, they were not meant to be printed as a replica of the
        replica, they were just some casual photos that people took. I was
        pondering the idea of creating the map digitally for some time now, so I
        took the opportunity that I finally had some reference images, and I
        started working on my v1 replica (that's what I call this one).
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images1} />
      <SpacerContent />

      <ReplicaParagraph>
        I had a lot of decision-making in front of me. Since this was the very
        first time I attempted to do something like this digitally, I was
        completely unprepared and most of the choices I made didn't make it into
        the final prop that you can see today. I've decided to make the map
        completely digital, that's about the only decision that stayed with me
        the whole time. I found a font that was the most similar to the one used
        in the map. I say 'the most similar', but the font is still very
        different, you just cannot mistake it for the original. Since I had to
        figure all the things out, I didn't care much about the font at the
        time. I also decided to print the parchment texture on a white paper,
        but most importantly, the resolution of the individual pages was not
        enough for an HD print, as you can see below - the details are
        pixelated. As you can see on the image above, I've created two of the
        props - the one on the right is a prototype without the back sides. The
        other one had the back sides glued to the front sides, it was not
        printed on the same paper. This made the paper way too thick.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images2} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
