import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
import { useImageLoaded, useScrollPercentage } from "hooks";

const Wrapper = styled.div`
  --paddingTop: 14vh;
  --paddingBottom: 20vh;

  flex: 1;
  padding: 0 4vw;
  padding-top: var(--paddingTop);
  padding-bottom: var(--paddingBottom);
  min-height: calc(100vh - var(--paddingTop) - var(--paddingBottom));
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
  overflow-y: auto;
`;

const BackgroundWrapper = styled.div<{
  scrollPercentage: number;
  backgroundVideoFade: number;
}>`
  transition: all 0.4 ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  opacity: 0.5;
  ${(props) => props.theme.breakpoint.M} {
    opacity: 0.75;
  }

  --fadePercentage: 10%;
  -webkit-mask-image: linear-gradient(
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );
  mask-image: linear-gradient(
    transparent,
    #000 calc(var(--fadePercentage)),
    #000 calc(100% - var(--fadePercentage)),
    transparent
  );

  ${({ scrollPercentage }) =>
    scrollPercentage > 0 &&
    css`
      filter: blur(${(scrollPercentage / 100) * 2}px);
    `}

  ${({ backgroundVideoFade }) =>
    backgroundVideoFade &&
    backgroundVideoFade > 0 &&
    css`
      opacity: ${backgroundVideoFade} !important;
    `}
`;

const Background = styled.div<{ isLoaded?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.6s ease;

    ${(props) =>
      props.isLoaded
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
            transform: scale(1.05);
          `}
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.6s ease;

    ${(props) =>
      props.isLoaded
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  }
`;

const Content = styled.div`
  margin: auto;
  width: 100%;
  flex: 1;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LayoutPage = (props: {
  children: any;
  background?: any;
  backgroundVideo?: any;
  backgroundVideoFade?: number;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { imageRef, imageLoaded } = useImageLoaded();
  const scrollPercentage = useScrollPercentage();

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <Wrapper>
      <BackgroundWrapper
        scrollPercentage={scrollPercentage}
        backgroundVideoFade={props.backgroundVideo && props.backgroundVideoFade}
      >
        {props.background && (
          <Background isLoaded={isLoaded && imageLoaded}>
            <img src={props.background} alt="" ref={imageRef} />
          </Background>
        )}
        {props.backgroundVideo && (
          <Background isLoaded={isLoaded}>
            <video width="750" height="500" autoPlay muted loop>
              <source src={props.backgroundVideo} type="video/mp4" />
            </video>
          </Background>
        )}
      </BackgroundWrapper>

      <Content>{props.children}</Content>
    </Wrapper>
  );
};

export default LayoutPage;
