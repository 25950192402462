import { css } from "styled-components";
import { paragraphCss } from "./Text";

//
// Default Styles
//

export const defaultLists = css`
  ol,
  ul,
  li {
    ${paragraphCss}

    color: ${(props) => props.theme.white}88;
    margin: 2px 0;

    ${(props) => props.theme.breakpoint.M} {
      padding-left: 5px;
    }

    * {
      margin-bottom: 15px;
    }
  }

  li ul {
    margin-top: 8px;
  }

  li li {
    color: ${(props) => props.theme.white}55;

    margin-bottom: 8px;
    * {
      margin-bottom: 8px;
    }
  }
  li li li {
    color: ${(props) => props.theme.white}44;
  }
`;
