import styled from 'styled-components'

const List = styled.ul`
  li {
    margin-bottom: 30px;
    p {
      margin: 0;
      text-align: justify;
    }
  }
`

const Title = styled.div`
  font-size: 1.1em;
  margin-bottom: 10px;
`

const ReplicaFeatureList = (props: {
  features: { title: string; description: any }[]
}) => {
  //
  // RENDER
  //
  return (
    <List>
      {props.features.map((item, i) => (
        <li key={i}>
          <Title>{item.title}</Title>
          <p>{item.description}</p>
        </li>
      ))}
    </List>
  )
}

export default ReplicaFeatureList
