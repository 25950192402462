import { ProductAAEHUDFactoryType } from "types";
import background from "./background.jpg";
import backgroundVideo from "./background-video.mp4";
import { importAll } from "utils";
import licenses from "assets/products/_licenses";
import productCategories from "assets/products/_categories";
import productGroups from "assets/products/_groups";
import thumbnail from "./thumbnail.png";

const id = "Mark-5-HUD";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

let widgetImages = [];
try {
  widgetImages = importAll(
    (require as any).context("./widgets", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const data: ProductAAEHUDFactoryType = {
  purchase: {
    downloadLink:
      "https://drive.google.com/file/d/1XA9nZ6gTV_ONguo9f2LrD1qcXxNU-S4h/view?usp=sharing",
    additionalDownload: {
      name: "AAE HUD Factory v2.0 - HUD Interface",
      description:
        "This is the base project for all AAE HUD Factory v2.0 products. Make sure to download it together with the HUD product(s).",
      downloadLink:
        "https://drive.google.com/file/d/1yuYMmcw0YCFat6kbCmYl2nydgNTv_wBZ/view?usp=sharing",
    },
  },
  thumbnail,
  background,
  backgroundVideo,
  isAAEHUDFactory: true,
  id: id,
  name: "Mark 5 HUD",

  price: 69,
  softwareRequired: ["Adobe After Effects CS6"],

  categories: [productCategories.hud],
  groups: [productGroups.ironMan],
  license: licenses.editorial,

  images: gallery,
  videos: [{ src: "https://www.youtube.com/watch?v=-HqcKJIwKkE", note: "" }],

  tags: [
    `${Object.keys(widgetImages).length} HUD Widgets`,
    "Advanced Rig",
    "Free Tutorials",
  ],

  widgets: !widgetImages
    ? []
    : Object.entries(widgetImages).map((entries) => {
        const filename = entries[0].replace(`${id}-`, "").replace(".jpg", "");
        return { name: filename, src: entries[1].default };
      }),
};

export default data;
