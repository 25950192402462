import AnimatedSprite, {
  AnimatedSpritePlay,
} from "components/Media/AnimatedSprite";
import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
  ReplicaType,
  ThumbnailSize,
} from "types";
import styled, { css } from "styled-components";

const Background = styled.div`
  --mix-dark: 0.9; // 0-1
  --mix-dark-on-hover: 0.85; // 0-1

  --opacity: 0.8;
  --opacity-on-hover: 0.8;

  position: absolute;
  width: 70%;
  height: 60%;
  top: 20%;
  background: ${(props) => props.theme.white};
  opacity: var(--opacity);
  transition: all 0.2s ease;

  &:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props.theme.black};
    opacity: var(--mix-dark);
    transition: all 0.2s ease;
  }
`;

const Thumbnail = styled.div<{ size?: ThumbnailSize; wide?: boolean }>`
  position: absolute;
  width: 90%;
  height: 80%;
  top: 0;

  ${(props) =>
    props.size === ThumbnailSize.L &&
    css`
      width: 110%;
      height: 100%;
      top: -7%;
    `}

  --shadow-blur: 1rem;
  --shadow-offset-y: 1rem;
  --saturate: 0.8;
  filter: drop-shadow(
      0 var(--shadow-offset-y) var(--shadow-blur)
        ${(props) => props.theme.black}88
    )
    saturate(var(--saturate));
  transition: all 0.2s ease;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const BundleThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BundleThumbnailSize = styled.div`
  opacity: 0.5;
  pointer-events: none !important;
`;

const BundleSubThumbnail = styled.div<{
  index: number;
  zIndex: number;
  stepXPercentage: number;
  stepScalePercentage: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${(props) =>
    css`
      filter: drop-shadow(0 0 8px ${props.theme.black});
      z-index: ${props.zIndex};
      transform: translateX(
          calc(${`${props.index} * ${props.stepXPercentage}%`})
        )
        scale(${props.stepScalePercentage}%);
    `}
`;

const Title = styled.div`
  position: absolute;
  top: 82%;
  width: 70%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: pre-wrap;
  line-height: 14px;

  --letterSpacing: 0.1em;
  letter-spacing: var(--letterSpacing);
  text-indent: var(--letterSpacing);

  color: ${(props) => props.theme.white};
`;
const Name = styled.div`
  opacity: 0.7;
`;

const Category = styled.div`
  font-size: 0.8em;
  margin-top: 5px;
  opacity: 0.4;
  min-height: 9px;
`;

const LabelWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 15%;
  left: 15%;
  border-radius: 2px;
  overflow: hidden;
  background: ${(props) => props.theme.black};
`;

const Label = styled.div`
  padding: 1px 8px;
  background: ${(props) => props.theme.white}AA;
  color: ${(props) => props.theme.black};

  font-size: 10px;
  font-weight: 800;
  transition: all 0.2s ease;
`;

const Wrapper = styled.div<{ thin: boolean; wide: boolean }>`
  background: none;
  border: none;
  outline: none;
  position: relative;
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ wide }) =>
    wide &&
    css`
      width: 370px;
    `}

  ${({ thin }) =>
    thin &&
    css`
      width: 190px;
      height: 270px;
    `}

&:hover {
    ${Background} {
      opacity: var(--opacity-on-hover);

      &:after {
        opacity: var(--mix-dark-on-hover);
      }
    }
    ${Label} {
      background: ${(props) => props.theme.white};
    }
  }

  ${(props) => props.theme.breakpoint.M} {
    margin: 0 -30px;
  }
`;

type Product =
  | ProductType
  | ProductBundleType
  | ProductAAEHUDFactoryType
  | (ReplicaType & {
      thumbnail: any;
      thumbnailSize?: ThumbnailSize;
    });

const ProductItem = (props: {
  product: Product;
  wide?: boolean;
  thin?: boolean;
  subtitle?: string;
  label?: string;
}) => {
  const renderImg = (product: Product) => {
    return (
      <AnimatedSprite
        src={product?.thumbnail}
        alt={product.name}
        animation={{
          play: AnimatedSpritePlay.infinite,
          frameDuration_Milliseconds: 50,
        }}
        loadingStyle={{
          cssDefault: css`
            transition: all 0.2s ease-out;
          `,
          cssBeforeLoad: css`
            opacity: 0;
            transform: translateY(10px);
          `,
        }}
      />
    );
  };

  const renderThumbnail = () => {
    const bundle = props.product as ProductBundleType;

    if (bundle?.isBundle && !bundle?.thumbnail) {
      return (
        <BundleThumbnail>
          <BundleThumbnailSize>
            {renderImg(bundle.products[0]?.thumbnail)}
          </BundleThumbnailSize>

          {(bundle.generatedThumbnail?.reverseOrder
            ? [...bundle.products].reverse()
            : bundle.products
          )?.map((p, i) => {
            const productCount = bundle.products.length;

            const centerIndex = (productCount - 1) / 2;
            const index = i - centerIndex;

            let zIndex = 100 - Math.abs(index);
            if (productCount === 2 && i === 1) {
              zIndex = 50;
            }

            const scale = bundle.generatedThumbnail?.scale || 1;
            const stepXPercentage = 100 / (productCount + 2);
            const stepScalePercentage =
              scale * (productCount === 2 ? 100 : 100 - Math.abs(index) * 10);

            return (
              <BundleSubThumbnail
                key={i}
                index={index}
                zIndex={zIndex * 2} // *2 to get rid of decimal places
                stepXPercentage={stepXPercentage}
                stepScalePercentage={stepScalePercentage}
              >
                {renderImg(p)}
              </BundleSubThumbnail>
            );
          })}
        </BundleThumbnail>
      );
    }

    return renderImg(props.product);
  };

  //
  // RENDER
  //
  return (
    <Wrapper wide={props.wide} thin={props.thin}>
      <Background />

      <Thumbnail size={(props.product as any).thumbnailSize} wide={props.wide}>
        {renderThumbnail()}
      </Thumbnail>

      <Title>
        <Name>{props.product.name}</Name>
        {props.subtitle || props.subtitle === "" ? (
          <Category>{props.subtitle}</Category>
        ) : null}
      </Title>

      {props.label || props.label === "" ? (
        <LabelWrapper>
          <Label>{props.label}</Label>
        </LabelWrapper>
      ) : null}
    </Wrapper>
  );
};

export default ProductItem;
