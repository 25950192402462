import Background from "assets/background/background_aae_hud_factory_section_huds.jpg";
import ButtonScrollToSection from "components/Interaction/ButtonScrollToSection";
import LayoutFullscreen from "layouts/LayoutFullscreen";
import ProductItem from "components/ProductItem";
import SectionSubHeading from "components/SectionSubHeading";
import { productsAAEHUDFactory } from "assets/products";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(5vh + 80px);
  padding-bottom: 5vh;

  ${(props) => props.theme.breakpoint.XL} {
    max-width: 1600px;
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Spacer = styled.div`
  height: 3vh;
`;

const Products = (props: { onNavClick: () => void }) => {
  return (
    <LayoutFullscreen background={Background} backgroundOpacity={0.6}>
      <Wrapper>
        <SectionSubHeading
          title="Standalone HUDs"
          description="Complete HUD interfaces with detailed tutorials (download tutorials
            for free), GUI panel and complex rigs that are VERY easy to use. Every
            HUD is a standalone product, but they can be easily combined. Every
            widget is automatically repainted to fit the look of the HUD package
            you're currently using. Everything is designed to 'just work'."
        />
        <ProductsWrapper>
          {productsAAEHUDFactory
            .filter((product) => !product.isAdditionalProduct)
            .map((product, i) => (
              <ProductItem key={i} product={product} wide />
            ))}
        </ProductsWrapper>

        <Spacer />
        <ButtonScrollToSection
          onClick={props.onNavClick}
          label="More Products"
        />
      </Wrapper>
    </LayoutFullscreen>
  );
};

export default Products;
