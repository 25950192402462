import {
  ProductAAEHUDFactoryType,
  ProductBundleType,
  ProductType,
} from "types";
import {
  addToCart as addToCartAction,
  removeFromCart as removeFromCartAction,
} from "store/actions";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { AttachMoney } from "@styled-icons/material-twotone/";
import { CartAdd } from "@styled-icons/boxicons-solid";
import { CloseOutline } from "@styled-icons/evaicons-outline/";
import { Link } from "react-router-dom";
import PayPalLogo from "assets/misc/logos/paypal-logo.png";
import { getShoppingCart } from "store/selectors";
import { routes } from "config";
import theme from "styles/theme";

const colorAdd = theme.green;
const colorRemove = theme.red;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;

  a {
    flex: 1;
  }
`;

const Button = styled.button<{
  isremovefromcart: boolean;
  isgotocheckout: boolean;
}>`
  width: 100%;
  padding: 5px;
  min-height: 56px;
  letter-spacing: 0.15em;
  background: ${`${colorAdd}88`};
  border-color: ${`${colorAdd}22`};
  &:hover {
    background: ${`${colorAdd}AA`};
  }

  ${({ isremovefromcart }) =>
    isremovefromcart &&
    css`
      height: calc(100% + 2px);
      width: 25%;
      background: ${`${colorRemove}88`};
      border: ${`${colorRemove}22`};
      &:hover {
        background: ${`${colorRemove}AA`};
      }
    `};

  ${({ isgotocheckout }) =>
    isgotocheckout &&
    css`
      flex: 1;
    `};
`;

const SecuredBy = styled.p`
  margin-top: 20px;
  color: ${(props) => props.theme.white}88;
  display: flex;
  align-items: center;
  img {
    width: 50px;
    filter: saturate(0) brightness(5);
    opacity: 0.6;
    margin-left: 5px;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Price = styled.div`
  color: ${(props) => props.theme.green}DD;
  display: flex;
  align-items: flex-end;
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 15px;
  span {
    margin-bottom: 2px;
    font-size: 0.8em;
    opacity: 0.8;
  }
`;
const PricePrevious = styled.div`
  color: ${(props) => props.theme.red}DD;
  margin-right: 5px;
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
`;

const AddToCartButton = (props: {
  product: ProductType | ProductBundleType | ProductAAEHUDFactoryType;
}) => {
  const dispatch = useDispatch();

  let product = props.product;
  const isBundle = product.isBundle;

  let previousPrice = null;

  if (!isBundle) {
    previousPrice = props.product.previousPrice;
  }
  if (isBundle) {
    product = props.product as ProductBundleType;
    let priceAggregated = 0;
    product.products.forEach((product) => {
      priceAggregated += product.price;
    });
    //const priceSaved = priceAggregated - product.price
    previousPrice = priceAggregated;
  }

  //
  // SHOPPING CART
  //
  const shoppingCart = useSelector(getShoppingCart);
  const isInCart = shoppingCart.map((p) => p.id).includes(product.id);

  const addToCart = () => dispatch(addToCartAction(product));
  const removeFromCart = () => dispatch(removeFromCartAction(product));

  //
  // RENDER
  //
  return (
    <Wrapper>
      <PriceWrapper>
        {previousPrice && (
          <Price>
            <PricePrevious>
              <span>$</span>
              {previousPrice}
            </PricePrevious>
          </Price>
        )}
        <Price>
          <span>$</span>
          {props.product.price}
        </Price>
      </PriceWrapper>
      {isInCart ? (
        <ButtonRow>
          <Button onClick={removeFromCart} isremovefromcart>
            <CloseOutline />
          </Button>

          <Link to={"/" + routes.cart.slug}>
            <Button isgotocheckout>
              <AttachMoney />
              Checkout
            </Button>
          </Link>
        </ButtonRow>
      ) : (
        <Button onClick={addToCart}>
          <CartAdd />
          Add to cart
        </Button>
      )}
      <SecuredBy>
        Secured by <img src={PayPalLogo} alt="paypal" />
      </SecuredBy>
    </Wrapper>
  );
};

export default AddToCartButton;
