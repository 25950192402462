import { createDate, importAll, transformImages } from "utils";

import Gallery from "components/Media/Gallery";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import { ReplicaVersionType } from "types";
import styled from "styled-components";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

const SpacerContent = styled.div`
  height: 2vh;
`;

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 27, month: 7, year: 2019 }),
  version: "5.1",
  isSeries: false,
  background: null,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  const images1 = [...galleryImages].splice(0, 2);
  const images2 = [...galleryImages].splice(2, 2);
  const images3 = [...galleryImages].splice(4, 2);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        A curious number - 5.1. This indicates that this version is not
        sufficiently different from the previous one to earn a new number (v6).
        So, what's the difference? There are two very distinctive differences.
        The first one is the multipage Hogwarts grounds design - the v5.0
        version had the original design twice (yep, the same design). This was
        deliberate, because it made the two pages interchangeable when each side
        of the map was opened on a different 'level'. However, after I finished
        the prop, I decided that I wanted to create something more unique. I
        originally intended to create the map of the entire castle as one of the
        pages, just like the rest of the designs, but I left the idea open for
        the time when I decide to create version 6. Once I saw the final prop,
        however, I realized that the castle map would fit perfectly in place of
        the duplicate design, so I went for it (v5.0 on the left, v5.1 on the
        right):
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images1} />
      <SpacerContent />

      <ReplicaParagraph>
        Just in case it's still not clear - the original castle design is
        present in both v5.0 and v5.1, the difference is that v5.0 uses the
        design twice. The other difference is that I drastically redesigned one
        of my own v5.0 page designs including the detail of the headmaster's
        office (featured in the Prisoner of Azkaban as a close up shot):
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images2} />
      <SpacerContent />

      <ReplicaParagraph>
        The film part with the detail of the headmaster's office was featured in
        one of my previous v4 page designs, but I've decided to get rid of it
        and design a completely new page around it. The first version of the
        v5.0 page is on the left. I wasn't quite pleased with the final look, so
        for the v5.1, I completely redesigned it. You might have noticed that I
        was aiming for a look similar to the Half-Blood Prince page with the
        seventh floor corridor. I like the compass rose on that page, so I
        created a new one with similar design for this page - it's not the same
        design component. Before I made the final prop, I did a lot of test
        prints to establish the final look. I do this with all of my paper
        props, and it's always worth the time and money, because I can be sure
        that I'm printing the final prop with the best settings possible.
      </ReplicaParagraph>

      <SpacerContent />
      <Gallery images={images3} />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
