import Gallery, { GallerySize } from "components/Media/Gallery";
import { createDate, importAll, transformImages } from "utils";

import IMG_1 from "./gallery/gallery_01.jpg";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import { ReplicaVersionType } from "types";
import YouTubeVideo from "components/Media/YouTubeVideo";
import background from "./background.jpg";
import thumbnail from "./thumbnail.png";

let gallery = [];
try {
  gallery = importAll(
    (require as any).context("./gallery", false, /\.(png|jpe?g|svg)$/)
  );
} catch (e) {
  console.warn(e);
}

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 25, month: 9, year: 2023 }),
  datePublished: createDate({ day: 5, month: 10, year: 2023 }),
  version: "1",
  isSeries: false,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const galleryImages = transformImages(gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        The map of Middle Earth appears in one of the very first shots in Peter
        Jackson's adaptation of The Lord of The Rings: The Fellowship of the
        Ring, and it is also used in The Hobbit: An Unexpected Journey to
        establish where everything takes place.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery
        images={[{ src: IMG_1, name: "Middle Earth" }]}
        size={GallerySize.FullWidth}
      />
      <ReplicaSpacer />

      <ReplicaParagraph>
        Middle Earth is full of interesting places, most of which can be seen in
        the films. It goes without saying that all of those places can also be
        found on the map, so you can easily follow the journey of Frodo, Sam,
        Merry and Pippin (and later Aragorn) from The Shire to Rivendell. From
        there, you can trace the path that the Fellowship took through the Mines
        of Moria all the way to the Falls of Rauros, where Frodo and Sam left
        the group (or what was remaining of it). The Dead Marches, Mount Doom
        with Barad-dûr in Mordor, Minas Tirith and Osgiliath in Gondor, Helm's
        Deep in Rohan and all the other famous locations are included. As for
        Bilbo's journey, Erebor can also be found on the map, together with
        Esgaroth, or The Misty Mountains that the dwarves with Gandalf and Bilbo
        had to cross.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <Gallery images={galleryImages} size={GallerySize.S} />
      <ReplicaSpacer />

      <ReplicaParagraph>
        The map is printed on a high-quality large-format printer, and its
        longest side measures almost 80cm (2.6ft) in length. The paper has been
        carefully aged to make the map look as an authentic document that
        belongs to Middle Earth. All the details of the original drawing (made
        by me) have been preserved throughout the printing process, so the map
        looks as if it was written in ink, no pixelation or other flaws
        associated with printing are present.
      </ReplicaParagraph>

      <ReplicaSpacer />
      <YouTubeVideo
        src="https://www.youtube.com/watch?v=imIQ9i6b9OI"
        dimensions={{ width: 1280, height: 860 }}
      />
      <ReplicaSpacer />
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
