//
// DEFS
//
export type BreakpointEnumType = "XS" | "S" | "M" | "L" | "XL";

type BreakpointsType = {
  XL: string;
  L: string;
  M: string;
  S: string;
  XS: string;
};

// dimensions
export const breakpoints: BreakpointsType = {
  XL: "1600px",
  L: "1200px",
  M: "800px",
  S: "600px",
  XS: "400px",
};

// media queries
const breakpoint = {};
Object.entries(breakpoints).forEach((entry) => {
  const size = entry[0];
  const value = entry[1];
  breakpoint[size] = `@media screen and (max-width: ${value})`;
});

export default breakpoint as BreakpointsType;
