import { createDate, transformImages } from "utils";

import FilmComparisons from "./film-comparisons";
import Gallery from "components/Media/Gallery";
import { HashLink } from "react-router-hash-link";
import ReplicaContent from "components/Replicas/ReplicaContent";
import ReplicaFeatureList from "components/Replicas/ReplicaFeatureList";
import ReplicaParagraph from "components/Replicas/ReplicaParagraph";
import ReplicaSpacer from "components/Replicas/ReplicaSpacer";
import ReplicaSubTitleAnchor from "components/Replicas/ReplicaSubTitleAnchor";
import ReplicaSubVersionContent from "components/Replicas/ReplicaSubVersionContent";
import ReplicaSubVersions from "components/Replicas/ReplicaSubVersionList";
import { ReplicaVersionType } from "types";
import V1EarlyProp from "./versions/Version (1) - Early Prop";
import V2PrisonerOfAzkaban from "./versions/Version (2) - Prisoner of Azkaban";
import V3OrderOfThePhoenix from "./versions/Version (3) - Order of the Phoenix";
import V4HalfBloodPrince from "./versions/Version (4) - Half-Blood Prince";
import V5DeathlyHallows from "./versions/Version (5) - Deathly Hallows";
import V6Differences from "./v6-differences";
import V6UltimateMap from "./versions/Version (6) - Ultimate Map";
import V7SnapeInsult from "./versions/Version (7) - Snape Insult";
import Video from "components/Media/Video";
import background from "./background.jpg";
import globalReplicaData from "..";
import thumbnail from "./thumbnail.png";

/* VERSION DATA */
export const VERSION_DATA: ReplicaVersionType = {
  isWIP: false,
  page: undefined,
  dateCreated: createDate({ day: 20, month: 8, year: 2020 }),
  version: "6",
  isSeries: true,
  background,
  thumbnail,
};

/* VERSION PAGE */
const Page = () => {
  const anchors = {
    comparison: "v6-film-comparisons",
    differences: "v6-differences",
  };

  const replicaSubVersions = [
    V1EarlyProp,
    V2PrisonerOfAzkaban,
    V3OrderOfThePhoenix,
    V4HalfBloodPrince,
    V5DeathlyHallows,
    V7SnapeInsult,
    V6UltimateMap,
  ];

  const v6DifferencesGallery = transformImages(V6Differences.gallery);
  const filmComparisonsGallery = transformImages(FilmComparisons.gallery);

  return (
    <ReplicaContent>
      <ReplicaParagraph>
        This is the sixth version of my take on the Marauder's Map. Since I'm
        obsessed with details, it is no wonder that I spent over a decade
        developing the replica, because there's always that one little detail
        that could be better... and I always end up throwing everything out of
        the window and start again from scratch. This time is no different - I
        once again did everything from scratch, focusing on every little detail
        from the film, trying to replicate even the ink stains to get the
        replica as close to the film as possible. The original film prop changed
        over time, so each film has its own version of the map. I always hated
        that I had to pick just one of the versions to incorporate into my
        replica, so this time I went big - I replicated every single version of
        the map (even the ones that did not make it into the film - Order of the
        Phoenix, for example), and even some more.
      </ReplicaParagraph>
      <ReplicaParagraph>
        To the untrained eye, the versions might look the same, but at a closer
        inspection, you'll find out that there are often different designs for
        the same page, sometimes a page is missing or it's replaced by a
        different page that is not featured in the other versions, and often
        times the whole layout is different. Once you scroll down through the
        versions, you'll find the{" "}
        <HashLink to={"#" + anchors.differences} smooth>
          differences explained
        </HashLink>
        , and you'll be able to see that the differences between the versions
        are not that small. After that, you'll be able to see some of the{" "}
        <HashLink to={"#" + anchors.comparison} smooth>
          differences between the official replica and the films
        </HashLink>
        .
      </ReplicaParagraph>

      <ReplicaSubVersions replicaSubVersions={replicaSubVersions} />

      <ReplicaParagraph>
        The version 6 of the Marauder's Map is superior to the previous versions
        in every single aspect - from graphics design to the final printing and
        aging process. Let me take you through some of the most notable changes:
      </ReplicaParagraph>

      <ReplicaFeatureList
        features={[
          {
            title: `Written by hand`,
            description: `For the previous versions of the map, I created a set of fonts and basically "typed" the map's design. This gives the writing a uniform look and it doesn't feel so natural. That is why I ditched the whole idea of using fonts, and actually wrote everything by hand. The process was much more difficult, because I had to basically change my natural style of writing to fit the original map. I often had to take a step back and redesign/rewrite parts of the map I didn't like (mostly my custom designs). The whole design process took several months to finish, but the results are definitely worth every second.`,
          },
          {
            title: `Professionally printed`,
            description: `I realized that printing everything at home is very limiting in terms of quality. No matter how good the design is, if it is not printed in the best resolution available, the overall quality of the replica will suffer. So, for the first time, I trusted a third-party company to print the replica for me.`,
          },
          {
            title: `Authentic aging process`,
            description: `Since I used to print most of my large format stuff on an inkjet printer (which doesn't do so good with water), I was forced to print the aged texture. However, since the new prints are water resistant, I could finally start experimenting with much more authentic ways of aging paper. I spent about three months just aging, aging and aging (paper, although I aged as well), trying to come up with different approaches and methods. This was actually the most difficult part of the whole process, because most of the aging methods I came up with gave me either unreliable results or didn't meet my expectations/requirements. After the three months, I was finally able to come up with my super-secret formula for aging paper and giving it a nice texture - reliably.`,
          },
        ]}
      />

      {/*
       **   VERSION SECTIONS
       */}
      {replicaSubVersions.map((replicaSubVersion, i) => (
        <ReplicaSubVersionContent
          key={i}
          replicaSubVersion={replicaSubVersion}
          replicaName={globalReplicaData.name}
          version={VERSION_DATA.version}
        />
      ))}

      {/*
       **   SECTION - V6 SUB-VERSION DIFFERENCES
       */}
      <ReplicaSubTitleAnchor
        title="What's the Difference?"
        subtitle={`v${VERSION_DATA.version} versions`}
        anchor={anchors.differences}
      />
      <ReplicaParagraph>
        I understand that to a stranger, the different versions of the map could
        look basically the same - not so to someone who spent years poring over
        all the reference pictures and films available, though, then the
        differences are night and day. Some of the page designs (or at least
        some parts of the designs) were often completely changed, this is most
        obvious when you try to compare the official replica with the films -
        some pages are completely different or even missing, because they didn't
        update their replica after Prisoner of Azkaban to accommodate the
        following films and additions to the map. It would take too much time to
        explain all the slight differences, so I put together an overview of
        some of the most notable differences, so you can see for yourself:
      </ReplicaParagraph>

      <ReplicaSpacer />
      {v6DifferencesGallery?.length > 0 && (
        <Gallery images={v6DifferencesGallery} />
      )}
      <ReplicaSpacer />

      <ReplicaParagraph>
        Please keep in mind that the differences shown above are just a small
        selection of all the differences. The images don't even highlight all of
        the differences on the pages, because it would make it harder to see.
        There are not only differences in the designs, but also in the whole
        layout of the map. I put together this little demo where you can see how
        the designs change across the different versions. You won't be able to
        make out all the intricate details, but it is still quite obvious that
        there are some major differences:
      </ReplicaParagraph>

      <Video src={V6Differences.video} blend autoPlay />

      <ReplicaParagraph>
        Overall, the Marauder's Map is one of the longest projects I've ever
        worked on. It's been a part of my life for a lot longer than a decade,
        and I'm honestly quite surprised that I'm still going. But I'm not
        complaining, I really love working on prop replicas, and this is one of
        my most favorite projects. I think that each version brought something
        new to the table, but this latest version (v6) is probably the biggest
        leap forward.
      </ReplicaParagraph>

      {/*
       **   SECTION - COMPARISON WITH FILMS
       */}
      <ReplicaSubTitleAnchor
        title="Comparison With Films"
        subtitle={`v${VERSION_DATA.version} versions vs Films vs Official Replica`}
        anchor={anchors.comparison}
      />
      <ReplicaParagraph>
        Let's take a look at how the replicas hold up when compared to the
        films. I'm also going to demonstrate the differences that the official
        replica has. If you've been following this project for a while, you
        probably already know that I have some issues with the official replica
        - simply because it doesn't represent what we see in the films (and
        because the piece I got was put together very poorly). I used to hate
        this fact, but now, after a few years, I think I have a slightly better
        insight into the reasons behind the differences, so I came to terms with
        it. The comparisons are simply to show you the differences I'm talking
        about.
      </ReplicaParagraph>

      <ReplicaSpacer />
      {filmComparisonsGallery?.length > 0 && (
        <Gallery images={filmComparisonsGallery} />
      )}
    </ReplicaContent>
  );
};

export default {
  ...VERSION_DATA,
  page: <Page />,
} as ReplicaVersionType;
