import { Close } from "styled-icons/remix-line";
import { paypal } from "config";
import styled from "styled-components";
import { useState } from "react";

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  left: 20px;
  background: ${(props) => props.theme.black};
`;

const Content = styled.div`
  background: ${(props) => props.theme.red}99;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Labels = styled.div`
  padding: 5px;
  padding-right: 12px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Label = styled.div`
  color: ${(props) => props.theme.white}88;
  font-size: 10px;
  font-weight: 600;
`;

const CloseButton = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;
  color: ${(props) => props.theme.white}88;
  padding: 8px;

  svg {
    width: 30px;
  }

  &:hover {
    color: ${(props) => props.theme.white};
  }
`;

const EnvWarning = () => {
  const [isOpen, setIsOpen] = useState(true);

  const environment = process.env.REACT_APP_ENVIRONMENT;
  const isProduction = environment === "PRODUCTION";
  const paypalSandbox = paypal.sandbox;

  const showWarning = !isProduction || paypalSandbox;

  return isOpen && showWarning ? (
    <Wrapper>
      <Content>
        <CloseButton onClick={() => setIsOpen(false)}>
          <Close />
        </CloseButton>

        <Labels>
          {paypalSandbox ? <Label>PAYPAL SANDBOX</Label> : null}

          {!isProduction ? (
            <Label>{process.env.REACT_APP_ENVIRONMENT}</Label>
          ) : null}
        </Labels>
      </Content>
    </Wrapper>
  ) : null;
};

export default EnvWarning;
