import { Link } from "react-router-dom";
import ProductItemBase from "components/ProductItemBase";
import { ReplicaType } from "types";
import { checkIsNew } from "utils";
import { routes } from "config";

const ReplicaItem = (props: {
  replica: ReplicaType;
  wide?: boolean;
  thin?: boolean;
  noLabel?: boolean;
}) => {
  const latestVersion = props.replica.versions[0];

  const isNew = checkIsNew(latestVersion.datePublished);

  //
  // RENDER
  //
  return (
    <Link to={`/${routes.replicas.slug}/${props.replica.id}`}>
      <ProductItemBase
        product={{
          ...props.replica,
          thumbnail: latestVersion.thumbnail,
          thumbnailSize: latestVersion.thumbnailSize,
        }}
        wide={props.wide}
        thin={props.thin}
        subtitle={props.replica.category.name}
        label={props.noLabel ? undefined : isNew ? "NEW" : undefined}
      />
    </Link>
  );
};

export default ReplicaItem;
