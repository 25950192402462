import ReplicaParagraph from 'components/Replicas/ReplicaParagraph'
import { ReplicaSubVersionType } from 'types'
import { importAll } from 'utils'
import thumbnail from './src/Version (2) - LOTR - Thumbnail.png'

let gallery = []
try {
  gallery = importAll(
    (require as any).context('./gallery', false, /\.(png|jpe?g|svg)$/)
  )
} catch (e) {
  console.log(e)
}

const data: ReplicaSubVersionType = {
  thumbnail,
  id: 'v2-sub2-lotr',
  name: 'The Lord of the Rings',
  gallery,
  video: null,
  content: (
    <>
      <ReplicaParagraph>
        This is the version of the map as it appeared in The Lord of the Rings:
        The Fellowship of the Ring. I have completely redrawn the design,
        because the Hobbit version is simply different. Actually, there are two
        versions of the map featured in the scene from The Lord of the Rings -
        on of the versions has a completely different style of the mountain
        drawing, which is taken from the book (The Hobbit). I didn't create the
        book version, but, knowing myself, I'll eventually do that one as well.
      </ReplicaParagraph>
    </>
  ),
}

export default data
