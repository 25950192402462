import { useEffect, useRef } from "react";

const useOnWindowResize = (onResize: () => void): void => {
  const prevWidthRef = useRef<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = (): void => {
      const currentWidth = window.innerWidth;

      // Check if viewport width has actually changed
      // This is needed, because mobile scrolling adds additional header to the viewport and triggers resize event
      if (currentWidth !== prevWidthRef.current) {
        prevWidthRef.current = currentWidth;

        // Call the onResize callback
        onResize();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onResize]);
};

export default useOnWindowResize;
