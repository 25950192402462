import AzazelFxPack from "assets/products/individual/Azazel-Fx-Pack";
import NightcrawlerFxPack from "assets/products/individual/Nightcrawler-Fx-Pack";
import { ProductBundleType } from "types";
import background from "./background.jpg";
import { createBundle } from "../bundle-generator";
import thumbnail from "./thumbnail.png";

const data: ProductBundleType = createBundle({
  id: "Nightcrawler-And-Azazel",
  name: "Nightcrawler And Azazel",
  thumbnail,
  background,
  products: [NightcrawlerFxPack, AzazelFxPack],
  discountPercentage: 35,
});

export default data;
